import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { SignupModule } from './signup/signup.module';
import { SigninModule } from './signin/signin.module';
import { PasswordModule } from './password/password.module';

const NG_MODULES = [CommonModule];

const MODULES = [SigninModule, SignupModule, PasswordModule, AuthRoutingModule];

@NgModule({
  imports: [...NG_MODULES, ...MODULES],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
    };
  }
}
