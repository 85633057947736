import { createAction, props } from '@ngrx/store';

import { ImageDto, ImageContent } from '@shared/_models';
import { ImageSize } from '@app/domain/image';

export const loadImageContent = createAction('[Images] Load Image Content', props<{ imageMeta: ImageDto; size?: ImageSize }>());

export const loadImagesContent = createAction('[Images] Load Images Content', props<{ imagesMeta: ImageDto[]; size?: ImageSize }>());

export const loadImageContentSuccess = createAction(
  '[Images] Load Image Content Success',
  props<{ data: ImageContent; size?: ImageSize }>()
);

export const loadImageContentFailure = createAction('[Images] Load Image Content Failure', props<{ id: string; size?: ImageSize }>());

export const handleCachedImage = createAction('[Images] Handle Cached Image', props<{ id: string; size?: ImageSize }>());
