import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//
import { SCHEMAS_API_URL } from './api';

@Injectable({
  providedIn: 'root',
})
export class ApiSchemasService {
  constructor(private http: HttpClient) {}

  load(): Observable<void> {
    return this.http.get<any>(SCHEMAS_API_URL);
  }
}
