import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/pl';
import { DefaultValueAccessor, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, TitleCasePipe } from '@angular/common';

import { APP_SETTINGS_GLOBAL, AppSettings } from './shared/_configuration';
import { environment } from '@environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiSchemasModule } from './api-schemas/api-schemas.module';
import { AuthModule } from './_auth/auth.module';
import { RootStoreModule } from '@shared/_root-store/root-store.module';
import { LabelPipe } from '@shared/pipes/label/label.pipe';
import { SplitPipe } from '@shared/pipes/split.pipe';
import { ToastModule } from '@shared/_modules';
import { ErrorInterceptor, JwtInterceptor } from '@shared/_interceptors';
import { DateFormatter } from './shared/dynamic-form/components/form-date/date-formatter';
import { GlobalErrorHandlerService } from '@shared/_services/global-error-handler.service';
import { RouterStoreModule } from '@app/shared/_root-store/router-store/router-store.module';
import { SortablejsModule } from 'nxt-sortablejs';

const NG_MODULES = [
  BrowserModule,
  HttpClientModule,
  ReactiveFormsModule,
  BrowserAnimationsModule,
  StoreDevtoolsModule.instrument({
    maxAge: 25, // Retains last 25 states
    logOnly: environment.production // Restrict extension to log-only mode
  })
];

const LIB_MODULES = [NgbModule, NgSelectModule];

const MODULES = [
  AuthModule.forRoot(),
  RouterStoreModule,
  AppRoutingModule,
  RootStoreModule,
  ApiSchemasModule,
  ToastModule,
  SortablejsModule.forRoot({ animation: 150 })
];

@NgModule({
  declarations: [AppComponent],
  imports: [...NG_MODULES, ...LIB_MODULES, ...MODULES],
  providers: [
    {
      provide: AppSettings,
      useValue: APP_SETTINGS_GLOBAL
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    DateFormatter,
    { provide: NgbDateParserFormatter, useClass: DateFormatter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'en_US'
    },
    DatePipe,
    LabelPipe,
    TitleCasePipe,
    SplitPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // hack for casting empty (cleared to be exact) input value to null instead of empty string
    // warning: this is global behaviour change!
    // sources:
    // https://github.com/angular/angular/issues/45317
    // https://stackoverflow.com/questions/38527535/set-null-as-empty-string-value-for-input-field
    DefaultValueAccessor.prototype.registerOnChange = function (fn: (_: string | null) => void): void {
      this.onChange = (value: string | null) => {
        fn(value === '' ? null : value);
      };
    };
  }
}
