import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ExperimentsEffects } from './experiments.effects';
import * as fromExperiments from './experiments.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromExperiments.EXPERIMENTS_FEATURE_KEY, fromExperiments.reducer),
    EffectsModule.forFeature([ExperimentsEffects]),
  ],
})
export class ExperimentsStoreModule {}
