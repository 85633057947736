import { Params } from '@angular/router';
import { ceil, isNil, omit } from 'lodash-es';
import { pipe } from './fp/pipe';

import { Pagination, Pageable, PaginationDto, Sortable } from '@shared/_models';

export function getPagesCount({ limit, total_records }: PaginationDto): number {
  return ceil((total_records ?? 0) / limit) || 1;
}

export function getDefaultPagination(limit = 20): PaginationDto {
  return {
    total_records: undefined,
    limit,
    offset: 0
  };
}

export function toPagination(pagination: PaginationDto): Pagination {
  const { limit } = pagination;

  return {
    limit,
    page: toCurrentPage(pagination),
    pageCount: getPagesCount(pagination)
  };
}

export function toCurrentPage({ offset, limit }: PaginationDto): number {
  return offset === 0 ? 1 : offset / limit + 1;
}

export function toParams(params: Pageable & Sortable): Params {
  if (!params) return {};

  const adjustPagingParams = (params: Pageable & Sortable): Pageable & Sortable => {
    const { page, limit } = params;

    if (isNil(page) || isNil(limit)) return params;

    return { ...omit(params, ['page', 'pageCount']), offset: limit * (page - 1) };
  };
  const adjustSortParams = (params: Pageable & Sortable): Pageable & Sortable => {
    const { prop, order, parameter_id } = params;

    if (!((prop || parameter_id) && order)) return params;

    if (parameter_id) {
      return {
        ...omit(params, ['prop', 'order']),
        parameter_id,
        sort_order: order
      };
    }

    return {
      ...omit(params, ['prop', 'order', 'parameter_id']),
      order_by: prop,
      sort_order: order
    };
  };
  const adjustSearchParams = (params: Params): Params => {
    if (!params.search) return omit(params, ['search']);

    return params;
  };
  const adjustParams = pipe(adjustPagingParams, adjustSortParams, adjustSearchParams);

  return adjustParams(params);
}

export function fromPaginationDto(pagination: PaginationDto): Pagination {
  return {
    limit: pagination.limit,
    page: toCurrentPage(pagination),
    pageCount: getPagesCount(pagination)
  };
}
