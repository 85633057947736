import { ChangeDetectionStrategy, Component, Input, computed, signal } from '@angular/core';
import { AsyncPipe, NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf, TitleCasePipe, NgClass } from '@angular/common';
import { toObservable } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { upperFirst } from 'lodash-es';

import { OptionIconComponent } from '@shared/_components/option-icon/option-icon.component';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { HandleItemParameterOrResultIfEmptyPipe, ItemReadValuePipe } from '@app/shared/pipes';
import { hasColor } from '@app/domain/field';
import { Field, DisplayFieldType, ViewMode } from '@shared/_models';
import { selectAvatarForId, selectUserById } from '@shared/_root-store/users-store/users.selectors';
import { loadUser } from '@shared/_root-store/users-store/users.actions';
import { getUserDisplayValue } from '@shared/dynamic-form/components/form-member/utils';
import { IconComponent } from '../../../../icon/components/icon/icon.component';
import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { DEFAULT_SELECT_PLACEHOLDER } from '@app/domain/field/placeholders/dropdown';
import { DEFAULT_MEMBER_PLACEHOLDER } from '@app/domain/field/placeholders/member';
import { defaultDatePlaceholder } from '@app/domain/field/placeholders/date';
import { WysiwygFieldComponent } from '@shared/_components/wysiwyg-field/wysiwyg-field.component';
import { FieldDateFormatPipe } from '@shared/pipes/field-date-format.pipe';

@Component({
  selector: 'app-field-value-preview',
  standalone: true,
  imports: [
    AsyncPipe,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgIf,
    NgClass,
    OptionIconComponent,
    UserAvatarComponent,
    ItemReadValuePipe,
    FieldDateFormatPipe,
    HandleItemParameterOrResultIfEmptyPipe,
    IconComponent,
    TitleCasePipe,
    WysiwygFieldComponent
  ],
  templateUrl: './field-value-preview.component.html',
  styleUrls: ['./field-value-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldValuePreviewComponent {
  @Input() set field(value: Field) {
    this.#onFieldChange(value);
  }
  @Input() viewMode: ViewMode;
  @Input() set minimized(value: boolean) {
    this.minimizedSignal.set(value);
  }
  minimizedSignal = signal<boolean>(false);
  fieldSignal = signal<Field>(null);
  placeholder = computed<string>(() => {
    if (this.minimizedSignal()) return '';

    switch (this.fieldSignal()?.type) {
      case DisplayFieldType.USER:
        return DEFAULT_MEMBER_PLACEHOLDER;
      case DisplayFieldType.SELECT:
        return DEFAULT_SELECT_PLACEHOLDER;
      case DisplayFieldType.DATE:
        return defaultDatePlaceholder(this.fieldSignal());
      default:
        return upperFirst(this.fieldSignal()?.type.replace('_', ' '));
    }
  });

  avatar$: Observable<string> = toObservable(this.fieldSignal).pipe(
    filter(field => field?.type === DisplayFieldType.USER),
    switchMap(field => this.store.select(selectAvatarForId(field.value))),
    map(avatar => avatar?.base64 ?? null)
  );
  userDisplayValue$ = toObservable(this.fieldSignal).pipe(
    filter(field => field?.type === DisplayFieldType.USER),
    switchMap(field => this.store.select(selectUserById(field.value)).pipe(map(user => getUserDisplayValue(user, field))))
  );

  readonly defaultDatePlaceholder = defaultDatePlaceholder;
  readonly hasColor = hasColor;
  readonly DisplayFieldType = DisplayFieldType;
  readonly DEFAULT_SELECT_PLACEHOLDER = DEFAULT_SELECT_PLACEHOLDER;
  readonly DEFAULT_MEMBER_PLACEHOLDER = DEFAULT_MEMBER_PLACEHOLDER;
  readonly IconColors = IconColors;
  readonly ViewMode = ViewMode;

  // potentially to refactor: so low level components should not know about store
  constructor(private readonly store: Store) {}

  #onFieldChange(field: Field) {
    if (field === this.fieldSignal()) return;

    this.fieldSignal.set(field);

    if (field?.value && field.type === DisplayFieldType.USER) {
      this.store.dispatch(loadUser({ id: field.value }));
    }
  }
}
