import { LabelPipe } from './label/label.pipe';
import { DevelopmentTypeCssClassPipe } from './development-type-css-class.pipe';
import { FileExtensionPipe } from './file-extension.pipe';
import { FileSafeUrlPipe } from './file-safe-url.pipe';
import { FilterPipe } from './filter.pipe';
import { ImageSafeStyleUrlPipe } from './image-safe-style-url.pipe';
import { LimitToPipe } from './limit-to.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SearchPipe } from './search.pipe';
import { SingularPipe } from './singular.pipe';
import { SlicePipe } from './slice.pipe';
import { SortPipe } from './sort.pipe';
import { SplitPipe } from './split.pipe';
import { StartCasePipe } from './start-case.pipe';
import { StatusIconPipe } from './status-icon.pipe';
import { SubnavExpandCollapsePipe } from './subnav-expand-collapse.pipe';
import { SubnavRotationStatePipe } from './subnav-rotation-state.pipe';
import { SubnavVisibilityPipe } from './subnav-visibility.pipe';
import { ConditionalPrefixPipe } from './conditional-prefix.pipe';
import { ToggleShowMoreBtnLabelPipe } from './toggle-show-more-btn-label.pipe';
import { TruncatePipe } from './truncate.pipe';
import { FileWithoutExtensionPipe } from './file-without-extension.pipe';

export const SHARED_PIPES = [
  FilterPipe,
  SafeHtmlPipe,
  SearchPipe,
  SingularPipe,
  SortPipe,
  SplitPipe,
  TruncatePipe,
  LabelPipe,
  StartCasePipe,
  StatusIconPipe,
  SlicePipe,
  SubnavExpandCollapsePipe,
  SubnavRotationStatePipe,
  SubnavVisibilityPipe,
  ImageSafeStyleUrlPipe,
  FileExtensionPipe,
  FileSafeUrlPipe,
  ConditionalPrefixPipe,
  LimitToPipe,
  ToggleShowMoreBtnLabelPipe,
  DevelopmentTypeCssClassPipe,
  FileWithoutExtensionPipe
];

export * from './label/label.pipe';
export * from './safe-html.pipe';
export * from './search.pipe';
export * from './singular.pipe';
export * from './split.pipe';
export * from './truncate.pipe';
export * from './filter.pipe';
export * from './sort.pipe';
export * from './start-case.pipe';
export * from './status-icon.pipe';
export * from './slice.pipe';
export * from './subnav-expand-collapse.pipe';
export * from './subnav-rotation-state.pipe';
export * from './subnav-visibility.pipe';
export * from './sorting-rotation-state.pipe';
export * from './image-safe-style-url.pipe';
export * from './limit-to.pipe';
export * from './toggle-show-more-btn-label.pipe';
export * from './item-read-value.pipe';
export * from './handle-item-parameter-or-result-if-empty.pipe';
export * from './file-without-extension.pipe';
export * from './conditional-prefix.pipe';
