import { Injectable } from '@angular/core';
import { IToast, IToastOptions } from '../../_models/toast.interface';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastSource = new Subject<IToast>();
  toast$ = this.toastSource.asObservable();

  show(text: string, options: IToastOptions): void {
    this.toastSource.next({ text, options });
  }
}
