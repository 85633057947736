<div data-testid="item-details-breadcrumb" class="breadcrumbs">
  <a data-testid="breadcrumbs-back-button" class="breadcrumbs__back-btn" (click)="onBackBtnClick()">
    <img class="me-2" src="../../../../assets/icons/chevron_2_icon.svg" alt="back arrow icon" />
    back
  </a>

  <ng-container *ngFor="let data of breadcrumbsData$ | async; first as isFirst; last as isLast">
    <ng-container *ngIf="isFirst">
      <span class="breadcrumbs__vertical-separator"></span>
      <a class="breadcrumbs__category" [routerLink]="getRoute(ROOT_CATEGORY_ID)" [ngSwitch]="developmentType()">
        <ng-container *ngSwitchCase="DevelopmentType.researchObject">Objects</ng-container>
        <ng-container *ngSwitchCase="DevelopmentType.experiment">Experiments</ng-container>
      </a>
    </ng-container>
    <ng-container *ngIf="!isLast">
      <span class="breadcrumbs__separator">></span>
      <a class="breadcrumbs__category" [routerLink]="getRoute(data.categoryId)">
        {{ data.categoryName }}
      </a>
    </ng-container>
    <ng-container *ngIf="isLast && isItemDetailsView$ | async">
      <span class="breadcrumbs__separator">></span>
      <a class="breadcrumbs__category" [routerLink]="getRoute(data.categoryId)">
        {{ data.categoryName }}
      </a>
    </ng-container>
  </ng-container>
</div>
