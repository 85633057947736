import { Injectable } from '@angular/core';
import { environment } from "@environments/environment";

/**
 * This service was created to ease testing services/components based on environment variable.
 */

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  environment = environment;
}
