import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExperimentStatusComponent } from './experiment-status/experiment-status.component';
import { StatusIconPipe } from '@shared/pipes/status-icon.pipe';

@NgModule({
  declarations: [ExperimentStatusComponent],
  imports: [CommonModule, StatusIconPipe],
  exports: [ExperimentStatusComponent]
})
export class ExperimentStatusModule {}
