import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ListItemResultBase } from '@app/shared/_components/item/list-item-result-base/list-item-result-base.component';

@Component({
  selector: 'app-table-item-result',
  templateUrl: './table-item-result.component.html',
  styleUrls: ['./table-item-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableItemResultComponent extends ListItemResultBase {
  @Input() resultsAreaHidden: boolean;
  constructor(readonly decimalPipe: DecimalPipe) {
    super(decimalPipe);
  }
}
