import { Params } from '@angular/router';
import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { flatten, flattenDeep } from 'lodash-es';

import { selectRouteParams } from '@app/shared/_root-store/router-store/selectors';
import { selectAll } from './category-templates.reducer';
import { CategoryTemplateDto, ParameterDto } from '@shared/_models';
import { CategoryTemplatesState } from './category-templates.reducer';
import { RootState } from '@shared/_root-store/reducer';
import { isTemplateNotEmpty } from '@app/domain/category';

export const selectCategoryTemplatesState = createSelector(
  (state: RootState) => state.categoryTemplates,
  state => state
);

export const selectTemplatesDictionary = createSelector(selectCategoryTemplatesState, (state: CategoryTemplatesState) => state.entities);

export const selectTemplates = createSelector(selectTemplatesDictionary, (dictionary: Dictionary<CategoryTemplateDto>) =>
  Object.values(dictionary)
);

export const selectNotEmptyTemplates = createSelector(selectTemplates, templates =>
  templates.filter(template => isTemplateNotEmpty(template))
);

export const anyNotEmptyTemplatesExists = createSelector(selectNotEmptyTemplates, notEmptyTemplates => notEmptyTemplates.length > 0);

export const selectTemplatesLoading = createSelector(selectCategoryTemplatesState, (state: CategoryTemplatesState) => state.loading);

export const selectTemplatesLoaded = createSelector(selectCategoryTemplatesState, (state: CategoryTemplatesState) => state.loaded);

export const selectTemplateById = (id: string) => createSelector(selectCategoryTemplatesState, state => state.entities[id]);

export const selectTemplateByCategoryId = (categoryId: string) =>
  createSelector(selectRouteParams, selectCategoryTemplatesState, (routeParams: Params, state: CategoryTemplatesState) => {
    const id = (categoryId || routeParams?.categoryId) as string;
    const templates: CategoryTemplateDto[] = selectAll(state);

    return templates.find(template => template.category_id === id);
  });

export const selectTemplateParameterById = (templateId: string, templateParameterId: string) =>
  createSelector(selectCategoryTemplatesState, (templatesState: CategoryTemplatesState) => {
    const template = templatesState.entities[templateId];
    const templateParameters = flatten(template.sections.map(section => section.fields));
    return templateParameters.find(templateParameter => templateParameter.id === templateParameterId);
  });
