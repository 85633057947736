<section id="viewport" class="h-100" [class.viewport--extra-space-for-navigation]="secondaryNavigationVisible">
  <header id="viewport-header">
    <ng-content select="[v-header]"></ng-content>
  </header>
  <section
    #viewportContent
    id="viewport-content"
    [class.viewport-content--template-preview]="fieldsViewMode === FieldsViewMode.TEMPLATE_PREVIEW"
    class="flex-fill {{ viewMode }}"
  >
    <ng-content select="[v-content]"></ng-content>
  </section>
  <footer id="viewport-footer">
    <ng-content select="[v-footer]"></ng-content>
  </footer>
</section>
