import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GridItemSkeletonComponent } from './grid-item-skeleton/grid-item-skeleton.component';
import { TableItemSkeletonComponent } from './table-item-skeleton/table-item-skeleton.component';
import { SkeletonDirective } from './skeleton.directive';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ItemsCounterComponent } from '@shared/_components/item/items-counter/items-counter.component';
import { ItemReadValuePipe } from '@app/shared/pipes';
import { OverflowContentPluginToNgbTooltipDirective } from '@app/shared/_directives';
import { ItemDetailsSkeletonComponent } from './item-details-skeleton/item-details-skeleton.component';

const COMPONENTS = [TableItemSkeletonComponent, GridItemSkeletonComponent];

const STANDALONE_COMPONENTS = [ItemDetailsSkeletonComponent];

const DIRECTIVES = [SkeletonDirective];

const PIPES = [ItemReadValuePipe];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    NgbPopoverModule,
    NgbTooltipModule,
    ItemsCounterComponent,
    OverflowContentPluginToNgbTooltipDirective,
    ...DIRECTIVES,
    ...PIPES,
    ...STANDALONE_COMPONENTS
  ],
  exports: [...COMPONENTS, ...DIRECTIVES, ...PIPES, OverflowContentPluginToNgbTooltipDirective]
})
export class SkeletonsModule {}
