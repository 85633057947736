import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ItemsRemoveService {
  openRejectionModal$: Subject<void> = new Subject();
  openConfirmationModal$: Subject<number> = new Subject();
  closeConfirmationModal$: Subject<void> = new Subject();
}
