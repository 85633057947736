import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//
import {
  RESEARCH_OBJECTS_CSV_EXPORT_API_URL,
  RESEARCH_OBJECTS_CSV_MULTIPLE_EXPORT_API_URL,
  RESEARCH_OBJECTS_FILES_API_URL
} from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ResearchObjectFileApiService {
  constructor(private readonly http: HttpClient) {}

  generateCSV(research_object_ids: string[]): Observable<Blob> {
    return this.http.post<any>(`${RESEARCH_OBJECTS_CSV_EXPORT_API_URL}`, { research_object_ids }, { responseType: 'blob' as 'json' });
  }

  generateMultipleCSV(ro_ids_grouped_by_category: string[][]): Observable<Blob> {
    return this.http.post<any>(
      `${RESEARCH_OBJECTS_CSV_MULTIPLE_EXPORT_API_URL}`,
      { ro_ids_grouped_by_category },
      { responseType: 'blob' as 'json' }
    );
  }

  upload(researchObjectId: string, data: any): Observable<any> {
    return this.http.post<any>(`${RESEARCH_OBJECTS_FILES_API_URL}/upload_file/${researchObjectId}`, data);
  }

  delete(ids_to_remove: string[]): Observable<any> {
    return this.http.post<any>(`${RESEARCH_OBJECTS_FILES_API_URL}/delete_file`, { ids_to_remove });
  }
}
