<ng-container [ngSwitch]="developmentType()">
  <app-experiment-grid-item [itemData]="itemData()" *ngSwitchCase="DevelopmentType.experiment"></app-experiment-grid-item>

  <app-research-object-grid-item [itemData]="itemData()" *ngSwitchCase="DevelopmentType.researchObject"></app-research-object-grid-item>

  <app-project-grid-item
    [itemData]="itemData()"
    [developmentType]="DevelopmentType.project"
    *ngSwitchCase="DevelopmentType.project"
  ></app-project-grid-item>
</ng-container>
