import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IWorkspace } from '@app/shared/_models/workspace';
import { AuthRoutingPath } from '@app/_auth/auth-routing-path';
import { AuthService } from '@app/_auth/auth.service';
import { PasswordRoutingPath } from '@app/_auth/password/password-routing-path';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { switchMap, tap } from 'rxjs';
import { AuthSignInMicrosoftResponse } from '@app/_auth/interface';

@Component({
  selector: 'app-signin-adamed',
  templateUrl: './signin-adamed.component.html',
  styleUrls: ['./signin-adamed.component.scss']
})
export class SigninAdamedComponent implements OnInit {
  readonly signUpRoutingPath = ['/', AuthRoutingPath.auth, AuthRoutingPath.signUp];
  readonly requestResetPasswordRoutingPath = ['/', AuthRoutingPath.password, PasswordRoutingPath.requestResetPassword];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private workspaceService: WorkspaceService
  ) {}

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.queryParamMap.get('code');
    const queryParamStateKey = this.activatedRoute.snapshot.queryParamMap.get('state');
    const returnUrl = sessionStorage.getItem('returnUrl');

    if (code && queryParamStateKey) {
      // ten endpoint zwraca token po pomyślnej autentykacji przez zewnętrzny serwis (na podstawie 'code' które pochodzi
      // z zewnętrznego serwisu)
      this.authService
        .callBackMicrosoft(code, queryParamStateKey)
        .pipe(
          switchMap(() => {
            const workspaceId = returnUrl?.split('/')[2];
            return this.workspaceService.getInitialWorkspace(workspaceId);
          }),
          tap((workspace: IWorkspace) => this.workspaceService.setCurrentWorkspace(workspace))
        )
        .subscribe((workspace: IWorkspace) => {
          this.authService.redirectToInitialPage(workspace?.id, returnUrl);
          sessionStorage.removeItem('returnUrl');
        });
    }
  }

  signInWithMicrosoft() {
    // ten endpoint pobiera url do zewnętrznego serwisu logowania (microsoft)
    this.authService.signInWithMicrosoft().subscribe((response: AuthSignInMicrosoftResponse) => {
      const returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');

      if (returnUrl) {
        sessionStorage.setItem('returnUrl', returnUrl);
      }

      // przekierowanie
      window.location.href = response.authorization_url;

      // po pomyślnym zalogowaniu zewnętrzny serwis przekieruje klienta z powrotem na nasz adres logowania umieszczająć
      // ten sam query param 'state' i query param 'code'
    });
  }
}
