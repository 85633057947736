import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, LowerCasePipe, SlicePipe, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SplitPipe } from '@shared/pipes/split.pipe';
import { GridItemComponent } from './grid-item/grid-item.component';
import { GridItemImageComponent } from './grid-item-image/grid-item-image.component';
import { GridItemResultComponent } from './grid-item-result/grid-item-result.component';
import { GridListComponent } from './grid-list/grid-list.component';
import { ExperimentGridItemComponent } from './experiment-grid-item/experiment-grid-item.component';
import { ResearchObjectGridItemComponent } from './research-object-grid-item/research-object-grid-item.component';
import { ExperimentStatusModule } from '../experiment-status/experiment-status.module';
import { SkeletonsModule } from '../skeletons/skeletons.module';
import { FileApiService } from '@shared/_services/file/file-api.service';
import { ProjectGridItemComponent } from './project-grid-item/project-grid-item.component';
import { ProjectModule } from '../project/project.module';
import { ItemsCounterComponent } from '@shared/_components/item/items-counter/items-counter.component';
import { ItemImageDetailsComponent } from '@shared/_components/item/item-image-details/item-image-details.component';
import { SearchSelectComponent } from '@shared/_components/search-select/search-select.component';
import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { ImageComponent } from '@shared/_components/image/components/image.component';
import { ProjectGridCardComponent } from '@app/_projects/project-grid-card/project-grid-card.component';
import { OptionIconComponent } from '../../_components/option-icon/option-icon.component';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { FieldValuePreviewComponent } from '@shared/_components/item/item-details/item-details-grid-section/field-value-preview/field-value-preview.component';
import { WysiwygFieldComponent } from '@shared/_components/wysiwyg-field/wysiwyg-field.component';

const NG_MODULES = [CommonModule, RouterModule];

const LIB_MODULES = [NgbTooltipModule, NgbPopoverModule];

const MODULES = [ProjectModule, ExperimentStatusModule, SkeletonsModule, ProjectModule];

const STANDALONE_COMPONENTS = [
  ItemsCounterComponent,
  ItemImageDetailsComponent,
  SearchSelectComponent,
  ButtonComponent,
  ImageComponent,
  ProjectGridCardComponent,
  OptionIconComponent,
  UserAvatarComponent,
  FieldValuePreviewComponent,
  WysiwygFieldComponent
];

const COMPONENTS = [
  GridListComponent,
  GridItemComponent,
  GridItemImageComponent,
  ExperimentGridItemComponent,
  ResearchObjectGridItemComponent,
  ProjectGridItemComponent,
  GridItemResultComponent
];

const PIPES = [TitleCasePipe, LowerCasePipe, DecimalPipe, SplitPipe, SlicePipe];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS, ...MODULES],
  providers: [...PIPES, FileApiService],
  imports: [...NG_MODULES, ...LIB_MODULES, ...MODULES, ...STANDALONE_COMPONENTS]
})
export class GridModule {}
