import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoginMethodType } from '@app/shared/_models/authentication-data.interface';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninComponent {
  loginMethod$ = this.authService.loginMethod();
  readonly LoginMethodType = LoginMethodType;
  constructor(private readonly authService: AuthService) {}
}
