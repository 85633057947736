import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { ProjectDto, UpdateProjectDto } from '@shared/_models';

export const loadProjects = createAction('[Projects] Load Projects', props<{ workspaceId: string }>());
export const loadProjectsSuccess = createAction('[Projects] Load Projects Success', props<{ data: ProjectDto[] }>());
export const loadProjectsFailure = createAction('[Projects] Load Projects Failure', props<{ error: HttpErrorResponse }>());
export const selectProject = createAction('[Projects] Select Project', props<{ project: ProjectDto }>());
export const updateProject = createAction('[Projects] Update Project', props<{ projectId: string; changes: Partial<UpdateProjectDto> }>());
export const updateProjectSuccess = createAction('[Projects] Update Project Success', props<{ data: ProjectDto }>());
export const updateProjectFailure = createAction('[Projects] Update Project Failure', props<{ error: HttpErrorResponse }>());
