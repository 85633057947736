import { ofType, createEffect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
//
import { apiSchemasActions } from './../actions';
import { ApiSchemasService } from './../../api-schemas.service';

@Injectable({
  providedIn: 'root',
})
export class ApiSchemasEffects {
  constructor(private actions$: Actions, private apiSchemasService: ApiSchemasService) {}

  loadSchemas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiSchemasActions.loadSchemas),
      mergeMap(() => this.apiSchemasService.load()),
      map((schemas: any) => {
        const { Project, Template } = schemas.definitions;
        const projects = {
          required: Project.required,
          properties: Project.properties,
        };
        const templates = {
          required: Template.required,
          properties: Template.properties,
        };

        return apiSchemasActions.schemasLoaded({ schemas: { projects, templates } });
      }),
      catchError(error => of(apiSchemasActions.schemasLoadingError({ error })))
    )
  );
}
