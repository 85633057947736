import { Injectable, computed, signal } from '@angular/core';

import { Filterable, IResearchObjectParams, Pageable, Searchable, Sortable, ViewMode } from '@app/shared/_models';
import { ParamsService } from '../params.service';
import { ROOT_CATEGORY_ID } from '@app/domain/category';
import { ProjectsStoreService } from '@shared/_root-store/projects-store/projects-store.service';

@Injectable({
  providedIn: 'root'
})
export class ResearchObjectParamsService extends ParamsService<IResearchObjectParams> {
  params = signal({
    pageSize: 20,
    indexPage: 1,
    search: '',
    inInstanceChecked: false,
    categoryId: null,
    sortState: null,
    viewMode: ViewMode.grid,
    ro_ids: [],
    project_id: null
  } as IResearchObjectParams);
  inInstanceChecked = computed(() => this.params().inInstanceChecked);

  constructor(private projectService: ProjectsStoreService) {
    super();
  }

  getHttpParams(): Searchable & Pageable & Sortable & Filterable {
    const params = this.params();
    const httpParams = {
      limit: params.pageSize,
      page: this.isSelectOnlyMode ? this.selectedOnlyIndexPage : params.indexPage,
      ...this.getFilters(),
      prop: params.sortState?.prop,
      order: params.sortState?.order,
      parameter_id: params.sortState?.parameter_id,
      search: this.isSelectOnlyMode ? '' : params.search,
      include_instances_in_search: params.inInstanceChecked,
      ro_ids: this.isSelectOnlyMode ? params.ro_ids : [],
      project_id: this.projectService.selectedProject()?.id
    };

    return httpParams;
  }

  private getFilters() {
    const category_id = this.params().categoryId;

    return {
      ...(!!category_id && category_id !== ROOT_CATEGORY_ID && { category_id })
    };
  }
}
