import { Pipe, PipeTransform } from '@angular/core';
import { DevelopmentType } from '@shared/_models';

/**
 * Returns css class which indicates that given DOM element should be styled per development type.
 */
@Pipe({
  name: 'developmentTypeCSSClass',
  standalone: true,
})
export class DevelopmentTypeCssClassPipe implements PipeTransform {
  transform(developmentType: DevelopmentType): string {
    switch (developmentType) {
      case DevelopmentType.researchObject:
        return 'lg--ro';
      case DevelopmentType.experiment:
        return 'lg--exp';
      case DevelopmentType.all:
        return 'lg--all';
    }
  }
}
