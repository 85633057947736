import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgStyle } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { takeUntil } from 'rxjs';

import { TreeSelectItem } from '@shared/_models';
import { AutoDestroyDirective } from '@shared/_directives/auto-destroy/auto-destroy.directive';

export class SearchEvent {
  term: string;
}

@Component({
  selector: 'app-tree-select',
  standalone: true,
  imports: [NgStyle, ReactiveFormsModule, NgSelectModule],
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss']
})
export class TreeSelectComponent extends AutoDestroyDirective implements OnInit {
  @Input() controlName: string;
  @Input() items: TreeSelectItem[];
  @Input() form: FormGroup;
  @Input() disabled = false;
  @Output() selectItem = new EventEmitter();
  @Output() focusin = new EventEmitter();

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  ngOnInit(): void {
    this.form.controls[this.controlName].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (!value) {
        this.ngSelectComponent.handleClearClick();
        this.ngSelectComponent.blur();
      }
    });
  }

  hasFocus = false;
  indentSize(level: number): string | number {
    if (level <= 0) {
      return 0;
    }

    return `${level * 30}px`;
  }

  onSearch(event: SearchEvent) {
    if (event.term == '') {
      this.form.controls[this.controlName].setValue(null);
    }
  }

  onSelectItem(item: TreeSelectItem, select: NgSelectComponent) {
    select.blur();
    this.selectItem.emit(item);
  }

  onFocusin() {
    this.hasFocus = true;
    this.focusin.emit();
  }
}
