<div
  [ngClass]="{ 'dev-items-viewer__container--project': developmentType === DevelopmentType.project }"
  class="dev-items-viewer__container"
>
  <ng-container
    *appSkeleton="
      !listDataLoaded || (listData.length === 0 && (queryParams$ | async).page > 1);
      repeat: (queryParams$ | async)?.limit;
      type: SkeletonType.GRID_ITEM
    "
  >
    <app-grid-item
      *ngFor="let item of listData; trackBy: trackById; let i = index"
      [itemData]="item"
      [developmentType]="developmentType"
    ></app-grid-item>
  </ng-container>
</div>
