import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AlertModule } from '@app/alert/alert.module';
import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';

const NG_MODULES = [CommonModule];
const MODULES = [AlertModule];
const COMPONENTS = [AuthLayoutComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...NG_MODULES, ...MODULES, IconComponent],
  exports: [...COMPONENTS],
})
export class AuthLayoutModule {}
