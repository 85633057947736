import { Directive, input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { toSignal } from '@angular/core/rxjs-interop';

import { DevelopmentType, RouteParam } from '@shared/_models';
import { selectRouteCategoryHasAnyChildCategory } from '@shared/_root-store/selectors';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors/router.selectors';

@Directive()
export class TableItemBaseComponent<T> {
  readonly DevelopmentType = DevelopmentType;
  developmentType = input<DevelopmentType>();
  itemData = input<T>();

  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));

  constructor(protected store: Store) {}

  get currentCategoryHasAnyChild$(): Observable<boolean> {
    return this.store.select(selectRouteCategoryHasAnyChildCategory);
  }
}
