import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Params, Router } from '@angular/router';
import { filter, first, map, startWith, Subject, takeUntil } from 'rxjs';

import { ROOT_CATEGORY_ID } from '@app/domain/category';
import { ViewMode } from '@shared/_models';

@Injectable({
  providedIn: 'root',
})
export class ActivatedRouteParamsService implements OnDestroy {
  private params: Params;
  private onDestroy$ = new Subject<boolean>();

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly router: Router) {
    this.initParamsSub();
  }

  getParams(): Params {
    return this.params;
  }

  private initParamsSub(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof ActivationEnd && Object.keys(e.snapshot.params).length > 0),
        startWith(this.router),
        map(e => (e instanceof ActivationEnd ? e.snapshot.params : {})),
        takeUntil(this.onDestroy$)
      )
      .subscribe(params => {
        if (Object.keys(params).length === 0) {
          // Activated route has empty params when used inside a service. This is a hack to find these params.
          this.initParamsFromFirstChildRoute();
          return;
        }
        if (params.categoryId) {
          this.params = params;
        }
      });
  }

  private initParamsFromFirstChildRoute(): void {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    route.params
      .pipe(
        first(),
        map(params => {
          if (Object.keys(params).length === 0) {
            this.params = {
              viewMode: ViewMode.grid,
              categoryId: ROOT_CATEGORY_ID,
            };
            return;
          }

          this.params = params;
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
