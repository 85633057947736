<section class="dtTable lg-table w-100 table-section">
  <header class="datatable__header datatable__header--development-item-table">
    <div class="row table-header-row align-items-center flex-nowrap">
      <div class="col checkbox-col lg-table__th selection-col">
        <div class="form-check">
          <input
            data-testid="list-view-checkbox-column-header"
            class="form-check-input lg-table__select-all-checkbox"
            type="checkbox"
            [checked]="isChecked$ | async"
            (change)="toggleAllResearchObjectsSelection($event)"
          />
        </div>
      </div>
      <div
        *ngIf="!(!(currentCategoryHasAnyChild$ | async) && (imageAreaHidden$ | async))"
        data-testid="list-view-image-column-header"
        class="col lg-table__th image-col text-left"
      >
        Image
      </div>
      <div
        data-testid="list-view-oid-column-header"
        class="col col-narrow col-sortable lg-table__th oid-col text-left"
        (click)="setSort(researchObjectFields.OID)"
      >
        OID
        <i
          data-testid="list-view-oid-sort-indicator"
          [@sortingRotationState]="researchObjectFields.OID | sortingRotationState: sort"
          class="sort-icon"
        ></i>
      </div>
      <div
        data-testid="list-view-name-column-header"
        class="col col-sortable lg-table__th name-col text-left"
        (click)="setSort(researchObjectFields.NAME)"
      >
        Name
        <i
          data-testid="list-view-name-sort-indicator"
          [@sortingRotationState]="researchObjectFields.NAME | sortingRotationState: sort"
          class="sort-icon"
        ></i>
      </div>
      <ng-container *ngIf="currentCategoryHasAnyChild$ | async; else additionalParamsAndBatchSection">
        <div
          data-testid="list-view-category-column-header"
          class="col col-sortable lg-table__th category-col text-left"
          (click)="setSort(researchObjectFields.CATEGORY)"
        >
          Category
          <i
            data-testid="list-view-category-sort-indicator"
            [@sortingRotationState]="researchObjectFields.CATEGORY | sortingRotationState: sort"
            class="sort-icon"
          ></i>
        </div>
        <div
          data-testid="list-view-added-by-column-header"
          class="col col-sortable lg-table__th added-by-col text-left"
          (click)="setSort(researchObjectFields.ADDED_BY)"
        >
          Added By
          <i
            data-testid="list-view-added-by-sort-indicator"
            [@sortingRotationState]="researchObjectFields.ADDED_BY | sortingRotationState: sort"
            class="sort-icon"
          ></i>
        </div>
      </ng-container>
      <ng-template #additionalParamsAndBatchSection>
        <ng-container *ngFor="let parameter of parameters$ | async | slice: 0 : 4; let i = index">
          <div
            *ngIf="!(parametersAreaHidden$ | async)"
            class="col col-sortable lg-table__th parameter-col text-left"
            (click)="setSort(parameter.field_template_id, true)"
          >
            {{ parameter.name }}
            <i [@sortingRotationState]="parameter.field_template_id | sortingRotationState: sort" class="sort-icon"></i>
          </div>
        </ng-container>
      </ng-template>
      <div
        *ngIf="!(!(currentCategoryHasAnyChild$ | async) && (instanceAreaHidden$ | async))"
        data-testid="list-view-sample-column-header"
        class="col col-sortable lg-table__th col-narrow intances-col text-left"
        (click)="setSort(researchObjectFields.INSTANCE)"
      >
        {{ (currentCategoryHasAnyChild$ | async) ? 'Sample' : getInstanceSectionName }}
        <i
          data-testid="list-view-sample-sort-indicator"
          [@sortingRotationState]="researchObjectFields.INSTANCE | sortingRotationState: sort"
          class="sort-icon"
        ></i>
      </div>
      <div
        *ngIf="showExperiments$ | async"
        data-testid="list-view-used-in-exp-column-header"
        class="col lg-table__th col-narrow used-in-exp-col text-left"
      >
        Used In Exp
      </div>
      <div
        data-testid="list-view-date-added-column-header"
        class="col col-sortable lg-table__th col-narrow date-col text-left"
        (click)="setSort(researchObjectFields.DATE_ADDED)"
      >
        Date Added
        <i
          data-testid="list-view-date-added-sort-indicator"
          [@sortingRotationState]="researchObjectFields.DATE_ADDED | sortingRotationState: sort"
          class="sort-icon"
        ></i>
      </div>
      <div class="col lg-table__th actions-col">&nbsp;</div>
      <div class="lg-table__th lg-table__th--item-btn"></div>
    </div>
  </header>
  <div class="table-body">
    <ng-container *appSkeleton="!listDataLoaded; repeat: 20; type: SkeletonType.TABLE_ITEM">
      <app-research-object-table-item
        *ngFor="let item of listData; trackBy: trackById"
        [itemData]="item"
        [imageAreaHidden]="imageAreaHidden$ | async"
        [instanceAreaHidden]="instanceAreaHidden$ | async"
        [parametersAreaHidden]="parametersAreaHidden$ | async"
        [showExperiments]="showExperiments$ | async"
        data-testid="ro-list-view-item"
      ></app-research-object-table-item>
    </ng-container>
  </div>
</section>
