import { Field, FieldType } from '@shared/_models';
import { comparePositions } from '@app/domain/shared/compare-positions';

export interface ComplexField {
  parent: Field;
  modifications: Field[];
  calculated: Field[];
}

export function toComplexFields(fields: Field[], instances: boolean = false): ComplexField[] {
  const isInstance = (field: Field) => (instances ? !!field.id : true);
  const parents = fields
    .filter(isInstance)
    .filter(
      field =>
        [FieldType.PEPSEQ, FieldType.SMILES, FieldType.SMILES_NPS].includes(field.field_type) && !(field.parent_id || field.value_from_id)
    );

  return parents.map(parent => ({
    parent,
    modifications: fields.filter(field => field.parent_id === parent.field_template_id).sort(comparePositions),
    calculated: fields.filter(field => field.value_from_id === parent.field_template_id).sort(comparePositions)
  }));
}
