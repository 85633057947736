import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { uniq } from 'lodash-es';

import { DisplayFieldType, Experiment, Field, ImageMetaUpdateDataForEntity, SectionType } from '@shared/_models';
import { ExperimentService } from '@shared/_services/experiment';
import { GridItemComponent } from '../grid-item/grid-item.component';
import { SPACE_CLASS_PREFIX } from '../grid-item/utils';
import { updateImageMeta } from '@shared/_root-store/experiments-store/experiments.actions';
import { toSectionFields } from '@shared/dto-adapters/field';
import { selectShowResearchObjects } from '@shared/_root-store/projects-store/projects.selectors';
import { comparePositions } from '@app/domain/shared/compare-positions';

@Component({
  selector: 'app-experiment-grid-item',
  templateUrl: './experiment-grid-item.component.html',
  styleUrls: ['./experiment-grid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentGridItemComponent extends GridItemComponent<Experiment> {
  currentCategoryId$ = this.route.params.pipe(map(params => params.categoryId));
  relatedResearchObjectsNames = computed<string[]>(() => uniq(this.itemData().linked_objects.map(ro => ro.ro_name)));
  results = computed<Field[]>(() => {
    const fields = toSectionFields(this.itemData(), { byType: { type: SectionType.RESULTS } });
    const condition = fields.some(field => !!field.value) ? (field: Field) => !!field.value : () => true;

    return fields.filter(condition).sort(comparePositions);
  });
  parameters = computed<Field[]>(() =>
    toSectionFields(this.itemData(), { byType: { type: SectionType.PARAMETERS } })
      .filter(field => !!field.value)
      .sort(comparePositions)
  );
  hideResultsArea = computed<boolean>(() => this.itemData().template?.hidden_section_types?.includes(SectionType.RESULTS));
  showResearchObjects = toSignal<boolean>(this.store.select(selectShowResearchObjects));
  sectionsSpace = computed<{ [key: string]: number }>(() => {
    const TOTAL_SPACE = 9;
    const FOOTER = 1;
    const USER = 1;
    const RELATED_RESEARCH_OBJECTS = this.showResearchObjects() ? 1 : 0;
    const image = this.hideResultsArea() ? TOTAL_SPACE - FOOTER - USER - RELATED_RESEARCH_OBJECTS - /*name*/ 1 : 3;
    const results = this.hideImageArea() ? TOTAL_SPACE - FOOTER - USER - RELATED_RESEARCH_OBJECTS - /*name*/ 1 : 2;
    const name =
      this.hideResultsArea() && this.hideImageArea()
        ? TOTAL_SPACE - FOOTER - USER - RELATED_RESEARCH_OBJECTS
        : /* when result & images are visible but we hide research objects then
           * one extra space is taken by name */
          !this.hideResultsArea() && !this.hideImageArea() && !this.showResearchObjects()
          ? 2
          : 1;

    return {
      image,
      results,
      name
    };
  });
  firstSectionSpace = computed<number>(() => {
    if (!this.hideImageArea()) {
      return this.sectionsSpace().image;
    }

    if (!this.hideResultsArea()) {
      return this.sectionsSpace().results;
    }

    return this.sectionsSpace().name;
  });
  imageSpaceClass = computed<string>(() => `${SPACE_CLASS_PREFIX}${this.sectionsSpace().image}`);
  resultsSpaceClass = computed<string>(() => `${SPACE_CLASS_PREFIX}${this.sectionsSpace().results}`);
  nameSpaceClass = computed<string>(
    () => `${SPACE_CLASS_PREFIX}${this.sectionsSpace().name} ${this.hideResultsArea() ? 'items-card__segment--main' : ''}`
  );
  DisplayFieldType = DisplayFieldType;
  gridItemHovered = false;

  constructor(
    private readonly experimentService: ExperimentService,
    private route: ActivatedRoute,
    store: Store
  ) {
    super(store);
  }

  toggleExperimentSelection($event, id: string): void {
    this.experimentService.toggleExperimentSelection($event, id);
  }

  isExperimentSelected(experimentId: string): Observable<boolean> {
    return this.experimentService.isExperimentSelected(experimentId);
  }

  onImageMetaChanged(data: ImageMetaUpdateDataForEntity) {
    this.store.dispatch(updateImageMeta({ data }));
  }
}
