import { Pipe, PipeTransform } from '@angular/core';

import { getFileSize } from '@shared/_helpers';

@Pipe({
  name: 'formatFileSize',
  standalone: true
})
export class FormatFileSizePipe implements PipeTransform {
  transform(fileSize: number): string {
    if (typeof fileSize === 'undefined' || fileSize === null) {
      return '';
    }

    return getFileSize(fileSize);
  }
}
