import { ChangeDetectionStrategy, Component, Input, WritableSignal, signal, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

import { DATE_FORMAT } from '@shared/dynamic-form/components/form-date/date-formatter';
import { toISO8601 } from '@shared/_helpers';
import { IconComponent } from '../../icon/components/icon/icon.component';
import { IconColors } from '../../icon/utils/icon-colors';

@Component({
  selector: 'app-date-input',
  standalone: true,
  templateUrl: './date-input.component.html',
  imports: [CommonModule, FormsModule, NgbModule, IconComponent],
  styleUrls: ['./date-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateInputComponent implements ControlValueAccessor {
  @Input() markAsInvalid: boolean;
  @Output() onBlur = new EventEmitter<void>();

  datepickerValue: WritableSignal<NgbDateStruct> = signal(null);

  readonly IconColors = IconColors;

  constructor(
    public control: NgControl,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) {
    this.control.valueAccessor = this;
  }

  registerOnChange(fn: (value: string) => void) {
    this.#onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.#onTouched = fn;
  }
  setDisabledState(): void {}
  writeValue(value: NgbDateStruct) {
    this.datepickerValue.set(value);
  }

  ngOnInit() {
    this.datepickerValue.set(this.#timestampToDateObj(this.control.value));
  }

  onNgModelChange(selectedValue: NgbDateStruct) {
    this.datepickerValue.set(selectedValue);
    this.#onTouched();
    this.#onChange(toISO8601(selectedValue));
  }

  clear() {
    this.datepickerValue.set(null);
    this.#onTouched();
    this.#onChange(null);
  }

  #onChange = (_: string) => void {};
  #onTouched = () => void {};

  #timestampToDateObj(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    const dateString = moment.utc(value).format(DATE_FORMAT);

    return this.ngbDateParserFormatter.parse(dateString);
  }
}
