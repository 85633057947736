import { LinkedObjectDto, DevelopmentItemDto } from '../_models';

export function toDevelopmentItemDto(linkedObject: LinkedObjectDto): DevelopmentItemDto {
  return {
    id: linkedObject.research_object_id,
    oid: linkedObject.ro_oid,
    name: linkedObject.ro_name,
    user_crt: linkedObject.user_crt,
    date_created: linkedObject.date_created,
    main_image_id: linkedObject.main_image?.id
  } as DevelopmentItemDto;
}
