<app-generic-modal
  [acceptButton]="acceptButton"
  [developmentType]="developmentType"
  [hideHeaderCloseIcon]="true"
  class="item-details-info"
  (close)="close()"
>
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
  </ng-template>
  <div class="info-modal__body">
    <div class="info-modal__body-header">Additional info</div>
    <app-item-details-additional-info [developmentType]="developmentType" [item]="item"></app-item-details-additional-info>
  </div>
</app-generic-modal>

<ng-template #modalHeaderTemplate>
  <app-modal-header-left
    [header]="header | capitalizeFirst"
    [developmentType]="developmentType"
    [itemName]="item.name"
    [categoryName]="item.template.category.name"
    (closeModal)="closeModal.emit()"
    imgSrc="clipboard_icon_white.svg"
  ></app-modal-header-left>
</ng-template>
