import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  signal,
  Signal,
  WritableSignal,
  computed,
  ElementRef
} from '@angular/core';
import { AsyncPipe, TitleCasePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgbCarousel, NgbModal, NgbModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, takeUntil } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

import { DevelopmentType, DevelopmentItem, Image, SectionType, SectionDto } from '@shared/_models';
import { ItemImageDetailsComponent } from '../..';
import { FileService } from '@shared/_modules/file/file.service';
import { DevelopmentItemService } from '@shared/_services/development-item.service';
import { ItemDetailsSectionComponent } from '@shared/_components/item/item-details/item-details-section/item-details-section.component';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { ImageSafeStyleUrlPipe } from '@shared/pipes/image-safe-style-url.pipe';
import { ImageComponent } from '@shared/_components/image/components/image.component';
import { AutoDestroyDirective } from '@shared/_directives/auto-destroy/auto-destroy.directive';
import { trackById } from '@shared/_helpers';
import { getFirstSection } from '@app/domain/category-template';
import { comparePositions } from '@app/domain/shared';

@Component({
  selector: 'app-item-details-images',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgFor,
    NgbModule,
    ItemDetailsSectionComponent,
    DevelopmentTypeCssClassPipe,
    ImageSafeStyleUrlPipe,
    ImageComponent,
    AsyncPipe,
    TitleCasePipe,
    NgTemplateOutlet,
    ItemImageDetailsComponent
  ],
  templateUrl: './item-details-images.component.html',
  styleUrls: ['./item-details-images.component.scss']
})
export class ItemDetailsImagesComponent extends AutoDestroyDirective implements OnDestroy {
  @Input() developmentType: DevelopmentType;
  @Input() images: Image[];

  @Input() set developmentItem(value: DevelopmentItem) {
    this.#onDevelopmentItemChange(value);
  }
  developmentItemSignal = signal<DevelopmentItem>(null);

  @Input() set sectionId(value: string) {
    this.#onSectionIdChange(value);
  }
  sectionIdSignal: WritableSignal<string> = signal(null);
  sectionId$ = toObservable(this.sectionIdSignal);

  @Input() editable = false;

  @Output() readonly imagePlaceholderClick = new EventEmitter<void>();
  @Output() readonly editBtnClick = new EventEmitter<void>();
  @Output() readonly addBtnClick = new EventEmitter<void>();
  @Output() readonly imageMetaChange = new EventEmitter<{ imageId: string; payload: { description?: string; main_image?: boolean } }>();
  @Output() readonly sectionIdChange = new EventEmitter<string>();
  @ViewChild('imageDetailsModal', { static: false }) imageDetailsModal: ElementRef;
  @ViewChild('ngbCarousel') carousel: NgbCarousel;
  entity$: Observable<DevelopmentItem> = toObservable(this.developmentItemSignal);
  activeArea = computed(() => this.developmentItemSignal()?.template?.active_section_types?.includes(SectionType.IMAGES));
  displaySections: Signal<SectionDto[]> = computed(() =>
    this.activeArea()
      ? this.developmentItemSignal()
          ?.template?.sections.filter(section => section.type === SectionType.IMAGES)
          .sort(comparePositions)
      : []
  );
  imageDetailsModalEditMode = false;
  get imageDetails(): Image {
    if (!this.#imageDetailsId) return;

    return this.images?.find(image => image.id === this.#imageDetailsId);
  }

  #detailsModalImageDestroy$: Subject<void> = new Subject();
  #imageDetailsId: string;

  readonly DevelopmentType = DevelopmentType;
  readonly SectionType = SectionType;
  readonly trackById = trackById;

  constructor(
    private readonly modalService: NgbModal,
    private readonly fileService: FileService,
    private readonly developmentItemService: DevelopmentItemService
  ) {
    super();
    this.fileService.detailsModalImage$.pipe(takeUntil(this.destroy$)).subscribe((image: Image) => {
      this.openModal(image);
    });
  }

  ngOnDestroy() {
    this.#detailsModalImageDestroy$.next();
  }

  openModal(image: Image): void {
    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }

    this.#imageDetailsId = image.id;
    this.modalService.open(this.imageDetailsModal, {
      size: 'xl',
      centered: true
    });
  }

  onImageDetailsModalClose(modal: NgbModalRef) {
    modal.close();
    this.#detailsModalImageDestroy$.next();
    this.#imageDetailsId = null;
  }

  onImageDetailsModalEditModeChange(value: boolean) {
    this.imageDetailsModalEditMode = value;
  }

  onImageDetailsModalDescriptionChanged(description: string) {
    this.imageMetaChange.emit({ imageId: this.#imageDetailsId, payload: { description } });
    this.imageDetailsModalEditMode = false;
  }

  addImageBtnClick(): void {
    if (!this.editable) return;

    this.addBtnClick.emit();
  }

  onCarouselPrevious() {
    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }
    this.carousel.prev();
  }

  onCarouselNext() {
    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }
    this.carousel.next();
  }

  changeTab(tabId: string) {
    this.sectionIdChange.emit(tabId);
  }

  onEdit() {
    if (!this.editable) return;

    this.editBtnClick.emit();
  }

  onImagePlaceholderClick() {
    if (!this.editable) return;

    this.imagePlaceholderClick.emit();
  }

  #onDevelopmentItemChange(value: DevelopmentItem) {
    this.developmentItemSignal.set(value);
    this.#initialize();
  }

  #onSectionIdChange(value: string) {
    this.sectionIdSignal.set(value);
    this.#initialize();
  }

  #initialize() {
    if (!this.developmentItemSignal() || this.sectionIdSignal()) return;

    const sections = this.developmentItemSignal().template.sections.filter(section => section.type === SectionType.IMAGES);
    const mainImageId = this.developmentItemSignal().main_image_id;
    const firstSectionId = mainImageId
      ? this.developmentItemSignal().images.find(image => image.id === mainImageId).section_id ??
        getFirstSection(this.developmentItemSignal().template, SectionType.IMAGES).id
      : sections.sort(comparePositions)[0].id;

    // avoiding emitting event in the same change detection cycle
    setTimeout(() => this.sectionIdChange.emit(firstSectionId), 0);
  }
}
