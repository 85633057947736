import { ChangeDetectionStrategy, Component, Input, computed, input } from '@angular/core';

import { DisplayFieldType, Field } from '@shared/_models';
import { OverflowContentPluginToNgbTooltipDirective } from '@shared/_directives';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ItemReadValuePipe } from '@shared/pipes';
import { WysiwygFieldComponent } from '@shared/_components/wysiwyg-field/wysiwyg-field.component';

@Component({
  selector: 'app-table-item-parameter',
  imports: [OverflowContentPluginToNgbTooltipDirective, NgbTooltip, ItemReadValuePipe, WysiwygFieldComponent],
  templateUrl: './table-item-parameter.component.html',
  styleUrls: ['./table-item-parameter.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableItemParameterComponent {
  @Input() readonly parametersAreaHidden: boolean;
  DisplayFieldType = DisplayFieldType;

  parameters = input.required<Field[]>();
  tableItemParameter = computed<Field>(() => this.parameters()?.[0]);
}
