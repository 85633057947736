import { ComponentRef, Directive, Input, OnInit, Type, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DynamicField, FieldConfig } from '../models';
import { DisplayFieldType } from '@shared/_models';
import { FormInputComponent } from '../components/form-input/form-input.component';
import { FormWysiwygComponent } from '../components/form-wysiwyg/form-wysiwyg.component';
import { FormSelectComponent } from '../components/form-select/form-select.component';
import { FormDateComponent } from '../components/form-date/form-date.component';
import { FormLongInputComponent } from '../components/form-long-input/form-long-input.component';
import { FormNumberComponent } from '../components/form-number-input/form-number-input.component';
import { FormMemberComponent } from '@shared/dynamic-form/components/form-member/form-member.component';
import { AutoDestroyDirective } from '@shared/_directives/auto-destroy/auto-destroy.directive';

@Directive({
  selector: '[appDynamicField]',
  standalone: true
})
export class DynamicFieldDirective extends AutoDestroyDirective implements OnInit {
  @Input() set config(value: FieldConfig) {
    this.#onConfigChange(value);
  }
  get config(): FieldConfig {
    return this.#config;
  }
  #config: FieldConfig;

  @Input() control: FormControl;
  @Input() set externalTouched(value: boolean) {
    if (!value) return;

    this.component.instance.externalTouched.set(true);
  }

  @Input() set backendErrorMessage(value: string) {
    this.#onBackendErrorMessageChange(value);
  }
  get backendErrorMessage(): string {
    return this.#backendErrorMessage;
  }
  #backendErrorMessage: string;

  private component: ComponentRef<DynamicField>;

  private readonly fieldTypesMap = new Map<DisplayFieldType, Type<DynamicField>>([
    [DisplayFieldType.TEXT, FormInputComponent],
    [DisplayFieldType.TEXT_LONG, FormLongInputComponent],
    [DisplayFieldType.WYSIWYG, FormWysiwygComponent],
    [DisplayFieldType.NUMBER, FormNumberComponent],
    [DisplayFieldType.SELECT, FormSelectComponent],
    [DisplayFieldType.SMILES, FormInputComponent],
    [DisplayFieldType.SEQUENCE, FormInputComponent],
    [DisplayFieldType.PEPSEQ, FormInputComponent],
    [DisplayFieldType.DATE, FormDateComponent],
    [DisplayFieldType.INTEGER, FormNumberComponent],
    [DisplayFieldType.URL, FormInputComponent],
    [DisplayFieldType.USER, FormMemberComponent]
  ]);

  constructor(private readonly container: ViewContainerRef) {
    super();
  }

  ngOnInit() {
    const component: Type<DynamicField> = this.fieldTypesMap.get(this.config.type);

    if (!component) {
      const supportedTypes = Array.from(this.fieldTypesMap.keys()).join(', ');

      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }

    this.component = this.container.createComponent<DynamicField>(component);
    this.component.instance.config.set(this.config);
    this.component.instance.control.set(this.control);
    this.component.instance.backendErrorMessage.set(this.backendErrorMessage);
    this.#setInitialValue();
  }

  #setInitialValue() {
    const control = this.component.instance.control();

    if (this.config.default && this.config.value === undefined) {
      control.setValue(this.config.default);
    }
  }

  #onBackendErrorMessageChange(value: string) {
    if (value === this.#backendErrorMessage) return;

    this.#backendErrorMessage = value;
    this.component?.instance.backendErrorMessage.set(value);
  }

  #onConfigChange(value: FieldConfig) {
    if (value === this.#config) return;

    this.#config = value;
    this.component?.instance.config.set(value);
    this.control?.setValue(value.value);
  }
}
