import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, Observable, startWith } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { selectCategories } from '@shared/_root-store/categories-store/categories.selectors';
import { getBreadcrumbsViewData } from '@shared/breadcrumbs/utils/get-breadcrumbs-view-data';
import { CategoryDto, DevelopmentType } from '@shared/_models';
import { IBreadcrumb } from '@shared/breadcrumbs/models/breadcrumb.iterface';
import { ROOT_CATEGORY_ID } from '@app/domain/category';

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {
  developmentType = input.required<DevelopmentType>();
  #currentCategoryId$: Observable<string> = this.route.params.pipe(map(params => params.categoryId));
  routeParams = toSignal(this.route.params);
  #categories$: Observable<CategoryDto[]> = this.store.select(selectCategories).pipe(filter(categories => categories.length > 0));
  isItemDetailsView$: Observable<boolean> = this.route.params.pipe(map(params => 'researchObjectId' in params || 'experimentId' in params));

  breadcrumbsData$: Observable<IBreadcrumb[]> = this.#currentCategoryId$.pipe(
    mergeMap(currentCategoryId =>
      this.#categories$.pipe(
        filter(() => currentCategoryId !== ROOT_CATEGORY_ID),
        map(categories => getBreadcrumbsViewData(categories, currentCategoryId)),
        startWith([])
      )
    )
  );

  DevelopmentType = DevelopmentType;
  ROOT_CATEGORY_ID = ROOT_CATEGORY_ID;

  constructor(
    private route: ActivatedRoute,
    private store: Store
  ) {}

  getRoute(categoryId: string): string[] {
    return [
      '',
      'app',
      this.routeParams().workspaceId,
      this.routeParams().projectId,
      `${this.developmentType()}s`,
      this.routeParams().viewMode,
      categoryId
    ];
  }

  onBackBtnClick() {
    history.back();
  }
}
