<app-breadcrumbs [developmentType]="developmentType()"></app-breadcrumbs>
<div class="details-nav">
  <div class="details-nav__body">
    <div class="row flex-fill align-items-center details-nav__row">
      <div class="details-nav__info-container col-5">
        <img
          src="../../../../assets/icons/atom_gradient_icon.svg"
          class="details-nav__item-icon"
          alt="research object icon"
          *ngIf="developmentType() === DevelopmentType.researchObject"
        />
        <img
          src="../../../../assets/icons/chart_gradient_icon.svg"
          class="details-nav__item-icon"
          alt="experiment icon"
          *ngIf="developmentType() === DevelopmentType.experiment"
        />
        <div class="d-flex flex-column w-100">
          <div class="item-details-header-containter">
            <span
              data-testid="item-details-header-name"
              class="details-nav__name"
              [ngClass]="{ 'item-details-header-name-exp': developmentType() === DevelopmentType.experiment }"
              (mouseenter)="nameHovered$.next(true)"
              (mouseleave)="nameHovered$.next(false)"
            >
              {{ developmentItem().name }}
            </span>
            @if (editable()) {
              <app-icon
                class="me-1 header-name-edit-icon"
                width="0.688rem"
                height="0.688rem"
                fileName="pen_icon.svg"
                [color]="IconColors.blue"
                (click)="onEditNameClick()"
                (mouseenter)="nameHovered$.next(true)"
                (mouseleave)="nameHovered$.next(false)"
              ></app-icon>
            }
            <app-copy-button
              *ngIf="showNameCopyButton$ | async"
              [value]="developmentItem().name"
              (mouseenter)="nameHovered$.next(true)"
              (mouseleave)="nameHovered$.next(false)"
            ></app-copy-button>
          </div>
          <div *ngIf="experimentStatus()">
            <app-experiment-status-dropdown
              [selectedStatus]="experimentStatus()"
              (update)="update.emit({ data: $event, area: UpdateArea.STATUS })"
            ></app-experiment-status-dropdown>
          </div>
          <span data-testid="item-details-oid" class="details-nav__oid details-nav__oid--{{ developmentType() }}">
            {{ developmentItem().oid }}
          </span>
        </div>
      </div>
      <div
        class="details-nav__nav justify-content-end col-7 pe-0"
        [ngClass]="{
          'details-nav__nav--experiment': developmentType() === DevelopmentType.experiment,
          'details-nav__nav--research-object': developmentType() === DevelopmentType.researchObject
        }"
      >
        <div class="details-nav__separator"></div>
        <div class="details-nav__nav-items-container">
          <a
            data-testid="item-details-object-details-button"
            class="details-nav__action-button details-nav__action-button--info"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLinkActive="details-nav__action-button--active"
            [routerLink]="['./info']"
            queryParamsHandling="preserve"
            placement="top"
            ngbTooltip="Object details"
          ></a>
          <a
            data-testid="item-details-files-button"
            class="details-nav__action-button details-nav__action-button--file"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLinkActive="details-nav__action-button--active"
            [routerLink]="['./files']"
            queryParamsHandling="preserve"
            placement="top"
            ngbTooltip="Files"
          ></a>
          <a
            data-testid="item-details-changelog-button"
            class="details-nav__action-button details-nav__action-button--changelog"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLinkActive="details-nav__action-button--active"
            [routerLink]="['./changelog']"
            queryParamsHandling="preserve"
            placement="top"
            ngbTooltip="Changelog"
          ></a>
          <a class="details-nav__action-button details-nav__action-button--more-actions">
            <app-more-actions
              [actions]="moreActions()"
              [color]="IconColors.mediumGrey"
              [hoverColor]="IconColors.blue"
              tooltip="More"
              class="dark"
            ></app-more-actions>
          </a>
        </div>
      </div>
    </div>
  </div>
  <button
    data-testid="item-details-previous-object-button"
    class="details-nav__previous-button"
    (click)="onPreviousBtnClick()"
    [disabled]="!previousBtnId()"
    ngbTooltip="Previous {{ developmentType() === 'experiment' ? 'experiment' : 'object' }}"
    placement="bottom"
  >
    <img src="../../../../assets/icons/arrow_left_icon.svg" alt="previous button icon" />
  </button>
  <button
    data-testid="item-details-next-object-button"
    class="details-nav__next-button"
    (click)="onNextBtnClick()"
    [disabled]="!nextBtnId()"
    ngbTooltip="Next {{ developmentType() === 'experiment' ? 'experiment' : 'object' }}"
    placement="bottom"
  >
    <img src="../../../../assets/icons/arrow_left_icon.svg" alt="next button icon" />
  </button>
</div>
<ng-template #itemDetailsEditNameModal let-modal>
  <app-item-details-edit-name-modal
    [developmentType]="developmentType()"
    [itemDetailsName]="developmentItem().name"
    (closeModal)="modal.close()"
    (saveBtnClick)="update.emit({ data: $event, area: UpdateArea.NAME })"
  ></app-item-details-edit-name-modal>
</ng-template>
