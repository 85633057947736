import { Component, EventEmitter, Input, Output, computed, signal } from '@angular/core';
import { AsyncPipe, LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { filter, switchMap } from 'rxjs/operators';
import { toObservable } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

import { DropdownComponent } from '@shared/_components/dropdown/dropdown.component';
import { ProjectProxy, IMemberEdit, MemberRole, UserAvatar } from '@shared/_models';
import { selectAvatarForId } from '@shared/_root-store/users-store/users.selectors';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { Observable } from 'rxjs';

export enum ELEMENT_TYPE {
  PROJECT = 'project',
  WORKSPACE = 'workspace'
}

@Component({
  selector: 'app-member-element',
  standalone: true,
  imports: [AsyncPipe, LowerCasePipe, NgClass, NgIf, DropdownComponent, UserAvatarComponent],
  templateUrl: './member-element.component.html',
  styleUrls: ['./member-element.component.scss']
})
export class MemberElementComponent {
  @Input() set member(value: IMemberEdit) {
    this.memberSignal.set(value);
  }
  memberSignal = signal<IMemberEdit>(null);

  @Input() index: number;
  @Input() type: ELEMENT_TYPE;
  @Input() projectProxy: ProjectProxy;
  @Output() memberUpdate = new EventEmitter<IMemberEdit>();

  badge = computed(() => this.memberSignal()?.roleAfterEdit?.[0]);
  editableRoles = computed(() => {
    if (this.projectProxy.isOwner) {
      return [MemberRole.owner, MemberRole.lead, MemberRole.editor, MemberRole.reader];
    }

    const member = this.memberSignal();

    if (member?.roleBeforeEdit == MemberRole.owner || member?.roleBeforeEdit == MemberRole.lead) {
      return [member.roleBeforeEdit];
    }

    return [MemberRole.editor, MemberRole.reader];
  });
  avatar$: Observable<UserAvatar> = toObservable(this.memberSignal).pipe(
    filter(Boolean),
    switchMap(member => this.store.select(selectAvatarForId(member.user.id)))
  );

  readonly ELEMENT_TYPE = ELEMENT_TYPE;
  readonly MEMBER_ROLE = MemberRole;

  constructor(private readonly store: Store) {}

  onMemberDeleteClick(): void {
    this.memberSignal.update(member => ({ ...member, isRemoved: true }));
    this.memberUpdate.emit(this.memberSignal());
  }

  onMemberRestoreClick(): void {
    this.memberSignal.update(member => ({ ...member, isRemoved: false }));
    this.memberUpdate.emit(this.memberSignal());
  }

  onEditMemberRole(roleAfterEdit: MemberRole): void {
    this.memberSignal.update(member => ({ ...member, roleAfterEdit }));
    this.memberUpdate.emit(this.memberSignal());
  }
}
