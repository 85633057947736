import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo',
  standalone: true,
})
export class LimitToPipe implements PipeTransform {
  transform(arr: any[], amount: number): any[] {
    return arr.slice(0, amount);
  }
}
