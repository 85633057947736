<div class="member-element" [class.member-element--even]="index % 2 == 0" [class.member-element--is-removed]="memberSignal()?.isRemoved">
  <span
    [ngClass]="{
      'member-element__badge--workspace-owner': memberSignal()?.roleAfterEdit === MEMBER_ROLE.owner && type === ELEMENT_TYPE.WORKSPACE
    }"
    class="member-element__badge member-element__badge--{{ memberSignal()?.roleAfterEdit | lowercase }}"
  >
    <span class="member-element__badge__text">{{ badge() }}</span>
  </span>
  <app-user-avatar [imageBase64]="(avatar$ | async)?.base64" avatarSize="1.3rem" data-testid="member-avatar"></app-user-avatar>
  <div class="member-element__info">
    <span class="member-element__info__name">{{ memberSignal()?.user.name }} {{ memberSignal()?.user.surname }}</span>
    <app-dropdown
      *ngIf="type === ELEMENT_TYPE.PROJECT"
      [items]="editableRoles()"
      [selectedItem]="memberSignal()?.roleAfterEdit"
      (select)="onEditMemberRole($event)"
    ></app-dropdown>
    <span *ngIf="type === ELEMENT_TYPE.WORKSPACE" class="member-element__info__mail">{{ memberSignal()?.user.coalesced_email }}</span>
  </div>
  <img
    *ngIf="!memberSignal()?.isRemoved && projectProxy?.canRemoveMember(memberSignal())"
    class="member-element__delete-btn"
    src="assets/icons/clear_icon.svg"
    (click)="onMemberDeleteClick()"
  />
  <img
    *ngIf="memberSignal()?.isRemoved"
    class="member-element__restore-btn"
    src="assets/icons/restore_icon.svg"
    (click)="onMemberRestoreClick()"
  />
</div>
