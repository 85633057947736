<section>
  <div class="accordion-item">
    <div class="vertical-allign">
      <div class="row error-container">
        <div class="col">
          <header>
            <p class="blue-color">404 error</p>
            <h1 class="not-found-header">Page Not Found</h1>
          </header>
          <div>
            <p class="description">
              Sorry, the page you are looking for doesn&acute;t exist.
              <br />
              Here are some helpful links:
            </p>
            <div>
              <a [routerLink]="['', 'app', (currentOrInitialWorkspace$ | async)?.id, 'projects']">
                <app-button class="general-primary h-45">Go home</app-button>
              </a>
            </div>
          </div>
        </div>
        <div class="col">
          <img src="../../../../assets/images/404.svg" />
        </div>
      </div>
    </div>
  </div>
</section>
