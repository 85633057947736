import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrorDto } from '@shared/_models';

@Pipe({
  name: 'validationError',
  standalone: true
})
export class ValidationErrorPipe implements PipeTransform {
  transform(value: ValidationErrorDto[], fieldTemplateId: string): string {
    return value.find(error => error.field_template_id === fieldTemplateId)?.detail;
  }
}
