import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { ProjectsState } from './projects.reducer';
import { RootState } from '@shared/_root-store/reducer';
import { DevelopmentType, ProjectDto, RouteParam, ProjectProxy } from '@shared/_models';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';
import { selectCurrentUser } from '@shared/_root-store/users-store/users.selectors';

export const selectProjectsState = createSelector(
  (state: RootState) => state.projects,
  state => state
);

export const selectProjectsDictionary = createSelector(selectProjectsState, (state: ProjectsState) => state.entities);
export const selectProjects = createSelector(selectProjectsDictionary, (dictionary: Dictionary<ProjectDto>) => Object.values(dictionary));
export const selectProjectsLoading = createSelector(selectProjectsState, (state: ProjectsState) => state.loading);
export const selectProjectsLoaded = createSelector(selectProjectsState, (state: ProjectsState) => state.loaded);
export const selectProjectProxy = createSelector(
  selectProjects,
  selectRouteParam(RouteParam.PROJECT_ID),
  selectCurrentUser,
  (projects, projectId, currentUser) => {
    const project = projects?.find(project => project.id === projectId);

    if (!(project && currentUser)) {
      return null;
    }

    return new ProjectProxy(currentUser, project);
  }
);
export const selectSelectedProject = createSelector(
  selectProjectsDictionary,
  selectRouteParam(RouteParam.PROJECT_ID),
  (dictionary: Dictionary<ProjectDto>, projectId: string) => dictionary[projectId]
);
export const selectShowResearchObjects = createSelector(
  selectSelectedProject,
  project => !project?.hidden_development_types?.includes(DevelopmentType.researchObject)
);
export const selectShowExperiments = createSelector(
  selectSelectedProject,
  project => !project?.hidden_development_types?.includes(DevelopmentType.experiment)
);
