import { CategoryTemplate, SectionDto } from '@shared/_models';
import { SectionSelector } from './section-selector';

export function getSectionBySelector(template: CategoryTemplate, selector: SectionSelector): SectionDto {
  if (selector.sectionId) return template.sections.find(section => section.id === selector.sectionId);

  if (selector.byType?.index) return template.sections.filter(section => section.type === selector.byType.type)?.[selector.byType.index];

  return template.sections.filter(section => section.type === selector.byType?.type)?.[0];
}
