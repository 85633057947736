import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

import { DevelopmentType, Image } from '@shared/_models';
import { FileSafeUrlPipe } from '@shared/pipes/file-safe-url.pipe';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { ButtonComponent } from '../../button/button.component';
import { ImageComponent } from '@shared/_components/image/components/image.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TextLongInputComponent } from '../../inputs/text-long-input/text-long-input.component';

@Component({
  selector: 'app-item-image-details',
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    NgIf,
    FileSafeUrlPipe,
    DevelopmentTypeCssClassPipe,
    ButtonComponent,
    ImageComponent,
    TextLongInputComponent,
    ReactiveFormsModule
  ],
  templateUrl: './item-image-details.component.html',
  styleUrls: ['./item-image-details.component.scss']
})
export class ItemImageDetailsComponent implements OnInit {
  @Input() developmentType: DevelopmentType;
  @Input() categoryName: string;
  @Input() developmentItemName: string;
  @Input() editMode = false;
  @Input() image: Image;
  @Input() isMain: boolean;
  @Output() closeBtnClick = new EventEmitter<void>();
  @Output() descriptionChanged = new EventEmitter<string>();
  @Output() editModeChange = new EventEmitter<boolean>();

  descriptionControl: FormControl;

  get markAsInvalid(): boolean {
    const control = this.descriptionControl;

    return control?.invalid && control?.touched;
  }

  ngOnInit() {
    this.descriptionControl = new FormControl(this.image?.description);
  }

  onCloseBtnClick(): void {
    this.closeBtnClick.emit();
  }

  onEditModeChange(): void {
    this.editModeChange.emit(!this.editMode);
  }

  onDescriptionSaveBtnClick(): void {
    this.descriptionChanged.emit(this.descriptionControl.value ?? '');
  }

  onDescriptionCancelBtnClick(): void {
    this.descriptionControl.setValue(this.image?.description);
    this.editModeChange.emit(false);
  }
}
