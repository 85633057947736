<app-item-details-section [sectionName]="'Latest files'" [showEditBtn]="false" [showAddMoreBtn]="editable" (addMore)="onAddMore()">
  <div class="d-flex flex-wrap">
    <div data-testid="file-placeholder" class="mb-3 file" *ngFor="let file of files?.slice(0, 8)">
      <app-details-file-thumb
        [developmentType]="developmentType"
        [file]="file"
        (fileThumbClick)="onFileThumbClick($event)"
        style="display: inline-flex"
      ></app-details-file-thumb>
      <span class="file__name" ngbTooltip="{{ file.name | fileWithoutExtension }}" appOverflowContentPluginToNgbTooltip>
        {{ file.name | fileWithoutExtension }}
      </span>
    </div>
  </div>
  <div>
    <app-button
      [ngClass]="developmentType + '-tertiary w-100 h-45'"
      [routerLink]="['../files']"
      *ngIf="files?.length > 8; else createFileBtn"
    >
      Show list of files
    </app-button>
    <ng-template #createFileBtn>
      <app-button [ngClass]="developmentType + '-tertiary w-100 h-45'" (click)="showAllFiles.emit()">List of all files</app-button>
    </ng-template>
  </div>
</app-item-details-section>
