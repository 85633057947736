import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
//
import { AuthService } from '../../auth.service';
import { AuthRoutingPath } from '../../auth-routing-path';
import { CONTACT_ADMINISTRATOR } from '@app/_auth/constants';

@Component({
  selector: 'app-signup-completed',
  templateUrl: './signup-completed.component.html',
  styleUrls: ['./signup-completed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupCompletedComponent implements OnInit {

  isUserVerified$Subject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isUserVerified$ = this.isUserVerified$Subject.asObservable();

  readonly signInRoutingPath = ['/', AuthRoutingPath.auth, AuthRoutingPath.signIn];
  readonly CONTACT_ADMINISTRATOR = CONTACT_ADMINISTRATOR;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');

    this.authService
      .verifyUser({ token })
      .subscribe(() => {
        this.isUserVerified$Subject.next(true)
      });
  }

}
