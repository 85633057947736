import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
//
import { SigninComponent } from './signin/signin.component';
import { SigninLayoutComponent } from './signin-layout/signin-layout.component';
import { SigninRoutingModule } from './signin-routing.module';
import { AuthLayoutModule } from './../auth-layout/auth-layout.module';
import { SigninFormComponent } from './signin/signin-form/signin-form.component';
import { SigninAdamedComponent } from './signin/signin-adamed/signin-adamed.component';
import { SignupTextComponent } from './signin/signup-text/signup-text.component';
import { SigninFormInputComponent } from './signin/signin-form/signin-form-input/signin-form-input.component';
import { ButtonComponent } from '@app/shared/_components/button/button.component';

const NG_MODULES = [CommonModule, ReactiveFormsModule];

const MODULES = [SigninRoutingModule, AuthLayoutModule];

const COMPONENTS = [
  SigninComponent,
  SigninLayoutComponent,
  SigninAdamedComponent,
  SigninFormComponent,
  SignupTextComponent,
  SigninFormInputComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...NG_MODULES, ...MODULES, ButtonComponent],
})
export class SigninModule {}
