export enum IconColors {
  darkBlue = '#1247a1',
  grey = '#7f888d',
  mediumGrey = '#a2bdcf',
  darkGrey = '#89969f',
  lightGrey = '#dadada',
  blue = '#4aa6e8',
  white = '#ffffff',
  invalidRed = '#eb7171',
  formControlIconColor = '#dadada' // name the same as scss variable with this color
}
