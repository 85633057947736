<ng-container [ngSwitch]="developmentType">
  <app-experiment-table-list
    [listData]="listData"
    [listDataLoaded]="listDataLoaded"
    [sort]="sort"
    *ngSwitchCase="DevelopmentType.experiment"
    (sortChange)="sortChange.emit($event)"
  ></app-experiment-table-list>

  <app-research-object-table-list
    [listData]="listData"
    [listDataLoaded]="listDataLoaded"
    [sort]="sort"
    *ngSwitchCase="DevelopmentType.researchObject"
    (sortChange)="sortChange.emit($event)"
  ></app-research-object-table-list>
</ng-container>
