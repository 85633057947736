import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

import { NavigationService } from '@app/shared/_services/navigation.service';
import { trackByIndex } from 'src/app/shared/_helpers';
import { DisplayFieldType, Field, ResearchObject, SectionType, ViewMode } from '@shared/_models';
import { ResearchObjectsStoreService } from 'src/app/shared/_root-store/research-objects-store/research-objects-store.service';
import { ResearchObjectService } from 'src/app/shared/_services/research-object';
import { TableItemBaseComponent } from '../table-item/table-item.component';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { toSectionFields } from '@app/shared/dto-adapters/field';
import { ResearchObjectParamsService } from '@shared/_services/research-object/research-object-params.service';
import { getInstanceSectionName } from '@app/domain/research-object';
import { getInstancesDefaultName } from '@app/domain/research-object';
import { selectAvatarForId } from '@shared/_root-store/users-store/users.selectors';
import { comparePositions } from '@app/domain/shared/compare-positions';

@Component({
  selector: 'app-research-object-table-item',
  templateUrl: './research-object-table-item.component.html',
  styleUrls: ['./research-object-table-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResearchObjectTableItemComponent extends TableItemBaseComponent<ResearchObject> {
  imageAreaHidden = input<boolean>();
  parametersAreaHidden = input<boolean>();
  instanceAreaHidden = input<boolean>();
  showExperiments = input.required<boolean>();
  currentCategoryHasAnyChild = toSignal(this.currentCategoryHasAnyChild$);
  avatar$: Observable<string> = toObservable(this.itemData).pipe(
    switchMap(experiment => this.store.select(selectAvatarForId(experiment.user_crt.id))),
    map(avatar => avatar?.base64 ?? null)
  );
  showInstancesDetails = false;
  DisplayFieldType = DisplayFieldType;
  SectionType = SectionType;
  instanceDetailsMessage = computed<string>(() => {
    if (this.itemData()?.instances.length > 0) {
      const section = this.itemData().template?.sections?.find(section => section.type === SectionType.INSTANCES);

      return section?.name || getInstancesDefaultName();
    }

    if (!this.itemData()?.instances.length) return '';

    // TODO: confirm ..
    return `No ${this.itemData().instances[0].serial_number.toLowerCase()} on list`;
  });
  showCounter = computed<boolean>(
    () =>
      (!this.currentCategoryHasAnyChild() && !this.instanceAreaHidden()) ||
      (this.currentCategoryHasAnyChild() && !this.itemData()?.template.hidden_section_types?.includes(SectionType.INSTANCES))
  );
  workspaceId = this.workspaceService.getCurrentWorkspaceId();
  parameters = computed<Field[]>(() =>
    toSectionFields(this.itemData(), { byType: { type: SectionType.PARAMETERS } }).sort(comparePositions)
  );
  instancesNumbers = computed<string[]>(() => this.itemData().instances.map(instance => instance.serial_number));
  instanceSectionName = computed<string>(() => getInstanceSectionName(this.itemData()));
  showImages = computed(() => this.itemData()?.template.hidden_section_types?.includes(SectionType.IMAGES));
  showInstances = computed(() => this.itemData()?.template.hidden_section_types?.includes(SectionType.INSTANCES));

  get instancesDefaultName(): string {
    return getInstancesDefaultName();
  }

  readonly trackByIndex = trackByIndex;
  readonly ViewMode = ViewMode;

  constructor(
    public readonly navigationService: NavigationService,
    private readonly researchObjectsStoreService: ResearchObjectsStoreService,
    private readonly researchObjectService: ResearchObjectService,
    private workspaceService: WorkspaceService,
    store: Store,
    private readonly researchObjectParamsService: ResearchObjectParamsService
  ) {
    super(store);
  }

  private exportToXLS($event: Event, researchObject: ResearchObject): void {
    $event.preventDefault();
    $event.stopPropagation();

    const researchObjectName = researchObject.name.split(' ').join('-');
    const fileName = `ro-${researchObjectName}`;

    this.researchObjectsStoreService.generateCSV(fileName, [researchObject.id]);
  }

  private delete($event: Event, researchObject: ResearchObject): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.researchObjectsStoreService.deleteResearchObject(researchObject.id, this.researchObjectParamsService.getHttpParams());
  }

  get contextMenuConfig(): { [key: string]: unknown } {
    return {
      title: 'Actions',
      actions: [
        {
          name: 'Export to XLS',
          type: 'export',
          fn: ($event: Event, itemData: ResearchObject) => this.exportToXLS($event, itemData)
        },
        {
          name: 'Delete Research Object',
          type: 'delete',
          fn: ($event: Event, itemData: ResearchObject) => this.delete($event, itemData)
        }
      ]
    };
  }

  switchShowInstancesDetails(showInstancesDetails: boolean) {
    this.showInstancesDetails = showInstancesDetails;
  }

  toggleResearchObjectSelection($event, id: string): void {
    this.researchObjectService.toggleResearchObjectSelection($event, id);
  }

  isResearchObjectSelected(researchObjectId: string): Observable<boolean> {
    return this.researchObjectService.isResearchObjectSelected(researchObjectId);
  }
}
