<div ngbDropdown (openChange)="isDropdownOpened = $event" class="dropdown">
  <div class="d-flex align-items-center" ngbDropdownToggle>
    <span class="dropdown__selected-item">
      <img class="dropdown__selected-item__icon" src="assets/icons/chevron_7_blue_icon.svg" />
      {{ selectedItem }}
    </span>

    <div ngbDropdownMenu class="dropdown__menu">
      <a
        ngbDropdownItem
        [class.dropdown__menu__item--active]="selectedItem == item"
        class="dropdown__menu__item"
        *ngFor="let item of items"
        (click)="onSelect(item)"
      >
        {{ item }}
      </a>
    </div>
  </div>
</div>
