import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs';

import { ExperimentUpdateDto, Filterable, Pageable, Searchable, Sortable } from '../../_models';
import {
  clearExperimentSelection,
  deleteExperiment,
  deleteExperiments,
  generateCSV,
  getAllExperimentsIds,
  loadExperiments,
  loadExperimentsAndStayOnCurrentPage,
  reloadExperiment,
  selectExperimentAction,
  selectExperimentsAction,
  unselectExperiment,
  unselectExperiments,
  updateExperiment,
  generateMultipleCSV
} from './experiments.actions';
import {
  selectExperiments,
  selectExperimentsLoaded,
  selectExperimentsLoading,
  selectExperimentsPagination,
  selectExperimentsSelectedIds,
  selectExperimentById
} from './experiments.selectors';

@Injectable({
  providedIn: 'root'
})
export class ExperimentsStoreService {
  readonly experiments$ = this.store.select(selectExperiments);
  readonly experimentsLoading$ = this.store.select(selectExperimentsLoading);
  readonly experimentsLoaded$ = this.store.select(selectExperimentsLoaded);
  readonly experimentsPagination$ = this.store.select(selectExperimentsPagination);
  readonly experimentsSelectedIds$ = this.store.select(selectExperimentsSelectedIds);
  readonly experimentsNotEmpty$ = this.experiments$.pipe(map(list => list.length !== 0));
  readonly experimentsSelected$ = this.experimentsSelectedIds$.pipe(
    switchMap(selectedIds => this.experiments$.pipe(map(exps => exps.filter(exp => selectedIds.includes(exp.id)))))
  );

  constructor(private readonly store: Store) {}

  selectExperimentById(id: string) {
    return this.store.select(selectExperimentById(id));
  }

  loadExperiments(params?: Searchable & Pageable) {
    this.store.dispatch(loadExperiments({ params }));
  }

  loadExperimentsAndStayOnCurrentPage(params: Searchable & Pageable & Sortable & Filterable) {
    this.store.dispatch(loadExperimentsAndStayOnCurrentPage({ params }));
  }

  reloadExperiment(experimentId: string) {
    this.store.dispatch(reloadExperiment({ experimentId }));
  }

  getAllExperimentsIds(params?: Params) {
    this.store.dispatch(getAllExperimentsIds({ params }));
  }

  selectExperiment(id: string): void {
    this.store.dispatch(selectExperimentAction({ id }));
  }

  selectExperiments(ids: string[]): void {
    this.store.dispatch(selectExperimentsAction({ ids }));
  }

  unselectExperiment(id: string): void {
    this.store.dispatch(unselectExperiment({ id }));
  }

  unselectExperiments(ids: string[]): void {
    this.store.dispatch(unselectExperiments({ ids }));
  }

  clearExperimentSelection(): void {
    this.store.dispatch(clearExperimentSelection());
  }

  generateCSV(fileName: string, experimentsIds: string[]): void {
    this.store.dispatch(generateCSV({ fileName, experimentsIds }));
  }

  generateMultipleCSV(fileName: string, expIdsGroupedByTemplateId: string[][]) {
    this.store.dispatch(generateMultipleCSV({ fileName, expIdsGroupedByTemplateId }));
  }

  deleteExperiment(experimentId: string, params: Searchable & Pageable & Sortable & Filterable): void {
    this.store.dispatch(deleteExperiment({ experimentId, params }));
  }

  deleteExperiments(experimentsIds: string[], params: Searchable & Pageable & Sortable & Filterable): void {
    this.store.dispatch(deleteExperiments({ experimentsIds, params }));
  }

  updateExperiment(experimentId: string, data: Partial<ExperimentUpdateDto>) {
    this.store.dispatch(updateExperiment({ experimentId, data }));
  }
}
