import { FieldType, Field } from '@shared/_models';
import { isCalculatedField } from './is-calculated-field';

export function isPartOfChemicalStructure(field: Field) {
  return isCalculatedField(field) || isChemicalStructure(field);
}

function isChemicalStructure(field: Field): boolean {
  return [FieldType.SMILES, FieldType.SMILES_NPS].includes(field.field_type) && !field.parent_id;
}
