import { Component, ChangeDetectionStrategy, computed, EventEmitter, Input, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { trackByIndex, getFileSize } from '@shared/_helpers';
import { TableItemBaseComponent } from '../table-item/table-item.component';
import { LinkedFileDto } from '@shared/_models';
import { FileWithoutExtensionPipe } from '@shared/pipes/file-without-extension.pipe';
import { IconComponent } from '../../../_components/icon/components/icon/icon.component';
import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { Store } from '@ngrx/store';
import { getExtension } from './utils';

@Component({
  selector: 'app-file-table-item',
  standalone: true,
  imports: [CommonModule, NgbPopoverModule, FileWithoutExtensionPipe, IconComponent],
  templateUrl: './file-table-item.component.html',
  styleUrls: ['./file-table-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileTableItemComponent extends TableItemBaseComponent<LinkedFileDto> {
  @Input() selected: boolean;
  @Output() readonly selectFile = new EventEmitter<void>();
  @Output() readonly unselectFile = new EventEmitter<void>();
  @Output() loadFiles = new EventEmitter<void>();
  @Output() share = new EventEmitter<void>();

  sharedIsHovered = signal(false);
  fileSize = computed<string>(() => getFileSize(this.itemData().size));

  readonly IconColors = IconColors;
  readonly trackByIndex = trackByIndex;
  readonly getExtension = getExtension;

  constructor(store: Store) {
    super(store);
  }

  toggleFileSelection(event: Event): void {
    if ((event.target as HTMLInputElement).checked) {
      this.selectFile.emit();
    } else {
      this.unselectFile.emit();
    }
  }
}
