import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { DevelopmentType, ExperimentDto, ISortOption, ResearchObject } from 'src/app/shared/_models';

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TableListComponent {
  readonly DevelopmentType = DevelopmentType;

  @Input() listData: ExperimentDto[] | ResearchObject[];
  @Input() listDataLoaded: boolean;
  @Input() developmentType!: DevelopmentType;
  @Input() sort: ISortOption;
  @Output() sortChange: EventEmitter<ISortOption> = new EventEmitter<ISortOption>();
}
