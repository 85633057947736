import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs';
//
import { Alert, AlertType } from './interface';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private subject = new Subject<Alert>();
  public alerts$ = this.subject.asObservable();
  private defaultId = 'default-alert';

  onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter(alert => alert && alert.id === id));
  }

  getMsg(message: any): string {
    const msgObj = message?.detail || message;
    const msg: string[] = [];

    if (Array.isArray(msgObj)) {
      for (const msgObjItem of msgObj) {
        msg.push(msgObjItem.msg);
      }
    } else {
      msg.push(msgObj);
    }

    return msg.join('<br>');
  }

  success(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: AlertType.Success, message }));
  }

  error(message: any, options?: any): void {
    const msgObj = this.getMsg(message);
    const heading = '<h4 class="alert-heading">Error</h4>';
    const msg = options?.pre ? `${heading}${options?.pre}${msgObj}` : `${heading}${msgObj}`;

    this.alert(new Alert({ ...options, type: AlertType.Error, message: msg }));
  }

  info(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: AlertType.Info, message }));
  }

  warn(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
  }

  alert(alert: Alert): void {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  clear(id = this.defaultId): void {
    this.subject.next(new Alert({ id }));
  }
}
