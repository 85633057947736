import { Component, input, output, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { OperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { DevelopmentType, DevelopmentItemLinkedFilesShortDto, RouteParam } from '@shared/_models';
import { FileSharingDevelopmentItemComponent } from '../file-sharing-development-item/file-sharing-development-item.component';
import { FileApiService } from '@shared/_services/file';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';
import { SearchSelectComponent } from '@shared/_components/search-select/search-select.component';
import { ButtonComponent } from '@shared/_components/button/button.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

enum FORM_KEYS {
  DEVELOPMENT_ITEMS_DROPDOWN_CONTROL = 'developmentItemsDropdownControl'
}

@Component({
  selector: 'app-file-sharing-development-items',
  standalone: true,
  imports: [FileSharingDevelopmentItemComponent, SearchSelectComponent, ButtonComponent, NgbTooltip],
  templateUrl: './file-sharing-development-items.component.html',
  styleUrls: ['./file-sharing-development-items.component.scss']
})
export class FileSharingDevelopmentItemsComponent {
  items = input<DevelopmentItemLinkedFilesShortDto[]>();
  fileId = input<string>();
  fileName = input<string>();
  developmentItemId = input<string>();
  developmentType = input<DevelopmentType>();
  link = output<DevelopmentItemLinkedFilesShortDto>();
  unlink = output<DevelopmentItemLinkedFilesShortDto>();

  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));
  showDevelopmentItemSearch = signal<boolean>(false);
  developmentItemsDropdown = new FormGroup({
    [FORM_KEYS.DEVELOPMENT_ITEMS_DROPDOWN_CONTROL]: new FormControl(null)
  });

  readonly FORM_KEYS = FORM_KEYS;

  constructor(
    private readonly fileApiService: FileApiService,
    private readonly store: Store
  ) {}

  searchDevelopmentItems: OperatorFunction<string, readonly DevelopmentItemLinkedFilesShortDto[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(searchForName =>
        this.#searchDevelopmentItems$(searchForName || null).pipe(
          map(response => {
            return response?.length ? response : [];
          })
        )
      )
    );

  onLink(developmentItem: DevelopmentItemLinkedFilesShortDto): void {
    if (!developmentItem) return;

    this.developmentItemsDropdown.controls[FORM_KEYS.DEVELOPMENT_ITEMS_DROPDOWN_CONTROL].reset();
    this.link.emit(developmentItem);
  }

  onUnlink(developmentItem: DevelopmentItemLinkedFilesShortDto) {
    this.unlink.emit(developmentItem);
  }

  #searchDevelopmentItems$(search: string): Observable<DevelopmentItemLinkedFilesShortDto[]> {
    return this.fileApiService.getNoneSharedDevelopmentItems(this.projectId(), this.fileId(), { search }).pipe(map(response => response));
  }
}
