import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { distinctUntilChanged, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { ErrorMessageSection, UpdateArea } from '@shared/_models';
import { combineLatest, Observable, of, BehaviorSubject, timer } from 'rxjs';

@Component({
  selector: 'app-item-details-loading-calculations',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './item-details-loading-calculations.component.html',
  styleUrls: ['./item-details-loading-calculations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemDetailsLoadingCalculationsComponent {
  @Input() set pendingArea(value: UpdateArea) {
    this.#pendingArea$.next(value);
  }
  #pendingArea$ = new BehaviorSubject<UpdateArea>(null);

  @Input() set errorMessage(value: ErrorMessageSection) {
    this.#errorMessage$.next(value);
  }
  #errorMessage$ = new BehaviorSubject<ErrorMessageSection>(null);

  loadingCalculationsComplete$: Observable<boolean> = combineLatest([
    this.#pendingArea$.pipe(pairwise()),
    this.#errorMessage$.pipe(
      map(errorMessage => !!errorMessage?.errorResponse),
      distinctUntilChanged()
    ),
  ]).pipe(
    switchMap(([[previousPendingArea, currentPendingArea], isError]) => {
      if (isError) return of(false);

      if (previousPendingArea === UpdateArea.PARAMETERS && !currentPendingArea)
        return timer(2000).pipe(
          map(_ => false),
          startWith(true)
        );

      return of(false);
    })
  );
  loadingCalculations$: Observable<boolean> = this.#pendingArea$.pipe(map(pendingArea => pendingArea === UpdateArea.PARAMETERS));
}
