import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, switchMap, map } from 'rxjs';

import { Filterable, Pageable, ResearchObject, Searchable, Sortable } from '../../_models';
import {
  clearResearchObjectSelection,
  getAllResearchObjectsIds,
  loadResearchObjects,
  selectResearchObjectAction,
  unselectResearchObject,
  unselectResearchObjects,
  selectResearchObjectsAction,
  generateCSV,
  deleteResearchObject,
  deleteResearchObjects,
  loadResearchObjectsAndStayOnCurrentPage,
  generateMultipleCSV
} from './research-objects.actions';
import {
  selectResearchObjectsLoaded,
  selectResearchObjectsLoading,
  selectResearchObjectsPagination,
  selectResearchObjectsSelectedIds,
  selectResearchObjectById,
  selectResearchObjects,
  selectPreviousResearchObjectId,
  selectNextResearchObjectId
} from './research-objects.selectors';

@Injectable({
  providedIn: 'root'
})
export class ResearchObjectsStoreService {
  readonly researchObjects$ = this.store.select(selectResearchObjects);
  readonly researchObjectsLoading$ = this.store.select(selectResearchObjectsLoading);
  readonly researchObjectsLoaded$ = this.store.select(selectResearchObjectsLoaded);
  readonly researchObjectsPagination$ = this.store.select(selectResearchObjectsPagination);
  readonly researchObjectsSelectedIds$ = this.store.select(selectResearchObjectsSelectedIds);
  readonly researchObjectsSelected$ = this.researchObjectsSelectedIds$.pipe(
    switchMap(selectedIds => this.researchObjects$.pipe(map(ros => ros.filter(ro => selectedIds.includes(ro.id)))))
  );

  constructor(private readonly store: Store) {}

  selectResearchObjectById(id: string) {
    return this.store.select(selectResearchObjectById(id));
  }

  selectPreviousResearchObjectId(currentId: string): Observable<string | number> {
    return this.store.select(selectPreviousResearchObjectId(currentId));
  }

  selectNextResearchObjectId(currentId: string): Observable<string | number> {
    return this.store.select(selectNextResearchObjectId(currentId));
  }

  loadResearchObjects(params: Params = {}) {
    this.store.dispatch(loadResearchObjects({ params }));
  }

  loadResearchObjectsAndStayOnCurrentPage(params: Searchable & Pageable & Sortable & Filterable) {
    this.store.dispatch(loadResearchObjectsAndStayOnCurrentPage({ params }));
  }

  getAllResearchObjectsIds(params?: Params) {
    this.store.dispatch(getAllResearchObjectsIds({ params }));
  }

  selectResearchObject(id: string): void {
    this.store.dispatch(selectResearchObjectAction({ id }));
  }

  toggleResearchObjectSelection(checked: boolean, id: string): void {
    if (checked) {
      this.selectResearchObject(id);
    } else {
      this.unselectResearchObject(id);
    }
  }

  toggleAllResearchObjectsSelection(checked: boolean): void {
    if (checked) {
      this.getAllResearchObjectsIds();
    } else {
      this.clearResearchObjectSelection();
    }
  }

  selectResearchObjects(ids: string[]): void {
    this.store.dispatch(selectResearchObjectsAction({ ids }));
  }

  unselectResearchObject(id: string): void {
    this.store.dispatch(unselectResearchObject({ id }));
  }

  unselectResearchObjects(ids: string[]): void {
    this.store.dispatch(unselectResearchObjects({ ids }));
  }

  clearResearchObjectSelection(): void {
    this.store.dispatch(clearResearchObjectSelection());
  }

  generateCSV(fileName: string, researchObjectsIds: string[]): void {
    this.store.dispatch(generateCSV({ fileName, researchObjectsIds }));
  }

  generateMultipleCSV(fileName: string, roIdsGroupedByTemplateId: string[][]) {
    this.store.dispatch(generateMultipleCSV({ fileName, roIdsGroupedByTemplateId }));
  }

  deleteResearchObject(researchObjectId: string, params: Searchable & Pageable & Sortable & Filterable): void {
    this.store.dispatch(deleteResearchObject({ researchObjectId, params }));
  }

  deleteResearchObjects(
    researchObjectsIds: string[],
    params: Searchable & Pageable & Sortable & Filterable,
    onDeleteFailed: (deleteFailRoIds: string[], deleteFailRelatedExpCount: number) => void
  ): void {
    this.store.dispatch(deleteResearchObjects({ researchObjectsIds, params, onDeleteFailed }));
  }
}
