import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileWithoutExtension',
  standalone: true,
})
export class FileWithoutExtensionPipe implements PipeTransform {
  transform(fileName: string): string {
    const lastDotIndex = fileName?.lastIndexOf('.');

    if (lastDotIndex !== -1) {
      return fileName?.substring(0, lastDotIndex);
    } else {
      return fileName;
    }
  }
}
