<div class="select-status" [class.open]="isOpen" #statusSelect>
  <div class="selected-option" (click)="toggle()">
    <span class="status-icon" [ngClass]="selectedStatus?.split('_').join('') | statusIcon"></span>
    {{ selectedStatus?.split('_').join(' ') | titlecase }}
    <app-icon class="select-status-icon" width="0.65rem" height="0.4rem" [color]="IconColors.grey" fileName="chevron_4_icon.svg"></app-icon>
  </div>
  <ul class="options" *ngIf="isOpen">
    <li
      [ngClass]="{ 'option-selected': status.name === selectedStatus }"
      *ngFor="let status of statusSelectItems"
      (click)="selectOption(status)"
    >
      <span class="status-icon" [ngClass]="status.name?.split('_').join('') | statusIcon"></span>
      {{ status.name?.split('_').join(' ') | titlecase }}
    </li>
  </ul>
</div>
