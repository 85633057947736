import { Injectable, signal } from '@angular/core';
import { Filterable, IExperimentParams, Pageable, Searchable, Sortable, ViewMode } from '@app/shared/_models';
import { ParamsService } from '../params.service';
import { ROOT_CATEGORY_ID } from '@app/domain/category';
import { ProjectsStoreService } from '@shared/_root-store/projects-store/projects-store.service';

@Injectable({
  providedIn: 'root'
})
export class ExperimentParamsService extends ParamsService<IExperimentParams> {
  params = signal({
    pageSize: 20,
    indexPage: 1,
    search: '',
    categoryId: null,
    sortState: null,
    viewMode: ViewMode.grid,
    exp_ids: [],
    project_id: null
  } as IExperimentParams);

  constructor(private projectService: ProjectsStoreService) {
    super();
  }

  getHttpParams(): Searchable & Pageable & Sortable & Filterable {
    const params = this.params();

    return {
      limit: params.pageSize || 20,
      page: this.isSelectOnlyMode ? this.selectedOnlyIndexPage : params.indexPage,
      ...this.getFilters(),
      prop: params.sortState?.prop,
      order: params.sortState?.order,
      search: this.isSelectOnlyMode ? '' : params.search,
      exp_ids: this.isSelectOnlyMode ? params.exp_ids : [],
      project_id: this.projectService.selectedProject()?.id,
      ...(params.ro_ids && { ro_ids: params.ro_ids })
    };
  }

  private getFilters() {
    const category_id = this.params().categoryId;

    return {
      ...(!!category_id && category_id !== ROOT_CATEGORY_ID && { category_id })
    };
  }
}
