import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportErrorsFieldComponent } from '../import-errors-field/import-errors-field.component';
import { ImportExcelError } from '@app/shared/_models/import-excel';

@Component({
  selector: 'app-import-errors',
  standalone: true,
  imports: [CommonModule, ImportErrorsFieldComponent],
  templateUrl: './import-errors.component.html',
  styleUrls: ['./import-errors.component.scss']
})
export class ImportErrorsComponent implements OnInit, AfterViewInit {
  @Input() importErrors: ImportExcelError[];
  @ViewChild('errorsContainer') errorsContainer: ElementRef;
  errorsLength: number;

  ngOnInit() {
    this.errorsLength = this.importErrors?.map(row => row.row_errors.length).reduce((a, b) => a + b, 0);
  }

  ngAfterViewInit(): void {
    this.errorsContainer.nativeElement.scrollTop = 0;
  }
}
