import { Component } from '@angular/core';
import { AuthRoutingPath } from '@app/_auth/auth-routing-path';

@Component({
  selector: 'app-signup-text',
  templateUrl: './signup-text.component.html',
  styleUrls: ['./signup-text.component.scss'],
})
export class SignupTextComponent {
  readonly signUpRoutingPath = ['/', AuthRoutingPath.signUp];
}
