import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';

import { DevelopmentType, SectionViewMode } from 'src/app/shared/_models';
import { GenericModalComponent } from '@app/shared/_components/generic-modal/generic-modal.component';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { ModalHeaderLeftComponent } from '@app/shared/_components/modal-header-left/modal-header-left.component';
import { ModalHeaderCenterComponent } from '@app/shared/_components/modal-header-center/modal-header-center.component';
import { ActionButton } from '@app/shared/_models/actionButton';
import { CapitalizeFirstPipe } from '@shared/pipes/capitalize-first.pipe';

export enum SectionName {
  ADDITIONAL_INFO = 'additional info',
  NOTES = 'notes',
  SHARE_THE_OBJECT = 'Share the Object',
  SHARE_THE_EXPERIMENT = 'Share the Experiment',
  DELETE = 'delete',
  IMPORT = 'import'
}

// TODO: Refactor this component (add separate modals)
@Component({
  selector: 'app-item-details-info-modal',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    GenericModalComponent,
    NgTemplateOutlet,
    ModalHeaderLeftComponent,
    ModalHeaderCenterComponent,
    CapitalizeFirstPipe
  ],
  templateUrl: './item-details-info-modal.component.html',
  styleUrls: ['./item-details-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsInfoModalComponent implements OnInit, OnChanges {
  readonly SectionViewMode = SectionViewMode;
  readonly DevelopmentType = DevelopmentType;

  @Input() sectionName: SectionName;
  @Input() developmentType: DevelopmentType;
  @Input() instanceNameSingular: string;
  @Input() itemName: string;
  @Input() categoryName: string;
  @Input() sectionViewMode: SectionViewMode;

  @Output() closeModal = new EventEmitter<null>();
  @Output() editBtnClick = new EventEmitter<null>();
  @Output() cancelBtnClick = new EventEmitter<null>();
  @Output() saveBtnClick = new EventEmitter<null>();

  imgSrc: string;
  closeButton: ActionButton = { text: '', style: '' };
  acceptButton: ActionButton = { text: '', style: '' };
  get header(): string {
    return this.developmentType?.replace('-', ' ');
  }

  constructor() {}

  ngOnInit() {
    switch (this.sectionName) {
      case SectionName.ADDITIONAL_INFO:
        this.imgSrc = 'clipboard_icon.svg';
        this.acceptButton.text = 'Close';
        this.closeButton.text = 'Edit';
        break;
      case SectionName.NOTES:
        this.imgSrc = 'note_icon_white.svg';
        this.acceptButton.text = 'Close';
        this.closeButton.text = 'Edit';
        break;
      case SectionName.SHARE_THE_OBJECT:
        this.imgSrc = 'link_white_icon.svg';
        this.acceptButton.text = 'Close';
        this.closeButton.text = 'Copy link';
        break;
      case SectionName.SHARE_THE_EXPERIMENT:
        this.imgSrc = 'link_white_icon.svg';
        this.acceptButton.text = 'Close';
        this.closeButton.text = 'Copy link';
        break;
      case SectionName.DELETE:
        this.imgSrc = 'trash_icon.svg';
        this.acceptButton.text = 'Close';
        this.acceptButton.style =
          this.developmentType === DevelopmentType.instance
            ? 'research-object-secondary h-45 w-100 ml-8'
            : this.developmentType + '-secondary h-45 w-100 ml-8';
        this.closeButton.text = 'Remove';
        this.closeButton.style =
          this.developmentType === DevelopmentType.instance
            ? 'research-object-tertiary w-100 h-45 mr-8'
            : this.developmentType + '-tertiary w-100 h-45 mr-8';
        break;
      case SectionName.IMPORT:
        switch (this.developmentType) {
          case DevelopmentType.researchObject:
            this.imgSrc = 'atom_icon_white_small.svg';
            break;
          case DevelopmentType.experiment:
            this.imgSrc = 'icon_experiment_white_small.svg';
            break;
        }
        break;
    }
  }

  ngOnChanges(): void {
    if (this.sectionViewMode === SectionViewMode.edit) {
      this.acceptButton.text = 'Save';
      this.closeButton.text = 'Cancel';
    } else {
      this.acceptButton.text = 'Close';
      this.closeButton.text = 'Edit';
    }
  }

  close(reason: CloseReason) {
    if (this.sectionName === SectionName.ADDITIONAL_INFO || this.sectionName === SectionName.NOTES) {
      switch (reason) {
        case CloseReason.CLOSE:
          switch (this.sectionViewMode) {
            case SectionViewMode.edit:
              this.cancelBtnClick.emit();
              break;
            case SectionViewMode.display:
              this.editBtnClick.emit();
              break;
            default:
              this.editBtnClick.emit();
          }
          break;
        case CloseReason.ACCEPT:
          switch (this.sectionViewMode) {
            case SectionViewMode.edit:
              this.saveBtnClick.emit();
              break;
            case SectionViewMode.display:
              this.closeModal.emit();
              break;
            default:
              this.closeModal.emit();
          }
          break;
      }
    } else {
      reason === CloseReason.CLOSE ? this.saveBtnClick.emit() : this.editBtnClick.emit();
    }
  }
}
