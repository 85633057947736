import { CategoryDto, DevelopmentType } from '@shared/_models';
import { ROOT_CATEGORY_ID } from '@app/domain/category';

export function currentCategoryHasAnyChild(categoryId: string, categories: CategoryDto[], developmentType: DevelopmentType) {
  if (categories.length === 0) return false;

  if (categoryId === ROOT_CATEGORY_ID)
    return categories.some(category => !category.parent_id && category.development_type === developmentType);

  return categories.some(category => category.parent_id === categoryId);
}
