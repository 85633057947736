<div class="d-flex">
  <span
    *ngIf="textBeforeCounter()"
    class="text-before-counter"
    [class.text-before-counter--counter-visible]="shouldCounterBeDisplayed()"
    ngbTooltip="{{ textBeforeCounter() }}"
    appOverflowContentPluginToNgbTooltip
    [defaultTooltipValue]="defaultTooltipValue()"
  >
    {{ textBeforeCounter() }}
  </span>
  <span
    data-testid="item-counter"
    *ngIf="shouldCounterBeDisplayed()"
    class="counter"
    triggers="mouseenter:mouseleave"
    [ngbPopover]="counterTooltip"
    [popoverTitle]="tooltipHeader()"
    [container]="container()"
  >
    {{ items().length }}
  </span>
  <span *ngIf="!items()?.length" ngbTooltip appOverflowContentPluginToNgbTooltip [defaultTooltipValue]="defaultTooltipValue()">
    {{ showCounter() ? '-' : ' ' }}
  </span>
</div>

<ng-template #counterTooltip>
  <ul>
    <li *ngFor="let item of items()">
      {{ item }}
    </li>
  </ul>
</ng-template>
