import { Field } from '@shared/_models';
import { ComplexField, toComplexFields } from '@app/domain/field/complex-field';
import { not, or } from '@shared/_helpers/fp';
import { isPartOfPepseq } from '@app/domain/field/is-part-of-pepseq';
import { isPartOfChemicalStructure } from '@app/domain/field/is-part-of-chemical-structure';

export enum FieldKind {
  NON_COMPLEX,
  COMPLEX
}

export type BaseFieldViewData = {
  type: FieldKind;
  dragOver?: boolean; // określa czy użytkownik planuje upuścić inne pole nad tym polem
};

export type NonComplexFieldViewData = BaseFieldViewData & {
  type: FieldKind.NON_COMPLEX;
  field: Field;
};

export type ComplexFieldViewData = BaseFieldViewData & {
  type: FieldKind.COMPLEX;
  field: ComplexField;
};

export type FieldViewData = NonComplexFieldViewData | ComplexFieldViewData;

/**
 * Create array with prepared fields view data: Field or ComplexField.
 * This array allows iterating over fields (both complex and non-complex) with preserver order of fields (taken from
 * category template)
 * @param fields
 */
export function toFieldsViewData(fields: Field[]): FieldViewData[] {
  const complexFields = toComplexFields(fields);
  const notComplexFields = fields.filter(not(or(isPartOfPepseq, isPartOfChemicalStructure)));

  return (
    fields
      .map(field => {
        const complexField = complexFields.find(group => group.parent.field_template_id === field.field_template_id);
        const notComplexField = notComplexFields.find(notComplexField => notComplexField.field_template_id === field.field_template_id);

        if (complexField) {
          return {
            type: FieldKind.COMPLEX,
            field: complexField,
            dragOver: false
          } as ComplexFieldViewData;
        }

        if (notComplexField) {
          return {
            type: FieldKind.NON_COMPLEX,
            field: notComplexField,
            dragOver: false
          } as NonComplexFieldViewData;
        }

        return null;
      })
      .filter(data => !!data)
      // sort fields per field.position property
      .sort((dataA, dataB) => {
        const positionA = dataA.type === FieldKind.NON_COMPLEX ? dataA.field.position : dataA.field.parent.position;
        const positionB = dataB.type === FieldKind.NON_COMPLEX ? dataB.field.position : dataB.field.parent.position;

        return positionA - positionB;
      })
  );
}
