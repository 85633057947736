<div class="selected-bar">
  <div class="selected-bar__left-container d-flex">
    <button
      data-testid="show-selected-items-button"
      class="selected-bar__selected-btn selected-bar__btn"
      [ngClass]="{ 'selected-bar__btn--active': onlySelectedShowed, disabled_button: selectedItemsAmount === 0 }"
      [disabled]="selectedItemsAmount === 0"
      (click)="onShowSelectedBtnClick()"
    >
      <i class="selected-bar__show-selected-icon selected-bar__icon"></i>
      Show: {{ selectedItemsAmount }} selected
    </button>
    <ng-container *ngIf="!onlySelectedShowed">
      <span class="selected-bar__select-wrapper">
        Select:
        <button data-testid="select-all-items-button" class="selected-bar__btn" (click)="onSelectAll()">All</button>
        <button data-testid="select-displayed-items-button" class="selected-bar__btn" (click)="onSelectAllOnCurrentPage()">
          Displayed
        </button>
      </span>
      <span class="selected-bar__unselect_wrapper">
        Unselect:
        <button
          data-testid="unselect-all-items-button"
          [disabled]="selectedItemsAmount === 0"
          [ngClass]="{ disabled_button: selectedItemsAmount === 0 }"
          class="selected-bar__btn"
          (click)="onUnselectAll()"
        >
          All
        </button>
      </span>
    </ng-container>
  </div>
  <div class="selected-bar__right-container" *ngIf="viewMode !== ViewMode.files">
    <button class="selected-bar__import selected-bar__btn me-4" (click)="onImport($event)">
      <div class="btn-status">
        <span class="spinner-border spinner-border-sm me-2" role="status"></span>
      </div>
      <span class="selected-bar__import-btn-content">
        <i class="selected-bar__import_icon d-inline-block selected-bar__icon"></i>
        import
      </span>
    </button>
    <button
      data-testid="export-selected-objects-button"
      [disabled]="selectedItemsAmount === 0"
      [ngClass]="{ disabled_button: selectedItemsAmount === 0 }"
      class="selected-bar__export selected-bar__btn me-4"
      [class.generatingFile]="generatingFile"
      (click)="onExportSelected($event)"
    >
      <div class="btn-status">
        <span class="spinner-border spinner-border-sm me-2" role="status"></span>
      </div>
      <span class="selected-bar__export-btn-content">
        <i class="selected-bar__export_icon d-inline-block selected-bar__icon"></i>
        export
      </span>
    </button>

    <button
      *ngIf="editable"
      data-testid="delete-selected-objects-button"
      [disabled]="selectedItemsAmount === 0"
      [ngClass]="{ disabled_button: selectedItemsAmount === 0 }"
      class="selected-bar__delete selected-bar__btn"
      (click)="onDeleteSelected($event)"
    >
      <i class="selected_bar__delete-icon selected-bar__icon"></i>
      delete
    </button>
  </div>
</div>
