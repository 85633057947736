<div class="experiment-results-details" data-test="expPrimRes">
  <ng-container *ngIf="resultToDisplay() as primaryResult; else noPrimaryResults">
    <div class="experiment-results-details__container" *ngIf="resultType() === DisplayFieldType.WYSIWYG">
      <app-wysiwyg-field
        [headerCustomStyles]="{ 'font-size': '1.375rem', 'font-weight': '700', color: 'inherit' }"
        iconSize="1rem"
        [iconColor]="hovered() ? IconColors.white : IconColors.darkGrey"
        [fieldValue]="primaryResult.value"
        tooltip="Experiment result"
      ></app-wysiwyg-field>
      <span class="items-card__description-text property-name">{{ primaryResult.name }}</span>
    </div>
    <ng-container *ngIf="resultType() === DisplayFieldType.TEXT_LONG">
      <div
        class="experiment-results-details__container experiment-results-details__container--long"
        [ngbPopover]="descriptionTpl"
        [popoverTitle]="primaryResult.name"
        triggers="mouseenter:mouseleave"
        container="body"
      >
        <header class="items-card__description-header" data-test="descriptionHeader">{{ primaryResult.name }}</header>
        <span class="items-card__description-text property-name">Mouse over here to see details</span>
      </div>

      <ng-template #descriptionTpl>
        <div class="experiment-results-details__container">
          <div class="experiment-results-details__popover-text w-100" [innerHtml]="primaryResult.value" data-test="popupConclusions"></div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="resultType() === DisplayFieldType.NUMBER">
      <div class="experiment-results-details__container">
        <header ngbTooltip="Experiment result" class="items-card__description-header" data-testid="field-value">
          {{ formattedResultToDisplayValue() }}
        </header>
        <span class="items-card__description-text property-name">{{ primaryResult.name }}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="resultType() === DisplayFieldType.TEXT || resultType() === DisplayFieldType.DATE">
      <div class="experiment-results-details__container">
        <header class="items-card__description-header" data-test="resultHeader" ngbTooltip="{{ primaryResult | itemReadValue }}">
          {{ (primaryResult | itemReadValue) || '-' }}
        </header>
        <span class="items-card__description-text property-name">{{ primaryResult.name }}</span>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noPrimaryResults>
    <div class="experiment-results-details__container">-</div>
  </ng-template>
</div>
