import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { Field, ValidationErrorDto, ErrorMessageSection } from '@shared/_models';

export function getErrorMessage(errorResponse: HttpErrorResponse): string {
  if (!errorResponse) return null;

  return (
    (errorResponse.error?.detail as string) ||
    (errorResponse.error?.validation_errors[0]?.detail as string) ||
    errorResponse.message ||
    errorResponse.statusText
  );
}

export function findValidationError(errorMessage: ErrorMessageSection, field: Field): ValidationErrorDto {
  if (typeof errorMessage?.errorResponse === 'boolean') return;

  const validationErrors = errorMessage?.errorResponse?.error?.validation_errors as ValidationErrorDto[];
  const match = validationErrors?.find(error => error.field_template_id === field.field_template_id);

  return match;
}

export function addErrorToForm(errorResponse: HttpErrorResponse, form: FormGroup) {
  const errorDetails = errorResponse?.error;

  if (!errorDetails) {
    return;
  }

  const handleValidationError = (error: ValidationErrorDto) => {
    const control = form.get(error.location);
    if (control) {
      control.setErrors({ backendError: error.detail });
    } else {
      throw errorResponse;
    }
  };

  if (errorDetails.validation_errors && errorDetails.validation_errors.length > 0) {
    errorDetails.validation_errors.forEach(handleValidationError);
  } else if (errorDetails.location) {
    handleValidationError(errorDetails);
  }
}
