import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-empty-search-result',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './empty-search-result.component.html',
  styleUrls: ['./empty-search-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptySearchResultComponent {
  @Input() message: string;
  @Input() icon: string;

  iconUrl(): string {
    return `url(${this.icon})`;
  }
}
