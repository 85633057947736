import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
//
import { STORAGE_TOKEN_KEY } from '../../../_auth/constants';
import { AuthSignInResponse, Token } from 'src/app/_auth/interface';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  readonly storage = this.document.defaultView?.sessionStorage;
  readonly localStorage = this.document.defaultView?.localStorage;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {}

  setLocalStorageUser(key: string, value: AuthSignInResponse): void {
    this.localStorage?.setItem(key, JSON.stringify(value));
  }

  getLocalStorageUser(key: string): AuthSignInResponse {
    return JSON.parse(this.localStorage?.getItem(key));
  }

  setToken(access_token: Token): void {
    this.storage?.setItem(STORAGE_TOKEN_KEY, `${access_token}`);
  }

  unsetToken(): void {
    this.storage?.removeItem(STORAGE_TOKEN_KEY);
  }

  getToken(): string {
    return this.storage?.getItem(STORAGE_TOKEN_KEY);
  }

}
