<div class="grid-container" [ngClass]="{ 'grid-container--selected': selected }">
  <div class="selection-col text-center">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" (change)="toggleFileSelection($event)" [checked]="selected" />
    </div>
  </div>
  <div data-testid="file-name" class="item-label name-col">
    <app-icon class="file-icon" width="1.125rem" height="1.375rem" fileName="file_icon.svg" [color]="IconColors.lightGrey"></app-icon>
    {{ itemData().name | fileWithoutExtension }}
  </div>
  @if (itemData().count_experiments + itemData().count_research_objects > 1) {
    <div class="item-label share-col" (click)="share.emit()">
      <div class="share-icon-container" (mouseenter)="sharedIsHovered.set(true)" (mouseleave)="sharedIsHovered.set(false)">
        @if (itemData().count_research_objects > 0) {
          <div class="shared__objects">
            <img src="../../../assets/icons/atom_gradient_icon.svg" />
            <span class="shared__object_count">{{ itemData().count_research_objects }}</span>
          </div>
        }
        @if (itemData().count_experiments > 0) {
          <div class="shared__objects">
            <img src="../../../assets/icons/chart_gradient_icon.svg" />
            <span class="shared__object_count">{{ itemData().count_experiments }}</span>
          </div>
        }
        @if (sharedIsHovered()) {
          <div class="share-hovered-container">
            <app-icon
              class="share-icon-hovered"
              width="0.6rem"
              height="0.6rem"
              fileName="share_icon.svg"
              [color]="IconColors.blue"
            ></app-icon>
          </div>
        }
      </div>
    </div>
  } @else {
    <div class="file-table-share">
      <div class="item-label share-col">
        <div class="share-icon-container" (mouseenter)="sharedIsHovered.set(true)" (mouseleave)="sharedIsHovered.set(false)">
          <app-icon
            class="share-icon"
            width="0.7rem"
            height="0.75rem"
            fileName="share_icon.svg"
            [color]="sharedIsHovered() ? IconColors.blue : IconColors.grey"
          ></app-icon>
          <div class="align-left" (click)="share.emit()">SHARE</div>
        </div>
      </div>
    </div>
  }
  <div class="item-label">{{ fileSize() }}</div>
  <div class="item-label">
    <img src="assets/icons/user_2_icon.svg" alt="user icon" />
    {{ itemData()?.user_crt?.name }}
    {{ itemData()?.user_crt?.surname }}
  </div>
  <div class="item-label" data-testid="file-type">{{ getExtension(itemData().name.toUpperCase()) }}</div>
  <div class="item-label align-right">{{ itemData()?.date_created | date: 'dd.MM.YYYY' }}</div>
</div>

<!--TODO: do wyrzucenia?-->
<ng-template let-index="index" let-itemData="itemData()" let-contextMenuConfig #actionsBtnAndPopover>
  <button
    #popover="ngbPopover"
    class="btn btn-transparent px-0 lg-table__action-btn"
    [ngClass]="{ active: popover.isOpen() }"
    [ngbPopover]="actionsPopoverContent"
    [popoverTitle]="actionsPopoverTitle"
    popoverClass="lg-popover"
    container="body"
    appClickStopPropagation
  >
    <img src="../../../assets/icons/ellipsis_icon.svg" alt="ellipsis icon" />
  </button>

  <ng-template #actionsPopoverTitle>{{ contextMenuConfig?.title || 'Actions' | uppercase }}</ng-template>
  <ng-template #actionsPopoverContent>
    <ng-container *ngFor="let action of contextMenuConfig.actions; last as isLast; trackBy: trackByIndex">
      <a href="" (click)="action.fn($event, itemData())">{{ action.name }}</a>
      <hr *ngIf="!isLast" divider />
    </ng-container>
  </ng-template>
</ng-template>
