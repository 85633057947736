import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { ViewContainerComponent } from './view-container/view-container.component';
import { GridModule } from '../grid';
import { TableModule } from '../table';
import { ListPaginationComponent } from '@app/shared/_components/list/list-pagination/list-pagination.component';
import { ListPageSizeSelectorComponent } from '@shared/_components/list/list-page-size-selector/list-page-size-selector.component';

const NG_MODULES = [CommonModule];

const LIB_MODULES = [ScrollingModule];

const MODULES = [GridModule, TableModule, ListPageSizeSelectorComponent, ListPaginationComponent];

const COMPONENTS = [ViewContainerComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...NG_MODULES, ...LIB_MODULES, ...MODULES],
  exports: [...MODULES, ...COMPONENTS]
})
export class ViewContainerModule {}
