import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
//
import { AlertComponent } from './alert/alert.component';
import { AlertSectionComponent } from './alert-section/alert-section.component';

@NgModule({
  declarations: [AlertComponent, AlertSectionComponent],
  imports: [CommonModule, RouterModule],
  exports: [AlertComponent, AlertSectionComponent],
})
export class AlertModule {}
