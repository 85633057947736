<div class="search-bar mr-2 {{ developmentType | developmentTypeCSSClass }}" [ngClass]="{ 'search-bar--expanded': searchBarExpanded }">
  <div class="input-group">
    <span class="search-bar__input-wrapper">
      <input
        data-testid="search-on-list-input-field"
        type="text"
        class="form-control search-bar__input {{ developmentType | developmentTypeCSSClass }}"
        value="{{ searchValue }}"
        placeholder="Search&hellip;"
        #search
      />
      <i data-testid="search-on-list-close-button" id="btn-close-search" class="fas fa-times" (click)="onCloseBtnClick($event)"></i>
    </span>
    <button
      *ngIf="developmentType === DevelopmentType.researchObject"
      class="btn search-bar__in-instances-btn"
      type="button"
      id="button-addon2"
      [ngClass]="{ 'search-bar__in-instances-btn--active': includeInstances }"
      (click)="onSearchInInstancesBtnClick()"
    >
      In samples
    </button>
  </div>
</div>
