import { ChangeDetectionStrategy, Component, EventEmitter, Output, computed, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { CategoryTemplateDto, DevelopmentType, LinkedObjectDto, SectionType } from '@app/shared/_models';
import { selectTemplates } from '@app/shared/_root-store/category-templates-store/category-templates.selectors';
import { selectAvatarForId } from '@app/shared/_root-store/users-store/users.selectors';
import { Store } from '@ngrx/store';
import { filter, switchMap, map } from 'rxjs';
import { UserAvatarComponent } from '../../../_components/user-avatar/user-avatar.component';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TableItemImageComponent } from '../table-item-image/table-item-image.component';
import { toDevelopmentItemDto } from '@shared/dto-adapters/linked-object';

@Component({
  selector: 'app-experiment-table-item-linked-objects',
  standalone: true,
  imports: [UserAvatarComponent, DatePipe, NgbTooltipModule, CommonModule, TableItemImageComponent],
  templateUrl: './experiment-table-item-linked-objects.component.html',
  styleUrls: ['./experiment-table-item-linked-objects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTableItemLinkedObjects {
  linkedObject = input.required<LinkedObjectDto>();
  linkedObject$ = toObservable(this.linkedObject);
  templates = toSignal<CategoryTemplateDto[]>(this.store.select(selectTemplates));
  avatar$ = this.linkedObject$.pipe(
    filter(linkedObject => !!linkedObject),
    switchMap(linkedObject => this.store.select(selectAvatarForId(linkedObject.user_crt.id)).pipe(map(avatar => avatar?.base64 ?? null)))
  );
  imageAreaHidden = computed(() =>
    this.templates()
      .find(template => template.category_id === this.linkedObject().category_id)
      .hidden_section_types.includes(SectionType.IMAGES)
  );
  instanceAreaHidden = computed(() =>
    this.templates()
      .find(template => template.category_id === this.linkedObject().category_id)
      .hidden_section_types.includes(SectionType.INSTANCES)
  );
  developmentItem = computed(() => toDevelopmentItemDto(this.linkedObject()));

  @Output() goToResearchObjectInfo = new EventEmitter<{ researchObjectId: string; researchObjectCategoryId: string }>();
  DevelopmentType = DevelopmentType;

  constructor(private store: Store) {}
}
