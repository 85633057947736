<div class="row">
  <ng-container *ngFor="let cell of sortedFields(); let i = index">
    <div
      data-testid="item-detail-form-row"
      [ngClass]="(cell.type | isTextLong) ? 'col-xl-12' : 'col-xl-6'"
      style="margin-bottom: 1rem"
      *ngIf="editMode || templatePreview || cell.value"
    >
      <div class="column" [ngClass]="{ 'invalid-border': errors()[i], 'blue-border': editMode }">
        <app-dynamic-field
          *ngIf="editMode; else readonlyView"
          [config]="cell"
          [backendErrorMessage]="errors()[i]"
          (valueChange)="onValueChange($event.value, cell)"
          (errorsChange)="errorsChange.emit($event)"
        ></app-dynamic-field>
        <ng-template #readonlyView>
          <ng-container *ngIf="!cell.value">
            <div class="field-value-container">
              <div class="field-value-preview__label" data-testid="field-value">{{ cell.name }}</div>
              <app-field-value-preview [field]="cell" [viewMode]="ViewMode.table"></app-field-value-preview>
            </div>
          </ng-container>
          <div class="column__value d-flex align-items-center justify-content-between" *ngIf="cell.value">
            <app-content-with-copy-btn [valueToCopy]="cell.value">
              <div class="field-value-container">
                <div class="field-value-preview__label" data-testid="field-value">{{ cell.name }}</div>
                <app-field-value-preview [field]="cell" [viewMode]="ViewMode.table"></app-field-value-preview>
              </div>
            </app-content-with-copy-btn>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>
