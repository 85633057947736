import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ImageDto, ImageMetaUpdateData, FileUploadParams } from '@shared/_models';
import { IMAGES_API_URL } from '@shared/_services/constants';
import { ImageSize } from '@app/domain/image';

@Injectable({
  providedIn: 'root'
})
export class ImageApiService {
  constructor(private http: HttpClient) {}

  get(id: string, size = ImageSize.LARGE): Observable<Blob> {
    const sizeParam = size === ImageSize.LARGE ? '' : `/${size}`;

    return this.http.get(`${IMAGES_API_URL}/${id}/content${sizeParam}`, { responseType: 'blob' });
  }

  upload(formData: FormData, params: FileUploadParams): Observable<HttpEvent<ImageDto>> {
    return this.http.post<ImageDto>(`${IMAGES_API_URL}`, formData, {
      reportProgress: true,
      observe: 'events',
      params
    });
  }

  delete(fileId: string): Observable<ImageDto> {
    return this.http.delete<ImageDto>(`${IMAGES_API_URL}/${fileId}`);
  }

  updateMeta({ imageId, payload }: ImageMetaUpdateData): Observable<ImageDto> {
    return this.http.put<ImageDto>(`${IMAGES_API_URL}/${imageId}`, payload);
  }
}
