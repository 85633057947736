export enum DisplayFieldType {
  NUMBER = 'number',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  TEXT = 'text',
  TEXT_LONG = 'text_long',
  SELECT = 'select',
  SMILES = 'smiles',
  SEQUENCE = 'sequence',
  DATE = 'date',
  PEPSEQ = 'pepseq',
  WYSIWYG = 'wysiwyg',
  URL = 'url', // needed ?
  USER = 'user' // needed ?
}

export enum FieldType {
  NUMBER = 'number',
  INTEGER = 'integer',
  TEXT = 'text',
  TEXT_LONG = 'text_long', // needed ?
  WYSIWYG = 'wysiwyg', // needed ?
  DATE = 'date',
  BOOLEAN = 'boolean',
  SELECT = 'select', // we have it also in DisplayFieldType, needed ?
  SMILES = 'smiles',
  SMILES_NPS = 'smiles_nps',
  SEQUENCE = 'sequence',
  PEPSEQ = 'pepseq',
  MW = 'mw',
  EXACT_MW = 'exact_mw',
  LOGP = 'logp',
  HBA = 'hba',
  HBD = 'hbd',
  NUM_HEAVY_ATOMS = 'num_heavy_atoms',
  HETEROATOMS = 'heteroatoms',
  ROTATABLE_BONDS = 'rotatable_bonds',
  RINGS = 'rings',
  FORMULA = 'formula',
  ATOMS = 'atoms',
  TPSA = 'tpsa',
  HYDROPHOBICITY_PH2 = 'hydrophobicity_ph2',
  HYDROPHOBICITY_PH7 = 'hydrophobicity_ph7',
  LENGTH = 'length',
  MASS = 'mass',
  BASIC_AMINO_ACIDS = 'basic_amino_acids',
  ACIDIC_AMINO_ACIDS = 'acidic_amino_acids',
  PEPTIDE = 'peptide',
  STAPLE = 'staple',
  LINKER = 'linker',
  ANCHOR = 'anchor',
  STAPLE_POSITION = 'staple_position',
  USER = 'user',
  URL = 'url',
  NPS = 'nps',
  NPS_COMMENT = 'nps_comment',
  NPS_COMMENT_EXCLUDE = 'nps_comment_exclude'
}
