import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ExperimentUpdateDto } from '../../_models';
import { ExperimentsStoreService } from '../../_root-store/experiments-store/experiments-store.service';
import { ROOT_CATEGORY_ID } from '@app/domain/category';

@Injectable({
  providedIn: 'root'
})
export class ExperimentService {
  constructor(private readonly experimentsStoreService: ExperimentsStoreService) {}

  toggleAllExperimentsSelection($event, categoryId: string): void {
    $event.preventDefault();
    $event.stopPropagation();

    const checked = $event.target.checked;

    if (checked) {
      this.experimentsStoreService.getAllExperimentsIds({
        ...(categoryId !== ROOT_CATEGORY_ID && { category_id: categoryId })
      });
    } else {
      this.experimentsStoreService.clearExperimentSelection();
    }
  }

  toggleExperimentSelection($event, id: string): void {
    $event.preventDefault();
    $event.stopPropagation();

    const checked = $event.target.checked;

    if (checked) {
      this.experimentsStoreService.selectExperiment(id);
    } else {
      this.experimentsStoreService.unselectExperiment(id);
    }
  }

  isExperimentSelected(experimentId: string): Observable<boolean> {
    return this.experimentsStoreService.experimentsSelectedIds$.pipe(map(ids => ids.find(id => id === experimentId)?.length > 0));
  }

  updateExperiment(experimentId: string, data: Partial<ExperimentUpdateDto>): void {
    this.experimentsStoreService.updateExperiment(experimentId, data);
  }
}
