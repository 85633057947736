import { Pipe, PipeTransform } from '@angular/core';

import { FileService } from '@shared/_modules/file/file.service';
import { SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'imageSafeStyleUrl',
  standalone: true,
})
export class ImageSafeStyleUrlPipe implements PipeTransform {
  constructor(private readonly fileService: FileService) {}

  transform(imagePath: string): SafeStyle {
    return this.fileService.getFileSafeStyleUrl(imagePath);
  }
}
