import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { CommonModule, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { InputWithClear } from '@app/shared/_components';
import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { GenericModalComponent } from '@app/shared/_components/generic-modal/generic-modal.component';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { ModalHeaderLeftComponent } from '@app/shared/_components/modal-header-left/modal-header-left.component';
import { DevelopmentType } from '@app/shared/_models';
import { InputWithValidationComponent } from '../../../inputs/input-with-validation/input-with-validation.component';
import { ResearchObjectsApiService } from '@app/shared/_services/research-object';
import { map, merge, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import { ExperimentsApiService } from '@app/shared/_services/experiment';

@Component({
  selector: 'app-item-details-edit-name-modal',
  standalone: true,
  templateUrl: './item-details-edit-name-modal.component.html',
  styleUrls: ['./item-details-edit-name-modal.component.scss'],
  imports: [
    InputWithClear,
    ButtonComponent,
    NgClass,
    ReactiveFormsModule,
    NgIf,
    GenericModalComponent,
    ModalHeaderLeftComponent,
    NgTemplateOutlet,
    InputWithValidationComponent,
    CommonModule
  ]
})
export class ItemDetailsEditNameModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter<null>();
  @Output() saveBtnClick = new EventEmitter<string>();
  @Input() developmentType: DevelopmentType = null;
  @Input() itemDetailsName: string;

  readonly DevelopmentType = DevelopmentType;
  readonly errorMessage$ = merge(
    this.researchObjectsApiService.errorMessageSection$.pipe(
      map(errorMessage => (<HttpErrorResponse>errorMessage.errorResponse)?.error?.validation_errors[0]?.detail)
    ),
    this.experimentsApiService.errorMessageSection$.pipe(
      map(errorMessage => (<HttpErrorResponse>errorMessage.errorResponse)?.error?.validation_errors[0]?.detail)
    ),
    of(null)
  );
  form: FormGroup;
  hasError = toSignal(this.errorMessage$);

  constructor(
    readonly researchObjectsApiService: ResearchObjectsApiService,
    readonly experimentsApiService: ExperimentsApiService
  ) {
    this.createFormControls();
  }

  ngOnInit() {
    this.#setInitialValue();
  }

  #setInitialValue() {
    this.form.get('name').setValue(this.itemDetailsName || null);
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.closeModal.emit();
    } else if (reason === CloseReason.ACCEPT) {
      this.saveBtnClick.emit(this.form.value);
      // timeout needed to wait for the error messages to be updated
      setTimeout(() => {
        if (!this.hasError()) {
          this.closeModal.emit();
        }
      }, 100);
    }
  }

  private createFormControls() {
    this.form = new FormGroup({
      name: new FormControl(null)
    });
  }

  onClearName() {
    this.form.get('name').setValue('');
  }
}
