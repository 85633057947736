import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { CategoryDto, CategoryTemplateDto, DevelopmentType, TreeSelectItem } from '@app/shared/_models';
import { FileUploadStatus } from '@app/shared/_modules/file/models/file-upload-status';
import { FileModule } from '@shared/_modules/file/file.module';
import { ImportErrorsComponent } from '@shared/_components/import/import-errors/import-errors.component';
import { ExcelApiService } from '@app/shared/_services/excel/excel-api.service';
import { FileService } from '@app/shared/_modules/file/file.service';
import { getShortGUID } from '@app/shared/_helpers';
import { ImportExcel } from '@app/shared/_models/import-excel';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { CloseReason, getActionButtonStyle } from '@app/shared/_components/generic-modal/utils';
import { CapitalizeFirstPipe } from '../../../../pipes/capitalize-first.pipe';
import { FileExtensionPipe } from '../../../../pipes/file-extension.pipe';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CategoriesStoreService } from '@app/shared/_root-store/categories-store/categories-store.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { TreeSelectComponent } from '@app/shared/_components/tree-select';
import { getTreeSelectItems, isTemplateNotEmpty } from '@app/domain/category';
import { Observable, combineLatest, map, takeUntil, tap } from 'rxjs';
import { selectTemplates } from '@app/shared/_root-store/category-templates-store/category-templates.selectors';
import { Store } from '@ngrx/store';
import { AutoDestroyDirective } from '@app/shared/_directives/auto-destroy/auto-destroy.directive';

export enum FormFields {
  SELECTED_CATEGORY = 'selectedCategory'
}

@Component({
  selector: 'app-item-details-import',
  standalone: true,
  imports: [
    CommonModule,
    FileModule,
    ImportErrorsComponent,
    IconComponent,
    CapitalizeFirstPipe,
    FileExtensionPipe,
    TreeSelectComponent,
    ReactiveFormsModule
  ],
  templateUrl: './item-details-import.component.html',
  styleUrls: ['./item-details-import.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsImportComponent extends AutoDestroyDirective implements OnInit {
  @Input() developmentType: DevelopmentType;
  @Output() closeModal: EventEmitter<CloseReason> = new EventEmitter<CloseReason>();
  @Output() importSelectedCategory: EventEmitter<string> = new EventEmitter<string>();
  readonly categories$ = this.categoriesStoreService.categories$;
  templates$: Observable<CategoryTemplateDto[]> = this.store.select(selectTemplates);
  categories = toSignal(this.categories$);
  excelFile: File;
  fileAnalysisCompleted: boolean = false;
  fileUploadStatuses: FileUploadStatus[] = [];
  importExcel: ImportExcel = null;
  fileUploadSuccess: boolean = false;
  fileUploadFail = signal(false);
  hideBrowseFile: boolean = false;
  currentDate: string;
  IconColors = IconColors;
  CloseReason = CloseReason;
  FORM_FIELDS = FormFields;
  selectedCategoryControl = new FormControl();
  selectedCategory: CategoryDto;
  categoriesSelectItems$: Observable<TreeSelectItem[]>;
  form: FormGroup;

  constructor(
    private readonly excelApiService: ExcelApiService,
    private readonly fileService: FileService,
    private readonly datePipe: DatePipe,
    private readonly categoriesStoreService: CategoriesStoreService,
    private readonly store: Store
  ) {
    super();
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yy');
  }

  ngOnInit(): void {
    this.importSelectedCategory.emit(null);
    this.#createFormControls();
    this.#setCategoryChangeSub();
    this.#setCategoriesSelectItems();
  }

  onFileSelected($event, dryRun: boolean): void {
    const newFilesToUpload = this.#addFileUploadStatus($event.target.files, dryRun);
    this.fileUploadStatuses = [...this.fileUploadStatuses, ...newFilesToUpload];
    this.hideBrowseFile = true;
  }

  onUploadInProgress(index: number): void {
    this.fileUploadStatuses[index].uploadPending = true;
  }

  onUploadSuccess(event: ImportExcel, index: number): void {
    this.importExcel = event;
    this.fileUploadStatuses[index].uploadPending = false;
    this.fileUploadSuccess = true;
  }

  onAnalyzeSuccess(event: ImportExcel, index: number): void {
    this.importExcel = event;
    this.fileUploadStatuses[index].uploadPending = false;
    this.fileAnalysisCompleted = true;
  }

  onUploadFail(index: number): void {
    this.fileUploadStatuses[index].uploadPending = false;
    this.fileUploadFail.set(true);
  }

  onUploadCancel(): void {
    this.hideBrowseFile = false;
    this.fileUploadStatuses = [];
    this.fileUploadFail.set(false);
    this.fileUploadSuccess = false;
    this.fileAnalysisCompleted = false;
    this.importExcel = null;
  }

  trackById(index: number, alert: { [key: string]: any }): string {
    return alert.id as string;
  }

  downloadSampleFile() {
    const devType = this.developmentType === DevelopmentType.researchObject ? 'RO' : 'EXP';
    const fileName = 'LG-' + devType + '-SampleFile-' + this.selectedCategory?.name;
    this.excelApiService.getImportTemplate(this.selectedCategory?.id).subscribe(blob => this.fileService.saveFile(blob, fileName));
  }

  downloadAnalysisOrImportSummary(isAnalysis: boolean) {
    const formData = new FormData();
    formData.append('file', this.excelFile);
    const summaryType = isAnalysis ? '-AnalisysSummary-' : '-ImportSummary-';
    const devType = this.developmentType === DevelopmentType.researchObject ? 'RO' : 'EXP';
    const fileName = 'LG-' + devType + summaryType + this.selectedCategory?.name + '-' + this.currentDate;
    this.excelApiService
      .getImportAnalysisSummary(formData, this.selectedCategory?.id)
      .subscribe(blob => this.fileService.saveFile(blob, fileName));
  }

  getClassStyle(closeReason: CloseReason) {
    return getActionButtonStyle(this.developmentType, closeReason, true);
  }

  getTotalObjects(): number {
    if (!this.importExcel) return 0;
    return this.importExcel.created_rows + this.importExcel.updated_rows + this.importExcel.error_rows;
  }

  onClose(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.fileUploadSuccess ? this.downloadAnalysisOrImportSummary(false) : this.downloadAnalysisOrImportSummary(true);
    }
    if (reason === CloseReason.ACCEPT) {
      this.fileUploadSuccess ? this.closeModal.emit(CloseReason.CLOSE) : this.#createFileUploadStatusFromFile(this.excelFile, false);
    }
  }

  getAnalisysSummaryText(): { text: string; class: string }[] {
    const parts = [];
    if (!this.importExcel) return [];
    if (this.importExcel.updated_rows) {
      parts.push({
        text: `${this.importExcel.updated_rows} ${this.fileUploadSuccess ? 'updated' : 'to update'}`,
        class: 'import-modal-analysis-updated'
      });
    }
    if (this.importExcel.created_rows) {
      parts.push({
        text: `${this.importExcel.created_rows} ${this.fileUploadSuccess ? 'new ones created' : 'to create'}`,
        class: 'import-modal-analysis-created'
      });
    }
    if (this.importExcel.error_rows) {
      parts.push({
        text: `${this.importExcel.error_rows} ${this.fileUploadSuccess ? 'not imported' : 'unable to import'}`,
        class: 'import-modal-analysis-unable'
      });
    }

    return parts;
  }

  #createFormControls() {
    this.form = new FormGroup({
      [FormFields.SELECTED_CATEGORY]: new FormControl(null, Validators.required)
    });
  }

  #setCategoriesSelectItems(): void {
    this.categoriesSelectItems$ = combineLatest([this.categoriesStoreService.categories$, this.templates$]).pipe(
      map(([categories, templates]) => {
        const hasTemplate = (category: CategoryDto) =>
          templates?.some(template => template.category_id === category.id && isTemplateNotEmpty(template));
        const developmentTypeCategories = categories.filter(category => category.development_type === this.developmentType);
        return getTreeSelectItems(developmentTypeCategories, hasTemplate);
      })
    );
  }

  #setCategoryChangeSub() {
    this.form
      .get(FormFields.SELECTED_CATEGORY)
      ?.valueChanges.pipe(
        tap(categoryId => {
          this.selectedCategory = this.categories().find(category => category.id === categoryId);
          this.importSelectedCategory.emit(this.selectedCategory.name);
          this.onUploadCancel();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  #addFileUploadStatus(files: File[], dryRun: boolean): FileUploadStatus[] {
    return Array.from(files).map((file: File) => this.#createFileUploadStatusFromFile(file, dryRun));
  }

  #createFileUploadStatusFromFile(file: File, dryRun: boolean): FileUploadStatus {
    const formData = new FormData();
    formData.append('file', file);
    this.excelFile = file;

    const uploadHttpEvents$ = this.excelApiService.importExcelFile(formData, this.selectedCategory?.id, dryRun);
    const fileStatus = {
      guid: getShortGUID(),
      fileToUpload: file,
      uploadPending: false,
      uploadHttpEvents$,
      dryRun: dryRun
    };

    if (!this.fileAnalysisCompleted) {
      return fileStatus;
    }
    this.fileUploadStatuses[0] = fileStatus;
  }
}
