import { Pipe, PipeTransform } from '@angular/core';
import { FileService } from '@shared/_modules/file/file.service';
import { FileDto } from '@shared/_models';

@Pipe({
  name: 'fileExtension',
  standalone: true,
})
export class FileExtensionPipe implements PipeTransform {
  constructor(private fileService: FileService) {}

  transform(file: FileDto): string {
    return this.fileService.getFileExtension(file);
  }
}
