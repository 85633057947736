import { ChangeDetectionStrategy, Component, input, computed } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

import { DevelopmentType, DevelopmentItem, ViewContainerDataType, SectionType } from '@shared/_models';
import { selectRouteCategoryHasAnyChildCategory } from '@shared/_root-store/selectors';
import { selectAvatarForId } from '@shared/_root-store/users-store/users.selectors';

@Component({
  selector: 'app-grid-item',
  templateUrl: './grid-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridItemComponent<T extends ViewContainerDataType> {
  developmentType = input<DevelopmentType>();

  /* had to revert from input.required - too many problems in tests
   * looks like input.required has problem with toObservable */
  itemData = input<T>();
  avatar$: Observable<string> = toObservable(this.itemData).pipe(
    switchMap(itemData => this.store.select(selectAvatarForId(itemData?.user_crt.id))),
    map(avatar => avatar?.base64 ?? null)
  );
  currentCategoryHasAnyChild = toSignal(this.store.select(selectRouteCategoryHasAnyChildCategory));
  hideImageArea = computed<boolean>(() => (<DevelopmentItem>this.itemData()).template?.hidden_section_types?.includes(SectionType.IMAGES));
  hideParametersArea = computed<boolean>(() =>
    (<DevelopmentItem>this.itemData()).template?.hidden_section_types?.includes(SectionType.PARAMETERS)
  );

  readonly DevelopmentType = DevelopmentType;

  constructor(protected store: Store) {}
}
