import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { environment } from 'src/environments/environment';
import { metaReducers, reducers } from './reducer';
import { CategoriesEffects } from './categories-store/categories.effects';
import { CategoryTemplatesEffects } from './category-templates-store/category-templates.effects';
import { ExperimentsStoreModule } from './experiments-store/experiments-store.module';
import { ResearchObjectsStoreModule } from './research-objects-store/research-objects-store.module';
import { ProjectsEffects } from './projects-store/projects.effects';
import { ImagesEffects } from './images-store/images.effects';
import { UsersEffects } from './users-store/users.effects';
import { AppEffects } from './app-store/app.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([AppEffects, ProjectsEffects, CategoriesEffects, CategoryTemplatesEffects, ImagesEffects, UsersEffects]),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    ExperimentsStoreModule,
    ResearchObjectsStoreModule
  ]
})
export class RootStoreModule {}
