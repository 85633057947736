import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const EmailWithDomainValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value ?? null;

  if (value) {
    const conditions = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Z|a-z]{2,}$');
    const meetsConditions = conditions.test(value);

    return meetsConditions ? null : { invalidEmail: true };
  }

  return null;
};
