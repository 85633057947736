import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';
import { ToastsComponent } from './toasts/toasts.component';

@NgModule({
  declarations: [ToastsContainerComponent, ToastsComponent],
  imports: [CommonModule, NgbToastModule],
  exports: [ToastsContainerComponent],
})
export class ToastModule {}
