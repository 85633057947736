<div #errorsContainer class="errors-container">
  <div *ngFor="let importError of importErrors; let i = index" class="errors-container__fields">
    <span class="errors-container__fields__row">{{ i + 1 + '. ' + importError?.row_name || 'Unknown object' }}</span>
    <span class="errors-container__fields__line">&nbsp;- {{ 'Line ' + importError?.row_number }}</span>
    <div class="row">
      <div *ngFor="let field of importError.row_errors" class="col-xl-6 errors-container__fields__field">
        <app-import-errors-field
          [field]="field"
          [rowNumber]="importError.row_number"
          class="errors-container__fields__field__component"
        ></app-import-errors-field>
      </div>
    </div>
  </div>
</div>
