import { ParameterDto } from './template';
import { CategoryDto } from './category';
import { Positionable } from './positionable';

export enum SectionType {
  PARAMETERS = 'parameters',
  RESULTS = 'results',
  INSTANCES = 'instances',
  IMAGES = 'images'
}

export interface SectionDto extends Positionable {
  id: string;
  type: SectionType;
  name: string;
  fields: ParameterDto[];
}

export interface CategoryTemplateDto {
  id?: string;
  category_id: string;
  hidden_section_types?: SectionType[];
  active_section_types?: SectionType[];
  sections: SectionDto[];
}

export interface CategoryTemplate extends CategoryTemplateDto {
  category: CategoryDto;
}

export interface UpdateCategoryTemplateDto {
  hidden_section_types: SectionType[];
  active_section_types: SectionType[];
}

export interface SectionAddDto {
  name: string;
  position: number;
  type: SectionType;
}

export interface SectionUpdateDto {
  name?: string;
  position?: number;
}
