<div class="container">
  <span class="image-details__modal-icons">
    <button data-testid="close-image-details-modal-button" (click)="onCloseBtnClick()" class="image-details__close-btn">
      <img class="image-details__close-icon" src="../../../../../assets/icons/close_icon.svg" alt="close icon" />
    </button>
  </span>
  <div class="row position-relative">
    <div class="col-lg-8 p-0">
      <div
        class="image-details__image-container {{ isMain && 'image-details__image-container--main' }}"
        [ngClass]="developmentType | developmentTypeCSSClass"
      >
        <div class="image-details__image-wrapper">
          <app-image [base64]="image?.content?.base64"></app-image>
        </div>
      </div>
    </div>
    <div data-testid="image-description-field" class="col-lg-4 p-0 d-flex flex-column justify-content-center image-details__col">
      <div class="image-details__info-container">
        <div class="mb-5 ms-3 image-details__development-item-data" *ngIf="categoryName && developmentItemName">
          <div data-testid="image-details-item-category" class="image-details__category-name">
            {{ categoryName }}
          </div>
          <div data-testid="image-details-item-name" class="image-details__development-item-name">
            {{ developmentItemName }}
          </div>
        </div>
        <div class="image-details__text-wrapper">
          <header data-testid="image-details-header" class="image-details__header">Image Description</header>
          <div data-testid="image-details-img-name" class="image-details__subheader mb-2">{{ image?.name }}</div>
          <ng-container *ngIf="!editMode; else editDescription">
            <p data-testid="image-description" class="image-details__description">
              {{ image?.description }}
            </p>
            <div class="row">
              <div class="col-md-6">
                <app-button
                  data-testid="add-edit-description-button"
                  [class]="developmentType + '-tertiary h-30 w-100 image-details__description-edit-btn'"
                  (click)="onEditModeChange()"
                >
                  {{ image?.description ? 'Edit' : 'Add' }}
                </app-button>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #editDescription>
          <app-text-long-input
            [formControl]="descriptionControl"
            [markAsInvalid]="markAsInvalid"
            [ngClass]="{ 'image-details__description-input-edit': editMode }"
            placeholder="Describe image ..."
            class="image-details__description-input"
            data-testid="description-image-inputfield"
          ></app-text-long-input>

          <div class="mt-2 image-details__buttons">
            <app-button
              data-testid="cancel-add-description-button"
              [class]="developmentType + '-tertiary w-100 h-30 mr-8'"
              (click)="onDescriptionCancelBtnClick()"
            >
              Cancel
            </app-button>
            <app-button
              data-testid="save-add-description-button"
              [class]="developmentType + '-primary w-100 h-30 ml-8'"
              (click)="onDescriptionSaveBtnClick()"
            >
              Save
            </app-button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
