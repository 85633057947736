import { Pipe, PipeTransform } from '@angular/core';
import { Field } from '@app/shared/_models';
import { SplitPipe } from '../split.pipe';

@Pipe({
  name: 'label',
  standalone: true
})
export class LabelPipe implements PipeTransform {
  constructor(private splitPipe: SplitPipe) {}

  transform(param: Field): unknown {
    return this.getParamName(param?.name);
  }

  private getParamName(name: string) {
    return this.splitPipe.transform(name, '_');
  }
}
