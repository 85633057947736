import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesUploadComponent } from './files-upload/files-upload.component';
import { FileThumbComponent } from './file-thumb/file-thumb.component';
import { FileUploadCardComponent } from './file-upload-card/file-upload-card.component';
import { MimeSubtypePipe } from './pipes/mime-subtype.pipe';
import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { ImagesListComponent } from './images-list/images-list.component';
import { ImagesListCardComponent } from './images-list-card/images-list-card.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageThumbComponent } from './image-thumb/image-thumb.component';
import { FilesModalIconCSSClassPipe } from './pipes/files-modal-icon-css-class.pipe';
import { FileUploadLimitAcceptedExtensionsPipe } from './pipes/file-upload-limit-accepted-extensions.pipe';
import { FileThumbRotateComponent } from './file-thumb-rotate/file-thumb-rotate.component';
import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { ImageComponent } from '@shared/_components/image/components/image.component';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { FileWithoutExtensionPipe } from '@app/shared/pipes';

const MODULES = [CommonModule, NgbPopoverModule];

@NgModule({
  imports: [
    ...MODULES,
    FilesUploadComponent,
    FileUploadCardComponent,
    ImagesListComponent,
    ImagesListCardComponent,
    ImageThumbComponent,
    FileThumbRotateComponent,
    ButtonComponent,
    ImageComponent,
    DevelopmentTypeCssClassPipe,
    FileWithoutExtensionPipe,
    FilesModalIconCSSClassPipe,
    FileUploadLimitAcceptedExtensionsPipe,
    FormatFileSizePipe,
    MimeSubtypePipe,
    FileThumbComponent
  ],
  exports: [FilesUploadComponent, FileThumbComponent, ImagesListComponent, FileUploadCardComponent, ButtonComponent]
})
export class FileModule {}
