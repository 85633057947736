<div
  [ngSwitch]="currentViewMode$ | async"
  class="view-container"
  [class.view-container--without-navigation]="developmentType === DevelopmentType.project"
>
  <ng-container *ngSwitchCase="ViewMode.grid">
    <app-grid-list [listData]="listData" [listDataLoaded]="listDataLoaded" [developmentType]="developmentType"></app-grid-list>
  </ng-container>
  <ng-container *ngSwitchCase="ViewMode.table">
    <app-table-list
      [sort]="sort"
      [listData]="listData"
      [listDataLoaded]="listDataLoaded"
      [developmentType]="developmentType"
      (sortChange)="sortChange.emit($event)"
    ></app-table-list>
  </ng-container>
  <ng-container *ngSwitchCase="ViewMode.instances">
    <app-table-list [listData]="listData" [listDataLoaded]="listDataLoaded" [developmentType]="developmentType"></app-table-list>
  </ng-container>
  <ng-container *ngSwitchDefault></ng-container>
</div>
