<div>
  <input
    #select
    #typehead="ngbTypeahead"
    class="search-select form-control"
    [class.invalid]="!form.controls[controlName].value && invalid"
    [class.search-select--miagnifying-glass-visible]="!form.controls[controlName].value || hasFocus"
    popupClass="lg-dropdown-menu lg-dropdown-menu--y-scroll"
    [(ngModel)]="ngModel"
    [ngbTypeahead]="searchFunction"
    [resultTemplate]="resultTemplate"
    [inputFormatter]="inputFormatter"
    [placeholder]="placeholder"
    (selectItem)="onSelectItem($event, select)"
    (focusin)="onFocusin()"
    (focusout)="onFocusout(typehead)"
    (keyup)="onKeyup($event.target.value)"
  />
  <span *ngIf="!form.controls[controlName].value || hasFocus" class="search-select__magnifying-glass-icon"></span>
  <button class="btn search-select__btn-clear px-3" *ngIf="ngModel" (click)="onClear(select)"></button>
</div>
