import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { RouterStateSerializer, MinimalRouterStateSerializer, MinimalRouterStateSnapshot } from '@ngrx/router-store';
import { isEqual } from 'lodash-es';

import { RouterState, PreviousRouteSnapshot } from './interface';

@Injectable({
  providedIn: 'root'
})
export class CustomSerializer extends MinimalRouterStateSerializer implements RouterStateSerializer<RouterState> {
  #previous: PreviousRouteSnapshot;

  serialize(routerState: RouterStateSnapshot): RouterState {
    const baseState: MinimalRouterStateSnapshot = super.serialize(routerState);

    const { url } = baseState;
    const segments = getSegments(routerState.root);
    const previous = this.#previous;

    if (!isEqual(segments, previous?.segments)) {
      this.#previous = { url, segments };
    }

    return { ...baseState, previous };
  }
}

function getSegments(routeSnapshot: ActivatedRouteSnapshot): string[] {
  if (!routeSnapshot) {
    return [];
  }

  return routeSnapshot.url.map((segment: UrlSegment) => segment.path).concat(getSegments(routeSnapshot.firstChild));
}
