<form [formGroup]="loginForm">
  <app-signin-form-input
    [control]="loginForm.get(FORM_KEYS.USERNAME)"
    [formSubmitted]="formSubmitted"
    [name]="'Email'"
    [placeholder]="'Enter Email'"
    [type]="'email'"
    [icon]="'far fa-envelope'"
  ></app-signin-form-input>
  <app-signin-form-input
    [control]="loginForm.get(FORM_KEYS.PASSWORD)"
    [formSubmitted]="formSubmitted"
    [name]="'Password'"
    [placeholder]="'Enter Password'"
    [type]="'password'"
    [icon]="'fas fa-lock'"
    class="d-block mt-3"
  ></app-signin-form-input>
  <div class="row mt-4">
    <div class="col">
      <a data-testid="forgot-password-button" [routerLink]="requestResetPasswordRoutingPath" class="float-end">Forgot password?</a>
    </div>
  </div>
  <div class="signin-button">
    <app-button class="general-primary w-100 h-60" (click)="signIn()">Sign In</app-button>
  </div>
</form>
