import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

const MINUS = '-';
const DECIMAL_SEPARATOR = '.';

@Directive({
  selector: '[appNumberMask]',
  standalone: true,
})
export class NumberMaskDirective {
  @Input() decimalPlaces: number;

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInput($event: Event) {
    const numberValue: string = this.#getNumberValueFromText(($event.target as HTMLInputElement).value);
    this.ngControl.control.setValue(numberValue);
  }

  #getNumberValueFromText(value: string): string {
    return value ? [...value].filter((_, index) => this.#isValidPartOfNumber(value, index)).join('') : null;
  }

  #isValidPartOfNumber(number: string, index: number): boolean {
    const partOfNumber: string = number[index];

    if (partOfNumber === MINUS) {
      return index === 0;
    }

    const decimalSeparatorIndex = number.indexOf(DECIMAL_SEPARATOR);

    // when decimal separator is present, disallow more than {decimalPlaces} numbers after it
    if (decimalSeparatorIndex > 0 && index > decimalSeparatorIndex + this.decimalPlaces) {
      return false;
    }

    // allow first separator to be included in number
    if (index > 0 && partOfNumber === DECIMAL_SEPARATOR) {
      const previousDecimalSeparator = number.slice(0, index).indexOf(DECIMAL_SEPARATOR);
      return number[index - 1] !== MINUS && previousDecimalSeparator === -1;
    }

    return !isNaN(partOfNumber as any);
  }
}
