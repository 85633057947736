<i
  [style.background-image]="'url(' + image + ')'"
  alt="user img"
  class="user-avatar__img"
  *ngIf="showUserImage(); else userPlaceholderIcon"
  [style.width]="avatarSize"
  [style.height]="avatarSize"
></i>
<ng-template #userPlaceholderIcon>
  <img
    src="assets/icons/user_light_icon.svg"
    class="user-avatar__placeholder"
    alt="user placeholder icon"
    [style.width]="avatarSize"
    [style.height]="avatarSize"
  />
</ng-template>
