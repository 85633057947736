import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { DevelopmentType, Image } from '@shared/_models';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { FileSafeUrlPipe } from '@shared/pipes/file-safe-url.pipe';
import { FormatFileSizePipe } from '@shared/_modules/file/pipes/format-file-size.pipe';
import { MimeSubtypePipe } from '@shared/_modules/file/pipes/mime-subtype.pipe';
import { ImageThumbComponent } from '@shared/_modules/file/image-thumb/image-thumb.component';

@Component({
  selector: 'app-images-list-card',
  standalone: true,
  imports: [
    CommonModule,
    NgbPopoverModule,
    DevelopmentTypeCssClassPipe,
    FileSafeUrlPipe,
    FormatFileSizePipe,
    MimeSubtypePipe,
    ImageThumbComponent
  ],
  templateUrl: './images-list-card.component.html',
  styleUrls: ['./images-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesListCardComponent {
  @Input() image: Image;
  @Input() developmentType: DevelopmentType;
  @Input() isMain: boolean;
  @Output() readonly deleteImage = new EventEmitter<null>();
  @Output() readonly setAsMainImage = new EventEmitter<null>();
  @Output() readonly showImageDetails = new EventEmitter<null>();
  readonly DevelopmentType = DevelopmentType;

  onDeleteImageClick(): void {
    this.deleteImage.emit();
  }

  onSetAsMainImageClick(): void {
    this.setAsMainImage.emit();
  }

  onShowMoreDetailsBtnClick(): void {
    this.showImageDetails.emit();
  }
}
