// Renamed from UpdateSection as now section means: one of "tabs" of old sections (now "areas")
// TODO: refactor values for api 2.1
export enum UpdateArea {
  NAME = 'name',
  FILES = 'files',
  IMAGES = 'images',
  PARAMETERS = 'parameters',
  RESULTS = 'results',
  STATUS = 'status',
  DATE_EXECUTION = 'dateExecution',
  RESEARCH_OBJECT = 'researchObject',
  EXPERIMENT = 'experiment',
  INSTANCES = 'instances',
  SECTION_ADD = 'adding section',
  SECTION_UPDATE = 'updating section',
  FIELD_ADD = 'adding field',
  FIELD_UPDATE = 'updating field',
  CATEGORY = 'category'
}
