<header class="grid-container">
  <div data-testid="select-all-files">
    <div class="form-check">
      <input
        class="lg-table__select-all-checkbox form-check-input"
        [checked]="allSelected()"
        type="checkbox"
        (change)="toggleAllFilesSelection($event)"
      />
    </div>
  </div>
  <div data-testid="file-name-column-header" class="header-label">Name</div>
  <div data-testid="file-shared-with-column-header" class="header-label">Shared With</div>
  <div data-testid="file-size-column-header" class="header-label">Size</div>
  <div data-testid="file-added-by-column-header" class="header-label">Added By</div>
  <div data-testid="file-type-column-header" class="header-label">Type</div>
  <div data-testid="file-date-added-column-header" class="header-label align-right">Date Added</div>
</header>
<div class="table-body">
  <ng-container *appSkeleton="!listDataLoaded(); repeat: 20; type: SkeletonType.TABLE_ITEM">
    @for (file of files(); track file) {
      <app-file-table-item
        (selectFile)="onSelectHandler(file.id)"
        (unselectFile)="onUnselectHandler(file.id)"
        (loadFiles)="onLoadFiles()"
        (share)="openFileSharingModal(file.id)"
        [selected]="selectedIds().includes(file.id)"
        [itemData]="file"
      ></app-file-table-item>
    }
  </ng-container>
</div>
<ng-template #fileSharingModal let-modal>
  <app-file-sharing-modal
    [file]="sharedFile()"
    [developmentItemId]="developmentItemId()"
    [developmentType]="developmentType()"
    (closeModal)="onClose(modal)"
    (loadFiles)="onLoadFiles()"
    (onLastUnlinked)="onClose(modal)"
    class="file-sharing-modal"
  ></app-file-sharing-modal>
</ng-template>
