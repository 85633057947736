import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ELEMENT_TYPE } from '@app/shared/_components/member-element/member-element.component';
import { IMemberEdit, MemberRole, ProjectProxy } from '@app/shared/_models';
import { MemberElementComponent } from '@shared/_components/member-element/member-element.component';

@Component({
  selector: 'app-members-elements',
  standalone: true,
  imports: [CommonModule, MemberElementComponent],
  templateUrl: './members-elements.component.html',
  styleUrls: ['./members-elements.component.scss']
})
export class MembersElementsComponent implements OnInit {
  @Input() members: IMemberEdit[];
  @Input() projectProxy: ProjectProxy;
  @Output() membersUpdate = new EventEmitter<IMemberEdit[]>();
  initialMembersLength: number;
  readonly ELEMENT_TYPE = ELEMENT_TYPE;

  ngOnInit(): void {
    this.initialMembersLength = this.getMembers()?.length;
  }

  getLeads() {
    return this.members?.filter(x => x.roleBeforeEdit == MemberRole.owner || x.roleBeforeEdit == MemberRole.lead);
  }

  getMembers() {
    return this.members?.filter(x => x.roleBeforeEdit == MemberRole.editor || x.roleBeforeEdit == MemberRole.reader);
  }

  onMemberUpdate(updatedMember: IMemberEdit) {
    this.members = this.members.map(member => (member.user.id === updatedMember.user.id ? updatedMember : member));
    this.membersUpdate.emit(this.members);
  }
}
