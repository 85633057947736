import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Output, Signal, signal, WritableSignal } from '@angular/core';
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { GridItemStatusComponent } from '@shared/_modules/grid/grid-item-status/grid-item-status.component';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { MemberRole, UserAvatar, UserDto, ProjectDto, ProjectProxy, DevelopmentType } from '@shared/_models';
import { SlicePipe } from '@shared/pipes/slice.pipe';
import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { selectAvatarForId } from '@shared/_root-store/users-store/users.selectors';

interface ProjectMember extends UserDto {
  avatar$: Observable<UserAvatar>;
  role: MemberRole;
}

@Component({
  selector: 'app-project-grid-card',
  standalone: true,
  imports: [
    GridItemStatusComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbTooltip,
    DatePipe,
    UserAvatarComponent,
    NgForOf,
    AsyncPipe,
    NgIf,
    NgClass,
    SlicePipe,
    IconComponent
  ],
  templateUrl: './project-grid-card.component.html',
  styleUrls: ['./project-grid-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectGridCardComponent {
  project = input.required<ProjectDto>();
  projectProxy = input.required<ProjectProxy>();
  @Output() edit = new EventEmitter<void>();
  @Output() leave = new EventEmitter<void>();
  @Output() manageMembers = new EventEmitter<void>();
  @Output() join = new EventEmitter<void>();
  @Output() openProject = new EventEmitter<void>();

  projectAllMembers = computed(() => {
    const project = this.project();
    const members = project.members.map(member => ({ ...member.user, role: MemberRole.member }));
    const leads = project.leads.map(lead => ({ ...lead, role: MemberRole.lead }));
    const owner = { ...project.user_crt, role: MemberRole.owner };

    return members
      .concat(leads)
      .concat([owner])
      .map(member => ({ ...member, avatar$: this.store.select(selectAvatarForId(member.id)) }));
  });
  daysSinceProjectStart = computed<number>(() => {
    const earlierDate = new Date(this.project().date_created);
    const today = new Date();
    const differenceInMilliseconds = today.getTime() - earlierDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
    const differenceInFullDays = Math.ceil(differenceInDays);

    return differenceInFullDays;
  });
  projectOwnerAndLeads: Signal<ProjectMember[]> = computed(() => {
    return this.projectAllMembers().filter(member => [MemberRole.owner, MemberRole.lead].includes(member.role));
  });
  hiddenResearchObjects = computed<boolean>(() => this.project().hidden_development_types.includes(DevelopmentType.researchObject));
  hiddenExperiments = computed<boolean>(() => this.project().hidden_development_types.includes(DevelopmentType.experiment));

  readonly IconColors = IconColors;

  constructor(private store: Store) {}

  onCardClick() {
    if (!this.projectProxy().belongsToProject) {
      this.join.emit();
    } else {
      this.openProject.emit();
    }
  }
}
