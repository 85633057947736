import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';

import { ProjectDto, UpdateProjectDto, CreateProjectDto } from '@app/shared/_models/project';
import { PROJECTS_API_URL, USERS_API_URL } from '../constants';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { UserDto } from '@app/shared/_models';

@Injectable({
  providedIn: 'root'
})
export class ProjectsApiService {
  constructor(
    private readonly http: HttpClient,
    private workspaceService: WorkspaceService
  ) {}
  get(projectId: string): Observable<ProjectDto> {
    return this.http.get<ProjectDto>(PROJECTS_API_URL + '/' + projectId).pipe(shareReplay());
  }

  getList(workspaceId: string) {
    return this.http.get<ProjectDto[]>(PROJECTS_API_URL, { params: { workspace_id: workspaceId } }).pipe(shareReplay());
  }

  getUsers() {
    return this.http
      .get<UserDto[]>(USERS_API_URL, { params: { workspace_id: this.workspaceService.getCurrentWorkspaceId() } })
      .pipe(shareReplay());
  }

  add(project: CreateProjectDto): Observable<ProjectDto> {
    return this.http.post<ProjectDto>(`${PROJECTS_API_URL}`, project);
  }

  update(projectId: string, changes: Partial<UpdateProjectDto>): Observable<ProjectDto> {
    return this.http.patch<ProjectDto>(`${PROJECTS_API_URL}/${projectId}`, changes);
  }

  delete(projectId: string): Observable<ProjectDto> {
    return this.http.delete<any>(`${PROJECTS_API_URL}/${projectId}`);
  }

  join(projectId: string): Observable<any> {
    return this.http.post<any>(`${PROJECTS_API_URL}/${projectId}/request_access`, {});
  }

  leave(projectId: any): Observable<any> {
    return this.http.post<any>(`${PROJECTS_API_URL}/${projectId}/leave_project`, {});
  }
}
