<div class="experiment-results-details" data-test="expPrimRes">
  <div *ngIf="!resultToDisplay() || !resultType()">-</div>
  <ng-container *ngIf="resultToDisplay(); else noPrimaryResults">
    <ng-container *ngIf="resultType() === DisplayFieldType.WYSIWYG">
      <app-wysiwyg-field [fieldValue]="resultToDisplay().value" [headerCustomStyles]="{ color: 'inherit' }"></app-wysiwyg-field>
    </ng-container>

    <ng-container *ngIf="resultType() === DisplayFieldType.TEXT_LONG">
      <div [ngbPopover]="descriptionTpl" [popoverTitle]="resultToDisplay().name || '-'" triggers="mouseenter:mouseleave" container="body">
        <header class="items-card__description-header" data-test="descriptionHeader">{{ resultToDisplay().name || '-' }}</header>
        <span class="items-card__description-text property-name">Mouse over here to see details</span>
      </div>

      <ng-template #descriptionTpl>
        <div
          class="experiment-results-details__popover-text"
          [innerHtml]="resultToDisplay().value || '-'"
          data-test="popupConclusions"
        ></div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="resultType() === DisplayFieldType.NUMBER">
      <header
        appOverflowContentPluginToNgbTooltip
        [ngbTooltip]="formattedResultToDisplayValue() || '-'"
        class="items-card__description-header"
        data-testid="field-value"
      >
        {{ resultsAreaHidden ? ' ' : formattedResultToDisplayValue() || '-' }}
      </header>
    </ng-container>

    <ng-container *ngIf="resultType() === DisplayFieldType.TEXT || resultType() == DisplayFieldType.DATE">
      <header
        class="items-card__description-header"
        data-test="resultHeader"
        ngbTooltip="{{ (resultToDisplay() | itemReadValue) || '-' }}"
        appOverflowContentPluginToNgbTooltip
      >
        {{ resultsAreaHidden ? ' ' : (resultToDisplay() | itemReadValue) || '-' }}
      </header>
    </ng-container>
  </ng-container>
  <ng-template #noPrimaryResults>-</ng-template>
</div>
