const BASE_URL = 'https://test-api-labgears.adamed.com';

export const environment = {
  production: false,
  baseUrl: BASE_URL,
  apiUrl: `${BASE_URL}/api`,
  authUrl: `${BASE_URL}/app-auth`,
  usersUrl: `${BASE_URL}/users`,
  filesUrl: `${BASE_URL}/storage`,
};
