<div class="confirmation-text">
  <ng-container *ngIf="isUserVerified$ | async; else notVerified">
    <h2>Account Activated Successfuly</h2>
    <p>
      You can now
      <a [routerLink]="signInRoutingPath">Sign In</a>
      to LabGears.
    </p>
    <p>
      In case of any questions, please contact administrator:
      <br />
      <a [href]="'mailto:' + CONTACT_ADMINISTRATOR">{{ CONTACT_ADMINISTRATOR }}</a>
    </p>
  </ng-container>

  <ng-template #notVerified>
    <h2>Account Activating...</h2>
    <p>Wait until Verification Completes.</p>
  </ng-template>
</div>
