import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgFor } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { distinctUntilChanged, Subscription } from 'rxjs';

import { Pagination } from 'src/app/shared/_models';
import { RouterService } from '@app/shared/_services/router/router.service';

enum FORM_KEYS {
  PAGE_SIZE = 'pageSize',
}

@Component({
  selector: 'app-list-page-size-selector',
  standalone: true,
  imports: [NgFor, ReactiveFormsModule],
  templateUrl: './list-page-size-selector.component.html',
  styleUrls: ['./list-page-size-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPageSizeSelectorComponent implements OnInit, OnDestroy {
  // TODO: Move to global settings
  PAGE_SIZE = 20;
  currentPageSize = 20;
  PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

  readonly FORM_KEYS = FORM_KEYS;
  pageSizeForm: FormGroup;
  pageSizeFormSub!: Subscription;
  @Input() pageLimit: number;
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();
  paramsSub!: Subscription;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routerService: RouterService
  ) {
    this.pageSizeForm = new FormGroup({
      [FORM_KEYS.PAGE_SIZE]: new FormControl(),
    });
    this.paramsSub = this.activatedRoute.queryParams
      .pipe(distinctUntilChanged((prev, curr) => prev.limit === curr.limit))
      .subscribe((params: Params) => {
        const { limit } = params;

        this.currentPageSize = limit || 20;
      });
  }

  ngOnInit(): void {
    this.pageSizeForm.get(FORM_KEYS.PAGE_SIZE).setValue(this.pageLimit);

    this.pageSizeFormSub = this.pageSizeForm.valueChanges.subscribe(pageSizeFormValue => {
      const pageSize = pageSizeFormValue[FORM_KEYS.PAGE_SIZE];

      this.routerService.navigateToCurrentRouteButWithDifferentQueryParams({ page: 1, limit: pageSize });
      this.pageSizeChanged.emit(pageSize);
    });
  }

  ngOnDestroy(): void {
    this.pageSizeFormSub.unsubscribe();
    this.paramsSub.unsubscribe();
  }
}
