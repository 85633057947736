import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(arr: { [key: string]: any }[] = [], key: string, value: unknown): any[] {
    if (arr && Array.isArray(arr) && value) {
      return arr.filter(item => item[key] === value);
    } else {
      return arr;
    }
  }
}
