import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-item-select-checkbox',
  standalone: true,
  templateUrl: './item-select-checkbox.component.html',
  styleUrls: ['./item-select-checkbox.component.scss'],
})
export class ItemSelectCheckboxComponent {
  @Input() selected: boolean;
  @Output() checkboxClick = new EventEmitter();

  constructor() {}

  onCheckboxClick($event) {
    // $event.preventDefault();
    // $event.stopPropagation();

    this.checkboxClick.emit($event);
  }
}
