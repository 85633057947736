import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { CloseReason } from '@shared/_components/generic-modal/utils';
import { AppSettings } from '@app/shared/_configuration';
import { DevelopmentType, UserDto, IMemberEdit, MemberRole, IUserWithRole, ProjectDto, ProjectProxy } from '@shared/_models';
import { GenericModalComponent } from '@shared/_components/generic-modal/generic-modal.component';
import { MembersDropdownComponent } from '@app/_projects/members-elements/members-dropdown/members-dropdown.component';
import { MembersElementsComponent } from '@app/_projects/members-elements/members-elements.component';
import { ModalHeaderLeftComponent } from '@shared/_components/modal-header-left/modal-header-left.component';
import { ButtonComponent } from '@shared/_components/button/button.component';

@Component({
  selector: 'app-project-members-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    GenericModalComponent,
    MembersDropdownComponent,
    MembersElementsComponent,
    ModalHeaderLeftComponent,
    ButtonComponent
  ],
  templateUrl: './project-members-edit-modal.component.html',
  styleUrls: ['./project-members-edit-modal.component.scss']
})
export class ProjectMembersEditModalComponent implements OnInit {
  @Input() modalRef: NgbModalRef;
  @Input() projectProxy: ProjectProxy;
  @Input() project: ProjectDto;
  @Input() currentUser: UserDto;
  @Input() membersChangedData: IUserWithRole[] = null;
  @Output() projectMembersChanged = new EventEmitter<IUserWithRole[]>();
  projectMembers: IUserWithRole[] = [];
  readonly DevelopmentType = DevelopmentType;
  members: IMemberEdit[];
  showMembersSearch = false;

  get header(): string {
    return this.project?.name;
  }

  constructor(@Inject(AppSettings) public readonly settings: AppSettings) {}

  ngOnInit(): void {
    this.members = this.membersChangedData?.map(x => this.#createMemberEdit(x));
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.closeModal();
    } else if (reason === CloseReason.ACCEPT) {
      this.updateListAndClose();
    }
  }

  onMemberUpdate(members: IMemberEdit[]) {
    this.members = members;
  }

  updateListAndClose() {
    const updatedMembers = this.members?.filter(x => !x.isRemoved).map(x => this.#createMember(x));
    const sortedMembers = updatedMembers
      ?.filter(x => x.role == MemberRole.owner)
      .concat(updatedMembers?.filter(x => x.role == MemberRole.lead))
      .concat(updatedMembers?.filter(x => x.role == MemberRole.editor || x.role == MemberRole.reader));
    this.projectMembersChanged.emit(sortedMembers);
    this.modalRef.close();
  }

  closeModal() {
    this.modalRef.close();
  }

  #createMemberEdit(member: IUserWithRole) {
    return {
      user: member.user,
      roleAfterEdit: member.role,
      roleBeforeEdit: member.role,
      isRemoved: false
    } as IMemberEdit;
  }

  #createMember(member: IMemberEdit) {
    return { user: member.user, role: member.roleAfterEdit } as IUserWithRole;
  }
}
