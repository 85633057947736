import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { CategoryDto, RouteParam } from '@shared/_models';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';
import { selectCategoryById } from '@shared/_root-store/categories-store/categories.selectors';

export function categoryResolver(): Observable<CategoryDto> {
  const store: Store = inject(Store);

  return store.select(selectRouteParam(RouteParam.CATEGORY_ID)).pipe(switchMap(categoryId => store.select(selectCategoryById(categoryId))));
}
