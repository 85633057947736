import { Pipe, PipeTransform } from '@angular/core';

export const EDITING_PREFIX = 'Editing';
export const ADDING_NEW_PREFIX = 'Adding New';

@Pipe({
  name: 'conditionalPrefix',
  standalone: true
})
export class ConditionalPrefixPipe implements PipeTransform {
  transform(value: string, condition: boolean, prefix: string): string {
    return condition ? `${prefix} ${value}` : value;
  }
}
