import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

import { ScrollManager } from './scroll-manager';

@Directive({
  selector: '[app-scroll-section]',
  standalone: true,
})
export class ScrollSectionDirective implements OnInit, OnDestroy {
  @Input('app-scroll-section') id: string | number;

  constructor(private host: ElementRef<HTMLElement>, private manager: ScrollManager) {}

  ngOnInit() {
    this.manager.register(this);
  }

  ngOnDestroy() {
    this.manager.remove(this);
  }

  scroll() {
    this.host.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
}
