import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const NameValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value ?? null;

  if (value) {
    // regexp: dopuszczalne wiele słów oddzielonych spacją, każde słowo może zawierać jeden lub więcej myślników,
    // ale każdy myślnik musi być poprzedzony literą i nie może być na końcu wyrazu
    const conditions = new RegExp('^([a-zA-Z]+(-[a-zA-Z]+)*( [a-zA-Z]+(-[a-zA-Z]+)*)*)$');
    const meetsConditions = conditions.test(value);

    return meetsConditions ? null : { meetsConditions: true };
  }

  return null;
};
