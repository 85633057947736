import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';

import { CategoryTemplateDto } from '@shared/_models/category-template';
import * as actions from './category-templates.actions';

export const CATEGORY_TEMPLATES_STATE_KEY = 'categoryTemplates';
export const categoryTemplatesAdapter: EntityAdapter<CategoryTemplateDto> = createEntityAdapter<CategoryTemplateDto>();
export interface CategoryTemplatesState extends EntityState<CategoryTemplateDto> {
  readonly loading: boolean;
  readonly loaded: boolean;
}

export const { selectAll, selectIds } = categoryTemplatesAdapter.getSelectors();

export const initialState: CategoryTemplatesState = categoryTemplatesAdapter.getInitialState({
  loading: false,
  loaded: false
});

export const reducer: ActionReducer<CategoryTemplatesState> = createReducer(
  initialState,
  on(actions.loadCategoryTemplates, state => {
    return { ...state, loading: true };
  }),
  on(actions.loadCategoryTemplatesSuccess, (state, action) =>
    categoryTemplatesAdapter.setAll(action.data, {
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(actions.loadCategoryTemplatesFailure, state => {
    return { ...state, loading: false, loaded: false };
  }),
  on(actions.updateCategoryTemplateSuccess, (state, action) =>
    categoryTemplatesAdapter.updateOne(
      {
        id: action.data.id,
        changes: action.data
      },
      state
    )
  ),
  on(actions.deleteCategoryTemplateOnCategoryDeleted, (state, action) => {
    const categoryTemplates = Object.values(state.entities);
    const categoryTemplateId = categoryTemplates.find(categoryTemplate => categoryTemplate.category_id === action.categoryId)?.id;

    return categoryTemplateId ? categoryTemplatesAdapter.removeOne(categoryTemplateId, state) : state;
  })
);
