import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//
import { SignupLayoutComponent } from './signup-layout/signup-layout.component';
import { SignupComponent } from './signup/signup.component';
import { SignupCompletedComponent } from './signup-completed/signup-completed.component';
import { SignUpRoutingPath } from './signup-routing-path';

const routes: Routes = [
  {
    path: '',
    component: SignupLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: SignUpRoutingPath.signUp,
      },
      {
        path: SignUpRoutingPath.signUp,
        component: SignupComponent,
      },
      {
        path: SignUpRoutingPath.signUpCompleted,
        component: SignupCompletedComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignupRoutingModule {}

