import { IBreadcrumb } from '@shared/breadcrumbs/models/breadcrumb.iterface';
import { CategoryDto } from '@shared/_models';

/**
 * Create array of categories that represent path from given category up to root category
 */
export function getBreadcrumbsViewData(categories: CategoryDto[], categoryId): IBreadcrumb[] {
  const breadcrumbsData: IBreadcrumb[] = [];

  let category: CategoryDto;

  while (categoryId !== null) {
    category = categories.find(category => category.id === categoryId);

    if (!category) return [];

    breadcrumbsData.unshift({ categoryId: category.id, categoryName: category.name });
    categoryId = category.parent_id;
  }
  return breadcrumbsData;
}
