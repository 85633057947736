import { Component, Input } from '@angular/core';
import { LowerCasePipe, NgClass, NgIf } from '@angular/common';

import { DevelopmentType } from '@app/shared/_models';

@Component({
  selector: 'app-item-details-remove-confirmation',
  standalone: true,
  imports: [LowerCasePipe, NgIf, NgClass],
  templateUrl: './item-details-remove-confirmation.component.html',
  styleUrls: ['./item-details-remove-confirmation.component.scss'],
})
export class ItemDetailsRemoveConfirmationComponent {
  @Input() developmentType: DevelopmentType;
  @Input() instanceNameSingular: string;
  readonly DevelopmentType = DevelopmentType;

  constructor() {}
}
