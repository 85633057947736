import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, map, of, withLatestFrom, filter } from 'rxjs';
import { Store } from '@ngrx/store';

import { CategoryTemplateApiService } from '@shared/_services/category-template/category-template-api.service';
import * as actions from './category-templates.actions';
import { selectCategoryTemplatesState } from './category-templates.selectors';
import { RouteParam } from '@shared/_models';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors/router.selectors';

@Injectable()
export class CategoryTemplatesEffects {
  constructor(
    private actions$: Actions,
    private categoryTemplateApiService: CategoryTemplateApiService,
    private readonly store: Store
  ) {}

  loadCategoryTemplatesIfNotCached$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCategoryTemplatesIfNotCached),
      withLatestFrom(this.store.select(selectCategoryTemplatesState)),
      filter(([_, categoryTemplatesState]) => !categoryTemplatesState.loaded && !!categoryTemplatesState.ids.length),
      map(() => actions.loadCategoryTemplates())
    )
  );

  loadCategoryTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCategoryTemplates),
      withLatestFrom(this.store.select(selectRouteParam(RouteParam.PROJECT_ID))),
      switchMap(([_, project_id]) =>
        this.categoryTemplateApiService.getList({ project_id }).pipe(
          map(data => actions.loadCategoryTemplatesSuccess({ data })),
          catchError(error => of(actions.loadCategoryTemplatesFailure({ error })))
        )
      )
    )
  );
}
