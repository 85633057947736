import { Action, createReducer, on } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
//
import { apiSchemasActions } from './../actions';

export interface ApiSchemasState {
  schemasLoaded: boolean;
  error: any;
}

export const initialApiSchemasState = {
  schemasLoaded: false,
  error: null,
};

export const apiSchemasReducerDef = createReducer(
  initialApiSchemasState,
  on(apiSchemasActions.schemasLoaded, (state, action) => {
    return { ...state, ...action.schemas, schemasLoaded: true, error: null };
  }),
  on(apiSchemasActions.schemasLoadingError, (state, action) => {
    return { ...state, ...[], schemasLoaded: false, error: action.error };
  })
);

export function apiSchemasReducer(state: ApiSchemasState | undefined, action: Action) {
  return apiSchemasReducerDef(state, action);
}

export const FEATURE_API_SCHEMAS_REDUCER = new InjectionToken<any>('API Schemas Reducer', {
  factory: () => apiSchemasReducer,
});
