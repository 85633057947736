import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PasswordRoutingModule } from './password-routing.module';
import { PasswordLayoutComponent } from './password-layout/password-layout.component';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthLayoutModule } from './../auth-layout/auth-layout.module';
import { PasswordStrengthMeterComponent } from '../password-strength-meter/password-strength-meter.component';
import { ButtonComponent } from '@app/shared/_components/button/button.component';

const NG_MODULES = [CommonModule, ReactiveFormsModule];

const MODULES = [PasswordRoutingModule, AuthLayoutModule];

const COMPONENTS = [PasswordLayoutComponent, RequestResetPasswordComponent, ResetPasswordComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...NG_MODULES, ...MODULES, PasswordStrengthMeterComponent, ButtonComponent],
})
export class PasswordModule {}
