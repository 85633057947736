import { createSelector } from '@ngrx/store';

import { selectCategories } from './categories-store/categories.selectors';
import { selectRouteParam } from './router-store/selectors';
import { RouteParam } from '@shared/_models/route-param';
import { ROOT_CATEGORY_ID } from '@app/domain/category';

export const selectRouteCategoryHasAnyChildCategory = createSelector(
  selectCategories,
  selectRouteParam(RouteParam.CATEGORY_ID),
  (categories, categoryId) => categories.some(category => category.parent_id === categoryId || categoryId === ROOT_CATEGORY_ID)
);
