import { ValidatorFn, Validators } from '@angular/forms';
import { isNil } from 'lodash-es';

import { OptionsDto } from '@shared/_models';

export function toValidation(options: OptionsDto): ValidatorFn[] {
  if (!options) return [];

  return Object.entries(options)
    .reduce((accumulation, [key, value]) => accumulation.concat(toValidatorFn(key, value)), [])
    .filter(Boolean);
}

function toValidatorFn(key: string, value: any): ValidatorFn {
  if (value === false || isNil(value)) return;

  if (key === 'min_length') return Validators.minLength(value);

  if (key === 'max_length') return Validators.maxLength(value);

  if (key === 'min_value') return Validators.min(value);

  if (key === 'max_value') return Validators.max(value);

  if (key === 'required') return Validators.required;

  if (key === 'pattern') return Validators.pattern(value);

  return;
}
