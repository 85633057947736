import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length: number): unknown {
    const biggestWord = 50;
    const ellipsis = '...';
    const valToTransform = value || '';

    if (!value || typeof value !== 'string') {
      return valToTransform;
    }

    if (valToTransform.length <= length) {
      return value;
    }

    let truncatedText: string = valToTransform.slice(0, length + biggestWord);

    while (truncatedText.length > length - ellipsis.length) {
      const lastSpace = truncatedText.lastIndexOf(' ');

      if (lastSpace === -1) {
        break;
      }

      truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?]$/, '');
    }

    return truncatedText + ellipsis;
  }
}
