import { Pipe, PipeTransform } from '@angular/core';
import { get as _get } from 'lodash-es';

@Pipe({
  name: 'sort',
  standalone: true,
})
export class SortPipe implements PipeTransform {
  transform(list: any[] = [], prop = null, dir: 'asc' | 'desc'): any[] {
    if (list && Array.isArray(list) && prop) {
      const sorted = list.sort((objA, objB) => {
        const objAVal = _get(objA, prop) as string;
        const objBVal = _get(objB, prop) as string;

        return objAVal?.toLowerCase() > objBVal?.toLowerCase() ? (dir && dir === 'asc' ? 1 : -1) : -1;
      });

      return [...sorted];
    } else {
      return list;
    }
  }
}
