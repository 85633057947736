import { MemberRole, ProjectDto, IUserWithRole, MemberPermission } from '@shared/_models';

export function getMembersData(project: ProjectDto): IUserWithRole[] {
  const members = project.members.map(member => ({
    user: member.user,
    role: member.permission === MemberPermission.WRITE ? MemberRole.editor : MemberRole.reader
  }));
  const leads = project.leads.map(lead => ({
    user: lead,
    role: MemberRole.lead
  }));
  const owner = project.user_crt;

  return [{ user: owner, role: MemberRole.owner }, ...leads, ...members];
}
