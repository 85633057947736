import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { trackById } from 'src/app/shared/_helpers';
import { Field, ResearchObject, RouteParam, SectionType, SkeletonType, SortDto, SortOrderTypes } from 'src/app/shared/_models';
import { ResearchObjectService } from 'src/app/shared/_services/research-object';
import { sortingRotationStateAnimation } from '../animations/sorting-rotation-state.animation';
import { toSectionFields } from '@app/shared/dto-adapters/field';
import { ResearchObjectsStoreService } from '@app/shared/_root-store/research-objects-store/research-objects-store.service';
import { selectRouteCategoryHasAnyChildCategory } from '@shared/_root-store/selectors';
import { getInstanceSectionName } from '@app/domain/research-object';
import { TemplateStoreService } from '@app/templates/services/template-store-service/template-store.service';
import { selectShowExperiments } from '@shared/_root-store/projects-store/projects.selectors';
import { comparePositions } from '@app/domain/shared/compare-positions';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';
import { selectSelectedAll } from '@app/shared/_root-store/research-objects-store/research-objects.selectors';

enum ResearchObjectFields {
  NAME = 'name',
  CATEGORY = 'category_name',
  ADDED_BY = 'user_crt',
  DATE_ADDED = 'date_created',
  INSTANCE = 'ro_instance',
  OID = 'oid'
}
@Component({
  selector: 'app-research-object-table-list',
  templateUrl: './research-object-table-list.component.html',
  styleUrls: ['./research-object-table-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [sortingRotationStateAnimation]
})
export class ResearchObjectTableListComponent {
  @Input() listData: ResearchObject[];
  @Input() listDataLoaded: boolean;
  @Input() sort: SortDto;
  @Output() sortChange: EventEmitter<SortDto> = new EventEmitter<SortDto>();

  parameters$: Observable<Field[]> = this.researchObjectsStoreService.researchObjects$.pipe(
    map(researchObjects =>
      researchObjects.length ? toSectionFields(researchObjects[0], { byType: { type: SectionType.PARAMETERS } }).sort(comparePositions) : []
    )
  );
  imageAreaHidden$ = this.templateStoreService.imageAreaHidden$;
  parametersAreaHidden$ = this.templateStoreService.parametersAreaHidden$;
  instanceAreaHidden$ = this.templateStoreService.instanceAreaHidden$;
  currentCategoryHasAnyChild$: Observable<boolean> = this.store.select(selectRouteCategoryHasAnyChildCategory);
  showExperiments$ = this.store.select(selectShowExperiments);
  categoryId = toSignal(this.store.select(selectRouteParam(RouteParam.CATEGORY_ID)));
  isChecked$ = this.store.select(selectSelectedAll);

  readonly trackById = trackById;
  readonly SkeletonType = SkeletonType;
  readonly researchObjectFields = ResearchObjectFields;

  get getInstanceSectionName(): string {
    return getInstanceSectionName(this.listData[0]);
  }

  constructor(
    private store: Store,
    private readonly researchObjectService: ResearchObjectService,
    private readonly researchObjectsStoreService: ResearchObjectsStoreService,
    private readonly templateStoreService: TemplateStoreService
  ) {}

  setSort(prop: string, byField = false): void {
    const reverseSortDirection = this.sort.prop === prop || this.sort.parameter_id === prop;
    this.sortChange.emit({
      prop: byField ? null : prop,
      order: reverseSortDirection ? this.getOppositeOrder(this.sort.order) : SortOrderTypes.ASCENDING,
      parameter_id: byField ? prop : null
    });
  }

  toggleAllResearchObjectsSelection($event): void {
    this.researchObjectService.toggleAllResearchObjectsSelection($event, this.categoryId());
  }

  private getOppositeOrder(order: SortOrderTypes): SortOrderTypes {
    if (order === SortOrderTypes.DESCENDING) {
      return SortOrderTypes.ASCENDING;
    }
    return SortOrderTypes.DESCENDING;
  }
}
