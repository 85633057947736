import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NO_VALUE_COLOR_INDEX } from '@app/domain/field/placeholders/dropdown';

import { OptionsDto } from '@shared/_models';

@Component({
  selector: 'app-option-icon',
  standalone: true,
  templateUrl: './option-icon.component.html',
  styleUrls: ['./option-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionIconComponent {
  @Input() options: OptionsDto;
  @Input() value: string;

  get valueColor(): number {
    return this.options?.allowed_values?.find(option => option.enum_value === this.value)?.enum_color ?? NO_VALUE_COLOR_INDEX;
  }
}
