<div class="file-thumb-rotate">
  @if (!fileAnalyzed) {
    <span *ngIf="!fileFailed" class="file-thumb-rotate__static">?</span>
    <div [ngClass]="{ 'file-thumb-rotate-icon-fail': fileFailed }" class="file-thumb-rotate-icon"></div>
  } @else {
    <app-icon
      class="icon"
      width="1.25rem"
      height="1.25rem"
      fileName="import_icon_with_question_mark.svg"
      [color]="IconColors.blue"
    ></app-icon>
  }
</div>
