<div [formGroup]="form" class="w-100">
  <ng-select
    #select
    bindLabel="name"
    bindValue="id"
    data-test="selectDocCategory"
    placeholder="start typing to search or choose from list"
    dropdownPosition="bottom"
    [class.show-magnifying-glass]="hasFocus || !form.controls[controlName].value"
    [class.ng-invalid]="(form.controls[controlName].dirty || form.controls[controlName].touched) && form.controls[controlName].errors"
    [disabled]="disabled"
    [formControlName]="controlName"
    [clearable]="false"
    [items]="items"
    [editableSearchTerm]="true"
    [typeToSearchText]
    (search)="onSearch($event)"
    (change)="onSelectItem($event, select)"
    (focusin)="onFocusin()"
    (focusout)="hasFocus = false"
  >
    <ng-template ng-label-tmp let-item="item">
      <span class="ng-select-label ng-select-label-padding" [attr.data-test]="'option_' + item.name">{{ item.name }}</span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <span
        data-testid="tree-select-dropdown-option"
        [class.fw-bold]="item.hasChildren"
        [ngStyle]="{ 'padding-left': indentSize(item.level) }"
        [attr.data-test]="'docCategory_' + item.name"
      >
        {{ item.name }}
      </span>
    </ng-template>
  </ng-select>
</div>
