export function getFileSize(sizeInBytes: number): string {
  const sizeInKiloBytes = sizeInBytes / 1024;
  const sizeInMegaBytes = sizeInKiloBytes / 1024;
  let size: number;
  let unit: string;

  if (sizeInMegaBytes >= 1) {
    size = sizeInMegaBytes;
    unit = 'MB';
  } else if (sizeInKiloBytes >= 1) {
    size = sizeInKiloBytes;
    unit = 'kB';
  } else {
    size = sizeInBytes;
    unit = 'B';
  }

  return `${Math.round(size * 100) / 100} ${unit}`;
}
