<div class="col table-col text-left image-col">
  <app-table-item-image
    [imageAreaHidden]="imageAreaHidden()"
    [developmentType]="DevelopmentType.researchObject"
    [developmentItem]="developmentItem()"
    [categoryName]="linkedObject().category_name"
  ></app-table-item-image>
</div>
<div class="col table-col oid-col">{{ linkedObject().ro_oid || '-' }}</div>
<div class="col table-col">{{ linkedObject().ro_name || '-' }}</div>
<div class="col table-col">{{ linkedObject().category_name || '-' }}</div>
<div class="col table-col">
  <app-user-avatar [imageBase64]="avatar$ | async" avatarSize="0.875rem"></app-user-avatar>
  <div class="user-col-value" appOverflowContentPluginToNgbTooltip ngbTooltip="{{ linkedObject().user_crt.coalesced_email || '-' }}">
    {{ linkedObject().user_crt.coalesced_email || '-' }}
  </div>
</div>
<div class="col table-col">
  {{ !instanceAreaHidden() ? linkedObject().ro_instance_serial_number || '-' : ' ' }}
</div>
<div class="col table-col used-in-exp-col">{{ linkedObject().count_experiments || '-' }}</div>
<div class="col table-col date-added-col">{{ linkedObject().date_created | date: 'dd.MM.YYYY' || '-' }}</div>
<button
  (click)="
    goToResearchObjectInfo.emit({
      researchObjectId: linkedObject().research_object_id,
      researchObjectCategoryId: linkedObject().category_id
    })
  "
  class="table-body-row__item-btn table-body-row__item-btn--ro"
>
  <img src="./../../../../../assets/icons/chevron_3_icon.svg" alt="arrow right icon" class="table-body-row__item-btn-icon" />
</button>
