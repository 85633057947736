import { ProjectDto } from './project';
import { UserDto } from './user.interface';
import { IMemberEdit, MemberPermission, MemberRole } from './member';

export class ProjectProxy {
  constructor(
    private currentUser: UserDto,
    private project: ProjectDto
  ) {}

  get isOwner() {
    return this.project?.user_crt?.id === this.currentUser?.id;
  }

  get isLead() {
    return this.project?.leads?.some(lead => lead.id === this.currentUser?.id);
  }

  get isReaderOrEditor() {
    return this.project?.members?.some(member => member.user.id === this.currentUser?.id);
  }

  get belongsToProject() {
    return this.isOwner || this.isLead || this.isReaderOrEditor;
  }

  get isPending() {
    return this.project?.pendings.some(pending => pending.id === this.currentUser?.id);
  }

  get canEdit(): boolean {
    const isEditor = () =>
      this.project?.members?.some(member => member.user.id === this.currentUser?.id && member.permission === MemberPermission.WRITE);

    return this.isOwner || this.isLead || isEditor();
  }

  get canEditTemplate(): boolean {
    return this.isOwner || this.isLead;
  }

  canRemoveMember(member: IMemberEdit) {
    if (this.isOwner) {
      return true;
    }

    return member.roleBeforeEdit === MemberRole.reader || member.roleBeforeEdit === MemberRole.editor;
  }
}
