import { omit } from 'lodash-es';

import { Field, FieldValueDto, ParameterDto, DisplayFieldType, DevelopmentItem } from '@shared/_models';
import { SectionSelector, getSectionBySelector } from '@app/domain/category-template';
import { isCalculatedField } from '@app/domain/field';

export function toFields(parameters: ParameterDto[], fieldValuesDto: FieldValueDto[] = []): Field[] {
  return (
    parameters?.map(parameter => {
      const valueDto = fieldValuesDto.find(fieldValueDto => fieldValueDto.field_template_id === parameter.id);

      return toField(parameter, valueDto);
    }) ?? []
  );
}

export function toSectionFields(developmentItem: DevelopmentItem, options: SectionSelector): Field[] {
  const section = getSectionBySelector(developmentItem.template, options);

  return (
    section?.fields.map(parameter => {
      const valueDto = developmentItem.field_values.find(fieldValueDto => fieldValueDto.field_template_id === parameter.id);

      return toField(parameter, valueDto);
    }) ?? []
  );
}

export function toFieldValueDto(field: Field): FieldValueDto {
  return {
    id: field.id,
    field_template_id: field.field_template_id,
    value: field.value || null
  };
}

export function toField(parameter: ParameterDto, valueDto: FieldValueDto = null): Field {
  const field: Field = {
    ...parameter,
    label: parameter.label || parameter.name,
    id: valueDto?.id,
    field_template_id: parameter.id,
    value: valueDto?.value,
    type: (parameter.display_options.display_type as DisplayFieldType) || DisplayFieldType.TEXT
  };

  return isCalculatedField(field) ? { ...field, name: parameter.field_type } : field;
}

export function toParameter(field: Field): ParameterDto {
  return {
    ...omit(field, ['id', 'field_template_id', 'value', 'type']),
    id: field.field_template_id
  };
}
