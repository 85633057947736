import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//
import { PasswordLayoutComponent } from './password-layout/password-layout.component';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordRoutingPath } from './password-routing-path';

const routes: Routes = [
  {
    path: '',
    component: PasswordLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: PasswordRoutingPath.requestResetPassword,
      },
      {
        path: PasswordRoutingPath.requestResetPassword,
        component: RequestResetPasswordComponent,
      },
      {
        path: PasswordRoutingPath.resetPassword,
        component: ResetPasswordComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PasswordRoutingModule {}

