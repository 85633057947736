<div data-testid="skeleton-details" class="skeleton-container">
  <div class="skeleton-breadcrumbs"></div>
  <div class="skeleton-nav">
    <div class="skeleton-nav__body">
      <div class="row flex-fill align-items-center skeleton-nav__row">
        <div class="skeleton-nav__info-container col-5">
          <span class="skeleton-nav-icon"></span>
          <div class="skeleton-nav-name__container">
            <span class="skeleton-nav-name"></span>
            <span class="skeleton-nav-id"></span>
          </div>
        </div>
        <div class="skeleton-nav__nav justify-content-end col-7 pe-0">
          <div class="skeleton-nav__nav-items-container">
            <span *ngFor="let item of [1, 2, 3, 4, 5]" class="skeleton-nav__nav-item"></span>
          </div>
        </div>
      </div>
      <div class="skeleton-nav__previous-next">
        <span class="skeleton-nav__previous"></span>
        <span class="skeleton-nav__next"></span>
      </div>
    </div>
  </div>
  <div class="skeleton-sections">
    <div class="row">
      <div class="col-xl-8">
        <div class="skeleton-images">
          <span class="skeleton-section-label"></span>
          <span class="skeleton-section-image"></span>
        </div>
        <div class="skeleton-grid">
          <span class="skeleton-section-label"></span>
          <span class="skeleton-section-grid">
            <span *ngFor="let item of [1, 2, 3, 4, 5, 6]" class="skeleton-section-grid-item"></span>
          </span>
        </div>
        <div class="skeleton-table">
          <span class="skeleton-section-label"></span>
          <span class="skeleton-section-table">
            <span *ngFor="let item of [1, 2, 3, 4, 5, 6]" class="skeleton-section-table-item">
              <span class="skeleton-section-table-item-label"></span>
              <span class="skeleton-section-table-item-value"></span>
            </span>
          </span>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="skeleton-related-items">
          <span class="skeleton-section-label"></span>
          <span class="skeleton-section-grid">
            <span *ngFor="let item of [1, 2, 3, 4, 5, 6]" class="skeleton-section-related-item"></span>
          </span>
          <span class="skeleton-section-button"></span>
        </div>
        <div class="skeleton-recent-files">
          <span class="skeleton-section-label"></span>
          <span class="skeleton-section-grid">
            <span *ngFor="let item of [1, 2, 3, 4, 5]" class="skeleton-section-files-item"></span>
          </span>
          <span class="skeleton-section-button"></span>
        </div>
      </div>
    </div>
  </div>
</div>
