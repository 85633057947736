<app-generic-modal [hideFooter]="true" [hideHeaderCloseIcon]="true">
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
  </ng-template>
  <div class="info-modal__body">
    <div class="file-sharing-modal__header">Share a file</div>
    <div class="sharing-modal-upload-container">
      <app-file-upload-card
        [fileSharing]="true"
        [fileUploadInProgress]="fileUploadStatus().uploadPending"
        [fileName]="fileName()"
        [fileSize]="fileSize()"
        [fileExtension]="fileExtension()"
        [uploadEvents$]="fileUploadStatus().uploadHttpEvents$"
        (uploadSuccess)="onUploadSuccess()"
        (uploadFail)="onUploadFail()"
        (uploadInProgress)="onUploadInProgress()"
        (fileSelected)="onFileSelected($event)"
      ></app-file-upload-card>
    </div>
    <div class="item-label">
      <div class="shared-label">File is shared with</div>
      <div class="share-icon-container">
        @if ((storeFile$ | async)?.count_research_objects > 0) {
          <div class="shared__objects">
            <img src="../../../assets/icons/atom_gradient_icon.svg" />
            <span class="shared__object_count">{{ (storeFile$ | async)?.count_research_objects }}</span>
          </div>
        }
        @if ((storeFile$ | async)?.count_experiments > 0) {
          <div class="shared__objects">
            <img src="../../../assets/icons/chart_gradient_icon.svg" />
            <span class="shared__object_count">{{ (storeFile$ | async)?.count_experiments }}</span>
          </div>
        }
      </div>
    </div>
    <app-file-sharing-development-items
      [items]="linkedToDevelopmentItems$ | async"
      [developmentItemId]="developmentItemId()"
      [developmentType]="developmentType()"
      [fileId]="this.file().id"
      [fileName]="this.file().name"
      (link)="onLink($event)"
      (unlink)="onUnlink($event)"
    ></app-file-sharing-development-items>
  </div>
</app-generic-modal>

<ng-template #modalHeaderTemplate>
  <app-modal-header-left
    header="Manage"
    itemName="File Sharing"
    imgSrc="share_icon_big.svg"
    [categoryName]="file().name | fileWithoutExtension"
    [developmentType]="DevelopmentType.all"
    (closeModal)="closeModal.emit()"
  ></app-modal-header-left>
</ng-template>
