import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { Pagination, ResearchObject } from '@shared/_models';
import { ResearchObjectsState, RESEARCH_OBJECTS_FEATURE_KEY } from './research-objects.reducer';

export const selectResearchObjectsState = createFeatureSelector<ResearchObjectsState>(RESEARCH_OBJECTS_FEATURE_KEY);

export const selectResearchObjectsDictionary = createSelector(selectResearchObjectsState, (state: ResearchObjectsState) => state.entities);

export const selectResearchObjects = createSelector(selectResearchObjectsDictionary, (dictionary: Dictionary<ResearchObject>) =>
  Object.values(dictionary)
);

export const selectResearchObjectsLoading = createSelector(selectResearchObjectsState, (state: ResearchObjectsState) => state.loading);

export const selectResearchObjectsLoaded = createSelector(selectResearchObjectsState, (state: ResearchObjectsState) => state.loaded);

export const selectResearchObjectsPagination = createSelector(
  selectResearchObjectsState,
  (state: ResearchObjectsState) => state.pagination
);

export const selectResearchObjectsSort = createSelector(selectResearchObjectsState, (state: ResearchObjectsState) => state.sort);

export const selectResearchObjectsSelectedIds = createSelector(
  selectResearchObjectsState,
  (state: ResearchObjectsState) => state.selectedIds
);

export const selectResearchObjectById = (id: string) =>
  createSelector(selectResearchObjectsState, researchObjects => researchObjects?.entities[id]);

// w obrebie aktualnej strony
// jesli index rowny jest limitowi (ilosci elem per strona)
// to pobieramy nastepna strone RO-sow

const getPrevIndex = (researchObjects, currentIndex: number, pagination: Pagination) => {
  const prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;

  if (prevIndex === 0 && pagination.page > 1) {
    return { id: researchObjects?.ids[prevIndex], page: pagination.page - 1 };
  } else {
    return { id: researchObjects?.ids[prevIndex], page: pagination.page };
  }
};

export const selectPreviousResearchObjectId = (id: string) =>
  createSelector(selectResearchObjectsState, researchObjects => {
    const { pagination } = researchObjects;

    const currentIndex = researchObjects?.ids.findIndex(storeId => storeId === id);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;
    return researchObjects?.ids[prevIndex];

    // return getPrevIndex(researchObjects, currentIndex, pagination);
  });

export const selectNextResearchObjectId = (id: string) =>
  createSelector(selectResearchObjectsState, researchObjects => {
    const currentIndex = researchObjects?.ids.findIndex(storeId => storeId === id);
    const idsLen = researchObjects?.ids.length;
    const nextIndex = currentIndex < idsLen - 1 ? currentIndex + 1 : idsLen - 1;

    return researchObjects?.ids[nextIndex];
  });

export const selectResearchObjectsSearch = createSelector(selectResearchObjectsState, (state: ResearchObjectsState) => state.search);

export const selectRelatedExperimentsCount = (roIds: string[]) =>
  createSelector(selectResearchObjects, objects => {
    const filteredObjects = objects.filter(object => roIds.includes(object.id));
    const relatedExpCount = filteredObjects.reduce((accumulator, ro) => accumulator + ro.experiments.length, 0);

    return relatedExpCount;
  });

export const selectSelectedAll = createSelector(
  selectResearchObjectsState,
  (state: ResearchObjectsState) => state.selectedIds.length === state.pagination.total_records
);
