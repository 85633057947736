import { Injectable } from '@angular/core';
import { NavigationStart, Params, Router } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { selectRouteParam } from '@shared/_root-store/router-store/selectors/router.selectors';
import { RouteParam } from '@shared/_models';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));
  categoryId = toSignal(this.store.select(selectRouteParam(RouteParam.CATEGORY_ID)));

  constructor(
    private readonly router: Router,
    private readonly ngbModal: NgbModal,
    private readonly store: Store
  ) {}

  navigateToCurrentRouteButWithDifferentQueryParams(params: Params) {
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge'
    });
  }

  handleActionsOnRouteChange() {
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(() => {
        this.ngbModal.dismissAll();
      });
  }
}
