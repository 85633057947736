import { calculatePageNumber } from './calculate-page-number';
import { Pagination } from 'src/app/shared/_models';

export type PageInfo = {
  label: string;
  value: number;
};

export function createPageArray(currentPage: number, pagination: Pagination, paginationRange: number): PageInfo[] {
  paginationRange = +paginationRange;

  const pages = [];
  const totalPages = pagination?.pageCount || 0;
  const halfWay = Math.ceil(paginationRange / 2);
  const isStart = currentPage <= halfWay;
  const isEnd = totalPages - halfWay < currentPage;
  const isMiddle = !isStart && !isEnd;
  const ellipsesNeeded = paginationRange < totalPages;
  let i = 1;

  while (i <= totalPages && i <= paginationRange) {
    let label: string;
    const pageNumber: number = calculatePageNumber(i, currentPage, paginationRange, totalPages);
    const openingEllipsesNeeded = i === 2 && (isMiddle || isEnd);
    const closingEllipsesNeeded = i === paginationRange - 1 && (isMiddle || isStart);

    if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
      label = '...';
    } else {
      label = pageNumber.toString();
    }
    pages.push({
      label,
      value: pageNumber,
    });

    i++;
  }

  return pages;
}
