import { Component } from '@angular/core';
import { AsyncPipe, Location } from '@angular/common';
import { RouterLink } from '@angular/router';

import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [AsyncPipe, RouterLink, ButtonComponent],
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  currentOrInitialWorkspace$ = this.workspaceService.getCurrentOrInitialWorkspace();

  constructor(private location: Location, private workspaceService: WorkspaceService) {}
}
