<div
  class="datatable__additional"
  [ngClass]="{
    'datatable__additional--selected': showInstancesDetails === true
  }"
>
  <app-table-item-details
    (showRelatedItemsChanged)="switchShowInstancesDetails($event)"
    [detailsMessage]="detailsMessage()"
  ></app-table-item-details>
  <ng-container *ngIf="showInstancesDetails">
    <div class="datatable__additional-header align-items-center">
      <div class="col batch-col text-left">
        {{ instanceSectionName() }} ID
        <img class="arrow-top" *ngIf="showInstancesDetails" [src]="'assets/icons/icon_arrow-top.svg'" />
      </div>
      <div *ngFor="let field of firstInstanceFieldsSignal() | slice: 0 : 7; trackBy: trackByIndex; let i = index" class="col text left">
        <span>{{ field.name }}</span>
      </div>
    </div>
    <div *ngFor="let instance of instancesVMSignal(); trackBy: trackByIndex" class="datatable__additional-row">
      <div appOverflowContentPluginToNgbTooltip [ngbTooltip]="instance.serial_number || '-'" class="col single-batch-col text-left">
        {{ instance.serial_number }}
      </div>
      <div
        class="col single-batch-col text-left"
        *ngFor="let field of instance.fields | slice: 0 : 7; trackBy: trackByIndex; let i = index"
      >
        <app-field-value-preview [field]="field" [minimized]="true"></app-field-value-preview>
      </div>
    </div>
  </ng-container>
</div>
