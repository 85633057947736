<p class="url-expose__hint">
  You can share this {{ developmentType }} with team members, so they will be able to quick view it or edit it&acute;s details.
</p>
<label *ngIf="developmentType === 'research-object'" for="ro-url" class="form-label url-expose__label">Link to object page</label>
<label *ngIf="developmentType === 'experiment'" for="ro-url" class="form-label url-expose__label">Link to experiment</label>
<div class="input-group input-group-sm mb-3">
  <span class="input-group-text url-expose__input-icon-container">
    <img src="../../../assets/icons/link_icon.svg" alt="link icon" />
  </span>
  <input
    data-testid="ro-share-url"
    #urlInput
    type="text"
    id="ro-url"
    name="ro-url"
    class="form-control url-expose__input"
    [(ngModel)]="link"
    (click)="selectUrl($event)"
    (change)="this.linkChange.emit(link)"
    readonly
  />
</div>
