import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { omitBy, isNil } from 'lodash-es';

import { AppSettings } from '@app/shared/_configuration';
import {
  ProjectDto,
  UpdateProjectDto,
  CreateProjectDto,
  MemberPermission,
  ProjectProxy,
  DevelopmentType,
  UserDto,
  IMembersFormData,
  DropdownItem,
  IUserWithRole,
  ActiveStatus,
  ProjectWorkspaceDto,
  EditMode,
  MemberDto
} from '@shared/_models';
import { ProjectsStoreService } from '@app/shared/_root-store/projects-store/projects-store.service';
import { ProjectsApiService } from '@app/shared/_services/project';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { UsersStoreService } from '@app/shared/_root-store/users-store/users-store.service';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { addErrorToForm } from '@app/shared/_helpers';
import { ModalType } from '@shared/_modules/project/utils';

export enum ACTION_TYPE {
  LEAVE = 'leave',
  JOIN = 'join',
  CHANGE_OWNER = 'changeOwner',
  DELETE = 'delete'
}

@Component({
  selector: 'app-project-edit-add',
  templateUrl: './project-edit-add.component.html',
  styleUrls: ['./project-edit-add.component.scss']
})
export class ProjectEditAddComponent implements OnInit, OnDestroy {
  @Input() modalType: ModalType;
  @Input() selectedProject: ProjectDto;
  @Input() membersChangedData: IUserWithRole[] = [];
  @Output() openProjectMembersEditModal = new EventEmitter<Partial<ProjectDto>>();
  @Output() closeModal = new EventEmitter<void>();
  @ViewChild('ownerChangesConfirmationModal', { static: false }) ownerChangesConfirmationModal: ElementRef;
  @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: ElementRef;

  readonly destroy$: Subject<boolean> = new Subject<boolean>();
  readonly ModalType = ModalType;
  readonly ACTION_TYPE = ACTION_TYPE;
  readonly DevelopmentType = DevelopmentType;
  form: FormGroup;
  statusSelectItems: DropdownItem[] = [
    { id: 1, name: ActiveStatus.ACTIVE },
    { id: 2, name: ActiveStatus.INACTIVE }
  ];
  projectProxy: ProjectProxy;
  IconColors = IconColors;
  currentUser$ = this.usersStoreService.currentUser$;

  get markDescriptionAsInvalid(): boolean {
    const control = this.controls.description;

    return control?.invalid && control?.touched;
  }

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    public readonly activeModal: NgbActiveModal,
    private readonly projectsApiService: ProjectsApiService,
    private readonly projectsStoreService: ProjectsStoreService,
    private readonly ngbModal: NgbModal,
    private readonly workspaceService: WorkspaceService,
    private readonly usersStoreService: UsersStoreService
  ) {}

  ngOnInit() {
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe(currentUser => {
      this.projectProxy = new ProjectProxy(currentUser, this.selectedProject);
      this.initForm(currentUser);
    });
  }

  get controls() {
    return this.form.controls;
  }

  private initForm(currentUser: UserDto) {
    this.form = new FormGroup({
      name: new FormControl(this.selectedProject?.name, Validators.required),
      status: new FormControl<string>(this.selectedProject?.status || 'active'),
      color: new FormControl<number>(this.selectedProject?.color || 0),
      description: new FormControl<string>(this.selectedProject?.description, Validators.maxLength(120)),
      owners: new FormControl<UserDto[]>([this.selectedProject?.user_crt || currentUser], [Validators.required, Validators.maxLength(1)]),
      leads: new FormControl<UserDto[]>(this.selectedProject?.leads || []),
      editors: new FormControl<UserDto[]>(
        this.selectedProject?.members?.filter(x => x.permission == MemberPermission.WRITE)?.map(x => x.user) || []
      ),
      readers: new FormControl<UserDto[]>(
        this.selectedProject?.members?.filter(x => x.permission == MemberPermission.READ)?.map(x => x.user) || []
      )
    });
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.closeModal.emit();
    } else if (reason === CloseReason.ACCEPT) {
      this.onSaveProject();
    }
  }

  onColorChange(color: number) {
    this.controls.color.setValue(color);
  }

  onMembersChange(membersFormData: IMembersFormData) {
    this.controls.owners.setValue(membersFormData.owners);
    this.controls.leads.setValue(membersFormData.leads);
    this.controls.editors.setValue(membersFormData.editors);
    this.controls.readers.setValue(membersFormData.readers);
  }

  onSaveProject() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (this.selectedProject && this.selectedProject.user_crt.id !== this.controls.owners.value[0].id) {
      this.openOwnerChangesConfirmationModal();

      return;
    }

    this.saveChanges();
    this.membersChangedData = null;
  }

  openDeleteModal() {
    this.ngbModal.open(this.deleteConfirmationModal, this.settings.MODAL_DEFAULT_CONFIG);
  }

  openOwnerChangesConfirmationModal() {
    this.ngbModal.open(this.ownerChangesConfirmationModal, this.settings.MODAL_DEFAULT_CONFIG);
  }

  onDeleteConfirmation(deleteConfirmation: NgbModalRef) {
    this.projectsApiService
      .delete(this.selectedProject.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.projectsStoreService.loadProjects(this.workspaceService.getCurrentWorkspaceId());
        deleteConfirmation.close();
        this.closeModal.emit();
      });
  }

  onChangeOwnerConfirmation(changeConfirmation: NgbModalRef) {
    this.saveChanges();
    changeConfirmation.close();
  }

  private saveChanges() {
    const mode = this.modalType === ModalType.NEW ? EditMode.ADD : EditMode.EDIT;
    const addOrUpdate =
      mode === EditMode.ADD
        ? this.projectsApiService.add(this.getProject(mode) as CreateProjectDto)
        : this.projectsApiService.update(this.selectedProject.id, this.getProject(mode));

    addOrUpdate.pipe(takeUntil(this.destroy$)).subscribe({
      complete: () => {
        this.projectsStoreService.loadProjects(this.workspaceService.getCurrentWorkspaceId());
        this.closeModal.emit();
      },
      error: error => addErrorToForm(error, this.form)
    });
  }

  private getProject(mode: EditMode): Partial<UpdateProjectDto> | CreateProjectDto {
    const editors: MemberDto[] = this.controls.editors.value.map(editor => ({ user_id: editor.id, permission: MemberPermission.WRITE }));
    const readers: MemberDto[] = this.controls.readers.value.map(reader => ({ user_id: reader.id, permission: MemberPermission.READ }));
    const leads: string[] = this.projectProxy.isOwner ? this.controls.leads.value.map(lead => lead.id) : null;
    const members: MemberDto[] = editors.concat(readers);
    const project =
      mode === EditMode.ADD
        ? {
            workspace_id: this.workspaceService.getCurrentWorkspaceId(),
            name: this.controls.name.value,
            description: this.controls.description.value,
            status: this.controls.status.value,
            color: this.controls.color.value,
            user_created: this.controls.owners.value.map(owner => owner.id)[0],
            leads,
            members
          }
        : {
            name: this.controls.name.value,
            description: this.controls.description.value,
            status: this.controls.status.value,
            color: this.controls.color.value,
            leads,
            members
          };

    return omitBy(project, isNil);
  }

  menageMembers() {
    this.openProjectMembersEditModal.emit(this.#getProjectDto());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  #getProjectDto(): Partial<ProjectDto> {
    const editors = this.controls.editors.value.map(editor => ({ user: editor, permission: MemberPermission.WRITE }));
    const readers = this.controls.readers.value.map(reader => ({ user: reader, permission: MemberPermission.READ }));

    return {
      id: this.selectedProject?.id ?? null,
      name: this.controls.name.value,
      description: this.controls.description.value,
      status: this.controls.status.value,
      color: this.controls.color.value,
      leads: this.controls.leads.value,
      members: editors.concat(readers),
      user_crt: this.controls.owners.value?.[0],
      workspace: this.workspaceService.currentWorkspace as ProjectWorkspaceDto
    };
  }
}
