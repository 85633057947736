import { ChangeDetectionStrategy, Component, ElementRef, Inject, signal, ViewChild } from '@angular/core';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, shareReplay, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppSettings } from '@app/shared/_configuration';
import { AuthService } from '../../auth.service';
import { AuthSignUpRequest, AuthSignUpResponse } from '../../interface';
import { AuthRoutingPath } from '../../auth-routing-path';
import { NameValidator, PasswordConditionsValidator } from '@shared/_validators';
import { CONTACT_ADMINISTRATOR } from '@app/_auth/constants';
import { PasswordStrengthMeterComponent } from '../../password-strength-meter/password-strength-meter.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GenericModalComponent } from '@app/shared/_components/generic-modal/generic-modal.component';
import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { EmailWithDomainValidator } from '@app/shared/_validators/email-with-domain.validator';
import { IconComponent } from '../../../shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';

enum SIGNUP_FORM_KEYS {
  NAME = 'name',
  SURNAME = 'surname',
  EMAIL = 'email',
  PASSWORD = 'password'
}

@Component({
  selector: 'app-signup',
  imports: [
    AsyncPipe,
    NgClass,
    NgIf,
    NgTemplateOutlet,
    ReactiveFormsModule,
    RouterLink,
    GenericModalComponent,
    PasswordStrengthMeterComponent,
    PrivacyPolicyComponent,
    UserAgreementComponent,
    ButtonComponent,
    IconComponent
  ],
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SignupComponent {
  @ViewChild('userAgreementModal', { static: false }) userAgreementModal: ElementRef;
  @ViewChild('privacyPolicyModal', { static: false }) privacyPolicyModal: ElementRef;

  signUpAction$ = new Subject<AuthSignUpRequest>();
  formSubmitted$ = new BehaviorSubject(false);
  signedUpUser$: Observable<AuthSignUpResponse> = this.signUpAction$.pipe(
    switchMap(requestData => this.authService.signUp(requestData)),
    shareReplay(1)
  );

  readonly signInRoutingPath = ['/', AuthRoutingPath.auth, AuthRoutingPath.signIn];
  readonly CONTACT_ADMINISTRATOR = CONTACT_ADMINISTRATOR;
  readonly SIGNUP_FORM_KEYS = SIGNUP_FORM_KEYS;
  readonly IconColors = IconColors;

  focusFirstName = signal(false);
  focusLastName = signal(false);
  focusEmail = signal(false);
  focusPassword = signal(false);

  signupForm = new FormGroup({
    [SIGNUP_FORM_KEYS.NAME]: new FormControl(null, [Validators.required, NameValidator]),
    [SIGNUP_FORM_KEYS.SURNAME]: new FormControl(null, [Validators.required, NameValidator]),
    [SIGNUP_FORM_KEYS.EMAIL]: new FormControl(null, [Validators.required, EmailWithDomainValidator]),
    [SIGNUP_FORM_KEYS.PASSWORD]: new FormControl(null, [Validators.required, PasswordConditionsValidator])
  });
  modalSetting = {
    ...this.settings.MODAL_DEFAULT_CONFIG,
    fullscreen: true
  };

  hasPasswordConditionsError$ = combineLatest([this.signupForm.valueChanges, this.signupForm.statusChanges, this.formSubmitted$]).pipe(
    map(([, , formSubmitted]) => formSubmitted && this.signupForm.controls.password.hasError('meetsConditions'))
  );

  constructor(
    @Inject(AppSettings) private readonly settings: AppSettings,
    private readonly authService: AuthService,
    private readonly ngbModal: NgbModal
  ) {}

  signUp($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    const requestData = this.signupForm.value as AuthSignUpRequest;

    this.formSubmitted$.next(true);

    if (this.signupForm.valid && requestData) {
      this.signUpAction$.next(requestData);
      return;
    }
  }

  showUserAgreement($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.ngbModal.open(this.userAgreementModal, this.modalSetting);
  }

  showPrivacyPolicy($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.ngbModal.open(this.privacyPolicyModal, this.modalSetting);
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }
}
