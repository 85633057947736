import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActiveStatus } from '@shared/_models/active-status';
import { NgClass } from '@angular/common';

//

@Component({
  selector: 'app-grid-item-status',
  standalone: true,
  imports: [NgClass],
  templateUrl: './grid-item-status.component.html',
  styleUrls: ['./grid-item-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridItemStatusComponent {
  @Input() status: ActiveStatus;
  ActiveStatus = ActiveStatus;
  constructor() {}
}
