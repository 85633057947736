import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LowerCasePipe, NgIf, NgClass, NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

import { trackByIndex } from '@shared/_helpers';
import { ExperimentStatus, RouteParam } from '@shared/_models';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { ItemDetailsSectionComponent } from '@shared/_components/item/item-details/item-details-section/item-details-section.component';
import { OverflowContentPluginToNgbTooltipDirective } from '@shared/_directives/overflow-content-plugin-to-ngb-tooltip.directive';
import { SlicePipe } from '@shared/pipes/slice.pipe';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors/router.selectors';

// TODO: Move to models
export interface IRelatedExperimentsDetailsViewData {
  resultValue: string;
  resultName: string;
  experimentName: string;
  status: ExperimentStatus;
  experimentId: string;
  experimentCategoryId: string;
}

@Component({
  selector: 'app-item-details-related-items',
  standalone: true,
  imports: [
    LowerCasePipe,
    NgIf,
    NgClass,
    NgFor,
    RouterLink,
    NgbModule,
    ItemDetailsSectionComponent,
    OverflowContentPluginToNgbTooltipDirective,
    SlicePipe
  ],
  templateUrl: './item-details-related-items.component.html',
  styleUrls: ['./item-details-related-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsRelatedItemsComponent {
  @Input() viewData: IRelatedExperimentsDetailsViewData[] = [];
  @Output() addExperimentBtnClick = new EventEmitter<null>();

  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));
  readonly trackByIndex = trackByIndex;
  workspaceId = this.workspaceService.getCurrentWorkspaceId();

  constructor(
    private store: Store,
    private workspaceService: WorkspaceService
  ) {}
}
