<ng-container *ngIf="(isRequestResetPasswordSuccessful$ | async) === false; else successTmpl">
  <form id="request-reset-password-form" [formGroup]="remindPasswordForm">
    <div
      *ngIf="remindPasswordForm.get(FORM_KEYS.EMAIL) as rpEmail"
      class="form-group"
      [ngClass]="{ focused: focusEmail, 'is-invalid': rpEmail.invalid && formSubmitted }"
    >
      <label class="text-uppercase">Email</label>
      <div class="input-wrapper">
        <i class="far fa-envelope"></i>
        <input
          type="email"
          class="form-control"
          [formControlName]="FORM_KEYS.EMAIL"
          placeholder="Enter email"
          (focusin)="focusEmail = true"
          data-testid="email-input"
          (focusout)="focusEmail = false"
        />
      </div>
      <ng-container *ngIf="formSubmitted && rpEmail.hasError('required')">
        <small data-testid="email-required-validation-error" class="form-text invalid-feedback d-block">Email is required</small>
      </ng-container>
      <ng-container *ngIf="formSubmitted && rpEmail.hasError('invalidEmail')">
        <small data-testid="inproper-email-validation-error" class="form-text invalid-feedback d-block">Inproper email format</small>
      </ng-container>
    </div>
    <div class="form-group mt-4 text-xs-center">
      <app-button class="general-primary w-100 h-45" (click)="remindPassword($event)">Send an email</app-button>
    </div>
    <div class="row additional-text">
      <div class="col">
        Go back to
        <a data-testid="go-back-to-sign-in" [routerLink]="signInRoutingPath">Sign In</a>
      </div>
    </div>
  </form>
</ng-container>
<ng-template #successTmpl>
  <div data-testid="password-reset-confirmation" class="confirmation-text">
    <h2>Password Reset Request</h2>
    <p>A link to Password Reset was sent. Please, check an email for further instructions.</p>
    <p>
      In case of any questions, please contact administrator:
      <a [href]="'mailto:' + CONTACT_ADMINISTRATOR">{{ CONTACT_ADMINISTRATOR }}</a>
    </p>
  </div>
</ng-template>
