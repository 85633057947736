import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { DevelopmentType, SkeletonType, ViewContainerDataType } from '@shared/_models';
import { selectQueryParams } from '@shared/_root-store/router-store/selectors';
import { trackById } from '@shared/_helpers';

@Component({
  selector: 'app-grid-list',
  templateUrl: './grid-list.component.html',
  styleUrls: ['./grid-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListComponent {
  @Input() listData: ViewContainerDataType[];
  @Input() listDataLoaded: boolean;
  @Input() developmentType!: DevelopmentType;

  readonly DevelopmentType = DevelopmentType;
  readonly SkeletonType = SkeletonType;
  readonly trackById = trackById;
  readonly queryParams$ = this.store.select(selectQueryParams);

  constructor(private store: Store) {}
}
