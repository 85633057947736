<div class="projects-colors__header">
  <label class="col-sm-3 project-color__header__label">Colors</label>
</div>
<div class="projects-colors__body">
  <div
    *ngFor="let color of projectColors; let i = index"
    class="projects-colors__color"
    [ngClass]="['option-color-' + i, selectedColor() === color ? 'projects-colors__color--selected' : '']"
    (click)="onColorClick(color, i)"
  ></div>
</div>
