import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { UserDto, UserAvatar } from '@app/shared/_models';

export const loadCurrentUser = createAction('[Users] Load Current User');
export const loadCurrentUserSuccess = createAction('[Users] Load Current User Success', props<{ user: UserDto }>());
export const loadCurrentUserFailure = createAction('[Users] Load Current User Failure', props<{ error: HttpErrorResponse }>());
export const loadUser = createAction('[Users] Load User', props<{ id: string }>());
export const loadUserSuccess = createAction('[Users] Load User Success', props<{ user: UserDto }>());
export const loadUserFailure = createAction('[Users] Load User Failure', props<{ error: HttpErrorResponse }>());
export const loadUsersWithAvatars = createAction('[Users] Load Users With Avatars');
export const loadUsersWithAvatarsSuccess = createAction('[Users] Load Users With Avatars Success', props<{ users: UserDto[] }>());
export const loadUsersWithAvatarsFailure = createAction('[Users] Load Users With Avatars Failure', props<{ error: HttpErrorResponse }>());
export const loadAvatars = createAction('[Users] Load Avatars', props<{ ids: string[] }>());
export const loadAvatarsStart = createAction('[Users] Load Avatars Start', props<{ ids: string[] }>());
export const loadAvatarsSuccess = createAction('[Users] Load Avatars Success', props<{ avatars: UserAvatar[] }>());
export const loadAvatarsFailure = createAction('[Users] Load Avatars Failure', props<{ ids: string[] }>());
