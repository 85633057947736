import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, of, merge } from 'rxjs';
import { debounceTime, startWith, switchMap, filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { toObservable } from '@angular/core/rxjs-interop';

import { UserDto } from '@shared/_models';
import { InputWithValidationComponent } from '@shared/_components/inputs/input-with-validation/input-with-validation.component';
import { loadUsersWithAvatars } from '@shared/_root-store/users-store/users.actions';
import { selectUsersByTerm, selectAvatarForId } from '@shared/_root-store/users-store/users.selectors';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { getUserDisplayValue } from './utils';
import { DynamicFieldBaseComponent } from '../dynamic-field-base/dynamic-field-base.component';

@Component({
  selector: 'app-form-member',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule, InputWithValidationComponent, UserAvatarComponent],
  templateUrl: './form-member.component.html',
  styleUrls: ['./form-member.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormMemberComponent extends DynamicFieldBaseComponent {
  searchTerms$ = new Subject<string>();
  selectedAvatar$: Observable<string> = toObservable(this.control).pipe(
    filter(control => !!control),
    switchMap((control: FormControl) => {
      if (!control.value) return control.valueChanges;

      return merge(of(control.value), control.valueChanges);
    }),
    switchMap((id: string) => (id ? this.store.select(selectAvatarForId(id)).pipe(map(avatar => avatar?.base64 ?? null)) : of(null)))
  );

  items$ = this.searchTerms$.pipe(
    debounceTime(250),
    startWith(''),
    switchMap((term: string) => this.store.select(selectUsersByTerm(term)))
  );

  constructor(private readonly store: Store) {
    super();
    this.store.dispatch(loadUsersWithAvatars());
  }

  toLabel(user: UserDto, selectComponent: NgSelectComponent): string {
    if (selectComponent.isOpen) return;

    return getUserDisplayValue(user, this.config());
  }

  onClear() {
    this.control().setValue(null);
  }
}
