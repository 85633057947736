export enum DevelopmentType {
  project = 'project',
  researchObject = 'research-object',
  experiment = 'experiment',
  instance = 'instance',
  workspace = 'workspace',
  all = 'all',
  file = 'file',
}

// TODO: should be replaced by function or pipe with DevelopmentType argument
export enum DevelopmentTypePlural {
  researchObjects = 'Objects',
  experiments = 'Experiments',
}

// TODO remove and replace with function or pipe (from above) with additional argument (minimize: boolean)
// Tam gdzie miejsca jest dużo może być Research Objects, a tam gdzie miejsca jest mało Objects
export const RESEARCH_OBJECTS = 'Research Objects';
export const OBJECTS = 'Objects';
