import { Component, computed, input } from '@angular/core';
import { upperFirst } from 'lodash';

import { ExperimentDto } from '../../../_models';

@Component({
  selector: 'app-experiment-status',
  templateUrl: './experiment-status.component.html',
  styleUrls: ['./experiment-status.component.scss']
})
export class ExperimentStatusComponent {
  itemData = input.required<ExperimentDto>();

  statusLabel = computed(() => upperFirst(this.itemData().status?.split('_').join(' ')));
}
