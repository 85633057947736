import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  TemplateRef,
  EventEmitter,
  Input,
  Output,
  HostBinding,
  OnInit
} from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';

import { ButtonComponent } from '../button/button.component';
import { DevelopmentType } from '@app/shared/_models';
import { getActionButtonStyle, CloseReason } from './utils';
import { ActionButton } from '@app/shared/_models/actionButton';

@Component({
  selector: 'app-generic-modal',
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, ButtonComponent],
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericModalComponent implements OnInit {
  @Input() title: string;
  @Input() closeButton: ActionButton;
  @Input() acceptButton: ActionButton;
  @Input() optionalButton: ActionButton;
  @Input() developmentType: DevelopmentType;
  @Input() primary: boolean;
  @Input() hideHeader = false;
  @Input() hideHeaderCloseIcon = false;
  @Input() hideFooter = false;
  @Input() minHeight = null;

  @Output() close = new EventEmitter<CloseReason>();

  @ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @ContentChild('footerTemplate') footerTemplate: TemplateRef<any>;

  @HostBinding('style.min-height') hostMinHeight = 'auto';

  CloseReason = CloseReason;

  ngOnInit(): void {
    this.hostMinHeight = this.minHeight ? this.minHeight + 'rem' : null;
  }

  onClose(reason: CloseReason) {
    this.close.emit(reason);
  }

  getClassStyle(closeReason: CloseReason) {
    if (closeReason === CloseReason.ACCEPT && this.acceptButton?.style) {
      return this.acceptButton.style;
    }

    if (closeReason === CloseReason.CLOSE && this.closeButton?.style) {
      return this.closeButton.style;
    }

    if (closeReason === null && this.optionalButton?.style) {
      return this.optionalButton.style;
    }

    return getActionButtonStyle(this.developmentType, closeReason, this.primary);
  }
}
