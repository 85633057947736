import { createAction, props } from '@ngrx/store';

const loadSchemas = createAction('[Configuration] Load Schemas');
const schemasLoaded = createAction('[Configuration] Schemas Loaded Successfully', props<{ schemas: any }>());
export const schemasLoadingError = createAction('[Configuration] Schemas Loading Error', props<{ error: any }>());

export const apiSchemasActions = {
  loadSchemas,
  schemasLoaded,
  schemasLoadingError,
};
