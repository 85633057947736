import { Pipe, PipeTransform } from '@angular/core';
import { FileService } from '@shared/_modules/file/file.service';
import { SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'fileSafeUrl',
  standalone: true,
})
export class FileSafeUrlPipe implements PipeTransform {
  constructor(private readonly fileService: FileService) {}

  transform(filePath: string): SafeUrl {
    return this.fileService.getFileSafeUrl(filePath);
  }
}
