import { flatten, uniqBy } from 'lodash-es';

import { CategoryDto, CategoryTemplateDto } from '@shared/_models';

// TODO: move to domain/category-template
export function isTemplateNotEmpty(template: CategoryTemplateDto): boolean {
  return template?.sections.some(section => !!section.fields.length);
}

function getHasNotEmptyTemplatePredicate(templates: CategoryTemplateDto[]) {
  if (!templates.length) return () => false;

  return (category: CategoryDto) => {
    const template = templates.find(template => template.category_id === category.id);

    return isTemplateNotEmpty(template);
  };
}

function getAncestors(category: CategoryDto, categories: CategoryDto[]): CategoryDto[] {
  const parent = category.parent_id ? categories.find(cat => cat.id === category.parent_id) : null;

  return parent ? [parent, ...getAncestors(parent, categories)] : [];
}

export function getWithoutEmptyTemplate(categories: CategoryDto[], templates: CategoryTemplateDto[]): CategoryDto[] {
  const hasNotEmptyTemplatePredicate = getHasNotEmptyTemplatePredicate(templates);
  const categoriesWithoutEmptyTemplate = categories.filter(hasNotEmptyTemplatePredicate);

  return uniqBy(
    categoriesWithoutEmptyTemplate.concat(
      flatten(categoriesWithoutEmptyTemplate.map(categoryWithoutEmptyTemplate => getAncestors(categoryWithoutEmptyTemplate, categories)))
    ),
    category => category.id
  );
}
