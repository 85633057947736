import { ParameterDto } from '@shared/_models';
import { PENDING_TAG } from './tags';

export function attachFields(parent: ParameterDto, fields: ParameterDto[]): ParameterDto[] {
  const calculatedFields: ParameterDto[] = fields
    .filter(field => field.value_from_id === PENDING_TAG)
    .map(field => ({ ...field, value_from_id: parent.id }));
  const modificationFields: ParameterDto[] = fields
    .filter(field => field.parent_id === PENDING_TAG)
    .map(field => ({ ...field, parent_id: parent.id }));

  return calculatedFields.concat(modificationFields);
}
