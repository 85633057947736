import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { BaseRouterStoreState, RouterReducerState, routerReducer } from '@ngrx/router-store';

import { environment } from './../../../environments/environment';
import { ProjectsState, reducer as projectsReducer, PROJECTS_STATE_KEY } from './projects-store/projects.reducer';
import { CategoriesState, reducer as categoriesReducer, CATEGORIES_STATE_KEY } from './categories-store/categories.reducer';
import { ROUTER_STATE_KEY } from './router-store/router-store.module';
import { ImagesState, reducer as imagesReducer, IMAGES_STATE_KEY } from './images-store/images.reducer';
import { resetStatesMetaReducer } from '@shared/_root-store/app-store/app.meta-reducers';
import {
  CategoryTemplatesState,
  reducer as categoryTemplatesReducer,
  CATEGORY_TEMPLATES_STATE_KEY
} from './category-templates-store/category-templates.reducer';
import { UsersState, reducer as usersReducer, USERS_STATE_KEY } from './users-store/users.reducer';
import { RouterState } from '@shared/_root-store/router-store/interface/router.state.interface';

export interface RootState {
  [ROUTER_STATE_KEY]: RouterReducerState<RouterState>;
  [PROJECTS_STATE_KEY]: ProjectsState;
  [CATEGORIES_STATE_KEY]: CategoriesState;
  [CATEGORY_TEMPLATES_STATE_KEY]: CategoryTemplatesState;
  [IMAGES_STATE_KEY]: ImagesState;
  [USERS_STATE_KEY]: UsersState;
}

export const reducers: ActionReducerMap<RootState> = {
  router: routerReducer,
  projects: projectsReducer,
  categories: categoriesReducer,
  categoryTemplates: categoryTemplatesReducer,
  images: imagesReducer,
  users: usersReducer
};

export const metaReducers: MetaReducer<RootState>[] = !environment.production ? [resetStatesMetaReducer] : [resetStatesMetaReducer];
