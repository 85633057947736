<section class="development-items-actions-bar">
  <div class="d-flex w-100">
    <div class="development-items-actions-bar__header-section">
      <app-breadcrumbs *ngIf="developmentType != DevelopmentType.project" [developmentType]="developmentType"></app-breadcrumbs>
      <div class="development-items-actions-bar__header">
        <span *ngIf="searchTextHasValue && total !== undefined">{{ total }} matching results in {{ header }}</span>
        <span *ngIf="!searchTextHasValue && instancesTableVisible">List of objects instances</span>
        <span *ngIf="!searchTextHasValue && !instancesTableVisible">{{ header }}</span>
      </div>
      <ng-template #instancesTableHeader>
        <div class="development-items-actions-bar__header">List of</div>
        <!-- {{ developmentTemplate?.sections.instanceNamePlural | lowercase }}{{ developmentTemplate?.sections.instanceNamePlural | lowercase }} -->
      </ng-template>
    </div>
    <div class="development-items-actions-bar__other-section d-flex align-items-center">
      <ng-content select="[v-form-actions]"></ng-content>
      <ng-container *ngIf="showActions">
        <ng-content select="[add-item-control]"></ng-content>
        <div *ngIf="showActions.search" class="ms-3 d-flex flex-nowrap align-items-center">
          <app-list-search
            [developmentType]="developmentType"
            (searchChange)="onSearchChange($event)"
            [searchBarExpanded]="searchBarExpanded"
            (close)="searchBarExpanded = false"
          ></app-list-search>
          <button
            data-testid="search-on-list-button"
            *ngIf="!searchBarExpanded"
            class="development-items-actions-bar__search-expand-btn"
            (click)="onExpandSearchBarBtnClick()"
          >
            <img src="../../../../../assets/icons/magnifier.svg" alt="magnifier" />
          </button>
        </div>
        <span *ngIf="showActions.search" class="vertical-separator mx-3"></span>
        @if (viewMode === ViewMode.grid && showActions.sort) {
          <div>
            <ng-content select="[sort-control]"></ng-content>
          </div>
          <span *ngIf="showActions.viewModeSwitch" class="vertical-separator mx-3"></span>
        }
        @if (showActions.viewModeSwitch) {
          <ng-content select="[view-control]"></ng-content>
          <span class="vertical-separator mx-3"></span>
        }
        <div>
          <ng-content select="[selection-action-control]"></ng-content>
        </div>
      </ng-container>
    </div>
  </div>
</section>
