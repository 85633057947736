import { Pipe, PipeTransform } from '@angular/core';
import { DisplayFieldType } from '../_models';

@Pipe({
  name: 'isTextLong',
  standalone: true,
})
export class IsTextLongPipe implements PipeTransform {
  transform(type: string): any {
    return type == DisplayFieldType.TEXT_LONG || type == DisplayFieldType.WYSIWYG;
  }
}
