import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusIcon',
  standalone: true
})
export class StatusIconPipe implements PipeTransform {
  transform(status: string): string {
    return `status-icon--${status?.toLowerCase()}`;
  }
}
