import { CommonModule, LowerCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DevelopmentType } from '@shared/_models';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-header-left',
  standalone: true,
  imports: [LowerCasePipe, CommonModule, NgbPopover],
  templateUrl: './modal-header-left.component.html',
  styleUrls: ['./modal-header-left.component.scss']
})
export class ModalHeaderLeftComponent {
  @Input() developmentType: DevelopmentType;
  @Input() header: string;
  @Input() itemName: string;
  @Input() categoryName: string;
  @Input() imgSrc: string;
  @Input() isTooltipBtnVisible: boolean;
  @Input() tooltipHeader: string;
  @Input() tooltipContent: string;
  @Input() whiteIconFilter: boolean;
  @Output() closeModal = new EventEmitter<void>();

  get fullImagePath(): string {
    const filePath = '../../../../../../assets/icons/';

    return `${filePath}${this.imgSrc}`;
  }
}
