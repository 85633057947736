import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FieldConfig } from '@shared/dynamic-form/models';

@Component({
  selector: 'app-input-with-validation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-with-validation.component.html',
  styleUrls: ['./input-with-validation.component.scss']
})
export class InputWithValidationComponent {
  @Input() control: FormControl;
  @Input() config: FieldConfig;
  @Input() backendErrorMessage: string;
  @Input() externalTouched: boolean = false;
}
