import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { NgFor, NgClass } from '@angular/common';

import { optionColors } from '@app/shared/_helpers/option-colors';

@Component({
  selector: 'app-project-colors',
  standalone: true,
  imports: [NgFor, NgClass],
  templateUrl: './project-colors.component.html',
  styleUrls: ['./project-colors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectColors {
  @Input() set projectColor(value: number) {
    this.selectedColor.set(this.projectColors[value]);
  }

  @Output() selectedColorIndex = new EventEmitter<number>();
  readonly projectColors = optionColors();
  selectedColor = signal<string>(null);

  onColorClick(color: string, index: number) {
    this.selectedColor.set(color);
    this.selectedColorIndex.emit(index);
  }
}
