import { Component } from '@angular/core';

import { RouterService } from '@shared/_services/router/router.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { LgPopoverConfig } from '@shared/_configuration/lg-popover-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: NgbPopoverConfig, useClass: LgPopoverConfig }, LgPopoverConfig]
})
export class AppComponent {
  constructor(private routerService: RouterService) {
    this.routerService.handleActionsOnRouteChange();
  }
}
