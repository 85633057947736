import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LowerCasePipe, TitleCasePipe } from '@angular/common';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { TreeSelectItem } from '@shared/_models';
import { DropdownItem } from '@app/shared/_models/status-dropdown';

@Component({
  selector: 'app-status-dropdown',
  standalone: true,
  imports: [NgSelectModule, LowerCasePipe, TitleCasePipe, FormsModule],
  templateUrl: './status-dropdown.component.html',
  styleUrls: ['./status-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StatusDropdownComponent,
    },
  ],
})
export class StatusDropdownComponent implements ControlValueAccessor {
  @Input() statusSelectItems: DropdownItem[] = [];
  selectedStatusName: string;
  onChange = selectedStatusName => {};
  onTouched = () => {};
  touched = false;

  onSelectItem(item: TreeSelectItem, select: NgSelectComponent) {
    this.selectedStatusName = item.name;
    this.onChange(this.selectedStatusName);
    select.blur();
  }

  onFocusin(select: NgSelectComponent) {
    this.markAsTouched();
    select.blur();
  }

  writeValue(selectedStatusName: string) {
    this.selectedStatusName = selectedStatusName;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
