<div
  class="psm__progress-bar"
  role="progressbar"
  [passwordStrength]="passwordStrength"
  [numberOfProgressBarItems]="numberOfProgressBarItems"
  [colors]="colors"
>
  <div class="psm__progress-bar-items"></div>
  <div class="psm__progress-bar-overlay"></div>
</div>
<ng-container *ngIf="enableFeedback && feedback">
  <small class="psm__feedback" *ngIf="feedback.warning">
    {{ feedback.warning }}
  </small>
  <small class="psm__suggestion" *ngIf="feedback.suggestions && feedback.suggestions.length">
    <ng-container *ngFor="let suggestion of feedback.suggestions; last as isLast">{{ suggestion }}{{ isLast ? '' : ' ' }}</ng-container>
  </small>
</ng-container>
