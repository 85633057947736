<div
  class="table-body-row align-items-center table-body-row--hover-labels"
  [ngClass]="{
    'table-body-row--selected': (isExperimentSelected(itemData().id) | async) || showRelatedResearchObjects === true
  }"
>
  <div class="datatable__row-wrapper align-items-center">
    <div class="col selection-col text-center">
      <div class="form-check">
        <input
          data-testid="exp-list-view-item-checkbox"
          class="form-check-input"
          type="checkbox"
          (change)="toggleExperimentSelection($event, itemData().id)"
          [checked]="isExperimentSelected(itemData().id) | async"
        />
      </div>
    </div>
    <div
      *ngIf="(currentCategoryHasAnyChild$ | async) || !showImages()"
      class="col image-col text-left table-body-row__image-col"
      data-title="Image"
    >
      <app-table-item-image
        [imageAreaHidden]="showImages()"
        [developmentType]="DevelopmentType.experiment"
        [developmentItem]="itemData()"
        [categoryName]="itemData().template.category.name"
      ></app-table-item-image>
    </div>
    <div class="col col-narrow text-left" data-title="EID">
      {{ itemData().oid }}
    </div>
    <div
      *ngIf="!(!(currentCategoryHasAnyChild$ | async) && resultsAreaHidden())"
      class="col result-col text-left"
      [attr.data-title]="results()[0]?.name"
    >
      <app-table-item-result [resultsAreaHidden]="showResults()" [results]="results()"></app-table-item-result>
    </div>
    <div class="col name-col text-left" data-title="Name">
      <div class="name-col-content" appOverflowContentPluginToNgbTooltip [ngbTooltip]="itemData().name">
        {{ itemData().name }}
      </div>
    </div>
    <div *ngIf="showResearchObjects()" class="col col-narrow object-col text-left" data-title="Objects Used">
      <ng-container *ngIf="itemData()?.linked_objects as experimentObjects">
        <div *ngIf="experimentObjects && experimentObjects.length" class="items-card__body-content items-card__body-content--less-padding">
          <div class="d-flex align-items-center w-100">
            <ng-container>
              <span
                appOverflowContentPluginToNgbTooltip
                [ngbTooltip]="experimentObjects[0]?.ro_name"
                class="items-card__related-object-name text-nowrap"
              >
                {{ experimentObjects[0]?.ro_name }}
              </span>
              <app-items-counter [items]="relatedResearchObjectsNames()" tooltipHeader="Objects used"></app-items-counter>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      *ngIf="!(!(currentCategoryHasAnyChild$ | async) && parametersAreaHidden())"
      class="col param-col text-left"
      [attr.data-title]="parameters()[0]?.name"
    >
      <div
        *ngIf="parameter()?.value"
        appOverflowContentPluginToNgbTooltip
        [ngbTooltip]="parameter() | itemReadValue"
        [disableTooltip]="parameter()?.type === DisplayFieldType.WYSIWYG"
      >
        <app-field-value-preview [field]="parameter()" [minimized]="true"></app-field-value-preview>
      </div>
    </div>
    <div class="col col-narrow status-col text-left" data-title="Status">
      <app-experiment-status class="position-static" [itemData]="itemData()"></app-experiment-status>
    </div>
    <div class="col added-by-col text-left" data-title="Added By">
      <div class="user-container">
        <app-user-avatar [imageBase64]="avatar$ | async" avatarSize="0.875rem"></app-user-avatar>
        <div data-test="userAdding" appOverflowContentPluginToNgbTooltip ngbTooltip="{{ itemData().user_crt.coalesced_email || '-' }}">
          {{ itemData().user_crt.coalesced_email || '-' }}
        </div>
      </div>
    </div>
    <div
      appOverflowContentPluginToNgbTooltip
      [ngbTooltip]="itemData().date_created | date: 'dd.MM.YYYY'"
      class="col col-narrow date-col text-left"
      data-title="Date Added"
    >
      {{ itemData().date_created | date: 'dd.MM.YYYY' }}
    </div>
    <div class="col actions-col lg-table__action-btn-container position-relative show-contents-on-hover">
      <ng-container
        *ngTemplateOutlet="actionsBtnAndPopover; context: { itemData: itemData(), $implicit: contextMenuConfig }"
      ></ng-container>
    </div>
    <button
      class="table-body-row__item-btn table-body-row__item-btn--exp"
      [ngClass]="{ 'table-body-row__item-btn--always-visible': showRelatedResearchObjects }"
      (click)="navigationService.goToExperimentInfo(workspaceId, projectId(), itemData().id, itemData().template.category_id)"
    >
      <img src="./../../../../../assets/icons/chevron_3_icon.svg" class="table-body-row__item-btn-icon" alt="arrow right icon" />
    </button>
  </div>
  <app-experiment-table-item-details
    *ngIf="showDetails()"
    [experiment]="itemData()"
    (showRelatedResearchObjectsChanged)="switchShowRelatedResearchObjects($event)"
  ></app-experiment-table-item-details>
</div>
<ng-template let-index="index" let-itemData="itemData" let-contextMenuConfig #actionsBtnAndPopover>
  <button
    #popover="ngbPopover"
    class="btn btn-transparent px-0 lg-table__action-btn"
    [ngClass]="{ active: popover.isOpen() }"
    [ngbPopover]="actionsPopoverContent"
    [popoverTitle]="actionsPopoverTitle"
    popoverClass="lg-popover"
    container="body"
    appClickStopPropagation
    triggers="click"
  >
    <img src="../../../assets/icons/ellipsis_icon.svg" alt="ellipsis icon" />
  </button>

  <ng-template #actionsPopoverTitle>{{ contextMenuConfig?.title || 'Actions' | uppercase }}</ng-template>
  <ng-template #actionsPopoverContent>
    <ng-container *ngFor="let action of contextMenuConfig.actions; last as isLast; trackBy: trackByIndex">
      <a href="" (click)="action.fn($event, itemData)">{{ action.name }}</a>
      <hr *ngIf="!isLast" divider />
    </ng-container>
  </ng-template>
</ng-template>
