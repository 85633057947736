import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subnavExpandCollapse',
  standalone: true,
  pure: false,
})
export class SubnavExpandCollapsePipe implements PipeTransform {
  transform(categoryId: string, visibleIds: string[] = []): string {
    return visibleIds.includes(categoryId) ? 'final' : 'initial';
  }
}
