import { Dictionary } from '@ngrx/entity';
import { omit } from 'lodash-es';

import { CategoryDto, Experiment, ExperimentDto, CategoryTemplateDto, PagedResponse } from '@shared/_models';
import { getTemplateFactory } from './get-template-factory';

export function toExperiments([experimentsResponse, categories, templates]: [
  PagedResponse<ExperimentDto>,
  Dictionary<CategoryDto>,
  Dictionary<CategoryTemplateDto>
]): PagedResponse<Experiment> {
  const getTemplate = getTemplateFactory(categories, templates);
  const data = experimentsResponse.data.map(
    experiment =>
      ({
        ...experiment,
        template: getTemplate(experiment.category_template_id),
      } as Experiment)
  );

  return {
    ...experimentsResponse,
    data,
  };
}

export function toExperiment([experiment, categories, templates]: [
  ExperimentDto,
  Dictionary<CategoryDto>,
  Dictionary<CategoryTemplateDto>
]): Experiment {
  const getTemplate = getTemplateFactory(categories, templates);
  return {
    ...experiment,
    template: getTemplate(experiment.category_template_id),
  };
}

function toExperimentDto(experiment: Experiment): ExperimentDto {
  return omit(experiment, ['template']);
}
