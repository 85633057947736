import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//
import { ApiSchemasStoreModule } from './store/api-schemas-store.module';
import { ApiSchemasService } from './api-schemas.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, ApiSchemasStoreModule],
  providers: [ApiSchemasService],
})
export class ApiSchemasModule {}
