import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function toISO8601(value: NgbDateStruct): string {
  if (!value) return null;

  const { year, month, day } = value;
  const now = new Date();
  const date = new Date(year, month - 1, day, now.getHours(), now.getMinutes(), now.getSeconds());

  return `${date.toISOString().slice(0, -5)}Z`;
}
