import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectRouteDataParam } from '@shared/_root-store/router-store/selectors';
import { Store } from '@ngrx/store';
import { RouteData } from '@shared/_models/route-data';

@Component({
  selector: 'app-empty-list-placeholder',
  standalone: true,
  templateUrl: './empty-list-placeholder.component.html',
  styleUrls: ['./empty-list-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyListPlaceholderComponent {
  @Input() header = '-';
  @Input() linkText = '-';
  @Output() addItem = new EventEmitter<void>();
  showSecondaryNavigation = toSignal(this.store.select(selectRouteDataParam(RouteData.SHOW_SECONDARY_NAVIGATION)));

  constructor(private store: Store) {}

  addNewItem() {
    this.addItem.emit();
  }
}
