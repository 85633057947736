import { Injectable } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { BehaviorSubject } from 'rxjs';
import { FileDownloadStatus, FileDto } from './../../_models';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  fileStatus: BehaviorSubject<{ [key: string]: FileDownloadStatus }> = new BehaviorSubject({});
  public fileStatus$ = this.fileStatus.asObservable();

  constructor(private readonly fileSaverService: FileSaverService) {}

  downloadParametrized(fileData: Partial<FileDto>, response: string): void {
    const { name, type } = fileData;
    const fileFullName = `${name}`?.toLowerCase();
    // application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip
    const data = new Blob([response], { type });

    this.fileSaverService.save(data, fileFullName);
  }
}
