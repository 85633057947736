import { Pipe, PipeTransform } from '@angular/core';
import { FileCategory } from '@shared/_modules/file/models/file-category';
import { FileType } from '@shared/_models';

@Pipe({
  name: 'fileUploadLimitAcceptedExtensions',
  standalone: true
})
export class FileUploadLimitAcceptedExtensionsPipe implements PipeTransform {
  transform(fileCategory: FileCategory): string {
    return fileCategory === FileCategory.images && `${FileType.PNG}, ${FileType.GIF}, ${FileType.JPG}`;
  }
}
