import { Pipe, PipeTransform } from '@angular/core';
import { DisplayFieldType, Field } from '../_models';
import { formatFieldDate } from '@shared/_helpers/format-field-date';

@Pipe({
  name: 'itemReadValue',
  standalone: true
})
export class ItemReadValuePipe implements PipeTransform {
  transform(item: Field): string {
    if (!item?.value) return item?.value;
    if (item.type == DisplayFieldType.DATE) {
      return formatFieldDate(item.value, item.display_options.date_format);
    }

    return item.value;
  }
}
