import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective implements AfterContentInit {
  public constructor(private el: ElementRef) {}

  public ngAfterContentInit(): void {
    this.el.nativeElement.focus();
    this.el.nativeElement.blur();
  }
}
