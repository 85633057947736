import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { FormBuilder, FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

import { FieldConfig, DynamicFormChangeData } from '@shared/dynamic-form/models';
import { DynamicFieldDirective } from '@shared/dynamic-form/directives/dynamic-field.directive';
import { toValidation } from '@app/domain/field';
import { AutoDestroyDirective } from '@shared/_directives/auto-destroy/auto-destroy.directive';
import { submitted } from './store';
import { FieldsErrors } from './utils';
import { FieldType } from '@app/shared/_models';

@Component({
  selector: 'app-dynamic-field',
  standalone: true,
  imports: [NgFor, ReactiveFormsModule, DynamicFieldDirective, CommonModule],
  template: `
    <ng-container
      appDynamicField
      [config]="config"
      [control]="control"
      [backendErrorMessage]="backendErrorMessage"
      [externalTouched]="submitted()"
      class="dynamic-form-field"
    ></ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFieldComponent extends AutoDestroyDirective implements OnInit {
  @Input() config: FieldConfig;
  @Input() backendErrorMessage: string;
  // TODO try to remove errors from valueChange(use only errorsChange for errors)
  @Output() valueChange: EventEmitter<DynamicFormChangeData> = new EventEmitter<DynamicFormChangeData>();
  // TODO: remove(right now it's just valueChange) or implement real touched
  @Output() formTouched: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() errorsChange: EventEmitter<FieldsErrors> = new EventEmitter<FieldsErrors>();

  control: FormControl;
  readonly submitted = submitted;

  #touched = false;
  #errors: ValidationErrors = null;

  constructor(private readonly fb: FormBuilder) {
    super();
    submitted.set(false);
  }

  ngOnInit() {
    this.control = this.#createControl();
    this.#emmitErrorsChange();
    this.#subscribeToFormChanges();
  }

  #subscribeToFormChanges(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value !== this.config.value) {
        this.valueChange.emit({
          value,
          errors: this.control.valid ? null : this.control.errors
        });
      }

      this.#setFormTouched(this.control.dirty);
    });
  }

  #setFormTouched(touched: boolean) {
    const hasChanged = touched !== this.#touched;
    this.#touched = touched;

    if (hasChanged) {
      this.formTouched.emit(touched);
    }
  }

  #createControl(): FormControl {
    const config = this.config;
    const { options } = config;
    const getvalue = () => {
      if (config.value) return config.value;

      if (config.field_type === FieldType.SELECT) return '';

      return undefined;
    };
    const disabled = config.disabled || !!config.value_from_id;

    return this.fb.control({ disabled, value: getvalue() }, toValidation(options));
  }

  #emmitErrorsChange() {
    const status = this.control.status;
    const getFieldsErrors = errors => ({ [this.config.field_template_id]: { errors, touched: this.control.touched } });

    if (this.control.invalid) {
      this.errorsChange.emit(getFieldsErrors(this.control.errors));
    }

    this.control.statusChanges
      .pipe(takeUntil(this.destroy$), startWith(status), pairwise())
      .subscribe(([previousStatus, currentStatus]) => {
        const errors = this.control.errors;

        if (previousStatus !== 'INVALID' && currentStatus === 'INVALID') {
          this.errorsChange.emit(getFieldsErrors(errors));
        }

        if (previousStatus !== 'VALID' && currentStatus === 'VALID') {
          this.errorsChange.emit(getFieldsErrors(null));
        }

        if (this.control.invalid && this.#errors && errors && !isEqual(this.#errors, errors)) {
          this.errorsChange.emit(getFieldsErrors(errors));
        }

        this.#errors = errors;
      });
  }
}
