import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { InputWithValidationComponent } from '../../../_components/inputs/input-with-validation/input-with-validation.component';
import { DateInputComponent } from '@shared/_components/inputs/date-input/date-input.component';
import { DynamicFieldBaseComponent } from '../dynamic-field-base/dynamic-field-base.component';

@Component({
  selector: 'app-form-date',
  standalone: true,
  imports: [ReactiveFormsModule, DateInputComponent, InputWithValidationComponent],
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormDateComponent extends DynamicFieldBaseComponent {}
