<div *ngIf="!hideHeader" class="modal-header">
  <ng-container [ngTemplateOutlet]="headerTemplate || defaultHeaderTemplate"></ng-container>

  <ng-template #defaultHeaderTemplate>
    <h4 class="modal-title">{{ title }}</h4>
  </ng-template>
  <button
    data-testid="close-header-button"
    *ngIf="!hideHeaderCloseIcon"
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClose(CloseReason.DISMISS)"
  ></button>
</div>
<div data-testid="modal-body" class="modal-body">
  <ng-content></ng-content>
</div>
<div *ngIf="!hideFooter" class="modal-footer">
  <ng-container [ngTemplateOutlet]="footerTemplate || defaultFooterTemplate"></ng-container>

  <ng-template #defaultFooterTemplate>
    <app-button
      *ngIf="closeButton"
      [class]="getClassStyle(CloseReason.CLOSE)"
      [disabled]="closeButton.disabled"
      (click)="onClose(CloseReason.CLOSE)"
    >
      {{ closeButton.text }}
    </app-button>
    <app-button
      *ngIf="optionalButton?.text?.length"
      [class]="getClassStyle(null)"
      [disabled]="optionalButton.disabled"
      (click)="onClose(null)"
    >
      {{ optionalButton.text }}
    </app-button>
    <app-button
      data-testid="accept-modal-button"
      *ngIf="acceptButton"
      [class]="getClassStyle(CloseReason.ACCEPT)"
      [disabled]="acceptButton.disabled"
      ngbAutofocus
      (click)="onClose(CloseReason.ACCEPT)"
    >
      {{ acceptButton.text }}
    </app-button>
  </ng-template>
</div>
