import { Dictionary } from 'lodash';
import { CategoryDto, PagedResponse, ResearchObject, ResearchObjectDto, CategoryTemplateDto } from '../_models';
import { getTemplateFactory } from './get-template-factory';

export function toResearchObjects([researchObjectsResponse, categories, templates]: [
  PagedResponse<ResearchObjectDto>,
  Dictionary<CategoryDto>,
  Dictionary<CategoryTemplateDto>
]): PagedResponse<ResearchObject> {
  const data = researchObjectsResponse.data.map(researchObject => toResearchObject([researchObject, categories, templates]));

  return {
    ...researchObjectsResponse,
    data,
  };
}

export function toResearchObject([researchObject, categories, templates]: [
  ResearchObjectDto,
  Dictionary<CategoryDto>,
  Dictionary<CategoryTemplateDto>
]): ResearchObject {
  const getTemplate = getTemplateFactory(categories, templates);
  const template = getTemplate(researchObject.category_template_id);

  return {
    ...researchObject,
    template,
  };
}
