import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
//
import { ApiSchemasEffects } from './effects';
import { FEATURE_API_SCHEMAS_REDUCER } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('api-schemas', FEATURE_API_SCHEMAS_REDUCER),
    EffectsModule.forFeature([ApiSchemasEffects]),
  ],
  providers: [ApiSchemasEffects],
})
export class ApiSchemasStoreModule {}
