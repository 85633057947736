<!-- wywolane jak usuwa sie powiazany element i jakis nie powiazany -->
<!-- zeby przycisk List of experiments used mial gradient experymentowy -->
<app-generic-modal
  [closeButton]="{ text: 'List of experiments used' }"
  [acceptButton]="{ text: 'Close' }"
  [developmentType]="DevelopmentType.researchObject"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  (close)="close($event)"
>
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalCenterHeaderTemplate"></ng-template>
  </ng-template>
  <div class="info-modal__body">
    <div class="info-modal__body-content">
      Selected objects are added to&nbsp;
      <span class="color-blue">{{ deleteFailRelatedExpCount }} experiments</span>
      , remove objects from experiments then try to delete them.
    </div>
  </div>
</app-generic-modal>

<ng-template #modalCenterHeaderTemplate>
  <app-modal-header-center [imgSrc]="'toast_icon_danger.png'" [header]="'Can\'t delete'"></app-modal-header-center>
</ng-template>
