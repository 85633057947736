import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { ExperimentsState, EXPERIMENTS_FEATURE_KEY } from './experiments.reducer';
import { Experiment } from '@shared/_models';

export const selectExperimentsState = createFeatureSelector<ExperimentsState>(EXPERIMENTS_FEATURE_KEY);

export const selectExperimentsDictionary = createSelector(selectExperimentsState, (state: ExperimentsState) => state.entities);

export const selectExperiments = createSelector(selectExperimentsDictionary, (dictionary: Dictionary<Experiment>) =>
  Object.values(dictionary)
);

export const selectExperimentsLoading = createSelector(selectExperimentsState, (state: ExperimentsState) => state.loading);

export const selectExperimentsLoaded = createSelector(selectExperimentsState, (state: ExperimentsState) => state.loaded);

export const selectExperimentsPagination = createSelector(selectExperimentsState, (state: ExperimentsState) => state.pagination);

export const selectExperimentsSelectedIds = createSelector(selectExperimentsState, (state: ExperimentsState) => state.selectedIds);

export const selectExperimentById = (id: string) => createSelector(selectExperimentsDictionary, dictionary => dictionary?.[id]);

export const selectSelectedAll = createSelector(
  selectExperimentsState,
  (state: ExperimentsState) => state.selectedIds.length === state.pagination.total_records
);
