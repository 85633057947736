import { Component, Input } from '@angular/core';

import { FieldsViewMode, ViewMode } from '../../_models';

@Component({
  selector: 'app-scrollable-list-viewport',
  standalone: true,
  templateUrl: './scrollable-list-viewport.component.html',
  styleUrls: ['./scrollable-list-viewport.component.scss']
})
export class ScrollableListViewportComponent {
  @Input() viewMode!: ViewMode;
  @Input() fieldsViewMode: FieldsViewMode;
  @Input() secondaryNavigationVisible = false;

  FieldsViewMode = FieldsViewMode;
}
