<app-item-details-section [sectionName]="'Experiment used'" [showEditBtn]="false">
  <ng-container>
    <div class="row">
      <div [ngClass]="viewData.length > 1 ? 'col-xl-6' : 'col-xl-12'" *ngFor="let data of viewData | slice: [0, 6]; trackBy: trackByIndex">
        <a
          class="related-item"
          [ngClass]="{ 'related-item--single': viewData?.length === 1 }"
          [routerLink]="[
            '',
            'app',
            workspaceId,
            projectId(),
            'experiments',
            {
              outlets: {
                primary: [data.experimentCategoryId, 'details', data.experimentId]
              }
            }
          ]"
        >
          <div class="related-item__body">
            <span
              class="related-item__text related-item__main-text"
              ngbTooltip="{{ data.resultValue }}"
              appOverflowContentPluginToNgbTooltip
            >
              {{ data.resultValue || '-' }}
            </span>
            <span class="related-item__text related-item__sub-text">{{ data.resultName || '-' }}</span>
            <div class="related-item__status related-item__status--active">
              <div class="status-icon status-icon--{{ data.status | lowercase }}"></div>
            </div>
          </div>
          <div class="related-item__divider"></div>
          <div class="related-item__footer">
            <span class="related-item__text related-item__footer-text">{{ data.experimentName || '-' }}</span>
          </div>
        </a>
      </div>
    </div>
    <div>
      <button class="action-btn" *ngIf="viewData?.length > 0; else createBtn" (click)="addExperimentBtnClick.emit()">
        list of experiments related to this ro
      </button>
      <ng-template #createBtn>
        <button class="action-btn">create experiment with this object</button>
      </ng-template>
    </div>
  </ng-container>
</app-item-details-section>
