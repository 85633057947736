<div id="list-view-nav" class="d-flex align-items-center">
  <button
    [disabled]="disabled"
    *ngIf="currentViewMode === ViewMode.table"
    (click)="selectView(ViewMode.grid)"
    data-testid="grid-view-button"
    class="px-0"
  >
    <img [src]="'assets/icons/grid_' + (disabled ? 'light' : 'dark') + '.svg'" alt="Grid icon" />
  </button>
  <button
    data-testid="list-view-button"
    [disabled]="disabled"
    [disabled]="disabled"
    *ngIf="currentViewMode === ViewMode.grid"
    (click)="selectView(ViewMode.table)"
  >
    <img [src]="'assets/icons/list_' + (disabled ? 'light' : 'dark') + '.svg'" alt="Table icon" />
  </button>
</div>
