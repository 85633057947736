import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgIf } from '@angular/common';

import { ListActionsBarVisibility } from '@shared/_models/list-actions-bar';
import { ExperimentParamsService } from '@shared/_services/experiment/experiment-params.service';
import { ResearchObjectParamsService } from '@shared/_services/research-object/research-object-params.service';
import { DevelopmentType, ViewMode } from '@shared/_models';
import { BreadcrumbsComponent } from '@shared/breadcrumbs';
import { ListSearchComponent } from '@shared/_components/list/list-search/list-search.component';

@Component({
  selector: 'app-list-actions-bar',
  standalone: true,
  imports: [NgIf, BreadcrumbsComponent, ListSearchComponent],
  templateUrl: './list-actions-bar.component.html',
  styleUrls: ['./list-actions-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListActionsBarComponent implements OnInit {
  @Input() searchTextHasValue: boolean;
  @Input() showActions = { search: true, sort: true, viewModeSwitch: true } as ListActionsBarVisibility;
  @Input() header: string;
  @Input() total: number;
  @Input() currentCategoryHasChildren!: boolean;
  @Input() developmentType!: DevelopmentType;
  @Input() viewMode: ViewMode;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  searchBarExpanded = false;

  readonly DevelopmentType = DevelopmentType;
  readonly ViewMode = ViewMode;

  instancesTableVisible = false;
  showInstancesBtn: boolean;

  constructor(
    private readonly researchObjectParamsService: ResearchObjectParamsService,
    private readonly experimentParamsService: ExperimentParamsService
  ) {}

  ngOnInit() {
    if (
      (this.developmentType === DevelopmentType.experiment && this.experimentParamsService.getCurrentParams().search) ||
      (this.developmentType === DevelopmentType.researchObject && this.researchObjectParamsService.getCurrentParams().search)
    ) {
      this.searchBarExpanded = true;
    }
  }

  onSearchChange(value: string): void {
    this.searchTextHasValue = !!value;
    this.searchChange.emit(value);
  }

  onExpandSearchBarBtnClick() {
    this.searchBarExpanded = !this.searchBarExpanded;
  }
}
