import { Injectable } from '@angular/core';
import { first } from 'rxjs';

import { AuthService } from '../auth.service';
import { NavigationService } from '@app/shared/_services/navigation.service';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';

@Injectable({
  providedIn: 'root',
})
export class IsNotAuthenticatedGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService,
    private readonly workspaceService: WorkspaceService
  ) {}

  canActivate(): boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    if (!isAuthenticated) {
      return true;
    }
    this.workspaceService
      .getCurrentOrInitialWorkspace()
      .pipe(first())
      .subscribe(workspace => this.navigationService.goToProjectsList(workspace.id));
    return false;
  }
}
