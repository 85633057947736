import { environment } from 'src/environments/environment';

//AUTH
export const LOGIN_API_URL = `${environment.apiUrl}/auth/token`;
export const REVOKE_TOKEN_API_URL = `${environment.apiUrl}/auth/token/revoke`;
export const GET_CURRENT_USER_URL = `${environment.apiUrl}/users/current`;

//EXPERIMENT
export const EXPERIMENTS_API_URL = `${environment.apiUrl}/experiments`;
export const EXPERIMENTS_DRAFTS_API_URL = `${environment.apiUrl}/experiment_draft`;
export const EXPERIMENTS_FILES_API_URL = `${EXPERIMENTS_API_URL}/files`;
export const EXPERIMENTS_CSV_EXPORT_API_URL = `${environment.apiUrl}/excel/export_experiments`;
export const EXPERIMENTS_CSV_MULTIPLE_EXPORT_API_URL = `${environment.apiUrl}/excel/export_experiments_grouped_by_category`;
export const EXPERIMENTS_CHANGELOG_URL = `${environment.apiUrl}/changelog/experiments`;

// RESEARCH OBJECT
export const RESEARCH_OBJECTS_API_URL = `${environment.apiUrl}/research_objects`;
export const RESEARCH_OBJECTS_FILES_API_URL = `${environment.apiUrl}/ro_document/files`;
export const RESEARCH_OBJECTS_CSV_EXPORT_API_URL = `${environment.apiUrl}/excel/export_research_objects`;
export const RESEARCH_OBJECTS_CSV_MULTIPLE_EXPORT_API_URL = `${environment.apiUrl}/excel/export_research_objects_grouped_by_category`;
export const RESEARCH_OBJECTS_CHANGELOG_URL = `${environment.apiUrl}/changelog/research_objects`;

// PROJECT
export const PROJECTS_API_URL = `${environment.apiUrl}/projects`;

// WORKSPACE
export const WORKSPACES_API_URL = `${environment.apiUrl}/workspaces`;

// TEMPLATE
export const CATEGORY_TEMPLATES_API_URL = `${environment.apiUrl}/category_templates`;
export const RESEARCH_OBJECTS_TEMPLATES_API_URL = `${environment.apiUrl}/ro_templates`;
export const EXPERIMENT_TEMPLATES_API_URL = `${environment.apiUrl}/experiment_templates`;

// USER
export const USERS_API_URL = `${environment.baseUrl}/users`;
export const USERS_CURRENT_API_URL = `${environment.baseUrl}/users/me`;

// CATEGORIES
export const CATEGORIES_API_URL = `${environment.apiUrl}/categories`;

// INSTANCE
export const RESEARCH_OBJECTS_INSTANCE_API_URL = `${environment.apiUrl}/ro_instances`;

// FILES
export const FILES_API_URL = `${environment.apiUrl}/files`;

// IMAGES
export const IMAGES_API_URL = `${environment.apiUrl}/images`;

// EXCEL
export const EXCEL_API_URL = `${environment.apiUrl}/excel`;
