<div class="field-container">
  <label class="field-container__label">
    {{ field.column_name }}
    <span class="field-container__label__location">({{ rowNumber + ' - ' + field.column_letter }})</span>
  </label>
  <textarea
    #textarea
    rows="1"
    appAutoFocus
    class="field-container__input"
    placeholder="-"
    [formControl]="control"
    (focus)="setHeightToContent($event.target)"
  ></textarea>
</div>
<p class="field-error">{{ field.detail }}</p>
