import { Pipe, PipeTransform } from '@angular/core';
import { DevelopmentType, ExperimentDto, ResearchObject } from '../_models';

@Pipe({
  name: 'search',
  standalone: true,
})
export class SearchPipe implements PipeTransform {
  transform(objectList: ResearchObject[] | ExperimentDto[], developmentType: DevelopmentType, searchText: string = ''): any {
    const preparedSearchText = searchText?.toLowerCase().trim();

    switch (developmentType) {
      case DevelopmentType.researchObject:
        return this.searchResearchObjects(objectList as ResearchObject[], preparedSearchText);
      case DevelopmentType.experiment:
        return this.searchExperiments(objectList as ExperimentDto[], preparedSearchText);
    }
  }

  private searchResearchObjects(researchObjects: ResearchObject[], searchText: string): ResearchObject[] {
    return researchObjects.filter(researchObject => {
      if (researchObject.name === null) {
        return false;
      }

      const lowerCaseName = researchObject.name.toLowerCase();

      return lowerCaseName.includes(searchText);
    });
  }

  private searchExperiments(experiments: ExperimentDto[], searchText: string): ExperimentDto[] {
    return experiments.filter(experiment => {
      if (experiment.name === null) {
        return false;
      }

      const lowerCaseName = experiment.name.toLowerCase();

      return lowerCaseName.includes(searchText);
    });
  }
}
