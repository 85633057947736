<div
  class="field-value-preview"
  [ngClass]="{ 'field-value-preview--table': viewMode === ViewMode.table, 'field-value-preview--minimized': minimizedSignal() }"
>
  <ng-container [ngSwitch]="fieldSignal()?.type">
    <a *ngSwitchCase="DisplayFieldType.URL" [href]="fieldSignal().value" class="field-value-preview__url" target="_blank">
      {{ fieldSignal().display_options?.url_format || fieldSignal().value }}
    </a>
    <div *ngSwitchCase="DisplayFieldType.USER" class="field-value-preview__user">
      <app-user-avatar [imageBase64]="avatar$ | async" avatarSize="21px"></app-user-avatar>
      <span>{{ (userDisplayValue$ | async) || placeholder() }}</span>
    </div>
    <div *ngSwitchCase="DisplayFieldType.SELECT">
      <span class="icon-container" *ngIf="hasColor(fieldSignal())">
        <app-option-icon [options]="fieldSignal().options" [value]="fieldSignal().value" data-testid="status-icon"></app-option-icon>
      </span>
      <span>{{ fieldSignal().value || placeholder() }}</span>
    </div>
    <div *ngSwitchCase="DisplayFieldType.DATE" class="field-value-container">
      <span *ngIf="!minimizedSignal()" class="icon-container">
        <app-icon class="me-1" width="1.1rem" height="1.1rem" fileName="field_date_icon.svg" [color]="IconColors.darkGrey"></app-icon>
      </span>
      <span>
        {{ (fieldSignal() | itemReadValue) || placeholder() }}
      </span>
    </div>
    <ng-container *ngSwitchCase="DisplayFieldType.WYSIWYG">
      <div
        *ngIf="!minimizedSignal(); else minimizedWysiwyg"
        [innerHtml]="(fieldSignal() | itemReadValue | handleItemParameterOrResultIfEmpty) || placeholder()"
        class="field-value-preview__wysiwyg"
      ></div>
      <ng-template #minimizedWysiwyg>
        <app-wysiwyg-field
          [fieldValue]="fieldSignal() | itemReadValue"
          [headerCustomStyles]="{ color: 'inherit' }"
          tooltip="Parameter"
        ></app-wysiwyg-field>
      </ng-template>
    </ng-container>
    <div *ngSwitchDefault class="field-value-preview__any" data-testid="field-value">
      {{ (fieldSignal() | itemReadValue | handleItemParameterOrResultIfEmpty) || placeholder() }}
    </div>
  </ng-container>
</div>
