import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { ItemImageDirective } from '@shared/_components/item/item-image.directive';
import { loadImageContent } from '@shared/_root-store/images-store/images.actions';
import { ImageSize } from '@app/domain/image';
import { ImageComponent } from '../../../_components/image/components/image.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-table-item-image',
  standalone: true,
  imports: [ImageComponent, CommonModule],
  templateUrl: './table-item-image.component.html',
  styleUrls: ['./table-item-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableItemImageComponent extends ItemImageDirective {
  @Input() imageAreaHidden: boolean;
  constructor(store: Store) {
    super(store);
  }

  loadMagnifiedImage() {
    this.store.dispatch(loadImageContent({ imageMeta: this.mainImage(), size: ImageSize.MEDIUM }));
  }
}
