import { Component, Input } from '@angular/core';
import { CommonModule, NgClass, NgIf } from '@angular/common';

import { ResearchObject, Experiment, DevelopmentType } from '@shared/_models';

@Component({
  selector: 'app-item-details-additional-info',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './item-details-additional-info.component.html',
  styleUrls: ['./item-details-additional-info.component.scss']
})
export class ItemDetailsAdditionalInfoComponent {
  @Input() item: ResearchObject | Experiment;
  @Input() developmentType: DevelopmentType;
  readonly DevelopmentType = DevelopmentType;
}
