import { Component, EventEmitter, Inject, Input, OnInit, Output, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { AppSettings } from '@app/shared/_configuration';
import { IMembersFormData, MemberRole, UserDto, IUserWithRole } from '@app/shared/_models';
import { selectAvatarForId } from '@shared/_root-store/users-store/users.selectors';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { ProjectMembersEditModalComponent } from '@app/_projects/project-members-edit-modal/project-members-edit-modal.component';
import { ModalType } from '@shared/_modules/project/utils';

@Component({
  selector: 'app-project-members',
  standalone: true,
  imports: [CommonModule, UserAvatarComponent, ProjectMembersEditModalComponent],
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.scss']
})
export class ProjectMembersComponent implements OnInit {
  @Input() modalType: ModalType;
  @Input() form: FormGroup;
  @Input() currentUser: UserDto;
  @Input() membersChangedData: IUserWithRole[] = [];
  @Output() readonly membersChanged = new EventEmitter<IMembersFormData>();
  @Output() readonly openProjectMembersEditModal = new EventEmitter<void>();

  readonly ModalType = ModalType;
  projectMembersEditModalRef: NgbModalRef;
  projectMembers = signal<IUserWithRole[]>([]);
  projectMembersWithAvatar = computed(() =>
    (this.projectMembers() ?? []).map(member => ({ ...member, avatar$: this.store.select(selectAvatarForId(member.user.id)) }))
  );

  constructor(
    @Inject(AppSettings) readonly settings: AppSettings,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.projectMembersChanged(this.membersChangedData);
  }

  projectMembersChanged(membersData: IUserWithRole[]) {
    this.#markAsTouched();
    this.projectMembers.set(membersData);
    const owners = membersData.filter(member => member.role == MemberRole.owner).map(owner => owner.user);
    const leads = membersData.filter(member => member.role == MemberRole.lead).map(lead => lead.user);
    const editors = membersData.filter(member => member.role == MemberRole.editor).map(editor => editor.user);
    const readers = membersData.filter(member => member.role == MemberRole.reader).map(reader => reader.user);
    this.membersChanged.emit({ owners, leads, editors, readers } as IMembersFormData);
  }

  #markAsTouched() {
    this.form.controls.owners.markAsTouched();
    this.form.controls.leads.markAsTouched();
    this.form.controls.editors.markAsTouched();
    this.form.controls.readers.markAsTouched();
  }
}
