import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-header-center',
  standalone: true,
  imports: [NgIf],
  templateUrl: './modal-header-center.component.html',
  styleUrls: ['./modal-header-center.component.scss'],
})
export class ModalHeaderCenterComponent {
  @Input() header: string;
  @Input() imgSrc: string;
  @Input() developmentType: string;
  @Input() hideHeaderContent: boolean = false;

  constructor(private sanitizer: DomSanitizer) {}

  getFullImagePath(fileName: string): SafeUrl {
    const filePath = '../../../../../../assets/icons/';
    const fullPath = `${filePath}${fileName}`;
    return this.sanitizer.bypassSecurityTrustUrl(fullPath);
  }
}
