import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginMethod } from '@app/shared/_models/authentication-data.interface';
import { Observable } from 'rxjs';
//
import {
  AUTH_FORGOT_PASSWORD_API_URL,
  AUTH_REQUEST_VERIFY_TOKEN_API_URL,
  AUTH_REQUEST_VERIFY_USER_API_URL,
  AUTH_RESET_PASSWORD_API_URL,
  AUTH_SIGN_IN_API_URL,
  AUTH_SIGN_OUT_API_URL,
  AUTH_SIGN_UP_API_URL,
  AUTH_SIGN_IN_WITH_MICROSOFT_API_URL,
  AUTH_CALL_BACK_WITH_MICROSOFT_API_URL,
  AUTH_LOGIN_METHOD
} from '.';
import {
  AuthRemindPasswordRequest,
  AuthResetPasswordRequest,
  AuthSignInMicrosoftResponse,
  AuthSignInResponse,
  AuthSignUpRequest,
  AuthSignUpResponse,
  AuthVerifyTokenRequest,
  AuthVerifyUserRequest
} from '../interface';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  constructor(private readonly http: HttpClient) {}

  signUp(singUpData: AuthSignUpRequest): Observable<AuthSignUpResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<AuthSignUpResponse>(AUTH_SIGN_UP_API_URL, singUpData, { headers });
  }

  signIn(singInData: Record<keyof AuthSignInResponse, string>): Observable<AuthSignInResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const requestData = new URLSearchParams(singInData);

    return this.http.post<AuthSignInResponse>(AUTH_SIGN_IN_API_URL, requestData, { headers });
  }

  signInWithMicrosoft(): Observable<AuthSignInMicrosoftResponse> {
    return this.http.get<AuthSignInMicrosoftResponse>(AUTH_SIGN_IN_WITH_MICROSOFT_API_URL);
  }

  callBackMicrosoft(code: string, state: string): Observable<AuthSignInResponse> {
    let params = new HttpParams();

    params = params.append('code', code);
    params = params.append('state', state);
    return this.http.get<AuthSignInResponse>(AUTH_CALL_BACK_WITH_MICROSOFT_API_URL, { params: params });
  }

  verifyToken(data: AuthVerifyTokenRequest): Observable<void> {
    return this.http.post<void>(AUTH_REQUEST_VERIFY_TOKEN_API_URL, data);
  }

  verifyUser(data: AuthVerifyUserRequest): Observable<void> {
    return this.http.post<void>(AUTH_REQUEST_VERIFY_USER_API_URL, data);
  }

  remindPassword(data: AuthRemindPasswordRequest): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<void>(AUTH_FORGOT_PASSWORD_API_URL, data, { headers });
  }

  resetPassword(data: AuthResetPasswordRequest) {
    return this.http.post(AUTH_RESET_PASSWORD_API_URL, data);
  }

  signOut(): Observable<void> {
    return this.http.get<void>(AUTH_SIGN_OUT_API_URL);
  }

  loginMethod(): Observable<LoginMethod> {
    return this.http.get<LoginMethod>(AUTH_LOGIN_METHOD);
  }
}
