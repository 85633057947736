import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//
import { SigninLayoutComponent } from './signin-layout/signin-layout.component';
import { SigninComponent } from './signin/signin.component';
import { SignInRoutingPath } from './signin-routing-path';
import { IsNotAuthenticatedGuard } from '../guards/is-not-authenticated.guard';

const routes: Routes = [
  {
    path: '',
    component: SigninLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: SignInRoutingPath.signIn,
      },
      {
        path: SignInRoutingPath.signIn,
        component: SigninComponent,
        canActivate: [IsNotAuthenticatedGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SigninRoutingModule {}
