import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  ElementRef,
  signal,
  computed
} from '@angular/core';
import { AsyncPipe, NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AutoFocusDirective } from '@shared/_directives/auto-focus/auto-focus.directive';
import { ContentWithCopyBtnComponent } from '@shared/_components/content-with-copy-btn/content-with-copy-btn.component';
import { Field, FieldType, CategoryDto, DevelopmentType, DisplayFieldType } from '@shared/_models';
import { isCalculatedField, isModificationField } from '@app/domain/field';
import { DYNAMIC_FORM } from '../../../dynamic-form';
import { FieldValuePreviewComponent } from '@shared/_components/item/item-details/item-details-grid-section/field-value-preview/field-value-preview.component';
import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { SmilesWysiwygModalComponent } from './components/smiles-wysiwyg-modal/smiles-wysiwyg-modal.component';
import { AppSettings } from '@app/shared/_configuration';
import { categoryResolver, developmentTypeResolver } from '@app/domain/shared';

@Component({
  selector: 'app-pepseq-form-field',
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    NgIf,
    UpperCasePipe,
    AutoFocusDirective,
    ContentWithCopyBtnComponent,
    FieldValuePreviewComponent,
    IconComponent,
    SmilesWysiwygModalComponent,
    ...DYNAMIC_FORM
  ],
  templateUrl: './pepseq-form-field.component.html',
  styleUrls: ['./pepseq-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PepseqFormFieldComponent {
  @Input() set field(value: Field) {
    this.fieldSignal.set(value);
  }
  fieldSignal = signal<Field>(null);
  @Input() editModeActive: boolean;
  @Input() editMode = false;
  @Input() errorMessage: string;
  @Input() hovered = false;
  @Output() valueChange = new EventEmitter<string>();
  @Output() remove = new EventEmitter<void>();

  @ViewChild('smilesWysiwygModal', { static: false }) smilesWysiwygModal: ElementRef;

  category$: Observable<CategoryDto> = categoryResolver();
  developmentType$: Observable<DevelopmentType> = developmentTypeResolver();

  // we don't want app-dynamic-field to show label
  dynamicField = computed<Field>(() => (this.fieldSignal() ? { ...this.fieldSignal(), label: null } : null));

  isParentField = computed<boolean>(() => !this.fieldSignal()?.parent_id && !this.fieldSignal()?.value_from_id);

  isCalculatedField = computed<boolean>(() => isCalculatedField(this.fieldSignal()));

  isModificationField = computed<boolean>(() => isModificationField(this.fieldSignal()));

  isSmilesField = computed<boolean>(() => this.fieldSignal()?.type === DisplayFieldType.SMILES);

  topLabel = computed<string>(() => {
    if (this.isParentField()) {
      return this.fieldSignal().label;
    }

    if (this.isModificationField()) {
      return 'Modification';
    }

    if (this.isCalculatedField()) {
      return this.fieldSignal().label.replaceAll('_', ' ').toUpperCase();
    }
  });

  bottomLabel = computed<string>(() => {
    if (this.isModificationField()) {
      return 'SMILES';
    }

    if (this.isParentField() && [FieldType.SMILES, FieldType.SMILES_NPS].includes(this.fieldSignal().field_type)) {
      return 'SMILES';
    }

    if (this.isParentField() && this.fieldSignal().field_type === FieldType.PEPSEQ) {
      return 'PepSeq';
    }
  });

  readonly IconColors = IconColors;

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private readonly ngbModal: NgbModal
  ) {}

  onRemoveBtnClick() {
    this.remove.emit();
  }

  openKetcher() {
    this.ngbModal.open(this.smilesWysiwygModal, { ...this.settings.MODAL_DEFAULT_CONFIG, size: 'xl' });
  }

  updateByKetcher(value: string, modal: NgbModalRef) {
    modal.close();
    this.valueChange.emit(value);
  }
}
