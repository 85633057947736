import { WritableSignal } from '@angular/core';
import { Subject } from 'rxjs';
import { Filterable, Pageable, Searchable, Sortable, CommonParams } from '../_models';

export abstract class ParamsService<T extends CommonParams> {
  selectedOnlyIndexPage = 1;
  isSelectOnlyMode = false;
  abstract params: WritableSignal<T>;

  // TODO: Refactor: very confusing code: triggers loading EX or RO
  resetSortState$ = new Subject<null>();

  setCurrentParams(params: T): void {
    this.params.set(params);
  }

  patchCurrentParams(params: Partial<T>): void {
    this.params.update(value => ({ ...value, ...params }));
  }

  getCurrentParams(): T {
    return this.params();
  }

  resetSortState() {
    this.resetSortState$.next(null);
  }

  getSelectedOnlyIndexPage() {
    return this.selectedOnlyIndexPage;
  }

  setSelectedOnlyIndexPage(indexPage: number) {
    this.selectedOnlyIndexPage = indexPage;
  }

  getIsSelectOnlyMode(): boolean {
    return this.isSelectOnlyMode;
  }

  setIsSelectOnlyMode(isSelectOnlyMode) {
    this.isSelectOnlyMode = isSelectOnlyMode;
  }

  abstract getHttpParams(): Searchable & Pageable & Sortable & Filterable;
}
