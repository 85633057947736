import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
  standalone: true,
})
export class SplitPipe implements PipeTransform {
  transform(value: string, splitBy?: string): string {
    if (splitBy === 'camelcase') {
      return (value && value.replace(/([a-z])([A-Z])/g, '$1 $2')) || value;
    }

    return (value && value.split(splitBy).join(' ')) || value;
  }
}
