import { environment } from '../../../environments/environment';

export const AUTH_SIGN_UP_API_URL = `${environment.authUrl}/register`;
export const AUTH_REQUEST_VERIFY_TOKEN_API_URL = `${environment.authUrl}/request-verify-token`;
export const AUTH_REQUEST_VERIFY_USER_API_URL = `${environment.authUrl}/verify`;
export const AUTH_FORGOT_PASSWORD_API_URL = `${environment.authUrl}/forgot-password`;
export const AUTH_RESET_PASSWORD_API_URL = `${environment.authUrl}/reset-password`;

export const AUTH_SIGN_IN_API_URL = `${environment.authUrl}/login`;
export const AUTH_SIGN_OUT_API_URL = `${environment.authUrl}/logout`;

export const AUTH_SIGN_IN_WITH_MICROSOFT_API_URL = `${environment.authUrl}/aad/authorize`;
export const AUTH_CALL_BACK_WITH_MICROSOFT_API_URL = `${environment.authUrl}/aad/callback`;
export const AUTH_LOGIN_METHOD = `${environment.apiUrl}/login-method`;
