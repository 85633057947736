import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mimeSubtype',
  standalone: true
})
export class MimeSubtypePipe implements PipeTransform {
  /**
   * Parse file mime format and extract subtype
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
   *
   */
  transform(mimeFormat: string): string {
    const parsedMimeFormat = mimeFormat?.split('/');

    if (parsedMimeFormat?.length === 2) {
      return parsedMimeFormat[1];
    }

    return '?';
  }
}
