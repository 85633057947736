import { Pipe, PipeTransform } from '@angular/core';

/**
 * Zmień wielkość pierwszej litery stringa (nie ważne czy to pojedyńcze słowo czy wiele słów)
 */
@Pipe({
  name: 'capitalizeFirst',
  standalone: true
})
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
