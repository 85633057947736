import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';

import { DevelopmentType, SectionViewMode, ResearchObject, Experiment } from '@shared/_models';
import { GenericModalComponent } from '@shared/_components/generic-modal/generic-modal.component';
import { ModalHeaderLeftComponent } from '@shared/_components/modal-header-left/modal-header-left.component';
import { ModalHeaderCenterComponent } from '@shared/_components/modal-header-center/modal-header-center.component';
import { ActionButton } from '@shared/_models/actionButton';
import { ItemDetailsAdditionalInfoComponent } from '../item-details-additional-info/item-details-additional-info.component';
import { CapitalizeFirstPipe } from '@shared/pipes/capitalize-first.pipe';

@Component({
  selector: 'app-item-details-additional-info-modal',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    ItemDetailsAdditionalInfoComponent,
    GenericModalComponent,
    NgTemplateOutlet,
    ModalHeaderLeftComponent,
    ModalHeaderCenterComponent,
    CapitalizeFirstPipe
  ],
  templateUrl: './item-details-additional-info-modal.component.html',
  styleUrls: ['./item-details-additional-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsAdditionalInfoModalComponent {
  @Input() developmentType: DevelopmentType;
  @Input() set item(value: ResearchObject | Experiment) {
    this.#item = value;
  }
  @Output() closeModal = new EventEmitter<void>();

  readonly SectionViewMode = SectionViewMode;
  readonly DevelopmentType = DevelopmentType;

  get item(): ResearchObject | Experiment {
    return this.#item;
  }

  get acceptButton(): ActionButton {
    return { text: 'Close' };
  }
  get header(): string {
    return this.developmentType?.replace('-', ' ');
  }

  #item: ResearchObject | Experiment;

  close() {
    this.closeModal.emit();
  }
}
