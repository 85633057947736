import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevelopmentType, Image } from '@shared/_models';
import { ImageComponent } from '@shared/_components/image/components/image.component';

@Component({
  selector: 'app-image-thumb',
  standalone: true,
  imports: [CommonModule, ImageComponent],
  templateUrl: './image-thumb.component.html',
  styleUrls: ['./image-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageThumbComponent {
  @Input() developmentType: DevelopmentType;
  @Input() gradientBorder = false;
  @Input() image: Image;
  readonly DevelopmentType = DevelopmentType;
}
