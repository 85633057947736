import { ChangeDetectionStrategy, Component, Input, WritableSignal, signal } from '@angular/core';
import { NgIf } from '@angular/common';

import { NEGLIGIBLE_BLOB_SIZE } from '@shared/_helpers/blob-to-base64';

@Component({
  selector: 'app-user-avatar',
  standalone: true,
  imports: [NgIf],
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {
  @Input() set imageBlob(value: Blob) {
    this.#onImageBlobChange(value);
  }
  #imageBlobSignal: WritableSignal<Blob> = signal(null);

  @Input() set imageBase64(value: string) {
    this.#onImageBase64Change(value);
  }
  #imageBase64: string;

  @Input() avatarSize: string;
  reader = new FileReader();
  showUserImage = signal(false);

  get image(): string {
    return this.#imageBase64 ?? (this.reader.result as string);
  }

  constructor() {
    this.reader.addEventListener('load', () => {
      this.showUserImage.set(true);
    });
  }

  #onImageBlobChange(value: Blob) {
    if (value === this.#imageBlobSignal()) return;

    this.#imageBlobSignal.set(value);

    if (!value || value.size < NEGLIGIBLE_BLOB_SIZE) {
      this.showUserImage.set(false);

      return;
    }

    this.reader.readAsDataURL(value);
  }

  #onImageBase64Change(value: string) {
    if (value === this.#imageBase64) return;

    this.#imageBase64 = value;

    this.showUserImage.set(!!value?.length);
  }
}
