import { ChangeDetectorRef, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EMPTY, Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpStatusCode } from '@angular/common/http';

import { FileDto, ImageDto } from '../../../_models';
import { FileWithoutExtensionPipe } from '@app/shared/pipes';
import { FormatFileSizePipe } from '@shared/_modules/file/pipes/format-file-size.pipe';
import { MimeSubtypePipe } from '@shared/_modules/file/pipes/mime-subtype.pipe';
import { FileThumbComponent } from '@shared/_modules/file/file-thumb/file-thumb.component';
import { FileThumbRotateComponent } from '@shared/_modules/file/file-thumb-rotate/file-thumb-rotate.component';
import { FileService } from '../file.service';
import { getProgress, isNothingToImport } from './utils';

@Component({
  selector: 'app-file-upload-card',
  standalone: true,
  imports: [CommonModule, FileWithoutExtensionPipe, FormatFileSizePipe, MimeSubtypePipe, FileThumbComponent, FileThumbRotateComponent],
  templateUrl: './file-upload-card.component.html',
  styleUrls: ['./file-upload-card.component.scss']
  // TODO: figure out why tests are failing when using OnPush
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadCardComponent implements OnInit {
  @Input() uploadExcel: boolean;
  @Input() analyzeExcel: boolean;
  @Input() fileSharing: boolean;
  @Input() fileUploadInProgress: boolean;
  @Input() fileName: string;
  @Input() fileSize: number;
  @Input() fileExtension: string;

  @Input() set uploadEvents$(value: Observable<HttpEvent<any>>) {
    this.#onuploadEvents$Change(value);
  }
  #uploadEvents$: Observable<HttpEvent<any>>;

  @Input() disabled: boolean;
  @Output() uploadCancel: EventEmitter<void> = new EventEmitter();
  @Output() uploadSuccess: EventEmitter<FileDto | ImageDto> = new EventEmitter();
  @Output() analyzeSuccess: EventEmitter<FileDto | ImageDto> = new EventEmitter();
  @Output() uploadFail: EventEmitter<void> = new EventEmitter();
  @Output() uploadInProgress: EventEmitter<void> = new EventEmitter<void>();
  @Output() remove: EventEmitter<string> = new EventEmitter();
  @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>();
  fileUploadProgress: number = 0;
  uploadSub: Subscription;
  fileId: string;
  failureMessage: string;
  fileUploadFail: boolean = false;
  excelAnalyzed: boolean = false;
  fileUpdateComplete: boolean = false;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly fileService: FileService
  ) {}

  ngOnInit(): void {
    this.#setup();
  }

  onCancelBtnClick(): void {
    this.uploadSub?.unsubscribe();
    this.uploadCancel.emit();
  }

  onRemoveBtnClick(): void {
    this.remove.emit(this.fileId);
  }

  getFileExtention(fileName: string): string {
    return this.fileService.extractFileExtensionFromFileName(fileName);
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) return;

    this.fileSelected.emit(input.files[0]);
  }

  #onuploadEvents$Change(value: Observable<HttpEvent<any>>) {
    if (value === this.#uploadEvents$) return;

    this.uploadSub?.unsubscribe();
    this.#uploadEvents$ = value;
    this.#setup();
  }

  #setup() {
    this.uploadSub = this.#uploadEvents$
      ?.pipe(
        catchError((error: HttpErrorResponse) => {
          this.fileUploadFail = true;
          this.failureMessage = 'Upload failed';
          this.uploadFail.emit();

          if (error.status >= 500 && error.status < 600) {
            return throwError(() => error);
          }

          return EMPTY;
        })
      )
      .subscribe((event: HttpEvent<any>) => {
        if (event.type == HttpEventType.Sent) {
          this.uploadInProgress.emit();
        }

        if (event.type == HttpEventType.UploadProgress) {
          this.fileUploadProgress = getProgress(event.loaded, event.total);
          this.changeDetector.markForCheck();
        }

        if (event.type == HttpEventType.Response && (event.status === HttpStatusCode.Created || event.status === HttpStatusCode.Ok)) {
          this.fileId = event.body.id;
          this.changeDetector.markForCheck();

          if (this.fileSharing) {
            this.fileUpdateComplete = true;
          }

          if (this.analyzeExcel) {
            if (isNothingToImport(event.body)) {
              this.fileUploadFail = true;
              this.failureMessage = 'Upload failed';
              this.uploadFail.emit();
            } else {
              this.excelAnalyzed = true;
              this.analyzeSuccess.emit(event.body);
            }
          } else {
            this.uploadSuccess.emit(event.body);
          }
        }
      });
  }
}
