import { animate, state, style, transition, trigger } from '@angular/animations';

export const sortingRotationStateAnimation = trigger('sortingRotationState', [
  state(
    'no-sorting',
    style({
      height: '0',
      overflow: 'hidden',
      opacity: '0',
      visibility: 'hidden',
    })
  ),
  state(
    'descending',
    style({
      transform: 'rotate(180deg)',
    })
  ),
  state(
    'ascending',
    style({
      transform: 'rotate(0)',
    })
  ),
  transition('ascending<=>descending', animate('250ms')),
]);
