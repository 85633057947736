import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-text-counter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-counter.component.html',
  styleUrls: ['./text-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextCounterComponent {
  @Input() length: number;
  @Input() maxLength: number;
}
