<div class="empty-area" [ngClass]="inactive && 'empty-area--inactive'">
  <div data-testid="add-sample-placeholder" class="empty-area__wrapper" [class.empty-area__wrapper--dark-mode]="darkMode">
    <div class="empty-area__placeholder" [ngClass]="{ 'empty-area__placeholder-cursor': sectionType !== SectionType.IMAGES && edit }">
      <i class="empty-area__placeholder__icon" [ngClass]="iconClass"></i>
      <span *ngIf="!inactive && sectionType !== SectionType.IMAGES && edit" class="empty-area__placeholder__label">
        <span data-testid="add-sample-button" class="empty-area__placeholder__add">{{ label }}</span>
      </span>
    </div>
  </div>
</div>
