<div
  data-testid="empty-placeholder"
  class="empty-placeholder"
  (click)="addNewItem()"
  [class.empty-placeholder--with-space-for-navigation]="showSecondaryNavigation()"
>
  <ng-content select="[placeholder-icon]"></ng-content>
  <div class="d-flex flex-column">
    <div data-testid="empty-placeholder-text" class="empty-placeholder__text">{{ header }}</div>
    <div data-testid="empty-placeholder-link" class="empty-placeholder__link">{{ linkText }}</div>
  </div>
</div>
