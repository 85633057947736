import { Component, EventEmitter, Input, Output } from '@angular/core';

// TODO: zrefaktorować ten komponent
//   Uporządkować z Markiem konkretnie ile rodzajów i jakie rodzaje przycisków wyróżniamy w całej aplikacji
//   (jakie tło, jaka wielkość, jaki wygląd on hover, jaki border itp) i to odpowiednio zaimplementować.
//   Sugeruję zrobić to bazując na docelowym wyglądzie przycisku, nie funkcji/miejscu występowania przycisku czyli np
//   {size: 'lg', bg: 'none',  border: 'roGradient', onHover: 'roGradient'}

enum ButotonType {
  button = 'button',
  submit = 'submit'
}

@Component({
  selector: 'app-button',
  standalone: true,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() disabled = false;
  @Input() type = ButotonType.button;
  @Output() click = new EventEmitter<Event>();

  onClick(event: Event) {
    event.stopPropagation();
    this.click.emit(event);
  }
}
