import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';

export enum AuthAction {
  SIGN_IN = 'signin',
  SIGN_UP = 'singup',
  RESET_PASSWORD = 'resetpassword',
}

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLayoutComponent {
  @Input() authActionType: AuthAction;
  readonly AuthAction = AuthAction;
  IconColors = IconColors;

  get authActionLabel(): string {
    if (this.authActionType === this.AuthAction.SIGN_IN) {
      return 'Sign in to Lab Gears';
    } else if (this.authActionType === this.AuthAction.SIGN_UP) {
      return 'Sign up to Lab Gears';
    } else if (this.authActionType === this.AuthAction.RESET_PASSWORD) {
      return 'Reset password to your Lab Gears account';
    } else {
      return '';
    }
  }
}
