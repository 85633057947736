import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

import {
  ResearchObjectAddDto,
  Pageable,
  PagedResponse,
  ResearchObjectDto,
  ResearchObjectUpdateDto,
  Searchable,
  ErrorMessageSection,
  UpdateArea,
  Sortable,
  NeighborsDto,
  DeleteMultipleResponseDto,
  ListIdsDto
} from '../../_models';
import { RESEARCH_OBJECTS_API_URL } from '../constants';
import { EnvironmentService } from '@shared/_services/environment.service';
import { toParams } from '@app/shared/_helpers';

@Injectable({
  providedIn: 'root'
})
export class ResearchObjectsApiService {
  constructor(
    private readonly http: HttpClient,
    private environmentService: EnvironmentService
  ) {}
  errorMessageSection$ = new BehaviorSubject<ErrorMessageSection>({
    errorResponse: false,
    section: null
  });

  getList(params: Searchable & Pageable & Sortable = {}) {
    return this.http
      .get<PagedResponse<ResearchObjectDto>>(`${RESEARCH_OBJECTS_API_URL}`, {
        params: toParams(params)
      })
      .pipe(shareReplay());
  }

  get(researchObjectId: string): Observable<ResearchObjectDto> {
    return this.http.get<ResearchObjectDto>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}`);
  }

  add(researchObject: ResearchObjectAddDto): Observable<ResearchObjectDto> {
    return this.http.post<ResearchObjectDto>(`${RESEARCH_OBJECTS_API_URL}`, researchObject);
  }

  update(researchObjectId: string, changes: Partial<ResearchObjectUpdateDto>) {
    return this.http.patch<ResearchObjectDto>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}`, changes);
  }

  delete(researchObjectId: string): Observable<string> {
    return this.http.delete<string>(`${RESEARCH_OBJECTS_API_URL}/${researchObjectId}`);
  }

  deleteMultiple(ids: string[]): Observable<DeleteMultipleResponseDto> {
    return this.http.post<DeleteMultipleResponseDto>(`${RESEARCH_OBJECTS_API_URL}/batch_delete`, ids);
  }

  getAllResearchObjectsIds(params: Params = {}): Observable<ListIdsDto> {
    return this.http.get<ListIdsDto>(`${RESEARCH_OBJECTS_API_URL}/list_ids`, {
      params
    });
  }

  setErrorMessage(errorResponse: HttpErrorResponse, section: UpdateArea): void {
    this.errorMessageSection$.next({ errorResponse, section });
  }

  getNeighborResearchObjectData(researchObjectId: string, params: Params) {
    return this.http.get<NeighborsDto>(`${this.environmentService.environment.apiUrl}/research_objects/${researchObjectId}/neighbors`, {
      params
    });
  }
}
