import { CategoryDto, DevelopmentType, DevelopmentTypePlural } from '@shared/_models';
import { ROOT_CATEGORY_ID } from './root-category-id';

export function addRootMenuItem(categories: CategoryDto[], developmentType: DevelopmentType): CategoryDto[] {
  const rootCategory = {
    id: ROOT_CATEGORY_ID,
    parent_id: null,
    development_type: developmentType,
    name: developmentType === DevelopmentType.experiment ? DevelopmentTypePlural.experiments : DevelopmentTypePlural.researchObjects,
  } as CategoryDto;

  return [
    rootCategory,
    ...categories.map(category => (category.parent_id == null ? { ...category, parent_id: ROOT_CATEGORY_ID } : category)),
  ];
}
