import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { Store } from '@ngrx/store';
import { toSignal } from '@angular/core/rxjs-interop';

import { DevelopmentType, RouteParam } from '@shared/_models';
import { DevelopmentItemService } from '@app/shared/_services/development-item.service';
import { ExperimentParamsService } from '@app/shared/_services/experiment/experiment-params.service';
import { NavigationService } from '@app/shared/_services/navigation.service';
import { ResearchObjectParamsService } from '@app/shared/_services/research-object/research-object-params.service';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';

@Component({
  selector: 'app-item-details-back-sidebar',
  standalone: true,
  imports: [NgClass, DevelopmentTypeCssClassPipe],
  templateUrl: './item-details-back-sidebar.component.html',
  styleUrls: ['./item-details-back-sidebar.component.scss']
})
export class ItemDetailsBackSidebarComponent {
  @Input() developmentType: DevelopmentType;
  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));
  workspaceId = toSignal(this.store.select(selectRouteParam(RouteParam.WORKSPACE_ID)));
  categoryId = toSignal(this.store.select(selectRouteParam(RouteParam.CATEGORY_ID)));

  constructor(
    private navigationService: NavigationService,
    private readonly researchObjectParamsService: ResearchObjectParamsService,
    private readonly experimentParamsService: ExperimentParamsService,
    private readonly developmentItemService: DevelopmentItemService,
    private readonly store: Store
  ) {}

  onSidebarClick() {
    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }

    const data = this.getParams();

    if (this.developmentType === DevelopmentType.researchObject) {
      this.goToRoList(data.indexPage, data.pageSize);
    } else if (this.developmentType === DevelopmentType.experiment) {
      this.goToExList(data.indexPage, data.pageSize);
    }
  }

  getParams() {
    return this.developmentType == DevelopmentType.experiment
      ? this.experimentParamsService.getCurrentParams()
      : this.researchObjectParamsService.getCurrentParams();
  }

  goToExList(page: number, limit: number) {
    this.navigationService.goToExperimentsListWithParams(this.workspaceId(), this.projectId(), this.categoryId(), {
      page,
      limit
    });
  }

  goToRoList(page: number, limit: number) {
    this.navigationService.goToResearchObjectsListWithParams(this.workspaceId(), this.projectId(), this.categoryId(), {
      page,
      limit
    });
  }
}
