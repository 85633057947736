import { CategoryDto, TreeSelectItem, CategoryTree, DevelopmentType } from '@shared/_models';
import { addRootMenuItem } from './add-root-menu-item';
import { comparePositions } from '@app/domain/shared';

export function getTreeSelectItems(categories: CategoryDto[], hasTemplate: (category: CategoryDto) => boolean): TreeSelectItem[] {
  const categoryTree: CategoryTree[] = getCategoryTree(categories);
  const shakedCategoryTree: CategoryTree[] = shakeEmptyLeaves(categoryTree, hasTemplate);

  return toTreeItems(shakedCategoryTree, 0, hasTemplate);
}

function shakeEmptyLeaves(tree: CategoryTree[], isNotEmpty: (category: CategoryDto) => boolean): CategoryTree[] {
  let continueShaking = true;
  let result = tree;

  while (continueShaking) {
    const beforeShakingCount = countNodesInTree(result);
    result = shakeEmptyTerminalNodes(result, isNotEmpty);
    const afterShakingCount = countNodesInTree(result);
    continueShaking = beforeShakingCount !== afterShakingCount;
  }

  return result;
}

function countNodesInTree(categoryTrees: CategoryTree[]): number {
  return categoryTrees.reduce((count, node) => count + countNodes(node), 0);
}

function countNodes(node: CategoryTree): number {
  return 1 + node.children.reduce((count, child) => count + countNodes(child), 0);
}

function shakeEmptyTerminalNodes(tree: CategoryTree[], isNotEmpty: (category: CategoryDto) => boolean): CategoryTree[] {
  return tree
    .map(leaf => {
      if (!leaf.children.length && !isNotEmpty(leaf)) return null;

      return {
        ...leaf,
        children: shakeEmptyTerminalNodes(leaf.children, isNotEmpty)
      };
    })
    .filter(Boolean);
}

function toTreeItems(categoryLeaf: CategoryTree[], index: number = 0, hasTemplate: (category: CategoryDto) => boolean): TreeSelectItem[] {
  const leaf = categoryLeaf[index];

  if (!leaf) return [];

  return [
    {
      id: leaf.id,
      name: leaf.name,
      level: leaf.level,
      hasChildren: !!leaf.children.length,
      disabled: !hasTemplate(leaf)
    }
  ]
    .concat(toTreeItems(leaf.children, 0, hasTemplate))
    .concat(toTreeItems(categoryLeaf, index + 1, hasTemplate));
}

function getCategoryTree(categories: CategoryDto[], parentId: string = null, level: number = 0): CategoryTree[] {
  const leafCategories = categories.filter(category => category.parent_id === parentId);

  if (!leafCategories.length) return [];

  return leafCategories.map(category => ({
    ...category,
    level,
    children: getCategoryTree(categories, category.id, level + 1).sort(comparePositions)
  }));
}

export function getCategoriesTree(categories: CategoryDto[], developmentType: DevelopmentType): CategoryTree[] {
  categories = categories.filter(category => category.development_type === developmentType);
  categories = addRootMenuItem(categories, developmentType);

  return getCategoryTree(categories);
}
