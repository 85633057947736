import { Params } from '@angular/router';

export const CATEGORIES_EDIT_QUERY_PARAMETER = 'categoriesEdit';

export enum CategoriesEditQueryParamValues {
  preview = 'preview',
  noPreview = 'no-preview'
}

export interface ListViewQueryParams extends Params {
  page?: number;
  limit?: number;
  ro_ids?: string[];
  [CATEGORIES_EDIT_QUERY_PARAMETER]?: CategoriesEditQueryParamValues;
}
