import { ChangeDetectionStrategy, Component, effect, input } from '@angular/core';
import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { NgbPopoverModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-wysiwyg-field',
  standalone: true,
  imports: [CommonModule, IconComponent, NgbPopoverModule, NgbTooltip],
  templateUrl: './wysiwyg-field.component.html',
  styleUrl: './wysiwyg-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WysiwygFieldComponent {
  iconSize = input<string>('0.75rem');
  iconColor = input<string>(IconColors.darkGrey);
  headerCustomStyles = input<{ [key: string]: string }>(null);
  fieldValue = input<string>('');
  tooltip = input<string>(null);

  IconColors = IconColors;
}
