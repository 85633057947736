import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';

import { CategoryDto, UpdateArea } from '../../_models';
import * as actions from './categories.actions';
import { ErrorMessageSection } from '@app/shared/_models/error-message';

export const CATEGORIES_STATE_KEY = 'categories';
export const categoriesAdapter: EntityAdapter<CategoryDto> = createEntityAdapter<CategoryDto>();
export interface CategoriesState extends EntityState<CategoryDto> {
  readonly loading: boolean;
  readonly loaded: boolean;
  errorMessage: ErrorMessageSection;
}

export const initialState: CategoriesState = categoriesAdapter.getInitialState({
  loading: false,
  loaded: false,
  errorMessage: null
});

export const reducer: ActionReducer<CategoriesState> = createReducer(
  initialState,

  on(actions.loadCategories, state => {
    return { ...state, loading: true, loaded: false };
  }),
  on(actions.loadCategoriesSuccess, (state, action) =>
    categoriesAdapter.setAll(action.data, {
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(actions.loadCategoriesFailure, state => {
    return { ...state, loading: false, loaded: false };
  }),
  on(actions.addCategory, state => {
    return { ...state, loading: true, loaded: false, errorMessage: null };
  }),
  on(actions.addCategoryFailure, (state, action) => {
    return { ...state, loading: false, errorMessage: { errorResponse: action.error, section: UpdateArea.CATEGORY } };
  }),
  on(actions.updateCategory, state => {
    return { ...state, loading: true };
  }),
  on(actions.updateCategorySuccess, (state, action) =>
    categoriesAdapter.updateOne(
      {
        id: action.category.id,
        changes: action.category
      },
      { ...state, loading: false, loaded: true }
    )
  ),
  on(actions.updateCategoryFailure, (state, action) => {
    return { ...state, loading: false, errorMessage: { errorResponse: action.error, section: UpdateArea.CATEGORY } };
  }),
  on(actions.deleteCategory, state => {
    return { ...state, loading: true };
  }),
  on(actions.deleteCategorySuccess, (state, action) =>
    categoriesAdapter.removeOne(action.categoryId, { ...state, loading: false, loaded: true })
  ),
  on(actions.deleteCategoryFailure, state => {
    return { ...state, loading: false };
  }),
  on(actions.duplicateCategoryFailure, (state, action) => {
    return { ...state, errorMessage: { errorResponse: action.error, section: UpdateArea.CATEGORY } };
  }),
  on(actions.clearErrorMessage, state => {
    return { ...state, errorMessage: null };
  })
);
