import { Injectable, Signal } from '@angular/core';
import { WorkspaceApiService } from '@shared/_services/workspace';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { BehaviorSubject, map, mergeMap, Observable, Subject, Subscription, first, tap, EMPTY, throwError } from 'rxjs';
import { IWorkspace } from '@app/shared/_models/workspace';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  private workspaceDeleted = new Subject<string>();
  private workspaceChanged = new Subject<boolean>();
  private workspaceCreated = new Subject<IWorkspace>();
  private currentWorkspaceSource = new BehaviorSubject<IWorkspace>(null);
  private workspacesSource = new BehaviorSubject<IWorkspace[]>([]);

  public workspaceChanged$ = this.workspaceChanged.asObservable();
  public workspaceCreated$ = this.workspaceCreated.asObservable();
  public workspaceDeleted$ = this.workspaceDeleted.asObservable();
  public currentWorkspace$ = this.currentWorkspaceSource.asObservable();

  currentWorkspace: IWorkspace; // this is snapshot (when observable is not necessary)
  workspaces: IWorkspace[] = [];
  workspaces$: Observable<IWorkspace[]> = this.workspacesSource.asObservable();
  favouriteWorkspace: Signal<IWorkspace> = toSignal(this.workspaces$.pipe(map(workspaces => workspaces?.find(ws => ws.is_favourite))));

  constructor(
    private workspaceApiService: WorkspaceApiService,
    private toastService: ToastService,
    private router: Router
  ) {}

  setCurrentWorkspace(workspace: IWorkspace) {
    this.currentWorkspace = workspace;
    this.currentWorkspaceSource.next(workspace);
  }

  onWorkspaceChanged() {
    this.workspaceChanged.next(true);
  }

  onWorkspaceCreated(workspace: IWorkspace) {
    this.workspaceCreated.next(workspace);
  }

  sendJoinRequest(code: string, workspaceId: string): Subscription {
    return this.workspaceApiService.sendJoinRequest(workspaceId, code).subscribe(
      () => {
        this.toastService.show('Success - request to join the workspace sent successfully', {
          header: 'Request sent',
          type: 'success'
        });
      },
      error => {
        if (error.status === 400 || error.status === 404 || error.status === 409) {
          this.toastService.show(error.error.detail, {
            header: 'The request was not sent',
            type: 'danger'
          });
        } else {
          throw error;
        }
      }
    );
  }

  leaveWorkspace(workspaceId: string): Observable<IWorkspace | boolean> {
    return this.workspaceApiService.leave(workspaceId).pipe(
      tap(
        () => {
          this.toastService.show('You left workspace successfully', {
            header: 'You left workspace',
            type: 'success'
          });
        },
        error => {
          if (error.status === 400 || error.status === 404 || error.status === 422) {
            this.toastService.show(error.error.detail, {
              header: 'Failed to leave workspace',
              type: 'danger'
            });
          }
        }
      ),
      mergeMap(() => this.getInitialWorkspace()),
      tap((workspace: IWorkspace) => this.currentWorkspaceSource.next(workspace))
    );
  }

  deleteWorkspace(workspaceId: string): Observable<IWorkspace | boolean> {
    return this.workspaceApiService.delete(workspaceId).pipe(
      tap(
        () => {
          this.toastService.show('Deleted workspace successfully', {
            header: 'Deleted',
            type: 'success'
          });
        },
        error => {
          if (error.status === 400 || error.status === 404 || error.status === 422) {
            this.toastService.show(error.error.detail, {
              header: 'Failed to delete workspace',
              type: 'danger'
            });
          }
        }
      ),
      mergeMap(() => this.getInitialWorkspace()),
      tap((workspace: IWorkspace) => this.currentWorkspaceSource.next(workspace))
    );
  }

  onWorkspaceInviteAccepted(workspaceId: string) {
    this.workspaceApiService
      .getWorkspace(workspaceId)
      .pipe(first())
      .subscribe(workspace => {
        this.workspacesSource.next(this.workspaces.concat(workspace));
      });
  }

  getInitialWorkspace(workspaceId?: string): Observable<IWorkspace> {
    return this.workspaceApiService.getList().pipe(
      tap(
        workspaces => this.setWorkspaces(workspaces),
        error => {
          // TODO: to prawdopodobnie do usunięcia (w tym momencie user jest już zalogowany więc nigdy nie dostanie 403)
          if (error.status === 403) {
            this.toastService.show('Please contact your administrator.', {
              header: 'You do not have access to requested workspace',
              type: 'danger'
            });

            return this.router.navigateByUrl('signin-form');
          }
        }
      ),
      map(workspaces => {
        if (workspaceId) {
          return workspaces.find(workspace => workspace.id === workspaceId);
        }
        return workspaces.find(workspace => workspace.is_favourite) || workspaces[0];
      })
    );
  }

  getWorkspaceById(workspaceId): Observable<IWorkspace> {
    return this.workspaceApiService.getWorkspace(workspaceId).pipe(
      catchError(error => {
        if (error.status === 422) {
          this.router.navigateByUrl('app/not-found');

          return EMPTY;
        }

        if (error.status === 403 && this.favouriteWorkspace()) {
          this.toastService.show('Please contact your administrator.', {
            header: 'You do not have access to requested workspace',
            type: 'danger'
          });
          this.router.navigate(['app', this.favouriteWorkspace().id, 'projects']);

          return EMPTY;
        }

        return throwError(() => error);
      })
    );
  }

  getCurrentOrInitialWorkspace(): Observable<IWorkspace> {
    return this.currentWorkspace ? this.currentWorkspace$ : this.getInitialWorkspace();
  }

  getWorkspaceList(): Observable<IWorkspace[]> {
    return this.workspaceApiService.getList();
  }

  getCurrentWorkspaceId(): string {
    return this.currentWorkspace?.id; // this is id from currentWorkspace snapshot only!
  }

  setWorkspaces(workspaceList: IWorkspace[]) {
    this.workspaces = workspaceList;
    this.workspacesSource.next(workspaceList);
  }

  resetWorkspaceState() {
    this.setWorkspaces([]);
    this.setCurrentWorkspace(null);
  }
}
