<ng-container *ngIf="isResetPasswordSuccessful() === false; else successTmpl">
  <form id="reset-password-form" [formGroup]="resetPasswordForm">
    <div
      *ngIf="resetPasswordForm.get(FORM_KEYS.PASSWORD) as rpPassword"
      class="form-group"
      [ngClass]="{ focused: focusPassword, 'is-invalid': (rpPassword.invalid || resetPasswordForm.errors?.contentsMatch) && formSubmitted }"
    >
      <label class="text-uppercase">Password</label>
      <div class="input-wrapper">
        <i class="fas fa-lock"></i>
        <input
          type="password"
          class="form-control"
          [formControlName]="FORM_KEYS.PASSWORD"
          placeholder="Enter password"
          (focusin)="focusPassword = true"
          data-test="passwordInput"
          (focusout)="focusPassword = false"
        />
      </div>
      <ng-container *ngIf="formSubmitted && rpPassword.hasError('required')">
        <small class="form-text invalid-feedback d-block">Password is required</small>
      </ng-container>
      <ng-container *ngIf="formSubmitted && rpPassword.hasError('meetsConditions')">
        <small class="form-text invalid-feedback d-block">Password must contain letters, numbers and have at least 8 characters</small>
      </ng-container>
    </div>
    <div
      *ngIf="resetPasswordForm.get(FORM_KEYS.CONFIRM_PASSWORD) as rtConfirmPassword"
      class="form-group mt-3"
      [ngClass]="{
        focused: focusPassword,
        'is-invalid': (rtConfirmPassword.invalid || resetPasswordForm.errors?.contentsMatch) && formSubmitted
      }"
    >
      <label class="text-uppercase">Confirm Password</label>
      <div class="input-wrapper">
        <i class="fas fa-lock"></i>
        <input
          type="password"
          class="form-control"
          [formControlName]="FORM_KEYS.CONFIRM_PASSWORD"
          placeholder="Retype password"
          (focusin)="focusRetypePassword = true"
          data-test="retypePasswordInput"
          (focusout)="focusRetypePassword = false"
        />
      </div>
      <ng-container *ngIf="formSubmitted && rtConfirmPassword.hasError('required')">
        <small class="form-text invalid-feedback d-block">Confirm Password is required</small>
      </ng-container>
      <ng-container *ngIf="formSubmitted && rtConfirmPassword.hasError('meetsConditions')">
        <small class="form-text invalid-feedback d-block">Password must contain letters, numbers and have at least 8 characters</small>
      </ng-container>
      <ng-container *ngIf="formSubmitted && resetPasswordForm.errors?.contentsMatch">
        <small class="form-text invalid-feedback d-block">Passsword and Confirm Password do not match.</small>
      </ng-container>
    </div>
    <div class="row mt-4">
      <div class="col">
        <app-password-strength-meter
          [password]="resetPasswordForm.get(FORM_KEYS.PASSWORD).value"
          [numberOfProgressBarItems]="4"
        ></app-password-strength-meter>
        <small>Must contain letters, numbers and have at least 8 characters.</small>
      </div>
    </div>
    <div class="form-group mt-4 text-xs-center">
      <app-button class="general-primary w-100 h-45" (click)="resetPassword($event)">Reset password</app-button>
    </div>
    <div class="row additional-text">
      <div class="col">
        Go to
        <a [routerLink]="signInRoutingPath">Sign In</a>
        .
      </div>
    </div>
  </form>
</ng-container>
<ng-template #successTmpl>
  <div class="confirmation-text" *ngIf="isResetPasswordSuccessful()">
    <h2>Password Reset</h2>
    <p>
      Password has been succcessfuly resetted. You can now
      <a [routerLink]="signInRoutingPath">Sign In</a>
      .
    </p>
  </div>
</ng-template>
