<div *ngFor="let alert of alerts; trackBy: trackById" class="{{ getCssClass(alert) }}" alert-dismissable data-test="alertCategory">
  <button
    type="button"
    class="btn-close"
    (click)="removeAlert(alert)"
    data-bs-dismiss="alert"
    aria-label="Close"
    data-test="closeErrorButton"
  ></button>
  <div [innerHTML]="alert.message"></div>
</div>
