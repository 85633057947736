import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { shareReplay } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Params } from '@angular/router';

import { InstanceDto, ResearchObjectInstanceDto, ResearchObjectInstanceAddDto, PagedResponse } from '../../_models';
import { RESEARCH_OBJECTS_INSTANCE_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class InstanceApiService {
  constructor(private readonly http: HttpClient) {}
  pending = signal<boolean>(false);

  getList(params: Params) {
    this.pending.set(true);

    return this.http.get<PagedResponse<ResearchObjectInstanceDto>>(`${RESEARCH_OBJECTS_INSTANCE_API_URL}`, { params }).pipe(
      shareReplay(),
      finalize(() => this.pending.set(false))
    );
  }

  add(researchObjectIntance: ResearchObjectInstanceAddDto) {
    this.pending.set(true);

    return this.http
      .post<InstanceDto>(`${RESEARCH_OBJECTS_INSTANCE_API_URL}`, researchObjectIntance)
      .pipe(finalize(() => this.pending.set(false)));
  }

  update(changes: Partial<InstanceDto>, instanceId: string) {
    this.pending.set(true);

    return this.http
      .patch<InstanceDto>(`${RESEARCH_OBJECTS_INSTANCE_API_URL}/${instanceId}`, changes)
      .pipe(finalize(() => this.pending.set(false)));
  }

  delete(instanceId: string) {
    this.pending.set(true);

    return this.http.delete<string>(`${RESEARCH_OBJECTS_INSTANCE_API_URL}/${instanceId}`).pipe(finalize(() => this.pending.set(false)));
  }
}
