import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

import { ToastService } from '@shared/_modules/toast/toast.service';

export const GENERIC_ERROR_MESSAGE = {
  content: 'Please contact administrator for help in solving this issue.',
  header: 'An unexpected error occured.'
};

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  #genericError$ = new Subject<void>();

  constructor(private toastService: ToastService) {
    this.#handleGenericError();
  }

  // TODO: Przedyskutowac czy naprawde chcemy dla kazdego exception ktore pada w
  // runtime JS'a wyświetlać toast dla uytkownika
  handleError(error: Error | HttpErrorResponse): void {
    // always log error
    console.log(error);

    if (error instanceof HttpErrorResponse && error.error.location == 'toast') {
      this.showToast(error.error.detail, 'Error');
      return;
    }

    this.#genericError$.next();
  }

  showToast(content: string, header: string) {
    this.toastService.show(content, {
      header: header,
      type: 'danger'
    });
  }

  #handleGenericError() {
    const { content, header } = GENERIC_ERROR_MESSAGE;

    this.#genericError$
      .pipe(
        throttleTime(250),
        tap(() => {
          this.showToast(content, header);
        })
      )
      .subscribe();
  }
}
