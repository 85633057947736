import * as routerStore from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

import { RootState } from '@app/shared/_root-store/reducer';
import { toBoolean } from '@app/shared/_helpers';

export const selectRouterReducerState = createSelector(
  (state: RootState) => state.router,
  state => state
);
export const selectRouterState = createSelector(
  (state: RootState) => state.router,
  state => state.state
);

export const {
  selectCurrentRoute, // select the current route
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data,
  selectRouteDataParam,
  selectUrl // select the current url
} = routerStore.getRouterSelectors(selectRouterReducerState);

export const selectPage = createSelector(selectQueryParams, params => params.page);

// this query param indicates whether neighbors are limited to current category or root category
export const selectFromRoot = createSelector(selectQueryParams, params => toBoolean(params.fromRoot));
export const selectPreviousRoute = createSelector(selectRouterState, state => state.previous);
