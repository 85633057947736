import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SignupLayoutComponent } from './signup-layout/signup-layout.component';
import { SignupComponent } from './signup/signup.component';
import { SignupCompletedComponent } from './signup-completed/signup-completed.component';
import { SignupRoutingModule } from './signup-routing.module';
import { AuthLayoutModule } from './../auth-layout/auth-layout.module';
import { PasswordStrengthMeterComponent } from '../password-strength-meter/password-strength-meter.component';

const NG_MODULES = [CommonModule, ReactiveFormsModule];

const MODULES = [SignupRoutingModule, AuthLayoutModule];

const COMPONENTS = [SignupLayoutComponent, SignupCompletedComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...NG_MODULES, ...MODULES, PasswordStrengthMeterComponent, SignupComponent],
})
export class SignupModule {}
