import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

import { DevelopmentType, Experiment, RouteParam, CategoryTemplateDto } from '@shared/_models';
import { NavigationService } from '@app/shared/_services/navigation.service';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors/router.selectors';
import { selectTemplates } from '@app/shared/_root-store/category-templates-store/category-templates.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-experiment-table-item-details',
  templateUrl: './experiment-table-item-details.component.html',
  styleUrls: ['./experiment-table-item-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTableItemDetailsComponent {
  @Input() experiment: Experiment;
  @Output() showRelatedResearchObjectsChanged = new EventEmitter<boolean>();
  showRelatedResearchObjects = false;
  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));

  readonly DevelopmentType = DevelopmentType;

  templates$: Observable<CategoryTemplateDto[]> = this.store.select(selectTemplates);

  constructor(
    private readonly navigationService: NavigationService,
    private store: Store,
    private workspaceService: WorkspaceService
  ) {}

  switchShowRelatedResearchObjects(showRelatedResearchObjects: boolean) {
    this.showRelatedResearchObjects = showRelatedResearchObjects;
    this.showRelatedResearchObjectsChanged.emit(showRelatedResearchObjects);
  }

  goToResearchObjectInfo(researchObjectId: string, researchObjectCategoryId: string) {
    this.navigationService.goToResearchObjectInfo(
      this.workspaceService.getCurrentWorkspaceId(),
      this.projectId(),
      researchObjectId,
      researchObjectCategoryId
    );
  }
}
