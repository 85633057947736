import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsNotAuthenticatedGuard } from './guards/is-not-authenticated.guard';

const routes: Routes = [
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
    canActivate: [IsNotAuthenticatedGuard],
  },
  {
    path: 'password',
    loadChildren: () => import('./password/password.module').then(m => m.PasswordModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./signin/signin.module').then(m => m.SigninModule),
    canActivate: [IsNotAuthenticatedGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
