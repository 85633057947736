import { computed, signal, Directive, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { submitted } from '@shared/dynamic-form/components/dynamic-field/store';
import { FieldsErrors, FieldErrors } from '@shared/dynamic-form/components/dynamic-field/utils';

@Directive()
export class ItemDetailsFieldsBaseComponent {
  editable = input.required<boolean>();
  errors = signal<FieldsErrors>({});
  areErrors = computed(() => {
    const fieldsErrors: FieldErrors[] = Object.values(this.errors() ?? {});
    const validationErrors: ValidationErrors[] = fieldsErrors.map(fieldErrors => fieldErrors.errors);

    return validationErrors.some(isError);
  });
  areTouchedErrors = computed(() => {
    const fieldsErrors: FieldErrors[] = Object.values(this.errors() ?? {});

    return fieldsErrors.some(fieldErrors => fieldErrors.touched && isError(fieldErrors.errors));
  });
  submitted = submitted;

  onErrorsChange(errors: FieldsErrors) {
    this.errors.update(value => ({ ...value, ...errors }));
  }
}

function isError(validationErrors: ValidationErrors): boolean {
  return Object.values(validationErrors ?? {}).some(error => !!error);
}
