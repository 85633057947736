import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, withLatestFrom, tap, mergeMap, switchMap, from } from 'rxjs';

import * as actions from './images.actions';
import { selectImagesState } from './images.selectors';
import { ImageApiService } from '@shared/_services/image/image-api.service';
import { isCached } from '@app/domain/image';
import { blobToBase64 } from '@shared/_helpers/blob-to-base64';

@Injectable()
export class ImagesEffects {
  constructor(
    private actions$: Actions,
    private imageApiService: ImageApiService,
    private store: Store
  ) {}

  loadImageContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadImageContent),
      withLatestFrom(this.store.select(selectImagesState)),
      mergeMap(([{ imageMeta, size }, state]) => {
        if (isCached(imageMeta, state.images[size].entities)) return of(actions.handleCachedImage({ id: imageMeta.id, size }));

        return this.imageApiService.get(imageMeta.id, size).pipe(
          switchMap(blob =>
            from(blobToBase64(blob)).pipe(
              map(base64 =>
                actions.loadImageContentSuccess({
                  data: {
                    id: imageMeta.id,
                    date_modified: imageMeta.date_modified,
                    date_last_accessed: new Date().getTime(),
                    base64,
                    size: blob.size
                  },
                  size
                })
              ),
              catchError(() => of(actions.loadImageContentFailure({ id: imageMeta.id, size })))
            )
          )
        );
      })
    )
  );

  loadImagesContent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loadImagesContent),
        tap(({ imagesMeta, size }) => imagesMeta.forEach(imageMeta => this.store.dispatch(actions.loadImageContent({ imageMeta, size }))))
      ),
    { dispatch: false }
  );
}
