import { inject } from '@angular/core';
import { Params } from '@angular/router';

import { RouterService } from '@shared/_services/router/router.service';
import { RouteParam } from '@shared/_models';

export function getAttachParams(...routeParams: RouteParam[]) {
  const routerService: RouterService = inject(RouterService);

  return (params: Params = {}): Params => ({
    ...params,
    ...(routeParams.includes(RouteParam.PROJECT_ID) ? { project_id: routerService.projectId() } : {}),
    ...(routeParams.includes(RouteParam.CATEGORY_ID) ? { category_id: routerService.categoryId() } : {})
  });
}
