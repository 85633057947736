import { DisplayFieldType, FieldType } from './field-type';
import { Positionable } from './positionable';

export interface AllowedValueDto {
  enum_value: string;
  enum_color?: number;
}

export interface OptionsDto {
  min_length: number;
  max_length: number;
  min_value: number;
  max_value: number;
  required: boolean;
  read_only: boolean;
  pattern: string;
  allowed_values?: AllowedValueDto[];
  generate_image?: string;
}

export type CellWidth = '1/3' | '2/3' | '3/3';
export type DateFormat = 'dd.mm.yyyy' | 'mm/dd/yyyy' | 'yyyy-mm-dd' | 'MM yyyy' | 'days-elapsed';
export enum DateRange {
  FUTURE = 'future',
  PAST = 'past',
  ALL = 'all'
}
export enum UserFormat {
  NAME_SURNAME = 'name_surname',
  EMAIL = 'email'
}

export interface DisplayOptionsDto {
  display_type?: DisplayFieldType;
  cell_width?: CellWidth;
  date_format?: DateFormat;
  date_range?: DateRange;
  count_visible?: boolean;
  decimal_places?: number;
  user_format?: UserFormat;
  url_format?: string;
}

export interface ParameterDto extends Positionable {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  field_type: FieldType;
  value_from_id?: string;
  parent_id?: string;
  default?: string;
  options?: OptionsDto;
  display_options?: DisplayOptionsDto;
}

export interface ParameterUpdateDto {
  name: string;
  label: string;
  placeholder: string;
  position: number;
  default: string;
  options: Partial<OptionsDto>;
  display_options: Partial<DisplayOptionsDto>;
}
