import { ItemDetailsInfoModalComponent } from './item-details-info-modal/item-details-info-modal.component';
import { ItemDetailsEditModalComponent } from './item-details-edit-modal/item-details-edit-modal.component';
import { ItemDetailsInstancesComponent } from './item-details-instances/item-details-instances.component';
import { ItemDetailsLinkComponent } from './item-details-link/item-details-link.component';
import { ItemDetailsNavComponent } from './item-details-nav/item-details-nav.component';
import { ItemDetailsRecentFilesComponent } from './item-details-recent-files/item-details-recent-files.component';
import { ItemDetailsRelatedItemsComponent } from './item-details-related-items/item-details-related-items.component';
import { ItemDetailsSectionComponent } from './item-details-section/item-details-section.component';
import { ItemDetailsBackSidebarComponent } from './item-details-back-sidebar/item-details-back-sidebar.component';
import { ItemDetailsImportComponent } from './item-details-import/item-details-import.component';
// VIEWS
import { ItemDetailsGridSectionComponent } from './item-details-grid-section/item-details-grid-section.component';
import { ItemDetailsTableSectionComponent } from './item-details-table-section/item-details-table-section.component';
// REMOVAL
import { ItemDetailsRemoveConfirmationComponent } from './item-details-remove-confirmation/item-details-remove-confirmation.component';
import { ItemDetailsRemoveRejectionComponent } from './item-details-remove-rejection/item-details-remove-rejection.component';
import { ItemDetailsParametersComponent } from './item-details-parameters/item-details-parameters.component';
import { ItemDetailsAdditionalInfoModalComponent } from './item-details-additional-info-modal/item-details-additional-info-modal.component';
import { ItemDetailsAdditionalInfoComponent } from './item-details-additional-info/item-details-additional-info.component';

export const ITEM_DETAILS_COMPONENTS = [
  ItemDetailsAdditionalInfoComponent,
  ItemDetailsInfoModalComponent,
  ItemDetailsAdditionalInfoModalComponent,
  ItemDetailsEditModalComponent,
  ItemDetailsInstancesComponent,
  ItemDetailsLinkComponent,
  ItemDetailsNavComponent,
  ItemDetailsRecentFilesComponent,
  ItemDetailsRelatedItemsComponent,
  ItemDetailsSectionComponent,
  ItemDetailsBackSidebarComponent,
  ItemDetailsImportComponent,
  ItemDetailsGridSectionComponent,
  ItemDetailsTableSectionComponent,
  ItemDetailsRemoveConfirmationComponent,
  ItemDetailsRemoveRejectionComponent,
  ItemDetailsParametersComponent
] as const;

export * from './item-details-additional-info/item-details-additional-info.component';
export * from './item-details-images/item-details-images.component';
export * from './item-details-info-modal/item-details-info-modal.component';
export * from './item-details-additional-info-modal/item-details-additional-info-modal.component';
export * from './item-details-edit-modal/item-details-edit-modal.component';
export * from './item-details-instances/item-details-instances.component';
export * from './item-details-link/item-details-link.component';
export * from './item-details-nav/item-details-nav.component';
export * from './item-details-recent-files/item-details-recent-files.component';
export * from './item-details-related-items/item-details-related-items.component';
export * from './item-details-section/item-details-section.component';
export * from './item-details-back-sidebar/item-details-back-sidebar.component';
export * from './item-details-import/item-details-import.component';
export * from './item-details-parameters/item-details-parameters.component';
// VIEWS
export * from './item-details-grid-section/item-details-grid-section.component';
export * from './item-details-table-section/item-details-table-section.component';
// REMOVAL
export * from './item-details-remove-confirmation/item-details-remove-confirmation.component';
export * from './item-details-remove-rejection/item-details-remove-rejection.component';
