import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevelopmentType, SectionType } from '@shared/_models';

@Component({
  selector: 'app-empty-area-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './empty-area-content.component.html',
  styleUrls: ['./empty-area-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyAreaContentComponent {
  @Input() developmentType: DevelopmentType;
  @Input() sectionType: SectionType | 'files' | 'changelog';
  @Input() edit: boolean;
  @Input() inactive = false;
  @Input() label = 'Add Field';
  @Input() darkMode = false;

  get iconClass(): string {
    if (!this.developmentType || !this.sectionType) return;

    const developmentTypeSuffix = this.developmentType === DevelopmentType.researchObject ? 'ro' : 'ex';
    const editSuffix = this.edit ? 'edit' : 'preview';

    return [
      `empty-area__placeholder__icon--${developmentTypeSuffix}`,
      `empty-area__placeholder__icon--${this.sectionType}`,
      `empty-area__placeholder__icon--${editSuffix}`
    ].join(' ');
  }

  readonly SectionType = SectionType;
}
