<div class="container-fluid login">
  <div class="row">
    <div class="col box-with-form bg-white">
      <div class="background-adamed-icon"></div>
      <div data-testid="welcome-header" class="welcome-label">Welcome !</div>
      <div data-testid="auth-type-header" class="auth-action-label">{{ authActionLabel }}</div>
      <div class="box-with-form-inner">
        <div class="box-with-form-contents w-100">
          <app-alert-section></app-alert-section>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <div class="col bg-darker">
      <section id="lead">
        <h1>
          Lab
          <br />
          Gears
        </h1>
        <p>
          One source of reasearch data: information about objects, experiments, images, files with the possibility to exchange them between
          <span class="highlight">labs, departments and projects.</span>
        </p>
      </section>
    </div>
  </div>
</div>
