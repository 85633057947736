export enum FileType {
  TEXT = 'text/plain',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/jpeg',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  CSV = 'text/csv;charset=utf-8',
  ZIP = 'application/zip',
}
