import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { CategoriesState } from './categories.reducer';
import { RootState } from '@shared/_root-store/reducer';
import { CategoryDto } from '@shared/_models';

export const selectCategoriesState = createSelector(
  (state: RootState) => state.categories,
  state => state
);
export const selectCategoriesDictionary = createSelector(selectCategoriesState, (state: CategoriesState) => state?.entities);

export const selectCategories = createSelector(selectCategoriesDictionary, (dictionary: Dictionary<CategoryDto>) =>
  dictionary ? Object.values(dictionary) : []
);

export const categoriesLoading = createSelector(selectCategoriesState, (state: CategoriesState) => state.loading);

export const categoriesLoaded = createSelector(selectCategoriesState, (state: CategoriesState) => state.loaded);

export const selectErrorMessage = createSelector(selectCategoriesState, (state: CategoriesState) => state.errorMessage);

export const selectCategoryById = (id: string) => createSelector(selectCategoriesState, categories => categories?.entities[id]);
