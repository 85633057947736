import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toggleShowMoreBtnLabel',
  standalone: true,
})
export class ToggleShowMoreBtnLabelPipe implements PipeTransform {
  transform(currentAmountVisible: number, defaultAmountVisible: number): string {
    const suffix = currentAmountVisible > defaultAmountVisible ? 'Less' : 'More';

    return `Show ${suffix}`;
  }
}
