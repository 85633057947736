<div class="items-pagination">
  <button
    data-testid="pagination-previous-page-button"
    class="btn btn-sm btn-transparent items-pagination__direct page pagination-previous"
    (click)="onPageIndexChanged(prevPageIndex())"
    [disabled]="currentPageIndex() === 1 || pages().length === 1"
  >
    <img class="me-2" src="assets/icons/arrow_left.png" alt="arrow left icon" />
    Previous
  </button>
  <ul class="items-pagination__navigation responsive">
    <li>
      <button
        data-testid="pagination-first-page-arrow-button"
        class="btn btn-sm btn-transparent page pagination-first"
        (click)="onPageIndexChanged(1)"
        [disabled]="currentPageIndex() === 1 || pagination()?.pageCount === 1"
      >
        <i class="fas fa-angle-double-left"></i>
      </button>
    </li>
    <li>
      <button
        data-testid="pagination-previous-page-arrow-button"
        class="btn btn-sm btn-transparent page pagination-previous"
        (click)="onPageIndexChanged(prevPageIndex())"
        [disabled]="currentPageIndex() === 1 || pagination()?.pageCount === 1"
      >
        <i class="fas fa-angle-left"></i>
      </button>
    </li>
    <li [class.ellipsis]="page.label === '...'" *ngFor="let page of pages(); index as i">
      <button
        class="btn btn-sm btn-transparent page"
        [ngClass]="{ 'bg-primary text-white': page.value === currentPageIndex() }"
        [attr.data-testid]="'pagination-page-number-' + page.value"
        (click)="onPageIndexChanged(page.value)"
        [disabled]="pages().length === 1"
      >
        {{ page.label }}
      </button>
    </li>
    <li>
      <button
        data-testid="pagination-next-page-arrow-button"
        class="btn btn-sm btn-transparent page pagination-next"
        (click)="onPageIndexChanged(nextPageIndex())"
        [disabled]="currentPageIndex() === pagination()?.pageCount || pagination()?.pageCount === 1"
      >
        <i class="fas fa-angle-right"></i>
      </button>
    </li>
    <li>
      <button
        data-testid="pagination-last-page-arrow-button"
        class="btn btn-sm btn-transparent page pagination-next"
        (click)="onPageIndexChanged(pagination()?.pageCount)"
        [disabled]="currentPageIndex() === +pagination()?.pageCount || +pagination()?.pageCount === 1"
      >
        <i class="fas fa-angle-double-right"></i>
      </button>
    </li>
  </ul>
  <button
    data-testid="pagination-next-page-button"
    class="btn btn-sm btn-transparent items-pagination__direct page pagination-next"
    (click)="onPageIndexChanged(nextPageIndex())"
    [disabled]="currentPageIndex() === +pagination()?.pageCount || +pagination()?.pageCount === 1"
  >
    Next
    <img class="ms-2" src="assets/icons/arrow_right.png" alt="arrow left icon" />
  </button>
</div>
