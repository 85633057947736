import { CommonModule, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';

import { TextCounterComponent } from '../text-long-input/text-counter/text-counter.component';
import { CounterOptions } from '../text-long-input/text-counter/utils';

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [NgClass, FormsModule, CommonModule, TextCounterComponent],
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() placeholder = '-';
  @Input() counterOptions: CounterOptions;
  @Output() onBlur = new EventEmitter<void>();

  constructor(private controlDirective: NgControl) {
    this.controlDirective.valueAccessor = this;
  }

  ngModel = signal('');
  registerOnChange(fn: (value: string) => void): void {
    this.#onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.#onTouched = fn;
  }
  setDisabledState(): void {}
  writeValue(value: string): void {
    this.ngModel.set(value);
  }

  setHeightToContent(textArea: HTMLTextAreaElement): void {
    textArea.style.height = 'auto';
    textArea.style.height = `${textArea.scrollHeight}px`;
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  onNgModelChange(value: string) {
    const valueWithoutEnters = value?.replace(/\r?\n|\r/g, ' ');
    this.ngModel.set(valueWithoutEnters);
    this.#onTouched();
    this.#onChange(this.ngModel());
  }

  #onChange = (_: string) => void {};
  #onTouched = () => void {};
}
