import { CommonParams } from '@shared/_models';
import { HttpParams } from '@angular/common/http';

export function getParamsChangeOnSearch(search: string, params: CommonParams): Partial<CommonParams> {
  if (search !== params.search && params.indexPage > 1)
    return {
      indexPage: 1,
      search,
    };

  return { search };
}

export type Params =
  | HttpParams
  | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };

/**
 * Return params with filtered keys - only keys with truthy values are included (except boolean and numbers)
 * @param params
 */
export function toReducedParams(params?: Params): { params: Params } {
  if (!params) return;

  return {
    params: Object.keys(params).reduce((acc, key) => {
      const value = params[key];

      if (value || typeof value === 'boolean' || typeof value === 'number') {
        return {
          ...acc,
          [key]: value,
        };
      }

      return acc;
    }, {}),
  };
}
