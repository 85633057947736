<ng-select
  #select
  class="dropdown-select"
  bindLabel="name"
  popupClass="lg-dropdown-menu lg-dropdown-menu--y-scroll"
  [clearable]="false"
  [editableSearchTerm]="false"
  [items]="statusSelectItems"
  (change)="onSelectItem($event, select)"
  (focusin)="onFocusin(select)"
  [ngModel]="selectedStatusName"
>
  <ng-template ng-label-tmp let-item="item">
    <i class="status-icon status-icon--{{ selectedStatusName | lowercase }}"></i>
    <span class="ng-select-label">{{ item.name | titlecase }}</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <div class="dropdown-select-option">
      <i class="status-icon status-icon--{{ item.name | lowercase }}"></i>
      <span class="ng-select-label">{{ item.name | titlecase }}</span>
    </div>
  </ng-template>
</ng-select>
