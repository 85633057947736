import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, WritableSignal, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { DevelopmentType, Image, ImageDto } from '../../../_models';
import { FileCategory } from '../models/file-category';
import { FileService } from '@shared/_modules/file/file.service';
import { trackById } from '@shared/_helpers';
import { FilesModalIconCSSClassPipe } from '@shared/_modules/file/pipes/files-modal-icon-css-class.pipe';
import { ImagesListCardComponent } from '@shared/_modules/file/images-list-card/images-list-card.component';
import { StartCasePipe } from '@shared/pipes/start-case.pipe';
import { FileSafeUrlPipe } from '@shared/pipes/file-safe-url.pipe';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { FormatFileSizePipe } from '@shared/_modules/file/pipes/format-file-size.pipe';
import { MimeSubtypePipe } from '@shared/_modules/file/pipes/mime-subtype.pipe';
import { ImageThumbComponent } from '@shared/_modules/file/image-thumb/image-thumb.component';
import { ButtonComponent } from '@shared/_components/button/button.component';

@Component({
  selector: 'app-images-list',
  standalone: true,
  imports: [
    CommonModule,
    NgbPopoverModule,
    FilesModalIconCSSClassPipe,
    ImagesListCardComponent,
    StartCasePipe,
    FileSafeUrlPipe,
    DevelopmentTypeCssClassPipe,
    FormatFileSizePipe,
    MimeSubtypePipe,
    ImageThumbComponent,
    ButtonComponent
  ],
  templateUrl: './images-list.component.html',
  styleUrls: ['./images-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagesListComponent {
  @Input() fileCategory: FileCategory;
  @Input() developmentType: DevelopmentType;
  @Input() sectionName: string;
  @Input() set images(value: Image[]) {
    this.imagesSignal.set(value);
  }
  imagesSignal: WritableSignal<Image[]> = signal([]);

  @Input() set mainImageId(value: string) {
    this.mainImageIdSignal.set(value);
  }
  mainImageIdSignal: WritableSignal<string> = signal(null);

  @Output() close = new EventEmitter<null>();
  @Output() addMoreImages = new EventEmitter<null>();
  @Output() setAsMainImage = new EventEmitter<string>();
  @Output() deleteImage = new EventEmitter<string>();

  sortedImages = computed(() => this.imagesSignal()?.sort((a, _) => (a.id === this.mainImageIdSignal() ? -1 : 0)));
  readonly trackById = trackById;
  readonly DevelopmentType = DevelopmentType;

  constructor(private readonly fileService: FileService) {}

  onCloseBtnClick(): void {
    this.close.emit();
  }

  onDeleteImage(imageId: string): void {
    this.deleteImage.emit(imageId);
  }

  onSetAsMainImage(image: ImageDto): void {
    this.setAsMainImage.emit(image.id);
  }

  onAddMoreImagesClick(): void {
    this.addMoreImages.emit();
  }

  onShowImageDetails(image: ImageDto): void {
    this.fileService.emitImageForDetailsModal(image);
  }
}
