import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-item-details',
  templateUrl: './table-item-details.component.html',
  styleUrls: ['./table-item-details.component.scss'],
})
export class TableItemDetailsComponent {
  @Input() detailsMessage: string;
  showRelatedItems = false;
  @Output() showRelatedItemsChanged = new EventEmitter<boolean>();

  switchShowRelatedItems() {
    this.showRelatedItems = !this.showRelatedItems;
    this.showRelatedItemsChanged.emit(this.showRelatedItems);
  }
}
