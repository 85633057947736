import { DecimalPipe } from '@angular/common';
import { Directive, Signal, computed, input } from '@angular/core';

import { DisplayFieldType, Field } from 'src/app/shared/_models';

@Directive()
export class ListItemResultBase {
  results = input.required<Field[]>();
  resultToDisplay: Signal<Field> = computed(() => this.results()?.[0]);
  resultType: Signal<DisplayFieldType> = computed(() => this.resultToDisplay()?.type);
  formattedResultToDisplayValue = computed(() => {
    const value: string = this.resultToDisplay().value;

    const isNumber = !isNaN(Number(value));

    return isNumber ? this.decimalPipe.transform(value, '1.0-2') : value;
  });

  readonly DisplayFieldType = DisplayFieldType;

  constructor(readonly decimalPipe: DecimalPipe) {}
}
