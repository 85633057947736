import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgFor } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [NgFor, NgbModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() items: string[];
  @Input() selectedItem: string;
  @Output() select = new EventEmitter<string>();
  isDropdownOpened = false;

  onSelect(item: string) {
    this.select.emit(item);
  }
}
