<app-input-with-validation
  [control]="control()"
  [config]="config()"
  [backendErrorMessage]="backendErrorMessage()"
  [externalTouched]="externalTouched()"
  class="form-input"
>
  <div
    [ngClass]="{
      'dynamic-field-border': config()?.isGridView && control().valid,
      'dynamic-field-border-invalid': markAsInvalid()
    }"
    class="form-input__wrapper"
  >
    <app-text-input
      [formControl]="control()"
      [counterOptions]="counterOptions()"
      (onBlur)="blur$.next()"
      class="form-input__wrapper__input"
      [class.form-input__wrapper__input--smiles]="isSmiles()"
      [class.form-input__wrapper__input--modification]="isModificationField()"
    ></app-text-input>
  </div>
</app-input-with-validation>
