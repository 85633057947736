import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
//
import { ResearchObjectsEffects } from './research-objects.effects';
import * as fromResearchObjects from './research-objects.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromResearchObjects.RESEARCH_OBJECTS_FEATURE_KEY, fromResearchObjects.reducer),
    EffectsModule.forFeature([ResearchObjectsEffects]),
  ],
})
export class ResearchObjectsStoreModule {}
