<section id="no-search-result">
  <div class="row">
    <div class="col-4">
      <i class="icon" [ngStyle]="{ 'background-image': iconUrl() }"></i>
    </div>
    <div class="col-5 message-container">
      <div>
        <h3 class="message" data-test="noResMsg">{{ message }}</h3>
        <p>Check the spelling or try searching for a diferent term.</p>
      </div>
    </div>
  </div>
</section>
