import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevelopmentType } from '@shared/_models';
import { GenericModalComponent } from '@shared/_components/generic-modal/generic-modal.component';
import { ModalHeaderCenterComponent } from '@shared/_components/modal-header-center/modal-header-center.component';

@Component({
  selector: 'app-not-implemented-modal',
  standalone: true,
  imports: [CommonModule, GenericModalComponent, ModalHeaderCenterComponent],
  templateUrl: './not-implemented-modal.component.html',
  styleUrls: ['./not-implemented-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotImplementedModalComponent {
  @Input() developmentType: DevelopmentType;
  @Output() close = new EventEmitter<void>();
}
