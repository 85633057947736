import { Injectable } from '@angular/core';
import { Params, UrlSegment } from '@angular/router';
import { Subject } from 'rxjs';

import { Field, ISortOption, DevelopmentType } from '@shared/_models';

@Injectable({
  providedIn: 'root'
})
// TODO: Refactor & Remove !!!
export class DevelopmentItemService {
  private sectionEditCancelEventSource = new Subject<void>();
  private sectionEditSaveEventSource = new Subject<void>();
  private showEditWarningModalEventSource = new Subject<void>();
  editActiveStatus: boolean = false;
  editActiveSectionName: string = '';

  sectionEditCancelEvent$ = this.sectionEditCancelEventSource.asObservable();
  sectionEditSaveEvent$ = this.sectionEditSaveEventSource.asObservable();
  showEditWarningModalEvent$ = this.showEditWarningModalEventSource.asObservable();

  emitSectionEditCancelEvent() {
    this.sectionEditCancelEventSource.next();
  }

  emitSectionEditSaveEvent() {
    this.sectionEditSaveEventSource.next();
  }

  emitShowEditWarningModalEvent() {
    this.showEditWarningModalEventSource.next();
  }

  prepareQueryParamsForGetNeighborRequest(category_id?: string, all_descendants?: boolean, sort?: ISortOption): Params {
    return {
      ...(category_id && { category_id }),
      ...(all_descendants && { all_descendants }),
      ...(sort && { order_by: sort.prop, sort_order: sort.order })
    };
  }

  getNeighborDevelopmentItemRouterCommands(
    viewMode: string,
    rootUrl: string[] | UrlSegment[],
    developmentItemId: string,
    categoryId: string
  ) {
    const outlets = {
      primary: [viewMode, categoryId, developmentItemId, 'info']
    };
    return [...rootUrl, { outlets }];
  }

  checkIfAnyNonEmptyValueFromItemParamsOrResults(parameters: Field[]) {
    const anyParameterWithNoEmptyValue = parameters?.find(parameter => parameter.value);
    return !!anyParameterWithNoEmptyValue;
  }

  setEditActiveStatus(status: boolean) {
    this.editActiveStatus = status;
  }

  getEditActiveStatus(): boolean {
    return this.editActiveStatus;
  }

  setEditActiveSectionName(section: string) {
    this.editActiveSectionName = section;
  }

  getEditActiveSectionName() {
    return this.editActiveSectionName;
  }
}
