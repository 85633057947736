import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
  WritableSignal
} from '@angular/core';
import { AsyncPipe, NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { upperFirst } from 'lodash-es';

import {
  CategoryTemplate,
  DevelopmentItem,
  DevelopmentType,
  DisplayFieldType,
  ErrorMessageSection,
  Field,
  FieldsViewMode,
  FieldValueDto,
  SectionDto,
  SectionType,
  UpdateArea,
  ValidationErrorsDto
} from '@shared/_models';
import { DevelopmentItemService } from '@shared/_services/development-item.service';
import { ItemDetailsSectionComponent } from '@shared/_components/item/item-details/item-details-section/item-details-section.component';
import { ItemDetailsGridSectionComponent } from '@shared/_components/item/item-details/item-details-grid-section/item-details-grid-section.component';
import { ConditionalPrefixPipe, EDITING_PREFIX } from '@shared/pipes';
import { PepseqFormGroupComponent } from '@shared/_components/item/pepseq-form-group/pepseq-form-group.component';
import { toFields } from '@shared/dto-adapters/field';
import { ComplexField, isPartOfChemicalStructure, isPartOfPepseq, toComplexFields, toUpdateFactory } from '@app/domain/field';
import { not, or } from '@shared/_helpers/fp';
import { trackById } from '@shared/_helpers';
import { ItemDetailsLoadingCalculationsComponent } from '@shared/_components/item/item-details/item-details-loading-calculations/item-details-loading-calculations.component';
import { submitted } from '@shared/dynamic-form/components/dynamic-field/store';
import { ItemDetailsFieldsBaseComponent } from '@shared/_components/item/item-details/item-details-fields-base/item-details-fields-base.component';
import { ItemDetailsTableSectionComponent } from '@shared/_components/item/item-details/item-details-table-section/item-details-table-section.component';
import { FieldKind, FieldViewData, toFieldsViewData } from '@app/domain/field/to-fields-view-data';
import { EmptyAreaContentComponent } from '@shared/_components/empty-area-content/empty-area-content.component';
import { comparePositions } from '@app/domain/shared';

@Component({
  selector: 'app-item-details-parameters',
  standalone: true,
  imports: [
    AsyncPipe,
    TitleCasePipe,
    NgIf,
    NgFor,
    NgClass,
    ItemDetailsSectionComponent,
    ItemDetailsGridSectionComponent,
    PepseqFormGroupComponent,
    ConditionalPrefixPipe,
    ItemDetailsLoadingCalculationsComponent,
    ItemDetailsTableSectionComponent,
    EmptyAreaContentComponent
  ],
  templateUrl: './item-details-parameters.component.html',
  styleUrls: ['./item-details-parameters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsParametersComponent extends ItemDetailsFieldsBaseComponent implements OnInit {
  @Input() set developmentItem(value: DevelopmentItem) {
    this.#onDevelopmentItemChange(value);
  }
  #developmentItem: WritableSignal<DevelopmentItem> = signal(null);

  // used only in template views, no developmentItem(.template) available there
  @Input() set template(value: CategoryTemplate) {
    this.#onTemplateChange(value);
  }
  templateSignal: WritableSignal<CategoryTemplate> = signal(null);

  @Input() set sectionType(value: SectionType) {
    this.#onSectionTypeChange(value);
  }
  sectionTypeSignal: WritableSignal<SectionType> = signal(null);

  @Input() set errorMessage(value: ErrorMessageSection) {
    this.#onErrorMessageChange(value);
  }
  get errorMessage(): ErrorMessageSection {
    return this.#errorMessage;
  }
  #errorMessage: ErrorMessageSection;

  @Input() set sectionId(value: string) {
    this.#onSectionIdChange(value);
  }
  sectionIdSignal: WritableSignal<string> = signal(null);

  @Input() pendingArea: UpdateArea;

  @Output() update = new EventEmitter<FieldValueDto[]>();
  @Output() sectionIdChange = new EventEmitter<string>();

  mode: WritableSignal<FieldsViewMode> = signal(FieldsViewMode.PREVIEW);
  initialParameters: Signal<Field[]> = computed(() => {
    const section = this.templateSignal()?.sections?.find(section => section.id === this.sectionIdSignal());

    if (!section) return [];

    const fieldTemplateIds = section.fields?.map(field => field.id) ?? [];
    const fieldValues: FieldValueDto[] =
      this.#developmentItem()?.field_values.filter(fieldValue => fieldTemplateIds.includes(fieldValue.field_template_id)) ?? [];

    return toFields(section.fields, fieldValues);
  });
  sections: Signal<SectionDto[]> = computed(() =>
    this.templateSignal()
      ?.sections?.filter(section => section.type === this.sectionTypeSignal())
      ?.sort(comparePositions)
  );
  parametersDataToSend: WritableSignal<Field[]> = signal([]);
  pepseqErrors: WritableSignal<ValidationErrorsDto> = signal(null);
  #complexFields: Signal<ComplexField[]> = computed(() =>
    toComplexFields(this.parametersDataToSend(), this.mode() === FieldsViewMode.PREVIEW)
  );
  areComplexFieldsPresent: Signal<boolean> = computed(() => this.#complexFields().length > 0);
  pepseqGroups: Signal<ComplexField[]> = computed(() =>
    this.#complexFields()?.filter(complexField => complexField.parent.type === DisplayFieldType.PEPSEQ)
  );
  chemicalStructureGroups: Signal<ComplexField[]> = computed(() =>
    this.#complexFields()?.filter(complexField => complexField.parent.type === DisplayFieldType.SMILES)
  );
  nonPepseqParameters: Signal<Field[]> = computed(() =>
    this.parametersDataToSend()?.filter(not(or(isPartOfChemicalStructure, isPartOfPepseq)))
  );

  fieldsViewData: Signal<FieldViewData[]> = computed(() => toFieldsViewData(this.parametersDataToSend()));

  anyParameterHasAValue: Signal<boolean> = computed(() => this.parametersDataToSend()?.some(param => param.value));
  anyNonPepseqParametersHasValue: Signal<boolean> = computed(() => this.nonPepseqParameters()?.some(param => param.value));
  anyFieldViewDataHasValue: Signal<boolean> = computed(() =>
    this.fieldsViewData()?.some(data => {
      if (data.type === FieldKind.NON_COMPLEX) {
        return data.field.value;
      }

      if (data.type === FieldKind.COMPLEX) {
        return data.field.parent.value;
      }
    })
  );
  showItemDetailsGridSection: Signal<boolean> = computed(() => {
    const paramsAreRoParams =
      this.developmentType() === DevelopmentType.researchObject && this.sectionTypeSignal() === SectionType.PARAMETERS;
    const paramsAreExpResults = this.developmentType() === DevelopmentType.experiment && this.sectionTypeSignal() === SectionType.RESULTS;

    return (
      (paramsAreRoParams || paramsAreExpResults) &&
      (this.anyFieldViewDataHasValue() || [FieldsViewMode.EDIT, FieldsViewMode.TEMPLATE_PREVIEW].includes(this.mode()))
    );
  });
  showItemDetailsTableSection: Signal<boolean> = computed(() => {
    const paramsAreExpParams = this.developmentType() === DevelopmentType.experiment && this.sectionTypeSignal() === SectionType.PARAMETERS;

    return (
      paramsAreExpParams &&
      (this.anyNonPepseqParametersHasValue() || [FieldsViewMode.EDIT, FieldsViewMode.TEMPLATE_PREVIEW].includes(this.mode()))
    );
  });
  showAddPlaceholder: Signal<boolean> = computed(() => {
    if (this.mode() === FieldsViewMode.PREVIEW) return !this.anyParameterHasAValue();

    if (this.mode() === FieldsViewMode.TEMPLATE_PREVIEW) return !this.initialParameters().length;

    return false;
  });
  developmentType: Signal<DevelopmentType> = computed(() => this.templateSignal()?.category?.development_type);
  activeArea = computed(() => this.templateSignal()?.active_section_types?.includes(this.sectionTypeSignal()));
  hiddenArea = computed(() => this.templateSignal()?.hidden_section_types?.includes(this.sectionTypeSignal()));
  displaySections: Signal<SectionDto[]> = computed(() =>
    this.activeArea()
      ? this.sections().filter(section => (this.mode() === FieldsViewMode.EDIT ? section.id === this.sectionIdSignal() : true))
      : []
  );
  currentSectionName: Signal<string> = computed(() =>
    this.activeArea() ? this.sections()?.find(section => section.id === this.sectionIdSignal())?.name : upperFirst(this.sectionTypeSignal())
  );
  destroyRef = inject(DestroyRef);

  readonly #toUpdate = toUpdateFactory();
  readonly FieldsViewMode = FieldsViewMode;
  readonly trackById = trackById;
  readonly EDITING_PREFIX = EDITING_PREFIX;

  constructor(private readonly developmentItemService: DevelopmentItemService) {
    super();
  }

  ngOnInit() {
    // TODO: what is this.. add some explanation in comment or refacor
    this.developmentItemService.sectionEditCancelEvent$.subscribe(() => {
      if (this.mode() === FieldsViewMode.EDIT) {
        this.cancel();
      }
    });
    this.developmentItemService.sectionEditSaveEvent$.subscribe(() => {
      if (this.mode() === FieldsViewMode.EDIT) {
        this.onUpdate();
      }
    });
  }

  edit() {
    if (!this.editable() || this.initialParameters().length === 0 || this.mode() === FieldsViewMode.TEMPLATE_PREVIEW) return;

    this.errorMessage = { errorResponse: false, section: null };
    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
    } else {
      this.mode.set(FieldsViewMode.EDIT);
      this.developmentItemService.setEditActiveSectionName(this.currentSectionName());
    }

    this.parametersDataToSend.set(this.initialParameters().map(parameter => ({ ...parameter })));
    this.#resetPepseqErrors();
  }

  onUpdate() {
    submitted.set(true);

    if (this.areErrors()) return;

    this.update.emit(this.#toUpdate(this.initialParameters(), this.parametersDataToSend()));
  }

  cancel() {
    this.parametersDataToSend.set(this.initialParameters());
    this.#resetPepseqErrors();
    this.mode.set(getDefaultFieldsViewMode(this.#developmentItem()));
    this.errorMessage = { errorResponse: false, section: null };
  }

  changeTab(tabId: string) {
    this.sectionIdChange.emit(tabId);
  }

  #onSectionIdChange(value: string) {
    this.sectionIdSignal.set(value);
    this.#setParametersDataForSelectedTab();
  }

  #setParametersDataForSelectedTab() {
    this.parametersDataToSend.set(this.initialParameters());
    this.#resetPepseqErrors();
  }

  #resetPepseqErrors() {
    this.pepseqErrors.set({
      validation_errors: []
    });
  }

  #updatePepseqErrors(errorDetails: ValidationErrorsDto) {
    if (!Array.isArray(errorDetails.validation_errors)) return;

    this.pepseqErrors.set(errorDetails);
  }

  #onDevelopmentItemChange(value: DevelopmentItem) {
    this.#developmentItem.set(value);
    this.templateSignal.set(value.template);
    this.#initialize();
  }

  #onTemplateChange(value: CategoryTemplate) {
    this.templateSignal.set(value);

    // template Input is used only in template-preview view
    if (value) this.mode.set(FieldsViewMode.TEMPLATE_PREVIEW);

    this.#initialize();
  }

  #onSectionTypeChange(value: SectionType) {
    this.sectionTypeSignal.set(value);
    this.#initialize();
  }

  #initialize() {
    this.mode.set(getDefaultFieldsViewMode(this.#developmentItem()));

    if (!(this.sectionTypeSignal() && this.templateSignal())) return;

    this.#setParametersDataForSelectedTab();
  }

  #onErrorMessageChange(value: ErrorMessageSection) {
    if (value === this.#errorMessage) return;

    this.#errorMessage = value;

    if (!value?.errorResponse) return;

    if (typeof value.errorResponse === 'boolean') return;

    this.#updatePepseqErrors(value.errorResponse.error);
  }
}

function getDefaultFieldsViewMode(developmentItem: DevelopmentItem): FieldsViewMode {
  return developmentItem ? FieldsViewMode.PREVIEW : FieldsViewMode.TEMPLATE_PREVIEW;
}
