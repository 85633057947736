import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import saveAs from 'file-saver';

import { FileDto, ImageDto } from 'src/app/shared/_models';
import { EnvironmentService } from '@shared/_services/environment.service';

// TODO remove this service, replace with helper functions
@Injectable({
  providedIn: 'root'
})
export class FileService {
  private readonly detailsModalImageSource = new Subject<ImageDto>();

  readonly detailsModalImage$ = this.detailsModalImageSource.asObservable();

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly environmentService: EnvironmentService
  ) {}

  emitImageForDetailsModal(image: ImageDto): void {
    this.detailsModalImageSource.next(image);
  }

  getFileNameMeta(file: Partial<FileDto>): { [key: string]: string } {
    const fileName = file?.name;

    if (!fileName) {
      return { name: '', ext: '' };
    }

    const ext: string = this.extractFileExtensionFromFileName(fileName);

    return { name: fileName, ext };
  }

  extractFileExtensionFromFileName(fileName: string): string {
    return fileName?.substring(fileName?.lastIndexOf('.') + 1);
  }

  getFileExtension(file: Partial<FileDto>): string {
    return this.getFileNameMeta(file).ext?.toLowerCase();
  }

  getFileSafeUrl(filePath: string): SafeUrl {
    const sanitizedFilePath = this.sanitizer.sanitize(SecurityContext.URL, filePath);
    const fileUrl = `${this.environmentService.environment.filesUrl}${sanitizedFilePath}`;

    return fileUrl;
  }

  getFileSafeStyleUrl(imagePath: string): string {
    const sanitizedImagePath = this.sanitizer.sanitize(SecurityContext.URL, imagePath);
    const fileUrl = `${this.environmentService.environment.filesUrl}${sanitizedImagePath}`;

    return `url('${fileUrl}')`;
  }

  // TODO: we have to migrate to using jest instead of jasmine to move this method
  // to function with possibility to mock it
  saveFile(blob: Blob, fileName: string): void {
    saveAs(blob, fileName);
  }
}
