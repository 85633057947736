<ng-container *ngFor="let toast of toasts; let i = index; trackBy: trackByIndex">
  <div class="toast" *ngIf="toast">
    <div>
      <img class="toast-type-icon" alt="toast-type" src="{{ '../../../../../assets/icons/toast_icon_' + toast.options.type + '.png' }}" />
    </div>
    <div class="toast__content">
      <div class="toast__header">{{ toast.options.header }}</div>
      <div class="toast__body">{{ toast.text }}</div>
    </div>
    <div>
      <button class="toast__close-icon" (click)="close(i)"></button>
    </div>
  </div>
</ng-container>
