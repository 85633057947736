<app-generic-modal
  [acceptButton]="{ text: 'Close' }"
  [developmentType]="developmentType"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  (close)="close.emit()"
>
  <ng-template #headerTemplate>
    <app-modal-header-center imgSrc="toast_icon_danger.png" header="To be implemented"></app-modal-header-center>
  </ng-template>
</app-generic-modal>
