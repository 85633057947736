import { CategoryTree } from '@shared/_models';

export function findCategoryTreeItemById(categoryTree: CategoryTree, id: string): CategoryTree | null {
  if (categoryTree.id === id) {
    return categoryTree;
  }

  if (categoryTree.children) {
    for (const child of categoryTree.children) {
      const found = findCategoryTreeItemById(child, id);
      if (found) {
        return found;
      }
    }
  }

  return null;
}
