<div class="input-group flex-nowrap">
  <div class="date-input__wrapper">
    <app-icon class="me-1" width="1.1rem" height="1.1rem" fileName="field_date_icon.svg" [color]="IconColors.darkGrey"></app-icon>
    <input
      [ngModel]="datepickerValue()"
      [ngClass]="{ 'is-invalid': markAsInvalid }"
      (ngModelChange)="onNgModelChange($event)"
      (click)="datepicker.toggle()"
      (blur)="onBlur.emit()"
      readonly
      ngbDatepicker
      #datepicker="ngbDatepicker"
      placeholder="DD.MM.YYYY"
      class="form-control datepicker-input"
    />
    <button [disabled]="control?.disabled" class="btn btn-clear" (click)="clear()" type="button">
      <i class="fa fa-close"></i>
    </button>
  </div>
</div>
