import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slice',
  standalone: true,
})
export class SlicePipe implements PipeTransform {
  transform<T>(arr: T[], [begin, end]: [number, number]): T[] {
    if (!begin || !end) {
      return arr;
    }

    return arr.slice(begin, end);
  }
}
