import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import { ViewMode } from 'src/app/shared/_models';

@Component({
  selector: 'app-list-selection-actions-bar',
  standalone: true,
  imports: [NgClass, NgIf],
  templateUrl: './list-selection-actions-bar.component.html',
  styleUrls: ['./list-selection-actions-bar.component.scss']
})
export class ListSelectionActionsBarComponent implements OnInit {
  readonly ViewMode = ViewMode;
  @Input() selectedItemsAmount: number = 0;
  @Input() viewMode: ViewMode;
  @Input() generatingFile: boolean = false;
  @Input() currentCategoryHasChildren: boolean;
  @Input() editable: boolean;

  @Output() selectAllOnCurrentPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() unselectAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() import: EventEmitter<void> = new EventEmitter<void>();
  @Output() exportSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() showSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() showAll: EventEmitter<void> = new EventEmitter<void>();
  onlySelectedShowed = false;

  constructor() {}

  ngOnInit(): void {}

  onSelectAllOnCurrentPage() {
    this.selectAllOnCurrentPage.emit();
  }

  onImport($event) {
    this.import.emit();
  }

  onExportSelected($event) {
    this.exportSelected.emit();
  }

  onDeleteSelected($event) {
    this.deleteSelected.emit();
  }

  onSelectAll() {
    this.selectAll.emit();
  }

  onUnselectAll() {
    this.unselectAll.emit();
  }

  onShowSelectedBtnClick() {
    if (this.onlySelectedShowed === true) {
      this.showAll.emit();
    } else {
      this.showSelected.emit();
    }
    this.onlySelectedShowed = !this.onlySelectedShowed;
  }
}
