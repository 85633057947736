import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DevelopmentType } from '@shared/_models';

export function developmentTypeResolver(): Observable<DevelopmentType> {
  const activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  return activatedRoute.data.pipe(map(data => data.developmentType));
}
