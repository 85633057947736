import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DevelopmentType } from '@app/shared/_models';

@Component({
  selector: 'app-item-details-link',
  standalone: true,
  imports: [NgIf, FormsModule],
  templateUrl: './item-details-link.component.html',
  styleUrls: ['./item-details-link.component.scss'],
})
export class ItemDetailsLinkComponent {
  @Input() link: string;
  @Input() developmentType: DevelopmentType;
  @Output() linkChange = new EventEmitter<string>();
  @ViewChild('urlInput', { static: false }) urlInput: ElementRef;

  constructor() {}

  selectUrl($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.urlInput.nativeElement.select();
  }
}
