<div class="loading-calculations-section" *ngIf="(loadingCalculations$ | async) || (loadingCalculationsComplete$ | async)">
  <div class="loading-calculations-info" *ngIf="loadingCalculations$ | async" data-testid="calculating-data">
    <img src="../../../../../../assets/icons/loading_dots_animation.gif" alt="loading dots animation" />
    <div class="calculations-header">Calculating Data</div>
    <div class="calculations-info">Some data are calculating please wait</div>
  </div>
  <div class="loading-calculations-info" *ngIf="loadingCalculationsComplete$ | async" data-testid="calculated-data">
    <img src="../../../../../../assets/icons/loading_dots_complete_icon.png" alt="loading dots complete icon" />
    <div class="calculations-header">Calculating Data Complete</div>
    <div class="calculations-info">Data is correctly calculated</div>
  </div>
</div>
