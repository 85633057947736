import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, of } from 'rxjs';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';

import { DevelopmentItemLinkedFilesShortDto, LinkedFileDto, DevelopmentType, DevelopmentItem, DevelopmentItemDto } from '@shared/_models';
import { FileApiService } from '@shared/_services/file/file-api.service';
import { notifyAboutError } from '@shared/_root-store/app-store/app.actions';
import { ExperimentsApiService } from '@app/shared/_services/experiment';
import { ResearchObjectsApiService } from '@app/shared/_services/research-object';

interface FileSharingModalState {
  file: LinkedFileDto;
  linkedToDevelopmentItems: DevelopmentItemLinkedFilesShortDto[];
  pending: boolean;
}

export const defaultFileSharingModalState: FileSharingModalState = {
  file: undefined,
  linkedToDevelopmentItems: undefined,
  pending: false
};

@Injectable()
export class FileSharingModalComponentStore extends ComponentStore<FileSharingModalState> {
  #onLastUnlinked: () => void;

  constructor(
    private fileApiService: FileApiService,
    private researchObjectsApiService: ResearchObjectsApiService,
    private experimentsApiService: ExperimentsApiService,
    private store: Store
  ) {
    super(defaultFileSharingModalState);
  }

  setup(file: LinkedFileDto, onLastUnlinked?: () => void) {
    this.setFile(file);

    if (onLastUnlinked) {
      this.#onLastUnlinked = onLastUnlinked;
    }
  }

  readonly file$ = this.select(state => state.file);
  readonly linkedToDevelopmentItems$ = this.select(state => state.linkedToDevelopmentItems);
  readonly pending$ = this.select(state => state.pending);

  readonly setFile = this.updater((state, file: LinkedFileDto) => ({ ...state, file }));
  readonly setLinkedToDevelopmentItems = this.updater((state, linkedToDevelopmentItems: DevelopmentItemLinkedFilesShortDto[]) => ({
    ...state,
    linkedToDevelopmentItems
  }));
  readonly setPending = this.updater((state, pending: boolean) => ({ ...state, pending }));

  readonly loadLinkedToDevelopmentItems = this.effect((data$: Observable<void>) => {
    return data$.pipe(
      tap(() => this.setPending(true)),
      withLatestFrom(this.file$),
      switchMap(([_, file]) => {
        return this.fileApiService.getSharedDevelopmentItems(file.id).pipe(
          tap((response: DevelopmentItemLinkedFilesShortDto[]) => {
            this.setLinkedToDevelopmentItems(response);
            this.setPending(false);
          }),
          catchError(() => {
            this.setPending(false);
            this.store.dispatch(notifyAboutError({}));

            return EMPTY;
          })
        );
      })
    );
  });

  readonly link = this.effect((data$: Observable<DevelopmentItemLinkedFilesShortDto>) =>
    data$.pipe(
      withLatestFrom(this.file$, this.linkedToDevelopmentItems$),
      tap(
        ([developmentItem, file, linkedToDevelopmentItems]: [
          DevelopmentItemLinkedFilesShortDto,
          LinkedFileDto,
          DevelopmentItemLinkedFilesShortDto[]
        ]) => {
          this.#updateDevelopmentItem({
            developmentItem: { ...developmentItem, files_ids: developmentItem.files_ids.concat(file.id) },
            onSuccess: () => {
              this.setLinkedToDevelopmentItems(linkedToDevelopmentItems.concat(developmentItem));
              this.setFile({
                ...file,
                count_research_objects:
                  developmentItem.development_type === DevelopmentType.researchObject
                    ? file.count_research_objects + 1
                    : file.count_research_objects,
                count_experiments:
                  developmentItem.development_type === DevelopmentType.experiment ? file.count_experiments + 1 : file.count_experiments
              });
            }
          });
        }
      )
    )
  );

  readonly unlink = this.effect((data$: Observable<DevelopmentItemLinkedFilesShortDto>) =>
    data$.pipe(
      withLatestFrom(this.file$, this.linkedToDevelopmentItems$),
      tap(
        ([developmentItem, file, linkedToDevelopmentItems]: [
          DevelopmentItemLinkedFilesShortDto,
          LinkedFileDto,
          DevelopmentItemLinkedFilesShortDto[]
        ]) => {
          this.#updateDevelopmentItem({
            developmentItem: { ...developmentItem, files_ids: developmentItem.files_ids.filter(fileId => fileId !== file.id) },
            onSuccess: () => {
              this.setLinkedToDevelopmentItems(linkedToDevelopmentItems.filter(item => item.id !== developmentItem.id));
              this.setFile({
                ...file,
                count_research_objects:
                  developmentItem.development_type === DevelopmentType.researchObject
                    ? file.count_research_objects - 1
                    : file.count_research_objects,
                count_experiments:
                  developmentItem.development_type === DevelopmentType.experiment ? file.count_experiments - 1 : file.count_experiments
              });

              if (linkedToDevelopmentItems.length === 1) {
                this.#onLastUnlinked();
              }
            }
          });
        }
      )
    )
  );

  readonly #updateDevelopmentItem = this.effect(
    (data$: Observable<{ developmentItem: DevelopmentItemLinkedFilesShortDto; onSuccess: () => void }>) =>
      data$.pipe(
        switchMap(({ developmentItem, onSuccess }) => {
          const getUpdateObservable = (): Observable<DevelopmentItemDto> => {
            if (developmentItem.development_type === DevelopmentType.researchObject) {
              return this.researchObjectsApiService.update(developmentItem.id, { files_ids: developmentItem.files_ids });
            }

            return this.experimentsApiService.update(developmentItem.id, { files_ids: developmentItem.files_ids });
          };

          return getUpdateObservable().pipe(
            tap(() => {
              onSuccess();
            }),
            catchError(() => {
              this.store.dispatch(notifyAboutError({}));

              return EMPTY;
            })
          );
        })
      )
  );
}
