<div class="development-item" [class.development-item--even]="index() % 2 == 0">
  <span class="development-item__badge development-item__badge--{{ developmentItem().development_type }}">
    <span
      class="development-item__badge__text"
      [ngbTooltip]="developmentItem().oid"
      appOverflowContentPluginToNgbTooltip
      defaultTooltipValue="Name"
      >{{ developmentItem().oid }}</span
    >
  </span>
  <div class="development-item__info">
    <span
      class="development-item__info__name"
      [ngbTooltip]="developmentItem().name"
      appOverflowContentPluginToNgbTooltip
      defaultTooltipValue="Name"
      >{{ developmentItem().name }}</span
    >
    <span class="development-item__info__category-name">{{ developmentItem().category_name }}</span>
  </div>
  <app-icon
    class="remove-development-item-icon"
    width="1.325rem"
    height="1.388rem"
    fileName="trash_icon.svg"
    [color]="IconColors.darkGrey"
    (click)="openUnlinkDevelopmentTypeConfirmationModal()"
  ></app-icon>
  <button
    class="development-item__item-btn development-item__item-btn--{{ developmentItem().development_type }}"
    (click)="goToDevelopmentItemFiles()"
  >
    <img src="./../../../../../assets/icons/chevron_3_icon.svg" class="development-item__item-btn-icon" />
  </button>
</div>
<ng-template #unlinkDevelopmentTypeConfirmationModal let-modal>
  <app-generic-modal
    [closeButton]="{ text: 'No, I want to keep it' }"
    [acceptButton]="{ text: 'Yes, remove it' }"
    [developmentType]="DevelopmentType.researchObject"
    [primary]="true"
    [hideHeaderCloseIcon]="true"
    (close)="close($event)"
  >
    <ng-template #headerTemplate>
      <ng-template [ngTemplateOutlet]="modalCenterHeaderTemplate"></ng-template>
    </ng-template>
    <div class="info-modal__body">
      <span class="info-modal__body-content">
        When you
        <span class="content-highlight">remove an object from the list, it will no longer have access to the file.</span>
        Sharing with this object will be disabled and the
        <span class="content-highlight">file will not be visible in its file list.</span>
        {{ developmentItemsLength() === 1 ? 'This file will be deleted because it is the last place it is shared' : '' }}
      </span>
    </div>
  </app-generic-modal>
</ng-template>
<ng-template #modalCenterHeaderTemplate>
  <app-modal-header-center
    imgSrc="toast_icon_warning.png"
    header="Are you sure you want to stop sharing file with this {{ developmentItem().development_type.replace('-', ' ') }}?"
  ></app-modal-header-center>
</ng-template>
