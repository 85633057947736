import { Injectable, Signal, computed } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { ProjectDto, DevelopmentType } from '@shared/_models';
import { loadProjects } from './projects.actions';
import { selectProjects, selectProjectsLoaded, selectProjectsLoading, selectSelectedProject } from './projects.selectors';

@Injectable({
  providedIn: 'root'
})
export class ProjectsStoreService {
  readonly projects$ = this.store.select(selectProjects);
  readonly projectsLoading$ = this.store.select(selectProjectsLoading);
  readonly projectsLoaded$ = this.store.select(selectProjectsLoaded);
  readonly selectedProject$: Observable<ProjectDto> = this.store.select(selectSelectedProject);
  readonly selectedProject: Signal<ProjectDto> = toSignal(this.selectedProject$);
  readonly selectedProjectId = computed<string>(() => this.selectedProject()?.id);
  readonly hiddenResearchObjects = computed<boolean>(
    () => !!this.selectedProject()?.hidden_development_types?.includes(DevelopmentType.researchObject)
  );
  readonly hiddenExperiments = computed<boolean>(
    () => !!this.selectedProject()?.hidden_development_types?.includes(DevelopmentType.experiment)
  );

  constructor(private readonly store: Store) {}

  loadProjects(workspaceId: string) {
    this.store.dispatch(loadProjects({ workspaceId }));
  }
}
