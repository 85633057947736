import { Directive, Input, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { toObservable } from '@angular/core/rxjs-interop';

import { DevelopmentItemDto, DevelopmentType, ImageDto, Image } from 'src/app/shared/_models';
import { selectContentByImageDto } from '@shared/_root-store/images-store/images.selectors';
import { ImageSize } from '@app/domain/image';

@Directive()
export class ItemImageDirective {
  readonly DevelopmentType = DevelopmentType;
  @Input() developmentType!: DevelopmentType;
  @Input() categoryName: string;

  @Input() set developmentItem(value: DevelopmentItemDto) {
    this.#developmentItem = value;
    this.#onDevelopmentItemChange(value);
  }
  get developmentItem(): DevelopmentItemDto {
    return this.#developmentItem;
  }
  #developmentItem: DevelopmentItemDto;

  // async pipe doesn't work with Subject here for some weird reason ( lost almost whole day on it ... )
  // works with BehaviorSubject and signals
  protected mainImage = signal<ImageDto>(null);

  mainImage$: Observable<Image> = toObservable(this.mainImage).pipe(
    switchMap(mainImage =>
      this.store
        .select(selectContentByImageDto(mainImage, ImageSize.SMALL))
        .pipe(map(content => (mainImage ? { ...mainImage, content } : null)))
    )
  );

  mainMediumImage$: Observable<Image> = toObservable(this.mainImage).pipe(
    switchMap(mainImage =>
      this.store
        .select(selectContentByImageDto(mainImage, ImageSize.MEDIUM))
        .pipe(map(content => (mainImage ? { ...mainImage, content } : null)))
    )
  );

  mainLargeImage$: Observable<Image> = toObservable(this.mainImage).pipe(
    switchMap(mainImage =>
      this.store
        .select(selectContentByImageDto(mainImage, ImageSize.LARGE))
        .pipe(map(content => (mainImage ? { ...mainImage, content } : null)))
    )
  );

  mainImageBase64$: Observable<string> = this.mainImage$.pipe(map(mainImage => mainImage?.content?.base64));
  mainMediumImageBase64$: Observable<string> = this.mainMediumImage$.pipe(map(mainImage => mainImage?.content?.base64));

  constructor(protected readonly store: Store) {}

  #onDevelopmentItemChange(value: DevelopmentItemDto) {
    const mainImage = value?.images?.find(image => image.id === value.main_image_id);
    this.mainImage.set(mainImage);
  }
}
