<div class="items-sort" *ngIf="viewMode == ViewMode.grid" [class.items-sort--dropdownOpened]="isDropdownOpened">
  <div class="items-sort__dropdown-container pt-1">
    <div ngbDropdown (openChange)="isDropdownOpened = $event" class="d-inline-block">
      <div class="d-flex align-items-center" ngbDropdownToggle>
        <span data-testid="list-sort-dropdown-button" class="items-sort__label me-1" *ngIf="sortState?.isDefault">Sort By</span>
        <a data-testid="list-sort-selected-dropdown-button" *ngIf="!sortState?.isDefault" class="items-sort__selector" role="button">
          {{ sortState?.label }}
        </a>
        <span data-test="orderSort" alt="chevron_down_icon" class="items-sort__chevron-icon"></span>
      </div>

      <div ngbDropdownMenu class="dropdown-menu">
        <a
          data-testid="list-sort-option"
          ngbDropdownItem
          [ngClass]="{
            'items-sort__active-sort-option':
              sortOption.prop === sortState?.prop && sortOption.order === sortState?.order && !sortState?.isDefault
          }"
          class="items-sort__sort-option"
          *ngFor="let sortOption of sortOptionsConfig"
          (click)="setSort(sortOption)"
        >
          {{ sortOption.label }}
          <span
            *ngIf="sortOption.prop === sortState?.prop && sortOption.order === sortState?.order && !sortState?.isDefault"
            class="items-sort__active-sort-option__icon"
          ></span>
        </a>
      </div>
    </div>
  </div>
</div>
