import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';

import { DevelopmentType, ISortOption, ViewMode, ViewContainerDataType } from '@shared/_models';

@Component({
  selector: 'app-view-container',
  templateUrl: './view-container.component.html',
  styleUrls: ['./view-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContainerComponent implements OnInit {
  readonly ViewMode = ViewMode;
  currentViewMode$!: Observable<ViewMode>;
  @Input() developmentType!: DevelopmentType;
  @Input() listData: ViewContainerDataType[];
  @Input() listDataLoaded: boolean;
  @Input() sort: ISortOption;
  @Output() sortChange: EventEmitter<ISortOption> = new EventEmitter<ISortOption>();

  DevelopmentType = DevelopmentType;
  constructor(private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.currentViewMode$ = combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams]).pipe(
      map(([params, queryParams]: [Params, Params]) => (queryParams.instances ? ViewMode.instances : params.viewMode))
    );
  }
}
