import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthAction } from '@app/_auth/auth-layout/auth-layout/auth-layout.component';

@Component({
  selector: 'app-signup-layout',
  templateUrl: './signup-layout.component.html',
  styleUrls: ['./signup-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupLayoutComponent {
  readonly AuthAction = AuthAction;
}
