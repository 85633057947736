import { Dictionary } from '@ngrx/entity';

import { CategoryDto, CategoryTemplate, CategoryTemplateDto } from '@shared/_models';

export function getTemplateFactory(categories: Dictionary<CategoryDto>, templates: Dictionary<CategoryTemplateDto>) {
  return (templateId: string): CategoryTemplate => {
    const template: CategoryTemplateDto = templates[templateId];

    if (!template) return;

    const category: CategoryDto = categories[template.category_id];

    if (!category) return;

    return { ...template, category } as CategoryTemplate;
  };
}
