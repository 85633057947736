import { inject, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { DisplayFieldType, Field, FieldValueDto } from '@shared/_models';
import { toFieldValueDto } from '@shared/dto-adapters/field';

export function toUpdateFactory() {
  const domSanitizer = inject(DomSanitizer);

  // When user removes parameter or removes it's value then to clear this valus on BE
  // this parameter must be sent with null value
  return (initialFields: Field[], fields: Field[]): FieldValueDto[] => {
    return initialFields
      .filter(initialField => !initialField.value_from_id)
      .map(initialField => {
        const getMatch = (): Field => {
          const match = fields.find(field => field.field_template_id === initialField.field_template_id);

          if (match?.type === DisplayFieldType.URL)
            return {
              ...match,
              value: domSanitizer.sanitize(SecurityContext.URL, match.value),
            };

          return match;
        };
        const match = getMatch();

        if (!match) {
          return initialField.value
            ? {
                ...toFieldValueDto(initialField),
                value: null,
              }
            : null;
        }

        return match.value
          ? toFieldValueDto(match)
          : initialField.value
          ? {
              ...toFieldValueDto(initialField),
              value: null,
            }
          : null;
      })
      .filter(Boolean);
  };
}
