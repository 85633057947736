import { SortOrderTypes } from './sort';

const pagedResponseDefault = {
  documents: {
    ids: [],
    entities: {},
  },
  pagination: {
    limit: 0,
    page: 0,
    pageCount: 0,
  },
  sort: {
    order: SortOrderTypes.ASCENDING,
    prop: 'id',
  },
};

Object.freeze(pagedResponseDefault);
Object.freeze(pagedResponseDefault.documents);
Object.freeze(pagedResponseDefault.pagination);
Object.freeze(pagedResponseDefault.sort);

export { pagedResponseDefault };
