import { Pipe, PipeTransform } from '@angular/core';

import { FileCategory } from '@shared/_modules/file/models/file-category';
import { DevelopmentType } from '@shared/_models';

@Pipe({
  name: 'filesModalIconCSSClass',
  standalone: true
})
export class FilesModalIconCSSClassPipe implements PipeTransform {
  transform(fileCategory: FileCategory, developmentType: DevelopmentType): string {
    const isResearchObject = developmentType === DevelopmentType.researchObject;
    const isExperiment = developmentType === DevelopmentType.experiment;

    switch (true) {
      case fileCategory === FileCategory.files && isResearchObject:
        return 'lg-files-modal__file-icon--ro';
      case fileCategory === FileCategory.files && isExperiment:
        return 'lg-files-modal__file-icon--exp';
      case fileCategory === FileCategory.images && isResearchObject:
        return 'lg-files-modal__image-icon--ro';
      case fileCategory === FileCategory.images && isExperiment:
        return 'lg-files-modal__image-icon--exp';
    }
  }
}
