<div data-testid="file-card" class="d-flex image-card">
  <app-image-thumb class="me-2" [gradientBorder]="isMain" [developmentType]="developmentType" [image]="image"></app-image-thumb>
  <div class="d-flex flex-column overflow-hidden flex-fill">
    <div class="image-card__top-container">
      <!-- TODO: przywrócić pipe developmentTypeCSSClass poniżej do | developmentType po tym jak uda się ją zaimportować do file module-->
      <span *ngIf="isMain" class="image-card__main-image-label" [ngClass]="developmentType | developmentTypeCSSClass">main image</span>
    </div>
    <div class="overflow-ellipsis image-card__image-name">{{ image?.name }}</div>
    <div class="d-flex justify-content-between image-card__meta-data-container">
      <div class="image-card__image-size">{{ image?.size | formatFileSize }}</div>
      <div class="image-card__image-type">{{ image?.type | mimeSubtype }}</div>
    </div>
    <div>
      <button
        data-testid="image-card-actions-button"
        class="image-card__action-btn"
        [ngbPopover]="popoverContent"
        [popoverTitle]="popoverTitle"
        triggers="click"
        popoverClass="lg-popover"
        placement="bottom"
      >
        actions
      </button>
    </div>
  </div>
</div>

<ng-template #popoverTitle><span class="text-uppercase">actions</span></ng-template>
<ng-template #popoverContent>
  <div class="mb-1">
    <button data-testid="img-show-more-details-button" (click)="onShowMoreDetailsBtnClick()" class="image-card__show-more-details-btn">
      Show more details
    </button>
  </div>
  <div>
    <button
      data-testid="img-set-as-main-image-button"
      (click)="onSetAsMainImageClick()"
      [disabled]="isMain"
      class="image-card__main-img-btn"
    >
      Set as main image
    </button>
  </div>
  <hr divider />
  <button data-testid="img-delete-button" class="image-card__delete-img-btn" (click)="onDeleteImageClick()">Delete image</button>
</ng-template>
