import { NgModule } from '@angular/core';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';

import { CustomSerializer } from './router-serializer';

export const ROUTER_STATE_KEY = 'router';

@NgModule({
  imports: [
    StoreRouterConnectingModule.forRoot({
      stateKey: ROUTER_STATE_KEY,
      routerState: RouterState.Minimal,
      serializer: CustomSerializer
    })
  ]
})
export class RouterStoreModule {}
