import { Injectable } from '@angular/core';

@Injectable()
export class PasswordStrengthMeterService {
  score(password: string): number {
    // *  0 - too guessable
    //  *  1 - very guessable
    //  *  2 - somewhat guessable
    //  *  3 - safely unguessable
    //  *  4 - very unguessable
    // TODO - return score 0 - 4 based on password
    const veryUnguessable = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    const safelyUnguessable = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
    const somewhatGuessable = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])');
    const veryGuessable = new RegExp('(?=.*[a-z])(?=.*[A-Z])');

    if (veryUnguessable.test(password)) {
      return 4;
    }

    if (safelyUnguessable.test(password)) {
      return 3;
    }

    if (somewhatGuessable.test(password)) {
      return 2;
    }

    if (veryGuessable.test(password)) {
      return 1;
    }

    return 0;
  }

  scoreWithFeedback(password: string): { score: number; feedback: { warning: string; suggestions: string[]; }; } {
    // TODO - return score with feedback
    return {
      score: 1,
      feedback: {
        warning: '',
        suggestions: [],
      },
    };
  }
}
