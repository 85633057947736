import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-item-details-remove-rejection',
  standalone: true,
  templateUrl: './item-details-remove-rejection.component.html',
  styleUrls: ['./item-details-remove-rejection.component.scss'],
})
export class ItemDetailsRemoveRejectionComponent {
  @Input() developmentTypeToDisplaySingular: 'research object' | 'experiment';
  @Input() developmentTypeToDisplayPlural: string;
  @Input() developmentItemName: string;
  @Input() noOfRelatedItems: number;
  relatedDevelopmentTypeToDisplay: 'research object' | 'experiment';

  ngOnInit() {
    if (this.developmentTypeToDisplaySingular === 'experiment') {
      this.relatedDevelopmentTypeToDisplay = 'research object';
    } else {
      this.relatedDevelopmentTypeToDisplay = 'experiment';
    }
  }
}
