<section class="dtTable lg-table w-100 table-section">
  <header class="datatable__header datatable__header--development-item-table">
    <div class="row table-header-row align-items-center flex-nowrap">
      <div class="col lg-table__th selection-col" data-test="select_colName">
        <div class="form-check">
          <input
            data-testid="list-view-checkbox-column-header"
            class="form-check-input lg-table__select-all-checkbox"
            type="checkbox"
            [checked]="isChecked$ | async"
            (change)="toggleAllExperimentsSelection($event)"
          />
        </div>
      </div>
      <div
        *ngIf="!(!(currentCategoryHasAnyChild$ | async) && (imageAreaHidden$ | async))"
        data-testid="list-view-image-column-header"
        class="col lg-table__th image-col text-left"
      >
        Image
      </div>
      <div data-testid="list-view-eid-column-header" class="col col-narrow lg-table__th text-left">EID</div>
      <div
        *ngIf="!(!(currentCategoryHasAnyChild$ | async) && (resultsAreaHidden$ | async))"
        data-testid="list-view-result-column-header"
        class="col lg-table__th result-col text-left"
      >
        Result
      </div>
      <div
        data-testid="list-view-name-column-header"
        class="col col-sortable lg-table__th name-col text-left"
        (click)="setSort(experimentFields.EXP_NAME)"
      >
        Name
        <i
          data-testid="list-view-name-sort-indicator"
          [@sortingRotationState]="experimentFields.EXP_NAME | sortingRotationState: sort"
          class="sort-icon"
        ></i>
      </div>
      <div
        *ngIf="showResearchObjects$ | async"
        data-testid="list-view-objects-used-column-header"
        class="col lg-table__th col-narrow object-col text-left"
      >
        Objects Used
      </div>
      <div
        *ngIf="!(!(currentCategoryHasAnyChild$ | async) && (parametersAreaHidden$ | async))"
        data-testid="list-view-parameter-column-header"
        class="col lg-table__th param-col text-left"
      >
        Parameter
      </div>
      <div
        data-testid="list-view-status-column-header"
        class="col col-sortable lg-table__th col-narrow status-col text-left"
        (click)="setSort(experimentFields.STATUS)"
      >
        Status
        <i
          data-testid="list-view-status-sort-indicator"
          [@sortingRotationState]="experimentFields.STATUS | sortingRotationState: sort"
          class="sort-icon"
        ></i>
      </div>
      <div
        data-testid="list-view-added-by-column-header"
        class="col col-sortable lg-table__th added-by-col text-left"
        (click)="setSort(experimentFields.ADDED_BY)"
      >
        Added By
        <i
          data-testid="list-view-added-by-sort-indicator"
          [@sortingRotationState]="experimentFields.ADDED_BY | sortingRotationState: sort"
          class="sort-icon"
        ></i>
      </div>
      <div
        data-testid="list-view-date-added-column-header"
        class="col col-sortable lg-table__th col-narrow date-col text-left"
        (click)="setSort(experimentFields.DATE_ADDED)"
      >
        Date Added
        <i
          data-testid="list-view-date-added-sort-indicator"
          [@sortingRotationState]="experimentFields.DATE_ADDED | sortingRotationState: sort"
          class="sort-icon"
        ></i>
      </div>
      <div class="col lg-table__th actions-col">&nbsp;</div>
      <div class="lg-table__th lg-table__th--item-btn"></div>
    </div>
  </header>
  <div class="table-body">
    <ng-container *appSkeleton="!listDataLoaded; repeat: 20; type: SkeletonType.TABLE_ITEM">
      <app-experiment-table-item
        *ngFor="let item of listData; trackBy: trackById"
        [itemData]="item"
        [imageAreaHidden]="imageAreaHidden$ | async"
        [resultsAreaHidden]="resultsAreaHidden$ | async"
        [parametersAreaHidden]="parametersAreaHidden$ | async"
        [showResearchObjects]="showResearchObjects$ | async"
        data-testid="exp-list-view-item"
      ></app-experiment-table-item>
    </ng-container>
  </div>
</section>
