import { ExperimentStatus } from '@shared/_models/experiment-status';
import { FieldValueDto } from './field-value';
import { CommonParams } from './common-params';
import { DevelopmentItemDto, DevelopmentItem } from './development-item';
import { ImageFileTemplateDto } from './image';

export const INSTANCES_DEFAULT_NAME = 'Samples';

export interface ResearchObjectAddDto {
  readonly category_template_id: string;
  readonly name: string;
  readonly description?: string;
}

export class IResearchObjectSelectItem {
  id: string;
  oid: number;
  name: string;
}

export interface IResearchObjectParams extends CommonParams {
  ro_ids: string[];
  inInstanceChecked: boolean;
}

export interface ResearchObjectUpdateDto {
  name: string;
  description: string;
  images_ids: ImageFileTemplateDto[];
  main_image_id?: string;
  files_ids: string[];
  field_values: FieldValueDto[];
  instances_ids: string[];
}

export interface FirstResultDto {
  id: string;
  field_template_id: string;
  name: string;
  value: string;
  field_type: string;
}

export interface ResearchObjectDto extends DevelopmentItemDto {
  instances: InstanceDto[];
  experiments: ResearchObjectRelatedExperimentsDto[];
}

export interface ResearchObject extends ResearchObjectDto, DevelopmentItem {}

export interface ResearchObjectRelatedExperimentsDto {
  id: string;
  oid: number;
  name: string;
  status: ExperimentStatus;
  first_result: FirstResultDto;
}

export interface InstanceDto {
  id: string;
  serial_number: string;
  section_id: string;
  field_values: FieldValueDto[];
}
