import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ScrollManager, ScrollSectionDirective, ScrollAnchorDirective } from '@app/shared/_directives/scroll';

@Component({
  selector: 'app-user-agreement',
  standalone: true,
  imports: [ScrollSectionDirective, ScrollAnchorDirective],
  providers: [ScrollManager],
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAgreementComponent {}
