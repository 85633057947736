export function calculatePageNumber(i: number, currentPage: number, paginationRange: number, totalPages: number): number {
  const halfWay = Math.ceil(paginationRange / 2);

  if (i === paginationRange) {
    return totalPages;
  } else if (i === 1) {
    return i;
  } else if (paginationRange < totalPages) {
    if (totalPages - halfWay < currentPage) {
      return totalPages - paginationRange + i;
    } else if (halfWay < currentPage) {
      return currentPage - halfWay + i;
    } else {
      return i;
    }
  } else {
    return i;
  }
}
