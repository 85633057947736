<app-input-with-validation
  [control]="control()"
  [config]="config()"
  [backendErrorMessage]="backendErrorMessage()"
  [externalTouched]="externalTouched()"
>
  <div
    [ngClass]="{
      'dynamic-field-border': config()?.isGridView && control().valid,
      'dynamic-field-border-invalid': markAsInvalid()
    }"
  >
    <app-number-input [formControl]="control()" [config]="config()" (onBlur)="blur$.next()"></app-number-input>
  </div>
</app-input-with-validation>
