export interface IAuthenticationData {
  email: string;
  password: any;
}

export enum AuthenticationError {
  USER_ALREADY_EXISTS = 'REGISTER_USER_ALREADY_EXISTS',
}

export class LoginMethod {
  loginMethod: string;
}

export enum LoginMethodType {
  LOCAL = 'local',
  TEST = 'test',
  UAT = 'uat',
  PROD = 'prod',
  PROD_EXTERNAL = 'prodexternal',
  FB = 'fb',
}
