import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { ToastService } from '@shared/_modules/toast/toast.service';
import { GENERIC_ERROR_MESSAGE } from '@shared/_services/global-error-handler.service';
import { notifyAboutError } from './app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private toastService: ToastService
  ) {}

  notifyAboutError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notifyAboutError),
        tap(({ notification }) => {
          const { content, header } = notification ?? GENERIC_ERROR_MESSAGE;

          this.toastService.show(content, {
            header,
            type: 'danger',
            delay: 5000
          });
        })
      ),
    { dispatch: false }
  );
}
