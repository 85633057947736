import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';
@Injectable()
export class DateFormatter extends NgbDateParserFormatter {
  parse(date: string): NgbDateStruct {
    if (!date) {
      return null;
    }
    const momentDate = moment.utc(date, DATE_FORMAT);
    return {
      day: parseInt(momentDate.format('D')),
      month: parseInt(momentDate.format('M')),
      year: parseInt(momentDate.format('YYYY')),
    } as NgbDateStruct;
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }
    return moment.utc(`${date.day}.${date.month}.${date.year}`, DATE_FORMAT).format(DATE_FORMAT);
  }
}
