import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { InstanceDto, ResearchObject, SectionType } from '@shared/_models';
import { ItemsCounterComponent } from '../../items-counter/items-counter.component';
import { getInstancesDefaultName } from '@app/domain/research-object';

@Component({
  selector: 'app-item-details-instances-dropdown',
  standalone: true,
  imports: [NgbDropdownModule, ItemsCounterComponent, NgClass, NgIf, NgFor],
  templateUrl: './item-details-instances-dropdown.component.html',
  styleUrls: ['./item-details-instances-dropdown.component.scss']
})
export class ItemDetailsInstancesDropdownComponent {
  @Input() researchObject: ResearchObject;
  @Input() activeInstance: InstanceDto;
  @Output() onActiveInstanceChange = new EventEmitter<InstanceDto>();

  instancesDropownOpened = false;

  get instancesSerialNumbers(): string[] {
    return this.researchObject.instances.map(x => x.serial_number);
  }

  get sectionName(): string {
    const section = this.researchObject?.template?.sections?.find(section => section.type === SectionType.INSTANCES);

    return section?.name || getInstancesDefaultName();
  }
}
