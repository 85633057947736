<div class="project-members-container">
  <div class="project-members__header">
    <label class="col-sm-3 members-project-label">Members*</label>
    <button (click)="openProjectMembersEditModal.emit()" class="btn members-edit__hover-btn">
      <img src="../../../assets/icons/pen_icon.svg" />
      Edit
    </button>
  </div>
  <div class="project-members__body" [class.project-members__body--invalid]="form.controls.owners.touched && form.controls.owners.invalid">
    <div class="project-member" *ngFor="let member of projectMembersWithAvatar()">
      <div class="project-member-role member-role--{{ member.role | lowercase }}">{{ member?.role[0] }}</div>
      <app-user-avatar [imageBase64]="(member.avatar$ | async)?.base64" avatarSize="1.3rem" data-testid="member-avatar"></app-user-avatar>
      <div data-testid="add-project-member-username" class="project-member-text">{{ member.user.name }} {{ member.user.surname }}</div>
    </div>
  </div>
</div>
<div class="invalid-feedback d-block" *ngIf="form.controls.owners.touched && form.controls.owners.errors?.maxlength">
  A project can only have one active project owner.
</div>
<div class="invalid-feedback d-block" *ngIf="form.controls.owners.touched && form.controls.owners.errors?.required">
  Changes cannot be saved without an active project owner.
</div>
