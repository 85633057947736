import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ResearchObjectsStoreService } from '../../_root-store/research-objects-store/research-objects-store.service';
import { ROOT_CATEGORY_ID } from '@app/domain/category';

@Injectable({
  providedIn: 'root'
})
export class ResearchObjectService {
  researchObjectListEmpty: boolean;

  constructor(private readonly researchObjectsStoreService: ResearchObjectsStoreService) {}

  toggleResearchObjectSelection($event, id: string): void {
    $event.preventDefault();
    $event.stopPropagation();

    const checked = $event.target.checked;

    this.researchObjectsStoreService.toggleResearchObjectSelection(checked, id);
  }

  toggleAllResearchObjectsSelection($event, categoryId: string): void {
    $event.preventDefault();
    $event.stopPropagation();
    const checked = $event.target.checked;
    if (checked) {
      this.researchObjectsStoreService.getAllResearchObjectsIds({
        ...(categoryId !== ROOT_CATEGORY_ID && { category_id: categoryId })
      });
    } else {
      this.researchObjectsStoreService.clearResearchObjectSelection();
    }
  }

  isResearchObjectSelected(researchObjectId: string): Observable<boolean> {
    return this.researchObjectsStoreService.researchObjectsSelectedIds$.pipe(
      map(ids => ids.find(id => id === researchObjectId)?.length > 0)
    );
  }

  isResearchObjectListEmpty(isEmpty: boolean) {
    this.researchObjectListEmpty = isEmpty;
  }
}
