<div class="scrollable-container">
  <span class="scrollable-container__group mt-0">Team Leads</span>
  <div *ngFor="let lead of getLeads(); let i = index">
    <app-member-element
      [type]="ELEMENT_TYPE.PROJECT"
      [member]="lead"
      [index]="i"
      [projectProxy]="projectProxy"
      (memberUpdate)="onMemberUpdate($event)"
      class="scrollable-container__group__items__item"
    ></app-member-element>
  </div>
  <span class="scrollable-container__group">Team Members: {{ getMembers()?.length }}</span>
  <div>
    <div *ngFor="let member of getMembers(); let i = index">
      <app-member-element
        [type]="ELEMENT_TYPE.PROJECT"
        [member]="member"
        [index]="i"
        [projectProxy]="projectProxy"
        (memberUpdate)="onMemberUpdate($event)"
        class="scrollable-container__group__items__item"
      ></app-member-element>
    </div>
  </div>
</div>
