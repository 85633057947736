import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
//
import { AuthRemindPasswordRequest } from '../../interface';
import { AuthService } from '../../auth.service';
import { AuthRoutingPath } from '../../auth-routing-path';
import { CONTACT_ADMINISTRATOR } from '@app/_auth/constants';
import { EmailWithDomainValidator } from '@app/shared/_validators/email-with-domain.validator';

enum FORM_KEYS {
  EMAIL = 'email',
}

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestResetPasswordComponent {
  isRequestResetPasswordSuccessfulSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isRequestResetPasswordSuccessful$ = this.isRequestResetPasswordSuccessfulSubject.asObservable();

  readonly signInRoutingPath = ['/', AuthRoutingPath.auth, AuthRoutingPath.signIn];
  focusEmail: boolean;
  formSubmitted = false;
  readonly FORM_KEYS = FORM_KEYS;
  readonly CONTACT_ADMINISTRATOR = CONTACT_ADMINISTRATOR;
  remindPasswordForm = new FormGroup({
    [FORM_KEYS.EMAIL]: new FormControl(null, [Validators.required, EmailWithDomainValidator]),
  });

  constructor(private readonly authService: AuthService) {}

  remindPassword($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.formSubmitted = true;
    if (this.remindPasswordForm.invalid) {
      this.remindPasswordForm.markAllAsTouched();
      return;
    }

    const requestData = this.remindPasswordForm.value as AuthRemindPasswordRequest;

    if (this.remindPasswordForm.valid && requestData) {
      this.authService.requestResetPassword(requestData).subscribe(() => this.isRequestResetPasswordSuccessfulSubject.next(true));
    } else {
      throw 'Unable to process Request Password data';
    }
  }
}
