export enum ViewMode {
  grid = 'grid',
  table = 'table',
  instances = 'instances', // does it fit here ?
  files = 'files', // does it fit here ?
}

export enum FieldsViewMode {
  PREVIEW = 'preview',
  EDIT = 'edit',
  TEMPLATE_PREVIEW = 'template_preview',
  TEMPLATE_EDIT = 'template_edit',
}

export enum ResearchObjectsViewMode {
  objects = 'objects',
  instances = 'instances',
}
