import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';

import { ImageContent } from '@shared/_models';
import * as actions from './images.actions';
import { AsyncOperationStatus } from '@shared/_models/async-operation-status';
import { ImageSize } from '@app/domain/image';

export const IMAGES_STATE_KEY = 'images';
export const imagesAdapter = {
  [ImageSize.LARGE]: createEntityAdapter<ImageContent>(),
  [ImageSize.MEDIUM]: createEntityAdapter<ImageContent>(),
  [ImageSize.SMALL]: createEntityAdapter<ImageContent>()
};

export interface ImagesState {
  images: {
    [ImageSize.LARGE]: EntityState<ImageContent>;
    [ImageSize.MEDIUM]: EntityState<ImageContent>;
    [ImageSize.SMALL]: EntityState<ImageContent>;
  };
  pending: {
    [ImageSize.LARGE]: { [id: string]: AsyncOperationStatus };
    [ImageSize.MEDIUM]: { [id: string]: AsyncOperationStatus };
    [ImageSize.SMALL]: { [id: string]: AsyncOperationStatus };
  };
}

export const initialState: ImagesState = {
  images: {
    [ImageSize.LARGE]: imagesAdapter[ImageSize.LARGE].getInitialState(),
    [ImageSize.MEDIUM]: imagesAdapter[ImageSize.MEDIUM].getInitialState(),
    [ImageSize.SMALL]: imagesAdapter[ImageSize.SMALL].getInitialState()
  },
  pending: {
    [ImageSize.LARGE]: {},
    [ImageSize.MEDIUM]: {},
    [ImageSize.SMALL]: {}
  }
};

export const reducer: ActionReducer<ImagesState> = createReducer(
  // TODO: use IndexedDB & https://ngrx.io/guide/store/metareducers & https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/estimate
  // for hydration on app launch ?
  initialState,

  on(actions.loadImageContent, (state, action) => {
    const size = action.size ?? ImageSize.LARGE;

    return {
      ...state,
      pending: {
        ...state.pending,
        [size]: {
          ...state.pending[size],
          [action.imageMeta.id]: AsyncOperationStatus.PENDING
        }
      }
    };
  }),
  on(actions.loadImageContentSuccess, (state, action) => {
    const size = action.size ?? ImageSize.LARGE;

    return {
      images: {
        ...state.images,
        [size]: imagesAdapter[size].upsertOne(action.data, state.images[size])
      },
      pending: {
        ...state.pending,
        [size]: {
          ...state.pending[size],
          [action.data.id]: AsyncOperationStatus.COMPLETED
        }
      }
    };
  }),
  on(actions.loadImageContentFailure, (state, action) => {
    const size = action.size ?? ImageSize.LARGE;

    return {
      ...state,
      pending: {
        ...state.pending,
        [size]: {
          ...state.pending[size],
          [action.id]: AsyncOperationStatus.FAILED
        }
      }
    };
  }),
  on(actions.handleCachedImage, (state, action) => {
    const size = action.size ?? ImageSize.LARGE;

    return {
      images: {
        ...state.images,
        [size]: imagesAdapter[size].updateOne(
          {
            id: action.id,
            changes: { date_last_accessed: new Date().getTime() }
          },
          state.images[size]
        )
      },
      pending: {
        ...state.pending,
        [size]: {
          ...state.pending[size],
          [action.id]: AsyncOperationStatus.COMPLETED
        }
      }
    };
  })
);
