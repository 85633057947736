import { Pipe, PipeTransform } from '@angular/core';
import { camelCase, isNil, startCase } from 'lodash-es';

@Pipe({
  name: 'startCase',
  standalone: true,
})
export class StartCasePipe implements PipeTransform {
  transform(value: string): string {
    return isNil(value) ? value : startCase(camelCase(value));
  }
}
