import { ChangeDetectionStrategy, Component, ElementRef, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { ImageMetaUpdateDataForEntity } from '@shared/_models';
import { ItemImageDirective } from '@shared/_components/item/item-image.directive';
import { loadImageContent } from '@shared/_root-store/images-store/images.actions';
import { ImageSize } from '@app/domain/image';

@Component({
  selector: 'app-grid-item-image',
  templateUrl: './grid-item-image.component.html',
  styleUrls: ['./grid-item-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridItemImageComponent extends ItemImageDirective {
  @Output() onMetaChanged = new EventEmitter<ImageMetaUpdateDataForEntity>();

  imageModalEditMode = false;

  #modalRef: NgbModalRef;

  @ViewChild('imageDetailsModal', { static: false }) imageDetailsModal: ElementRef;

  constructor(
    private readonly ngbModal: NgbModal,
    store: Store
  ) {
    super(store);
  }

  onImageThumbClick($event: Event) {
    $event.stopPropagation();
    this.openModal();
  }

  openModal(): void {
    this.store.dispatch(loadImageContent({ imageMeta: this.mainImage(), size: ImageSize.LARGE }));
    this.#modalRef = this.ngbModal.open(this.imageDetailsModal, {
      size: 'xl',
      centered: true
    });
  }

  handleImageModalCloseBtnClick() {
    this.#modalRef.close();
  }

  handleImageModalDescriptionChange(newDescription: string) {
    const entityId = this.developmentItem.id;
    const updateData = { imageId: this.mainImage().id, payload: { description: newDescription } };
    this.onMetaChanged.emit({ entityId, updateData });
    this.imageModalEditMode = false;
  }

  onImageModalEditModeChange(newEditMode: boolean) {
    this.imageModalEditMode = newEditMode;
  }
}
