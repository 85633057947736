import { Component, EventEmitter, Output } from '@angular/core';
import { IAlertsChange } from '../interface/alerts-change.interface';

@Component({
  selector: 'app-alert-section',
  templateUrl: './alert-section.component.html',
  styleUrls: ['./alert-section.component.scss'],
})
export class AlertSectionComponent {
  @Output() alertsChange: EventEmitter<IAlertsChange> = new EventEmitter<IAlertsChange>();

  constructor() {}

  alertsChangeFn($event: IAlertsChange): void {
    this.alertsChange.emit($event);
  }
}
