import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
//
import { AuthService } from '../../_auth/auth.service';
import { StorageService } from '../_services/storage/storage.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthService,
    private readonly storageService: StorageService,
  ) {}

  private isJwtUrl(request: HttpRequest<any>): boolean {
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const isUsersUrl = request.url.startsWith(environment.usersUrl);

    return isApiUrl || isUsersUrl;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storageService.getToken();
    const isApiUrl = this.isJwtUrl(request);

    if (token && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request);
  }

}
