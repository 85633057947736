import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWorkspace, IWorkspacePendingMember } from '@app/shared/_models/workspace';
import { Observable, shareReplay } from 'rxjs';
import { WORKSPACES_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceApiService {
  constructor(private readonly http: HttpClient) {}

  // FIXME: usunąć bo chyba nieużywane
  getFavouriteWorkspace(): Observable<IWorkspace> {
    return this.http.get<IWorkspace>(`${WORKSPACES_API_URL}/favourite`);
  }

  getWorkspace(workspaceId: string): Observable<IWorkspace> {
    return this.http.get<IWorkspace>(`${WORKSPACES_API_URL}/${workspaceId}`);
  }

  getList() {
    return this.http.get<IWorkspace[]>(`${WORKSPACES_API_URL}`).pipe(shareReplay());
  }

  create(workspace: { name: string }): Observable<IWorkspace> {
    return this.http.post<any>(`${WORKSPACES_API_URL}`, workspace);
  }

  update(workspaceId: string, changes: { name: string }): Observable<IWorkspace> {
    return this.http.patch<any>(`${WORKSPACES_API_URL}/${workspaceId}`, changes);
  }

  sendJoinRequest(workspaceId: string, code: string): Observable<IWorkspace> {
    return this.http.patch<IWorkspace>(`${WORKSPACES_API_URL}/${code}/request_join`, null).pipe(shareReplay());
  }

  setFavorite(workspaceId: string): Observable<IWorkspace[]> {
    return this.http.post<any>(`${WORKSPACES_API_URL}/${workspaceId}/set_favourite`, {});
  }

  leave(workspaceId: string): Observable<IWorkspace> {
    return this.http.delete<any>(`${WORKSPACES_API_URL}/${workspaceId}/leave_workspace`);
  }

  delete(workspaceId: string): Observable<IWorkspace> {
    return this.http.delete<any>(`${WORKSPACES_API_URL}/${workspaceId}`);
  }

  deleteMembers(workspaceId: string, usersToDelete: string[]): Observable<IWorkspace> {
    return this.http.delete<any>(`${WORKSPACES_API_URL}/${workspaceId}/remove_members`, { body: usersToDelete });
  }

  getPendingMembers() {
    return this.http.get<IWorkspacePendingMember[]>(`${WORKSPACES_API_URL}/pending_members`).pipe(shareReplay());
  }

  getInvitedWorkspaces() {
    return this.http.get<IWorkspace[]>(`${WORKSPACES_API_URL}/invited_workspaces`).pipe(shareReplay());
  }

  acceptInvite(workspaceId: string): Observable<IWorkspace> {
    return this.http.patch<IWorkspace>(`${WORKSPACES_API_URL}/${workspaceId}/accept_invite`, null).pipe(shareReplay());
  }

  rejectInvite(workspaceId: string): Observable<IWorkspace> {
    return this.http.delete<any>(`${WORKSPACES_API_URL}/${workspaceId}/reject_invite`);
  }

  acceptPendingMember(workspaceId: string, userId: string): Observable<IWorkspace> {
    return this.http.patch<IWorkspace>(`${WORKSPACES_API_URL}/${workspaceId}/accept_pending?user_id=${userId}`, null).pipe(shareReplay());
  }

  invite(workspaceId: string, userMail: { email: string }) {
    return this.http.post<any>(`${WORKSPACES_API_URL}/${workspaceId}/invite`, userMail);
  }
}
