<app-input-with-validation
  [control]="control()"
  [config]="config()"
  [backendErrorMessage]="backendErrorMessage()"
  externalTouched="externalTouched()"
>
  <div class="form-select-input" [ngClass]="{ 'dynamic-field-border': config()?.isGridView }">
    <div class="form-select-input__content">
      <app-option-icon *ngIf="hasColor()" [options]="config()?.options" [value]="control()?.value"></app-option-icon>
      <select
        class="dynamic-field-select"
        [ngClass]="{ 'dynamic-field-select--color': hasColor() }"
        [formControl]="control()"
        (blur)="blur$.next()"
      >
        <option value="">{{ config()?.placeholder || DEFAULT_SELECT_PLACEHOLDER }}</option>
        <option *ngFor="let option of options()" [value]="option.enum_value">
          {{ option.enum_value }}
        </option>
      </select>
    </div>
  </div>
</app-input-with-validation>
