import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';
import { uniq } from 'lodash-es';

import { ProjectsApiService } from '@shared/_services/project/projects-api.service';
import * as actions from './projects.actions';
import { loadAvatars } from '../users-store/users.actions';

@Injectable()
export class ProjectsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly projectsApiService: ProjectsApiService
  ) {}

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadProjects),
      concatMap(({ workspaceId }) =>
        this.projectsApiService.getList(workspaceId).pipe(
          map(data => actions.loadProjectsSuccess({ data })),
          catchError(error => of(actions.loadProjectsFailure({ error })))
        )
      )
    )
  );

  loadAvatarsForProjectMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadProjectsSuccess),
      map(({ data: projects }) => {
        const ids = uniq(
          projects.reduce((acc, project) => {
            return acc.concat(...project.leads.map(lead => lead.id), ...project.members.map(member => member.user.id), project.user_crt.id);
          }, [])
        );

        return loadAvatars({ ids });
      })
    )
  );

  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateProject),
      concatMap(({ projectId, changes }) =>
        this.projectsApiService.update(projectId, changes).pipe(
          map(data => actions.updateProjectSuccess({ data })),
          catchError(error => of(actions.updateProjectFailure({ error })))
        )
      )
    )
  );
}
