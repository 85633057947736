import { Component, ElementRef, Inject, input, output, computed, ViewChild } from '@angular/core';
import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgbModal, NgbModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

import {
  DevelopmentType,
  ExperimentStatus,
  ExperimentUpdateDto,
  ResearchObjectUpdateDto,
  UpdateArea,
  DevelopmentItem,
  Experiment,
  ProjectProxy,
  RouteParam
} from '@shared/_models';
import { BreadcrumbsComponent } from '@shared/breadcrumbs';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { ItemDetailsEditNameModalComponent } from '../item-details-edit-name-modal/item-details-edit-name-modal.component';
import { AppSettings } from '@shared/_configuration';
import { ExperimentStatusDropdown } from './components/experiment-status-dropdown/experiment-status-dropdown.component';
import { MoreActionsComponent } from '@shared/_components/more-actions/more-actions.component';
import { MenuAction } from '@shared/_components/more-actions/utils';
import { CopyButtonComponent } from '@shared/_components/copy-button/copy-button.component';
import { NavigationService } from '@shared/_services/navigation.service';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';

@Component({
  selector: 'app-item-details-nav',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    NgbModule,
    RouterLink,
    RouterLinkActive,
    BreadcrumbsComponent,
    DevelopmentTypeCssClassPipe,
    FormsModule,
    CommonModule,
    IconComponent,
    ExperimentStatusDropdown,
    ItemDetailsEditNameModalComponent,
    MoreActionsComponent,
    CopyButtonComponent
  ],
  templateUrl: './item-details-nav.component.html',
  styleUrls: ['./item-details-nav.component.scss']
})
export class ItemDetailsNavComponent {
  developmentItem = input.required<DevelopmentItem>();
  previousBtnId = input.required<string>();
  nextBtnId = input.required<string>();
  projectProxy = input.required<ProjectProxy>();
  editable = input(true);
  linkBtnClick = output<void>();
  exportBtnClick = output<void>();
  deleteBtnClick = output<void>();
  nextBtnClick = output<string>();
  previousBtnClick = output<string>();
  update = output<{ data: Partial<ExperimentUpdateDto> | Partial<ResearchObjectUpdateDto>; area: UpdateArea }>();
  nameHovered$ = new BehaviorSubject<boolean>(false);
  /* we dalay emitting false value to prevent hiding copy icon when
   * user moves mouse from name element to copy icon element */
  showNameCopyButton$: Observable<boolean> = this.nameHovered$.pipe(
    switchMap(value => (value ? of(true) : timer(250).pipe(map(() => false))))
  );

  @ViewChild('itemDetailsEditNameModal', { static: false }) itemDetailsEditNameModal: ElementRef;

  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));
  workspaceId = toSignal(this.store.select(selectRouteParam(RouteParam.WORKSPACE_ID)));
  categoryId = toSignal(this.store.select(selectRouteParam(RouteParam.CATEGORY_ID)));
  developmentType = computed<DevelopmentType>(() => this.developmentItem().template?.category.development_type);
  experimentStatus = computed<ExperimentStatus>(() => (<Experiment>this.developmentItem()).status);
  moreActions = computed<MenuAction<void>[]>(() =>
    [
      {
        callback: () => this.linkBtnClick.emit(),
        label: 'Link',
        icon: { fileName: 'link_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.9rem', height: '0.9rem' }
      },
      {
        callback: () => this.exportBtnClick.emit(),
        label: 'Export XLS',
        icon: { fileName: 'export_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.9rem', height: '0.9rem' }
      }
    ]
      .concat(
        this.projectProxy()?.canEditTemplate
          ? [
              null,
              {
                callback: () =>
                  this.navigationService.goToTemplateEdit(this.workspaceId(), this.projectId(), this.categoryId(), this.developmentType()),
                label: 'Edit category',
                icon: { fileName: 'pen_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.6rem', height: '0.6rem' }
              }
            ]
          : []
      )
      .concat(
        this.projectProxy()?.canEdit
          ? [
              null,
              {
                callback: () => this.deleteBtnClick.emit(),
                label: 'Delete',
                icon: { fileName: 'trash_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.9rem', height: '0.9rem' }
              }
            ]
          : []
      )
  );

  readonly UpdateArea = UpdateArea;
  readonly IconColors = IconColors;
  readonly DevelopmentType = DevelopmentType;

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private readonly ngbModalService: NgbModal,
    private readonly store: Store,
    private readonly navigationService: NavigationService
  ) {}

  onNextBtnClick() {
    this.nextBtnClick.emit(this.nextBtnId());
  }

  onPreviousBtnClick() {
    this.previousBtnClick.emit(this.previousBtnId());
  }

  onEditNameClick() {
    this.ngbModalService.open(this.itemDetailsEditNameModal, this.settings.MODAL_DEFAULT_CONFIG);
  }
}
