import { TrackByFunction } from '@angular/core';

import { FieldKind, FieldViewData } from '@app/domain/field/to-fields-view-data';

export interface WithId<T extends string = string> {
  readonly id: T;
}

export const trackById: TrackByFunction<WithId> = <T extends string, U extends WithId<T>>(_index: number, item: U): T => item.id;

export const trackBySelf: TrackByFunction<string> = <T extends string>(_index: number, item: T): string => item;

export const trackByIndex: TrackByFunction<unknown> = (index: number, _item: unknown): number => index;

export const trackByField: TrackByFunction<FieldViewData> = (_: number, data: FieldViewData): string => {
  return (
    (data.type === FieldKind.NON_COMPLEX && data.field?.field_template_id) ||
    (data.type === FieldKind.COMPLEX && data.field?.parent.field_template_id)
  );
};
