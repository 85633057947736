export function hexToRgba(hex: string, opacity: number): string {
  // Ensure the hex code is in the correct format
  const sanitizedHex = hex.replace('#', '');

  if (sanitizedHex.length !== 6) {
    throw new Error('Invalid hex color format. Expected a 6 character string.');
  }

  // Convert hex to RGB
  const r = parseInt(sanitizedHex.substring(0, 2), 16);
  const g = parseInt(sanitizedHex.substring(2, 4), 16);
  const b = parseInt(sanitizedHex.substring(4, 6), 16);

  // Return the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
