import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { ProjectMembersComponent } from './projects-members/project-members.component';
import { ProjectColors } from './project-colors/project-colors.component';
import { DYNAMIC_FORM } from '../../dynamic-form';
import { ProjectEditAddComponent } from './project-edit-add/project-edit-add.component';
import { ProjectActionsModal } from './project-actions-modal/project-actions-modal.component';
import { ProjectMembersEditModalComponent } from '@app/_projects/project-members-edit-modal/project-members-edit-modal.component';
import { MembersDropdownComponent } from '@app/_projects/members-elements/members-dropdown/members-dropdown.component';
import { MemberElementComponent } from '@app/shared/_components/member-element/member-element.component';
import { MembersElementsComponent } from '@app/_projects/members-elements/members-elements.component';
import { SearchSelectComponent } from '@shared/_components/search-select/search-select.component';
import { DropdownComponent } from '@shared/_components/dropdown/dropdown.component';
import { InputWithClear } from '@app/shared/_components';
import { StatusDropdownComponent } from '@shared/_components/status-dropdown/status-dropdown.component';
import { TextLongInputComponent } from '@app/shared/_components/inputs/text-long-input/text-long-input.component';
import { InputWithValidationComponent } from '@app/shared/_components/inputs/input-with-validation/input-with-validation.component';
import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { GenericModalComponent } from '@app/shared/_components/generic-modal/generic-modal.component';
import { ModalHeaderLeftComponent } from '@app/shared/_components/modal-header-left/modal-header-left.component';
import { ModalHeaderCenterComponent } from '@app/shared/_components/modal-header-center/modal-header-center.component';

const NG_MODULES = [CommonModule, ReactiveFormsModule, FormsModule, NgbModule];

const LIB_MODULES = [NgbPopoverModule, NgSelectModule];

const COMPONENTS = [ProjectEditAddComponent, ProjectActionsModal];

const STANDALONE_COMPONENTS = [
  MemberElementComponent,
  DropdownComponent,
  SearchSelectComponent,
  StatusDropdownComponent,
  InputWithClear,
  TextLongInputComponent,
  InputWithValidationComponent,
  ButtonComponent,
  IconComponent,
  GenericModalComponent,
  ModalHeaderLeftComponent,
  ModalHeaderCenterComponent,
  ProjectColors,
  MembersDropdownComponent,
  ProjectMembersEditModalComponent,
  MembersElementsComponent,
  ProjectMembersComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...NG_MODULES, ...LIB_MODULES, ...DYNAMIC_FORM, ...STANDALONE_COMPONENTS],
  exports: [...COMPONENTS, ...STANDALONE_COMPONENTS]
})
export class ProjectModule {}
