import { Directive, WritableSignal, computed, signal, effect, Signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Observable, Subject, merge, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { DynamicField, FieldConfig } from '@shared/dynamic-form/models';
import { CounterOptions } from '@shared/_components/inputs/text-long-input/text-counter/utils';
import { markAsInvalid } from '@shared/dynamic-form/models';

@Directive()
export class DynamicFieldBaseComponent implements DynamicField {
  config: WritableSignal<FieldConfig> = signal(null);
  control: WritableSignal<FormControl> = signal(null);
  control$: Observable<FormControl> = toObservable(this.control);
  valueChanges$: Observable<any> = this.control$.pipe(
    filter(control => !!control),
    switchMap(control => control.valueChanges)
  );
  backendErrorMessage: WritableSignal<string> = signal(null);
  externalTouched: WritableSignal<boolean> = signal(false);
  counterOptions = computed<CounterOptions>(() => {
    if (!this.config()?.display_options?.count_visible) return;

    return { show: true, maxLength: this.config().options.max_length };
  });
  backendErrorMessage$ = toObservable(this.backendErrorMessage);

  // TODO: investigate very weaird behaviour: when we use this.control$, it's not working properly
  invalid$: Observable<boolean> = toObservable(this.control).pipe(
    filter(control => !!control),
    withLatestFrom(this.backendErrorMessage$),
    switchMap(([control, backendErrorMessage]) => {
      return merge(
        of(control.invalid || !!backendErrorMessage),
        control.statusChanges.pipe(
          filter(status => ['VALID', 'INVALID'].includes(status)),
          map(status => status === 'INVALID' || !!backendErrorMessage)
        )
      );
    })
  );
  invalid: Signal<boolean> = toSignal(this.invalid$, { initialValue: this.control()?.invalid || !!this.backendErrorMessage() });
  blur$ = new Subject<void>();
  touched$: Observable<boolean> = merge(this.blur$, this.valueChanges$).pipe(map(() => true));
  touched: Signal<boolean> = toSignal(this.touched$, { initialValue: this.control()?.touched });
  markAsInvalid = computed<boolean>(() => markAsInvalid(this.config()?.isGridView, this.invalid(), this.touched(), this.externalTouched()));

  constructor() {
    this.#handleExternalTouched();
    this.#handlebackendErrorMessageClear();
  }

  #handleExternalTouched() {
    effect(() => {
      if (!this.externalTouched() || this.control()?.touched) return;

      this.control()?.markAsTouched();
    });
  }

  #handlebackendErrorMessageClear() {
    this.valueChanges$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (!this.backendErrorMessage()) return;

      this.backendErrorMessage.set(null);
    });
  }
}
