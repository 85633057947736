import moment from 'moment';

export function formatFieldDate(dateString: string, dateFormat: string): string {
  if (dateFormat === 'dd.mm.yyyy') return moment.utc(dateString).format('DD.MM.YYYY');
  if (dateFormat === 'mm/dd/yyyy') return moment.utc(dateString).format('MM/DD/YYYY');
  if (dateFormat === 'yyyy-mm-dd') return moment.utc(dateString).format('YYYY-MM-DD');
  if (dateFormat === 'MM yyyy') {
    return `${moment.utc(dateString).format('MMMM')} ${moment.utc(dateString).format('YYYY')}`;
  }
  if (dateFormat === 'days-elapsed') {
    const getDifferenceInDays = (): number => {
      const today = moment.utc().startOf('day');
      const date = moment.utc(dateString).startOf('day');

      return today.diff(date, 'days');
    };
    const differenceInDays = getDifferenceInDays();
    const daysPart = Math.abs(differenceInDays) > 1 ? 'Days' : 'Day';

    if (differenceInDays < 0) return `${-differenceInDays} ${daysPart} Left`;

    if (differenceInDays > 0) return `${differenceInDays} ${daysPart} Ago`;

    return 'Today';
  }

  return dateString;
}
