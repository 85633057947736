<div data-testid="item-section" class="section">
  <div data-testid="section-area" *ngIf="activeArea" class="section__area-name">
    <app-icon
      *ngIf="hiddenArea && mode === FieldsViewMode.TEMPLATE_PREVIEW"
      width="0.75rem"
      height="0.75rem"
      fileName="eye_closed_icon.svg"
      [color]="IconColors.darkGrey"
    ></app-icon>
    <span [ngClass]="{ 'section__header-name-hidden': hiddenArea && mode === FieldsViewMode.TEMPLATE_PREVIEW }">
      {{ sectionName }}
    </span>
  </div>
  <div data-testid="section-header" class="section__header" [class.section__header--no-tabs]="!!sectionName && !activeArea">
    <span
      data-testid="item-section-header-name"
      [ngClass]="{ 'section__header-name-edit': editMode || addMode }"
      class="section__header-name text-truncate"
      *ngIf="sectionName && !activeArea"
    >
      <app-icon
        *ngIf="hiddenArea && mode === FieldsViewMode.TEMPLATE_PREVIEW"
        width="0.75rem"
        height="0.75rem"
        fileName="eye_closed_icon.svg"
        [color]="IconColors.darkGrey"
      ></app-icon>
      <span [ngClass]="{ 'section__header-name-hidden': hiddenArea && mode === FieldsViewMode.TEMPLATE_PREVIEW }">
        {{ sectionName }}
      </span>
    </span>
    <div class="section__header-elements flex-fill align-self-stretch d-flex align-items-center">
      <ng-content select="[header-elements]"></ng-content>
    </div>
    <ng-container *ngIf="showAddMoreBtn">
      <button data-testid="add-file-button" id="section__add-btn" class="btn section__hover-btn" (click)="addMore.emit()">
        <app-icon
          class="section__hover-icon"
          width="0.6rem"
          height="0.6rem"
          fileName="plus_2_icon.svg"
          [color]="IconColors.blue"
        ></app-icon>
        Add
      </button>
    </ng-container>
    <ng-container *ngIf="showEditBtn">
      <ng-container *ngIf="!editMode; else editModeActiveTpl">
        <button data-testid="manage-file-button" id="section__hover-btn" class="btn section__hover-btn" (click)="edit.emit()">
          <img *ngIf="sectionName === 'Images'" src="../../../assets/icons/icon_manage.svg" alt="edit btn icon" />
          <img *ngIf="sectionName !== 'Images'" src="../../../assets/icons/pen_icon.svg" alt="edit btn icon" />
          {{ sectionName === 'Images' ? 'Manage' : 'Edit' }}
        </button>
      </ng-container>
      <ng-template #editModeActiveTpl>
        <ng-container *ngIf="!showArrangeBtn">
          <button
            id="section__cancel-btn"
            [ngClass]="{ 'section__hover-btn-visible': editMode || addMode }"
            class="section__hover-btn-cancel"
            (click)="cancel.emit()"
          >
            Cancel
          </button>
          <button
            id="section__save-btn"
            [ngClass]="{ 'section__hover-btn-visible': editMode || addMode }"
            [disabled]="disabled"
            class="section__hover-btn"
            (click)="save.emit()"
          >
            Save
          </button>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="showArrangeBtn">
      <button class="btn section__hover-btn" (click)="edit.emit()" *ngIf="!editMode; else closeArrangeTpl">
        <app-icon class="section__hover-icon" width="0.6rem" height="0.6rem" fileName="pen_icon.svg" [color]="IconColors.blue"></app-icon>
        Arrange
      </button>
      <ng-template #closeArrangeTpl>
        <button class="section__btn" (click)="arrangeClose.emit()">Close</button>
      </ng-template>
    </ng-container>
  </div>
  <div class="section__body">
    <ng-content></ng-content>
    <ng-content select="[empty-message]"></ng-content>
  </div>
</div>
