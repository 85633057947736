import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';

import { loadCategories } from './categories.actions';
import {
  categoriesLoaded,
  categoriesLoading,
  selectCategories,
  selectCategoryById,
  selectCategoriesDictionary
} from './categories.selectors';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';
import { switchMap } from 'rxjs/operators';
import { RouteParam } from '@shared/_models';

@Injectable({
  providedIn: 'root'
})
export class CategoriesStoreService {
  readonly categories$ = this.store.select(selectCategories);
  readonly categoriesDictionary$ = this.store.select(selectCategoriesDictionary);
  readonly categoriesLoading$ = this.store.select(categoriesLoading);
  readonly categoriesLoaded$ = this.store.select(categoriesLoaded);

  constructor(private readonly store: Store) {}

  selectCategoryByIdFromRouteParam() {
    return this.store
      .select(selectRouteParam(RouteParam.CATEGORY_ID))
      .pipe(switchMap((categoryId: string) => this.selectCategoryById(categoryId)));
  }

  selectCategoryById(id: string) {
    return this.store.select(selectCategoryById(id));
  }

  loadCategories() {
    this.store.dispatch(loadCategories());
  }
}
