import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const PasswordConditionsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value ?? null;

  if (value) {
    const conditions = new RegExp('(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])');
    const meetsConditions = conditions.test(value);

    return meetsConditions ? null : { meetsConditions: true };
  }

  return null;
};
