import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'handleItemParameterOrResultIfEmpty',
  standalone: true,
})
export class HandleItemParameterOrResultIfEmptyPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (value !== null && value !== '') {
      return value;
    }
    return '-';
  }
}
