import { FormGroup, ValidatorFn } from '@angular/forms';

export function ContentsMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.contentsMatch) {
      return;
    }

    // if (control.value === matchingControl.value) {
    //   matchingControl.setErrors(null);
    // } else {
    //   matchingControl.setErrors({ contentsMatch: true });
    // }

    return control.value === matchingControl.value ? null : { contentsMatch: true };
  };
}
