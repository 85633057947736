export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = Object.assign(new FileReader(), {
      onload: () => resolve(reader.result as string),
      onerror: () => reject(reader.error)
    });
    reader.readAsDataURL(blob);
  });
}

// For some reason, null api response returns a blob with size > 0, 4 for example
export const NEGLIGIBLE_BLOB_SIZE = 100;
