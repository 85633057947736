import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// import { SignupLayoutComponent } from './auth/signup/signup-layout/signup-layout.component';
// import { SignupComponent } from './auth/signup/signup/signup.component';
//
// import { ApiSchemasResolver } from './api-schemas/api-schemas.resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'app',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./_auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '**',
    redirectTo: 'app/not-found',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
