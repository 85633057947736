<div
  ngbDropdown
  class="section-dropdown"
  (openChange)="instancesDropownOpened = !instancesDropownOpened"
  [ngClass]="{ 'section-dropdown--opened': instancesDropownOpened }"
>
  <button data-testid="item-dropdown-menu-button" type="button" class="section-dropdown__btn" ngbDropdownToggle>
    <i class="section-dropdown__icon"></i>
    <div class="d-flex">
      <app-items-counter
        [items]="instancesSerialNumbers"
        [textBeforeCounter]="activeInstance?.serial_number"
        [tooltipHeader]="sectionName"
      ></app-items-counter>
    </div>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <button
      data-testid="item-dropdown-option-button"
      *ngFor="let instance of researchObject.instances"
      ngbDropdownItem
      class="section-dropdown__item"
      (click)="onActiveInstanceChange.emit(instance)"
    >
      {{ instance.serial_number }}
    </button>
  </div>
</div>
