import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-item-details-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './item-details-skeleton.component.html',
  styleUrls: ['./item-details-skeleton.component.scss']
})
export class ItemDetailsSkeletonComponent {}
