<div class="additional-information">
  <div class="row">
    <div data-testid="additional-info-user" class="col-12 col-lg-6">
      <div class="additional-information__subcategory">Added by</div>
      <div class="additional-information__content d-flex align-items-center">
        <i class="additional-information__user-icon"></i>
        {{ item.user_crt.name + ' ' + item.user_crt.surname || '-' }}
      </div>
    </div>
    <div data-testid="additional-info-date-added" class="col-12 col-lg-6">
      <div class="additional-information__subcategory">Date added</div>
      <div class="additional-information__content">
        {{ item.date_created | date: 'dd.MM.YYYY' || '-' }}
      </div>
    </div>
  </div>
  <div class="row">
    <div data-testid="additional-info-last-change" class="col-12 col-lg-6">
      <div class="additional-information__subcategory">Last changes</div>
      <div class="additional-information__content">
        {{ item.date_modified | date: 'dd.MM.YYYY' || '-' }}
      </div>
    </div>
  </div>
</div>
