import { HttpErrorResponse } from '@angular/common/http';

import { UpdateArea } from './section-update';

export type ErrorMessageSection = { errorResponse: HttpErrorResponse | boolean; section: UpdateArea };

export type ValidationErrorDto = {
  detail: string;
  field_template_id?: string;
  location: string;
};

export type ValidationErrorsDto = {
  validation_errors: ValidationErrorDto[];
};

export function errorMessageSectionComparer(previous: ErrorMessageSection, current: ErrorMessageSection): boolean {
  return previous.errorResponse === current.errorResponse && previous.section === current.section;
}

export function isValidationError(errorResponse: HttpErrorResponse | boolean) {
  if (typeof errorResponse === 'boolean') return false;

  const isValidationErrorChecks: ((errorResponse: HttpErrorResponse) => boolean)[] = [
    (errorResponse: HttpErrorResponse) => typeof errorResponse.error?.detail === 'string',
    (errorResponse: HttpErrorResponse) => Array.isArray(errorResponse.error?.validation_errors)
  ];

  return isValidationErrorChecks.some(check => check(errorResponse));
}

export function getValidationErrorFor(errorResponse: HttpErrorResponse, location: string): ValidationErrorDto {
  if (!isValidationError(errorResponse)) return;

  return (errorResponse.error as ValidationErrorsDto).validation_errors?.find(
    (validationError: ValidationErrorDto) => validationError.location === location
  );
}
