import { Component } from '@angular/core';

@Component({
  selector: 'app-file-thumb',
  standalone: true,
  imports: [],
  template: `
    <div class="file-thumb"></div>
  `,
  styleUrls: ['./file-thumb.component.scss']
})
export class FileThumbComponent {}
