import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subnavVisibility',
  standalone: true,
})
export class SubnavVisibilityPipe implements PipeTransform {
  transform(categoryId: string, visibleIds: string[] = []): string {
    return visibleIds.includes(categoryId) ? 'expanded' : '';
  }
}
