<div *ngIf="loginMethod$ | async; let loginMethod">
  <div *ngIf="[LoginMethodType.LOCAL, LoginMethodType.TEST, LoginMethodType.UAT, LoginMethodType.FB].includes(loginMethod)">
    <app-signin-form></app-signin-form>
    <div class="horizontal-line"><a class="horizontal-line__text">or</a></div>
    <app-signin-adamed></app-signin-adamed>
    <app-signup-text></app-signup-text>
  </div>

  <div *ngIf="loginMethod === LoginMethodType.PROD_EXTERNAL">
    <app-signin-form></app-signin-form>
    <app-signup-text></app-signup-text>
  </div>
  <app-signin-adamed *ngIf="loginMethod === LoginMethodType.PROD"></app-signin-adamed>
</div>
